import React from "react";
import Popup from "reactjs-popup";
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import SelectedDeviceResetPopupItem from './SelectedDeviceResetPopupItem';


const contentStyle = {
  maxWidth: "360px",
  width: "90%",
  "z-index": "3"
};


export default function SelectedDeviceResetPopup(param) {

  let pSelectedDeviceList = [];
  let pSelectedDeviceListOrrg = [];
  for ( let i=0;i<param.selectedDeviceList.length;i++ ) {
    pSelectedDeviceList.push(param.selectedDeviceList[i]);
    pSelectedDeviceListOrrg.push(param.selectedDeviceList[i]);
  }

  const [selectedDeviceList,    setSelectedDevice]    = React.useState(pSelectedDeviceList);
  const [selectedDeviceListOrg, setSelectedDeviceOrg] = React.useState(pSelectedDeviceListOrrg);

  const clearAll = () => {
    let wkSelectedDeviceList = [];
    setSelectedDevice([]);
  }


  const removeDevice = deviceNumber => {
    let wkSelectedDeviceList = [];
    for ( let i=0;i<selectedDeviceList.length;i++ ) {
      if ( selectedDeviceList[i] !== deviceNumber) {
        wkSelectedDeviceList.push(selectedDeviceList[i]);
      }
    }
    setSelectedDevice(wkSelectedDeviceList);
  };


  let rowNum = param.rowNum;

  const deviceList = (!selectedDeviceList ? [] : selectedDeviceList).map((item, key) => (
    <SelectedDeviceResetPopupItem deviceNumber={selectedDeviceList[key]} removeDeviceEvent={removeDevice}/>
  ))

  return (

    <Popup
      trigger={<button className="button">Reset</button>}
      contentStyle={contentStyle}
    >
      {close => (
        <div>
          <div className="header">Cancel Selected Devices</div>
          {deviceList}
          <div className="actions">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectedDeviceOrg(selectedDeviceList);
                param.handleResetSelectedDevice(rowNum,selectedDeviceList);
                close();
              }}
              style={{"width":"100px","margin-left":"10px"}}
              startIcon={<SaveIcon />}
            >Reset</Button>,
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                clearAll();
                setSelectedDeviceOrg([]);
                param.handleResetSelectedDevice(rowNum,[]);
                close();
              }}
              style={{"width":"100px","margin-left":"10px"}}
              startIcon={<SaveIcon />}
            >All</Button>,
            <Button
              variant="contained"
              color="default"
              size="small"
              onClick={() => {
                setSelectedDevice(selectedDeviceListOrg);
                param.handleResetSelectedDevice(-1,selectedDeviceList);
                close();
              }}
            style={{"width":"100px","margin-left":"10px"}}
            startIcon={<CloseIcon />}
            >Close</Button>
          </div>
        </div>
      )}
    </Popup>
  );

}