import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    "z-index": "1500 !important",
    "background-color": "none",
  },
  dialogTitle: {
    "min-width": "400px",
    "background-color": "#b0c4de",
    "padding-top": "0.2em",
    height: "1em",
  },
  dialogContents: {
    height: "150px",
  },
}));

export default function InformDialog(pPrams) {
  const params = pPrams;
  const classes = useStyles();

  const handleClose = () => {
    params.informDialogCloseEvent();
  };

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={params.informDialogStatus}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={classes.dialogTitle}
        >
          {params.informDialogTitle}
        </DialogTitle>
        <DialogContent className={classes.dialogContents}>
          <pre>
            <DialogContentText id="alert-dialog-slide-description">
              {params.informDialogMessage}
            </DialogContentText>
          </pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
