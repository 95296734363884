import React from 'react';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles(theme => ({
  labelStyle: {
    'text-align'  : 'left' ,
    'font-weight' : 'bold',
    'padding-top' : '30px'
  },
  textStyle:{
    marginTop  : '1px', 
  },
  radioLabel: {
    'font-size' : '0.8rem'
  }
}));


export default function DeveiStatusRadio(pPrams) {

  const classes = useStyles();
  const params = pPrams;

  let readOnly = params.readOnly;

  return (
    <FormControl>
      <FormLabel component="legend" className={classes.labelStyle}>Device Status</FormLabel>
      <RadioGroup aria-label="position" name="deviceStatus" value={params.radioValue} onChange={params.onChangeEvent} row >
        <FormControlLabel
          value="0"
          control={<Radio color="primary" />}
          label="Available"
          labelPlacement="end"
          checked={params.radioValue==0}
          disabled={readOnly}
        />
        <FormControlLabel
          value="1"
          control={<Radio color="primary" />}
          label="In-Use"
          labelPlacement="end"
          checked={params.radioValue==1}
          disabled={readOnly}
        />
        <FormControlLabel
          value="2"
          control={<Radio color="primary" />}
          label="Broken"
          labelPlacement="end"
          checked={params.radioValue==2}
          disabled={readOnly}
        />
        <FormControlLabel
          value="3"
          control={<Radio color="primary" />}
          label="UnAvailable"
          labelPlacement="end"
          checked={params.radioValue==3}
          disabled={readOnly}
        />
      </RadioGroup>
    </FormControl>
  );
}