import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import HeaderGW from './HeaderGW'
import MenuGWInvoice from './menu/MenuGWInvoice'
import MenuGWExpenditure from './menu/MenuGWExpenditure'
import MenuGWRevenue from './menu/MenuGWRevenue'

import {connect} from 'react-redux';
import {selectMenuItemInvoice} from '../actions';
import {selectMenuItemRevenue} from '../actions';
import {selectMenuItemExpenditure} from '../actions';

class MenuGW extends Component {

  constructor(props) {

    super(props);
    this.state = {
      email: '',
      password: '',
      status:0,
    };

    this.handleChange = this.handleChange.bind(this);

  }


  componentDidMount(prevProps) {
  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  render() {

    return (
      <div className="App" id="div" >
        <HeaderGW login_user_name={this.props.userName} login_user_email={this.props.userEmail} />
        <Grid container spacing={3} style={{marginTop:'5px',marginBottom:'5px',marginTop:'1px','backgroundColor':'#dcdcdc','position':'relative'}} >
          <Grid item xs={12} style={{'display': 'inline-block','float':'center','padding':'5px'}} >
            <Grid container justify="center" spacing={1} style={{paddingBottom:'7px'}}>
              <Grid item style={{paddingBottom:'1px',}}>
                <MenuGWExpenditure selectedMenuIndex={this.props.selectedMenuItemIndexExpenditure} onClickEvent={this.props.selectMenuItemExpenditure} screenTransitEvent={this.props.history}/>
              </Grid>
              <Grid item style={{paddingBottom:'1px',}}>
                <MenuGWRevenue selectedMenuIndex={this.props.selectedMenuItemIndexRevenue} onClickEvent={this.props.selectMenuItemRevenue} screenTransitEvent={this.props.history}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    userName                         : state.login.userName,
    userEmail                        : state.login.userEmail,
    isCorporate                      : state.login.isCorporate,
    isLocation                       : state.login.isLocation,
    admin                            : state.login.admin,
    selectedMenuItemIndexInvoice     : state.menu.selectedMenuItemIndexInvoice,
    selectedMenuItemIndexExpenditure : state.menu.selectedMenuItemIndexExpenditure,
    selectedMenuItemIndexRevenue     : state.menu.selectedMenuItemIndexRevenue,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    selectMenuItemInvoice     : (selectMenuItemIndexInvoice,functionName) => dispatch(selectMenuItemInvoice(selectMenuItemIndexInvoice,functionName)),
    selectMenuItemExpenditure : (selectMenuItemIndexExpenditure,functionName) => dispatch(selectMenuItemExpenditure(selectMenuItemIndexExpenditure,functionName)),
    selectMenuItemRevenue     : (selectMenuItemIndexRevenue,functionName) => dispatch(selectMenuItemRevenue(selectMenuItemIndexRevenue,functionName)),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuGW);