import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const columns = [
  { id: 'doc_number',       label: 'Docu Number',       minWidth: 100,  class: 'docNo',     },
  { id: 'corporate_name',   label: 'Company Name',      minWidth: 200,  align: 'left',      },
  { id: 'name',             label: 'Staff name',        minWidth: 150,  align: 'left',      },
  { id: 'date',             label: 'Date',              minWidth: 100,  align: 'center',    },
  { id: 'accounting_status',label: 'Accounting',        minWidth: 100,  align: 'center',    },
  { id: 'payment_status',   label: 'Payment',           minWidth: 100,  align: 'center',    },
];


let rows = [
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 450,
  },
  docNo : {
    "text-decoration" : "underline",
    "line-height"     : "0.2rem",
    "cursor"          : "hand",
    "cursor"          : "pointer"
  },
  header_cell:{
    "background-color":"#afeeee",
    "line-height":"0.2rem"
  },
  cell:{
  },
});


export default function ExpenditureList(pPrams) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const params = pPrams;
  rows = params.dataRows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.header_cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              const docNo = <input type="hidden" value={row['expenditure_id']}    id="expenditure_id" />;
              const bgcolor   = row['bgcolor'];
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.expenditure_id} style={{backgroundColor:bgcolor}}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} onClick={params.onCellClickEvent} className={ (column.id == 'doc_number') ? classes.docNo : classes.cell } >
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                        {column.id == 'doc_number' ? docNo : ''}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}