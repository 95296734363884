import React, { Component } from 'react';
import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';

import CommonButton from '../../../../common/mirai_component/CommonButton'
import LocationlListTable from './LocationlListTable';
import LocationEditDialog from './LocationEditDialog';

import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';

import {connect} from 'react-redux';


class Location extends Component {

  constructor(props) {

    super(props);

    this.state = {
      searchParamLocation  : '',
      locationList         : [],
      showEditDialog       : false,
      editLocationId       : null,
      pickupMethodList     : [],
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleEditRowClick = this.handleEditRowClick.bind(this);
    this.handleEditDialogClose = this.handleEditDialogClose.bind(this);
    this.handleClickAddNew = this.handleClickAddNew.bind(this);

  }


  componentDidMount(prevProps){

    let requestParam = {};

    fetch('/api/getAllPickupMethod', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let pickupMethodList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let pickupMethodRow = {};
        pickupMethodRow.id    = responseJson[i].pickup_method_id;
        pickupMethodRow.label = responseJson[i].pickup_method;
        pickupMethodList.push(pickupMethodRow);
      }
      this.setState({
        pickupMethodList:pickupMethodList
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  handleClickSearch(){

    let requestParam = {};
    requestParam.location = this.state.searchParamLocation;

    // ユーザ検索
    fetch('/api/searchLocation', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({
        locationList:responseJson
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  handleClickAddNew(event){

    this.setState({
      showEditDialog:true,
      editLocationId:0,
    });

  }


  handleEditRowClick(event){

    if ( event.target.cellIndex === 1 ) {
      let locationId = event.target.children[0].value;
      this.setState({
        showEditDialog:true,
        editLocationId:locationId,
      });
    }

  }


  handleEditDialogClose(){

    this.setState({
      showEditDialog:false,
      editLocationId:null
    });

    this.handleClickSearch();

  }


  render() {

    return (
      <div align="center">
        <Menu history={this.props.history} />
        <ScreenTitle title={this.props.selectedMenuName} />
        <MiraiTextField handleChange={this.handleChange}  readOnly={false}  id="searchParamLocation"   name="searchParamLocation"  label="Location"  value={this.state.searchParamLocation}   errorText={''} />
        <br />
        <CommonButton visible={true}   clickEvent={this.handleClickSearch}   label="Search"   />
        <CommonButton visible={true}   clickEvent={this.handleClickAddNew}   label="Add New"  />

        <LocationlListTable dataRows={this.state.locationList} rowClickEvent={this.handleEditRowClick} />
        <LocationEditDialog showEditDialog={this.state.showEditDialog} handleEditDialogCloseEvent={this.handleEditDialogClose} editLocationId={this.state.editLocationId} pickupMethodList={this.state.pickupMethodList} />
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Location);