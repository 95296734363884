import React, { Component } from 'react';
import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';

import FormLabel from '@material-ui/core/FormLabel';
import CommonCheckbox from '../../../../common/CommonCheckbox';

import CommonButton from '../../../../common/mirai_component/CommonButton'
import UnitPriceListTable from './UnitPriceListTable';
import UnitPriceEditDialog from './UnitPriceEditDialog';

import {connect} from 'react-redux';


class UnitPrice extends Component {

  constructor(props) {

    super(props);

    this.state = {
      plan_list           : [],
      destination_list    : [],
      price_id_list       : [{id:0,label:"Normal"},{id:1,label:"Roam"},{id:2,label:"Special(Klook)"}],
      unitPriceList       : [],
      plan_ids            : [],
      destination_ids     : [],
      price_ids           : [],
      showEditDialog      : false,
      editPlanId          : -1,
      editDestinationId   : -1,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleEditDialogClose = this.handleEditDialogClose.bind(this);
    this.handleClickAddNew = this.handleClickAddNew.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);

  }



  componentDidMount(prevProps) {

    let requestParam = {};

    fetch('/api/getAllPlan', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let planList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let planRow = {};
        planRow.id = responseJson[i].plan_id;
        planRow.label = responseJson[i].plan;
        planList.push(planRow);
      }
      this.setState({
        plan_list:planList
      });
    })
    .catch((error) =>{
      console.error(error);
    });


    fetch('/api/getAllDestination', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let destinationList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let destinationRow = {};
        destinationRow.id = responseJson[i].destination_id;
        destinationRow.label = responseJson[i].destination;
        destinationList.push(destinationRow);
      }
      this.setState({
        destination_list:destinationList
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  handleCheckboxChange(event) {

    const name = event.name;
    const checked_list = [];
    for(const each of event.list){
      if(each.checked){
        checked_list.push(each.id);
      }
    }
    this.setState({ [name] : checked_list});

  }



  handleClickSearch(){

    const search_condition = {
      plan_id        : this.state.plan_ids,
      destination_id : this.state.destination_ids,
      price_id       : this.state.price_ids,
    };

    // 単価検索
    fetch('/api/searchUnitPrice', {
      method: "POST",
      body: JSON.stringify(search_condition), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      this.setState({
        unitPriceList:responseJson
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  handleClickAddNew(event){

    this.setState({
      showEditDialog    : true,
      editPlanId        : 0,
      editDestinationId : 0,
    });

  }



  handleEditDialogClose(){

    this.setState({
      showEditDialog      : false,
      editPlanId          : -1,
      editDestinationId   : -1,
    });

    this.handleClickSearch();

  }



  handleRowClick(event){

    if ( event.target.cellIndex === 3 ) {

      let planId         = event.target.children[0].value;
      let destinationId = event.target.children[1].value;

      this.setState({
        showEditDialog     : true,
        insertData         : false,
        editPlanId         : planId,
        editDestinationId  : destinationId,
      });
    }

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Plan</FormLabel></div>
            <CommonCheckbox name="plan_ids" checkboxValue={this.state.plan_list} onCheckedValue={this.handleCheckboxChange}/>
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Destination</FormLabel></div>
            <CommonCheckbox name="destination_ids" checkboxValue={this.state.destination_list} onCheckedValue={this.handleCheckboxChange}/>
        </div>
        <CommonButton clickEvent={this.handleClickSearch} label="Search"/>
        <CommonButton visible={true}   clickEvent={this.handleClickAddNew}   label="Add New"  />
        <UnitPriceListTable  dataRows={this.state.unitPriceList} rowClickEvent={this.handleRowClick}/>
        <UnitPriceEditDialog editPlanId={this.state.editPlanId} editDestinationId={this.state.editDestinationId} showEditDialog={this.state.showEditDialog} handleEditDialogCloseEvent={this.handleEditDialogClose} planList={this.state.plan_list} destinationList={this.state.destination_list} priceIdList={this.state.price_id_list} />
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitPrice);