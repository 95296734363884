import React from "react";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles((theme) => ({
  labelStyle: {
    "text-align": "left",
    "font-weight": "bold",
    "padding-top": "30px",
  },
  textStyle: {
    marginTop: "1px",
  },
  radioLabel: {
    "font-size": "0.8rem",
  },
}));

export default function CommonRadio(pPrams) {
  const classes = useStyles();
  const params = pPrams;

  let readOnly = params.readOnly;
  let visible = params.visible;

  const list = (!params.radioList ? [] : params.radioList).map((item, key) => (
    <FormControlLabel
      value={item.id}
      control={<Radio color="primary" />}
      label={item.label}
      labelPlacement="end"
      checked={params.radioValue == item.id}
      disabled={readOnly}
      key={item.id}
    />
  ));

  if (visible == false) {
    return <FormControl></FormControl>;
  } else {
    return (
      <FormControl error={!!params.errorText ? true : false}>
        <FormLabel component="legend" className={classes.labelStyle}>
          {params.caption}
        </FormLabel>
        <RadioGroup
          aria-label="position"
          name={params.name}
          value={params.radioValue}
          onChange={params.onChangeEvent}
          row
        >
          {list}
        </RadioGroup>
        {!!params.errorText && (
          <FormHelperText>{params.errorText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}
