import React, { Component } from 'react';
import checkSessionStatus from '../../../common/CheckSession'
import ExpenditureList from './ExpenditureList';
import InformDialog from '../../../common/mirai_component/InformDialog'
import CommonSelect from '../../../common/CommonSelect'


import MenuGW from '../../../common/MenuGW';
import ScreenTitle from '../../../common/ScreenTitle';

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import CommonCheckbox from '../../../common/CommonCheckbox';

import CommonButton from '../../../common/mirai_component/CommonButton'
import UpdateLogDialog from '../../../common/UpdateLogDialog'

import {connect} from 'react-redux';
import * as constVal from '../../../common/constant';
import * as commonFunction from '../../../common/commonFunction';

import const_val from '../../../const';

class SummaryExpenditure extends Component {

  constructor(props) {

    super(props);
    this.state = {
      expenditure_year_month : '',
      dataRows               : [],
      destination_list       : [],
      showUpdateLogDialog    : false,
      selectedExpenditureId  : 0,
      user_id                : null,
      informDialogOpen       : false,
      informDialogTitle      : '',
      informDialogMessage    : '',
      groundTotal            : '0',
      totalNotPayed          : '0',
      totalPayed             : '0',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.closeUpdateLogDialogEvent = this.closeUpdateLogDialogEvent.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }



  componentDidMount(prevProps) {
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  closeUpdateLogDialogEvent(){
    this.setState({showUpdateLogDialog:false});
  }



  // 一覧のCellClick時
  onCellClick(event){
  
    let rowIndex = event.target.parentElement.rowIndex;
    let cellIndex = event.target.cellIndex;
    let keyVal = event.target.parentElement.children[0].textContent;

    if ( cellIndex == 0 ) {
      var expenditure_id = event.target.parentElement.querySelector("#expenditure_id").value;
      this.props.history.push('/entryExpenditure/' + expenditure_id);
    }

  }



  handleCheckboxChange(event) {

    const name = event.name;
    const checked_list = [];
    for(const each of event.list){
      if(each.checked){
        checked_list.push(each.id);
      }
    }
    this.setState({ [name] : checked_list});

  }

  

  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

  }



  // 検索ボタン押下時の処理
  handleClickSearch(){
 
    // Expenditure検索API
    const search_condition = {
      expenditure_year_month : this.state.expenditure_year_month,
    };
    fetch('/api/summaryExpenditure', {
      method: "POST",
      body: JSON.stringify(search_condition), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);


      let groundTotal = 0;
      let totalNotPayed = 0;
      let totalPayed = 0;

      let resultDate = [];
      for ( let i=0;i<responseJson.length;i++ ) {

        let resultRow = {};

        resultRow['corporate_name'] = !responseJson[i]['m20_corporate'] ? '' : responseJson[i]['m20_corporate']['corporate_name'];
        resultRow['doc_number'] = responseJson[i]['doc_number'];
        const expenditureDate = new Date(responseJson[i]['date']);
        const expenditureDateString = expenditureDate.getFullYear() + "-" + ("0" + (expenditureDate.getMonth()+1)).slice(-2) + "-" + ("0" + (expenditureDate.getDate())).slice(-2)
        resultRow['date'] = expenditureDateString;

        if ( responseJson[i]['payment_status'] === 0 ) {
          resultRow['bgcolor'] = 'pink'; 
        }
        else if ( responseJson[i]['payment_status'] === 1 ) {
          resultRow['bgcolor'] = 'lightcyan'; 
        }
        else {
          resultRow['bgcolor'] = 'darkgray'; 
        }

        resultRow['name'] = responseJson[i]['m10_user']['name'];

        if ( responseJson[i]['payment_status'] === 0 ) {
          resultRow['payment_status'] = 'Not Payed';
        }
        else if ( responseJson[i]['payment_status'] === 1 ) {
          resultRow['payment_status'] = 'Payed';
        }
        else {
          resultRow['payment_status'] = 'Cancelled';
        }
        resultRow['accounting_status'] = commonFunction.getKeyByValue(const_val.accounting_status, responseJson[i]['accounting_status']);
        resultRow['expenditure_id'] = responseJson[i]['expenditure_id'];

        groundTotal += Number(responseJson[i]['total_price']);

        if ( resultRow['payment_status'] === 'Not Payed' ) {
          totalNotPayed += Number(responseJson[i]['total_price']);
        }

        if ( resultRow['payment_status'] === 'Payed' ) {
          totalPayed += Number(responseJson[i]['total_price']);
        }

        resultDate.push(resultRow);
      }

      this.setState({
        dataRows      : resultDate,
        groundTotal   : groundTotal.toLocaleString(),
        totalNotPayed : totalNotPayed.toLocaleString(),
        totalPayed    : totalPayed.toLocaleString()
      });

      if ( responseJson.length === 0 ) {

        this.setState({
          informDialogTitle   : "Search Expenditure Result",
          informDialogMessage : "No data found.",
          informDialogOpen    : true,
          groundTotal         : 0,
          totalNotPayed       : 0,
          totalPayed          : 0,
        });

      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  render() {

    return (
      <div align="center">
        <MenuGW history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Expenditure Year-Month</FormLabel></div>
            <TextField onChange={this.handleChange} id="expenditure_year_month" name="expenditure_year_month" style={{width: "200px"}} type="month" /> 
        </div>

        <br />

        <CommonButton clickEvent={this.handleClickSearch} label="Search expenditure"/>

        <br />

        <ExpenditureList dataRows={this.state.dataRows} onCellClickEvent={this.onCellClick}/>


        <br />
        <TextField readOnly={true} label="Ground Total" id="groundTotal" name="groundTotal" style={{width: "200px"}} value={this.state.groundTotal} /> 
        &nbsp;&nbsp;&nbsp;
        <TextField readOnly={true} label="Total(Not Payed)" id="totalNotPayed" name="totalNotPayed" style={{width: "200px"}} value={this.state.totalNotPayed} /> 
        &nbsp;&nbsp;&nbsp;
        <TextField readOnly={true} label="Total(Payed)" id="totalPayed" name="totalPayed" style={{width: "200px"}} value={this.state.totalPayed} /> 

        <UpdateLogDialog showUpdateLogDialog={this.state.showUpdateLogDialog} closeUpdateLogDialogEvent={this.closeUpdateLogDialogEvent} selectedExpenditureId={this.state.selectedExpenditureId} selectedExpenditureId={this.state.selectedExpenditureId}/>
        <InformDialog informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryExpenditure);