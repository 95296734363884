import React from 'react';
import { render } from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App/App';


import {createStore} from 'redux';
import {Provider} from 'react-redux';

import reducer from './reducers';
import persistState from "redux-localstorage";


// localstorageに状態を格納
const store = createStore(reducer,persistState());


render((
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));

