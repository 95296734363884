import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const columns = [
  { id: 'service_type',     label: 'Service',       minWidth: 100,  align: 'left',      },
  { id: 'year',             label: 'Year',          minWidth: 100,  align: 'left',      },
  { id: 'month',            label: 'Month',         minWidth: 100,  align: 'left',      },
  { id: 'corporate_name',   label: 'Company Name',  minWidth: 200,  class: '_id',     },
  { id: 'revenue_price',    label: 'Revenue (VND)', minWidth: 100,  align: 'center',    },
  { id: 'vat_price',        label: 'VAT',           minWidth: 100,  align: 'center',    },
  { id: 'total_price',    	label: 'Total',         minWidth: 100,  align: 'center',    },
  { id: 'total_price_vat',	label: 'Total with VAT',minWidth: 100,  align: 'center',    },
  { id: 'note',             label: 'Note',          minWidth: 100,  align: 'center',    },
];


let rows = [
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 450,
  },
  _id : {
    "text-decoration" : "underline",
    "line-height"     : "0.2rem",
    "cursor"          : "hand",
    "cursor"          : "pointer"
  },
  header_cell:{
    "background-color":"#afeeee",
    "line-height":"0.2rem"
  },
  cell:{
  },
});


export default function RevenueList(pPrams) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const params = pPrams;
  rows = params.dataRows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.header_cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              const _id = <input type="hidden" value={row['revenue_id']}    id="revenue_id" />;
              const bgcolor   = row['bgcolor'];
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.revenue_id} style={{backgroundColor:bgcolor}}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} onClick={params.onCellClickEvent} className={ (column.id == 'corporate_name') ? classes._id : classes.cell } >
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                        {column.id == 'corporate_name' ? _id : ''}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}