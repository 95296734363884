import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import Menu from '../../../common/Menu';
import ScreenTitle from '../../../common/ScreenTitle';
import MiraiTextField from '../../../common/mirai_component/MiraiTextField';
import SearchCorporate from '../../../common/SearchCorporate';
import ItemListTable from './ItemListTable';

import CommonSelect from '../../../common/CommonSelect'
import CommonButton from '../../../common/mirai_component/CommonButton'
import InformDialog from '../../../common/mirai_component/InformDialog'
import ConfirmDialog from '../../../common/mirai_component/ConfirmDialog'
import RefundDialog from './RefundDialog';

import {connect} from 'react-redux';
import * as constVal from '../../../common/constant';
import CommonRadio from '../../../common/CommonRadio';
import DeviceAssignmentDialog from './DeviceAssignmentDialog';
import ExtendDialog from './ExtendDialog';
import * as commonFunction from '../../../common/commonFunction';

import UpdateLogDialog from '../../../common/UpdateLogDialog'
import ApplyInfoDialog from './ApplyInfoDialog';

class bookingCorporate extends Component {

  constructor(props) {

    super(props);

    this.state = {
      functionName            : 'BookingCorporate', 
      bookingStatus           : '',
      bookingId               : '',
      applyNumber             : '',
      email                   : '',
      password                : '',
      corporateId             : '',
      userName                : '',
      invoiceNote             : '',
      contractType            : 0,
      corporateName           : '',
      address                 : '',
      taxCode                 : '',
      vatNumber               : '',
      isLongTermUser          : 0,
      discountRate            : 0,
      webDiscountRate         : 0,
      pickupMethodId          : 0,
      pickupMethodLabel       : '',
      pickupMethodAddress     : '',
      returnMethodId          : 0,
      returnMethodLabel       : '',
      returnMethodAddress     : '',
      paymentMethod           : 1,
      paymentStatus           : 0 ,
      itemList                : [],
      rentalFee               : 0,
      rentalFeeOrg            : 0,
      deliveryFee             : 0,
      discountAmount          : 0,
      vat                     : 0,
      totalFee                : 0,
      personInCharge          : 0,
      campaignId              : null,
      userList                : [],
      campaignList            : [],
      campaignMasterData      : [],
      updatedAt               : '',
      deviceAssign            : 0,
      deviceAssigned          : false,
      invoiceIssueRequestId   : null,
      priceId                 : 0,
      orderRoute              : 0,
      errorMsg:{
        contractType        : '',
        corporateName       : '',
        userName            : '',
        invoiceNote         : '',
        address             : '',
        taxCode             : '',
        vatNumber           : '',
        rentalFee           : '',
        deliveryFee         : '',
        discountAmount      : '',
        vat                 : '',
        totalFee            : '',
        pickupMethodAddress : '',
        returnMethodAddress : '',
        itemList            : '',
        errMsg              : '',
        paymentMethod       : '',
        personInCharge      : '',
      },
      informDialogOpen      : false,
      informDialogTitle     : '',
      informDialogMessage   : '',
      confirmDialogOpen     : false,
      confirmDialogTitle    : '',
      confirmDialogMessage  : '',
      confirmDialogContinue : false,
      readOnlyProps:{
        vat                 : false,
        itemList            : false,
        corporate           : false,
        userName            : false,
        invoiceNote         : false,
        pickupMethodId      : false,
        returnMethodId      : false,
        pickupMethodAddress : false,
        deviceAssign        : false,
        paymentMethod       : false,
        discountAmount      : false,
        personInCharge      : false,
        campaignId          : false,
      },
      visibleProps:{
        pickupMethodAddress : true,
        saveButton          : true,
        cancelButton        : false,
        extendButton        : false,
        deviceAssign        : true,
        paymentMethod       : true,
        refundButton        : false,
        personInCharge      : true,
      },
      planList                   : [{"1":"dummy"}],
      destinationList            : [{'0':{"1":"dummy"}}],
      countryList                : [{"1":"dummy"}],
      countryMasterData          : [],
      countryByDestination       : [],
      unitPriceTable             : [],
      pickupMethodTable          : [],
      isInputDeliveryDate        : false,
      isInputReturnDate          : false,
      longTermUserPickupMethodId : 0,
      deliveryPickupMethodId     : [],
      deviceAssignList:[
        { id : '0' , label : 'Assign Later'},
        { id : '1' , label : 'Assign Now'},
      ],
      paymentMethodList :[
        { id : '0' , label : 'Cash'},
        { id : '1' , label : 'Bank Transfer'},
        { id : '2' , label : 'Credit Card'},
      ],
      showDeviceAssignmentDialog : false,
      showExtendDialog           : false,
      extendToList               : [],
      bookingDetailsOrg          : [],
      showRefundDialog           : false,
      showUpdateLogDialog        : false,
      selectedBookingId          : '',
      selectedApplyNumber        : '',
      showApplyInfoDialog        : false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSelectCorporate = this.onSelectCorporate.bind(this);
    this.handleChangePickupMethod = this.handleChangePickupMethod.bind(this);
    this.handleChangeReturnMethod = this.handleChangeReturnMethod.bind(this);
    this.handleChangeItemList = this.handleChangeItemList.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.calcTotal = this.calcTotal.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.closeDeviceAssignmentDialogEvent = this.closeDeviceAssignmentDialogEvent.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.handleClickExtend = this.handleClickExtend.bind(this);
    this.closeExtendDialogEvent = this.closeExtendDialogEvent.bind(this);
    this.handleChangeDiscountAmount = this.handleChangeDiscountAmount.bind(this);
    this.handleChangeCampaign = this.handleChangeCampaign.bind(this);
    this.confirmDialogCloseEventCancel = this.confirmDialogCloseEventCancel.bind(this);
    this.confirmDialogCloseEventContinue = this.confirmDialogCloseEventContinue.bind(this);
    this.handleClickRefund = this.handleClickRefund.bind(this);
    this.closeRefundDialogEvent = this.closeRefundDialogEvent.bind(this);
    this.showApplyInfo = this.showApplyInfo.bind(this);
    this.showLog = this.showLog.bind(this);
    this.closeUpdateLogDialogEvent = this.closeUpdateLogDialogEvent.bind(this);
    this.closeApplyInfoDialogEvent = this.closeApplyInfoDialogEvent.bind(this);

    const applyNumber = props.match.params.applyNumber;
    // 一覧からの遷移時
    if ( applyNumber !== undefined && applyNumber !== '' ) {
      this.state.applyNumber = applyNumber;
    }
    // 新規作成
    else {
      this.state.bookingStatus = 0;
    }

  }



  componentDidMount(prevProps) {

    let applyNumberParam = "";
    if ( this.state.applyNumber !== undefined && this.state.applyNumber !== '' ) {
      applyNumberParam = "/" + this.state.applyNumber;
    }

    // bookingデータ取得
    if ( this.state.applyNumber !== undefined && this.state.applyNumber !== '' ) {

      const reqJson = { 'apply_number' : this.state.applyNumber };

      // Booking検索
      fetch('/api/searchBooking', {
        method: "POST",
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqJson)
      })
      .then((response) => response.json())
      .then((responseJson) => {

        // 個人だったら -> 個人用のページへ飛ばす
        if ( responseJson[0].contract_type === 1 ) {
          this.props.history.push('/bookingPersonal' + applyNumberParam);
        }


        // BookingDetail情報
        let detailRows = [];
        let bookingDetailsOrg = [];

        // 明細ごとのステータスで最も進んでいるもの
        let maxSubStatus = 0;
        // 明細ごとのステータスで最も進んでいないもの
        let minSubStatus = 900;
        // Extendボタン表示(使用中の明細がある場合のみExtendボタンは押せる)
        let showExtendButton = false;

        for ( let i=0;i<responseJson[0]["t20_booking_details"].length;i++ ) {

          if ( responseJson[0]["t20_booking_details"][i].booking_sub_status > maxSubStatus ) {
            maxSubStatus = responseJson[0]["t20_booking_details"][i].booking_sub_status;
          }
          if ( minSubStatus > responseJson[0]["t20_booking_details"][i].booking_sub_status ) {
            minSubStatus = responseJson[0]["t20_booking_details"][i].booking_sub_status;
          }
          if ( responseJson[0]["t20_booking_details"][i].booking_sub_status == 600 || responseJson[0]["t20_booking_details"][i].booking_sub_status == 700 ) {
            showExtendButton = true;
          }


          let detailRow = {};

          detailRow.row_id                = (i+1);
          detailRow.bookingDetailId       = responseJson[0]["t20_booking_details"][i].booking_detail_id;
          detailRow.deliveryDate          = responseJson[0]["t20_booking_details"][i].delivery_date;
          detailRow.startDate             = responseJson[0]["t20_booking_details"][i].start_date;
          detailRow.endDate               = responseJson[0]["t20_booking_details"][i].end_date;
          detailRow.returnDate            = responseJson[0]["t20_booking_details"][i].return_date;
          detailRow.planId                = responseJson[0]["t20_booking_details"][i].plan_id;
          detailRow.plan                  = '';  // 名称は後で（Validation時）にセット 
          detailRow.destinationId         = responseJson[0]["t20_booking_details"][i].destination_id;
          detailRow.destination           = '';  // 名称は後で（Validation時）にセット 
          detailRow.countryId             = responseJson[0]["t20_booking_details"][i].country_id;
          detailRow.country               = '';  // 名称は後で（Validation時）にセット 
          detailRow.quantity              = responseJson[0]["t20_booking_details"][i].quantity;
          detailRow.price                 = responseJson[0]["t20_booking_details"][i].price;
          detailRow.unitPrice             = responseJson[0]["t20_booking_details"][i].unit_price;
          detailRow.isTaxInclude          = responseJson[0]["t20_booking_details"][i].is_tax_include;
          detailRow.isExtended            = responseJson[0]["t20_booking_details"][i].is_extended;
          detailRow.bookingSubStatus      = responseJson[0]["t20_booking_details"][i].booking_sub_status;
          detailRow.bookingSubStatusLabel = commonFunction.getBookingStatusNameById(responseJson[0]["t20_booking_details"][i].booking_sub_status);

          // Assign済みDevice情報
          // bookingDetailRow ← これは不要 
          //    itemList = detailRows に全ての情報を格納する。
          //    更新前情報も一緒に格納する
          //    DeviceAssignmentDialogは、このitemListから描画
          //    apiでは、orgDevices / newDevicesを比較してDB更新
          //    明細のステータスによってDeviceAssignmentDialogでの表示をomitt
          //    usingまで進んでしまったら表示しない、ReadyForSend以前の明細のDeviceの選択が変更されたらStatusを戻す
          //    選択解除も出来るようにする

          let deviceNumber = '';
          let deviceNumberOrg = '';
          let deviceNumnberList = [];
          let deviceNumnberListOrg = [];
          let selectedDevicesUpdatedAtList = [];
          let selectedDevicesUpdatedAtListOrg = [];

          for ( let j=0;j<responseJson[0]["t20_booking_details"][i]["t30_booking_detail_devices"].length;j++ ) {

            deviceNumnberList.push(responseJson[0]["t20_booking_details"][i]['t30_booking_detail_devices'][j].device_number);
            deviceNumnberListOrg.push(responseJson[0]["t20_booking_details"][i]['t30_booking_detail_devices'][j].device_number);

            selectedDevicesUpdatedAtList.push(responseJson[0]["t20_booking_details"][i]['t30_booking_detail_devices'][j]["m70_device"].updat);
            selectedDevicesUpdatedAtListOrg.push(responseJson[0]["t20_booking_details"][i]['t30_booking_detail_devices'][j]["m70_device"].updat);

            if ( deviceNumber !== '' ) {
              deviceNumber += ' / ';
              deviceNumberOrg += ' / ';
            }
            deviceNumber += responseJson[0]["t20_booking_details"][i]['t30_booking_detail_devices'][j].device_number;
            deviceNumberOrg += responseJson[0]["t20_booking_details"][i]['t30_booking_detail_devices'][j].device_number;

          }

          detailRow.selectedDevices = deviceNumnberList;
          detailRow.selectedDevicesUpdatedAt = selectedDevicesUpdatedAtList;
          detailRow.selectedDevicesOrg = deviceNumnberListOrg;
          detailRow.selectedDevicesOrgUpdatedAt = selectedDevicesUpdatedAtListOrg;

          detailRow.deviceNumber     = deviceNumber;     // DeviceAssignDialogで選択済みのDeviceをまとめて表示するようのカラム
          detailRow.deviceNumberOrg  = deviceNumberOrg;  // DeviceAssignDialogで選択済みのDeviceをまとめて表示するようのカラム

          detailRows.push(detailRow);
          bookingDetailsOrg.push(Object.assign({}, JSON.parse(JSON.stringify(detailRow))) );

        }

        // -------------------
        // Statusによる画面制御
        // -------------------
        const readOnlyProps = Object.assign({}, this.state.readOnlyProps);
        const visibleProps = Object.assign({}, this.state.visibleProps);

        visibleProps.cancelButton = true;
 
        // 保存ボタン
        // 最も進んでいるいない明細のステータスが「使用中」より進んでいたら（全てが既に使用中だったら）、もう更新は出来ない
        // 既に全体がキャンセルされていたら、更新は出来ない
        if ( minSubStatus >= 600 || responseJson[0].booking_status == 900 ) {
          visibleProps.saveButton = false;
        }

        // キャンセルボタン
        // 最も進んでいる明細のステータスが「使用中」より進んでいたら（少なくとも１つが既に使用中だったら）、もうキャンセルは出来ない
        // 既に全体がキャンセルされていたら、キャンセルは出来ない
        if ( maxSubStatus >= 600 || responseJson[0].booking_status == 900 ) {
          visibleProps.cancelButton = false;
        }

        // DevicceAssignボタン
        // 最も進んでいるいない明細のステータスが「DeviceAssign済み」より進んでいたら（全てが既がDeviceAssign済みだったら）、もうDeviceAssignは出来ない（変更不可）
        if ( minSubStatus >= 300 ) {
          readOnlyProps.deviceAssign = true;
        }

        // 会社、受け取り方法、返却方法
        // 最も進んでいるいない明細のステータスが「DeviceAssign済み」より進んでいたら（全てが既がDeviceAssign済みだったら）、もう変更できない
        if ( minSubStatus >= 300 ) {
          readOnlyProps.corporate      = true;
          readOnlyProps.userName       = true;
          readOnlyProps.invoiceNote    = true;
          readOnlyProps.pickupMethodId = true;
          readOnlyProps.returnMethodId = true;
          readOnlyProps.personInCharge = true;
          readOnlyProps.campaignId     = true;
        }

        // Extendボタン - 使用中の明細がある場合のみExtendボタンは押せる
        visibleProps.extendButton = showExtendButton;

        // Refundボタン
        // 最も進んでいる明細のステータスが[Using]より進んでいいたら、Refund出来る
        if ( minSubStatus >= 600 ) {
          visibleProps.refundButton = true;
        }

        // LongTermUser向け処理
        // LongTermUserは、PickUpMethod/ReturnMethodは[Long Term User]固定
        let pickupMethodLabel = '';
        let returnMethodLabel = '';
        let isLongTermUser = false;

        if ( responseJson[0]['m20_corporate'].is_long_term_user == 1 ) {

          isLongTermUser = true;

        }


        // DeviceAssignのRadio選択肢
        let deviceAssignList = this.state.deviceAssignList;
        let deviceAssign = 0;
        // 最も進んでいない明細が既に「使用中」だったらDeviceAssign不要
        if ( maxSubStatus >= 600 ) {

          deviceAssign = 2;
          readOnlyProps.deviceAssign = true;

          deviceAssignList = [
            { id : '0' , label : 'Assign Later'},
            { id : '1' , label : 'Assign Now'},
            { id : '2' , label : 'Assigned'},
          ]

        }


        this.setState({
          bookingStatus           : responseJson[0].booking_status,
          bookingId               : responseJson[0].booking_id,
          applyNumber             : responseJson[0].apply_number,
          corporateId             : responseJson[0].corporate_id,
          userName                : responseJson[0].user_name,
          invoiceNote             : responseJson[0].invoice_note,
          contractType            : responseJson[0].contract_type,
          corporateName           : responseJson[0]['m20_corporate'].corporate_name,
          address                 : responseJson[0]['m20_corporate'].address,
          discountRate            : responseJson[0]['m20_corporate'].discount_rate,
          webDiscountRate         : responseJson[0]['m20_corporate'].web_discount_rate,
          pickupMethodId          : responseJson[0].pickup_method_id,
          pickupMethodLabel       : pickupMethodLabel,
          pickupMethodAddress     : responseJson[0].pickup_method_address,
          returnMethodId          : responseJson[0].return_method_id,
          returnMethodLabel       : returnMethodLabel,
          returnMethodAddress     : responseJson[0].return_method_address,
          paymentMethod           : responseJson[0].payment_method,
          paymentStatus           : responseJson[0].payment_status,
          rentalFee               : responseJson[0].rental_fee,
          rentalFeeOrg            : responseJson[0].rental_fee,
          deliveryFee             : responseJson[0].delivery_fee,
          discountAmount          : responseJson[0].discount_amount,
          vat                     : responseJson[0].vat,
          totalFee                : responseJson[0].total_fee,
          taxCode                 : responseJson[0]['m20_corporate'].tax_code,
          vatNumber               : responseJson[0]['m20_corporate'].vat_number,
          isLongTermUser          : responseJson[0]['m20_corporate'].is_long_term_user,
          personInCharge          : responseJson[0].person_in_charge,
          campaignId              : responseJson[0].campaign_id,
          itemList                : detailRows,
          readOnlyProps           : readOnlyProps,
          visibleProps            : visibleProps,
          updatedAt               : responseJson[0].updatedAt,
          deviceAssign            : deviceAssign,
          deviceAssignList        : deviceAssignList,
          deviceAssigned          : false,
          bookingDetailsOrg       : bookingDetailsOrg,
          invoiceIssueRequestId   : responseJson[0]['t100'] !== null ?  responseJson[0]['t100'].invoice_issue_request_id : null,
          priceId                 : responseJson[0]['m20_corporate'].price_id,
          orderRoute              : responseJson[0].order_route,
        });


        fetch('/api/getAllPickupMethod', {
          method: "POST",
          body: JSON.stringify(requestParam), 
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((responseJson) => {
    
          const readOnlyProps = Object.assign({}, this.state.readOnlyProps);
          const visibleProps = Object.assign({}, this.state.visibleProps);
          let pickupMethodTable = [];
          let deliveryPickupMethodId = [];
          let longTermUserPickupMethodId = 0;
    
          for ( let i=0;i<responseJson.length;i++ ) {
    
            if ( responseJson[i].is_delivery === 0 ) {
              deliveryPickupMethodId.push(responseJson[i].pickup_method_id);
            }
            else if ( responseJson[i].is_delivery === 2 ) {
              longTermUserPickupMethodId = responseJson[i].pickup_method_id;
            }
    
            // LongTermUserは選択肢から除外
            // LongTermUserの場合は追加
            if ( isLongTermUser || responseJson[i].is_delivery !== 2 ) {
              let pickupMethodRow = {}
              pickupMethodRow.id = responseJson[i].pickup_method_id;
              pickupMethodRow.label = responseJson[i].pickup_method;
              pickupMethodRow.isDelivery = responseJson[i].is_delivery;
              pickupMethodTable.push(pickupMethodRow);
            }
    
            if ( this.state.pickupMethodId == responseJson[i].pickup_method_id ) {
              if ( responseJson[i].is_delivery == 1 || responseJson[i].is_delivery == 2 ) {
                visibleProps.deviceAssign = false;
              }
            }
          }
    
          let isInputDeliveryDate = false;
          readOnlyProps.pickupMethodAddress = false;
          let pickupMethodId = this.state.pickupMethodId;
          for ( let i=0;i<pickupMethodTable.length;i++ ) {
            if ( pickupMethodTable[i].id == pickupMethodId ) {
              if ( pickupMethodTable[i].isDelivery == 0 ) {
                isInputDeliveryDate = true;
              }
              else {
                readOnlyProps.pickupMethodAddress = true;
                isInputDeliveryDate = false;
              }
              break;
            }
          }
    
          let isInputReturnDate = false;
          let returnMethodId = this.state.returnMethodId;
          for ( let i=0;i<pickupMethodTable.length;i++ ) {
            if ( pickupMethodTable[i].id == returnMethodId ) {
              if ( pickupMethodTable[i].isDelivery == 0 ) {
                isInputReturnDate = true;
              }
              else {
                isInputReturnDate = false;
              }
              break;
            }
          }
    
          this.setState({
            pickupMethodTable:pickupMethodTable,
            isInputDeliveryDate:isInputDeliveryDate,
            isInputReturnDate:isInputReturnDate,
            readOnlyProps:readOnlyProps,
            visibleProps:visibleProps,
            longTermUserPickupMethodId:longTermUserPickupMethodId,
            deliveryPickupMethodId:deliveryPickupMethodId
          });
    
        })
        .catch((error) =>{
          console.error(error);
        });

      })
      .catch((error) =>{
        console.error(error);
      });

    }
    else {

      let requestParam = {};
      fetch('/api/getAllPickupMethod', {
        method: "POST",
        body: JSON.stringify(requestParam), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
  
        const readOnlyProps = Object.assign({}, this.state.readOnlyProps);
        const visibleProps = Object.assign({}, this.state.visibleProps);
        let pickupMethodTable = [];
        let deliveryPickupMethodId = [];
        let longTermUserPickupMethodId = 0;
  
        for ( let i=0;i<responseJson.length;i++ ) {
  
          if ( responseJson[i].is_delivery === 0 ) {
            deliveryPickupMethodId.push(responseJson[i].pickup_method_id);
          }
          else if ( responseJson[i].is_delivery === 2 ) {
            longTermUserPickupMethodId = responseJson[i].pickup_method_id;
          }
  
          // LongTermUserは除外
          if ( responseJson[i].is_delivery !== 2 ) {
            let pickupMethodRow = {}
            pickupMethodRow.id = responseJson[i].pickup_method_id;
            pickupMethodRow.label = responseJson[i].pickup_method;
            pickupMethodRow.isDelivery = responseJson[i].is_delivery;
            pickupMethodTable.push(pickupMethodRow);
          }
  
          if ( this.state.pickupMethodId == responseJson[i].pickup_method_id ) {
            if ( responseJson[i].is_delivery == 1 ) {
              visibleProps.deviceAssign = false;
            }
          }
        }
  
        let isInputDeliveryDate = false;
        readOnlyProps.pickupMethodAddress = false;
        let pickupMethodId = this.state.pickupMethodId;
        for ( let i=0;i<pickupMethodTable.length;i++ ) {
          if ( pickupMethodTable[i].id == pickupMethodId ) {
            if ( pickupMethodTable[i].isDelivery == 0 ) {
              isInputDeliveryDate = true;
            }
            else {
              readOnlyProps.pickupMethodAddress = true;
              isInputDeliveryDate = false;
            }
            break;
          }
        }
  
        let isInputReturnDate = false;
        let returnMethodId = this.state.returnMethodId;
        for ( let i=0;i<pickupMethodTable.length;i++ ) {
          if ( pickupMethodTable[i].id == returnMethodId ) {
            if ( pickupMethodTable[i].isDelivery == 0 ) {
              isInputReturnDate = true;
            }
            else {
              isInputReturnDate = false;
            }
            break;
          }
        }
  
        this.setState({
          pickupMethodTable:pickupMethodTable,
          isInputDeliveryDate:isInputDeliveryDate,
          isInputReturnDate:isInputReturnDate,
          readOnlyProps:readOnlyProps,
          visibleProps:visibleProps,
          longTermUserPickupMethodId:longTermUserPickupMethodId,
          deliveryPickupMethodId:deliveryPickupMethodId
        });
  
      })
      .catch((error) =>{
        console.error(error);
      });

      this.setState({
        personInCharge : this.props.id
      })

    }


    let requestParam = {};

    fetch('/api/getAllPlan', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let planList = {};
      for ( let i=0;i<responseJson.length;i++ ) {
        planList[responseJson[i].plan_id] = responseJson[i].plan;
      }
      this.setState({
        planList:  planList
      });
    })
    .catch((error) =>{
      console.error(error);
    });


    fetch('/api/getAllCountry', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let countryList = {};
      let countryByDestination = {};
      for ( let i=0;i<responseJson.length;i++ ) {
        countryList[responseJson[i].country_id] = responseJson[i].name;
        if (!countryByDestination[responseJson[i].destination_id]) {
          countryByDestination[responseJson[i].destination_id] = {};
        }
        countryByDestination[responseJson[i].destination_id][responseJson[i].country_id] = responseJson[i].name;
      }
      this.setState({
        countryList          : countryList,
        countryMasterData    : responseJson,
        countryByDestination : countryByDestination
      });

    })
    .catch((error) =>{
      console.error(error);
    });


    fetch('/api/getAllDestination', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let destinationList = {};

      // Normal/roma/Klookそれぞれ用のDestinationList
      destinationList['0'] = {};
      destinationList['1'] = {};
      destinationList['2'] = {};

      for ( let i=0;i<responseJson.length;i++ ) {
        destinationList[responseJson[i].price_id+''][responseJson[i].destination_id] = responseJson[i].destination;
      }

      this.setState({
        destinationList:destinationList
      });

    })
    .catch((error) =>{
      console.error(error);
    });


    fetch('/api/getAllUnitPrice', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({
        unitPriceTable:responseJson
      });
    })
    .catch((error) =>{
      console.error(error);
    });


    fetch('/api/getUserList',{
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      let userList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let userListRow = {}
        userListRow.id = responseJson[i].id;
        userListRow.label = responseJson[i].name;
        userList.push(userListRow);
      }
      this.setState({
        userList : userList,
      });
    })
    .catch((error) =>{
      console.error(error);
    });


    fetch('/api/getAllCampaign',{
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let campaignList = [];
      let campaignListRowBlank = {}
      campaignListRowBlank.id    = null;
      campaignListRowBlank.label = ' --- ';
      campaignList.push(campaignListRowBlank);

      for ( let i=0;i<responseJson.length;i++ ) {
        let campaignListRow = {}
        campaignListRow.id    = responseJson[i].campaign_id;
        campaignListRow.label = responseJson[i].campaign_title;
        campaignList.push(campaignListRow);
      }

      this.setState({
        campaignList       : campaignList,
        campaignMasterData : responseJson,
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // 会社選択時の処理
  onSelectCorporate(corporate_id) {

    const reqJson = { 'corporate_id' : corporate_id };

    // 会社検索
    fetch('/api/searchCorporateById', {
      method: "POST",
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqJson)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if ( responseJson.length === 1 ) {

        const errorMsg = Object.assign({}, this.state.errorMsg);
        errorMsg.corporateName = '';

        const readOnlyProps = Object.assign({}, this.state.readOnlyProps);
        const visibleProps = Object.assign({}, this.state.visibleProps);

        let pickupMethodLabel = '';
        let returnMethodLabel = '';

        let pickupMethodTable = this.state.pickupMethodTable;
        let pickupMethodTableNew = [];

        readOnlyProps.pickupMethodId = false;
        readOnlyProps.returnMethodId = false;
        visibleProps.pickupMethodAddress = true;
        visibleProps.deviceAssign = true;

        // 一旦、longTermUserなしでリストを作る
        for ( let i=0;i<pickupMethodTable.length;i++ ) {
          if ( pickupMethodTable[i].isDelivery !== 2 ) {
            pickupMethodTableNew.push(pickupMethodTable[i]);
          }
        }

        let pickupMethodId = null;

        // LongTermUserの場合は、LongTermUser選択肢を追加
        if ( responseJson[0].is_long_term_user == 1 ) {

          let pickupMethodRow = {}
          pickupMethodRow.id = this.state.longTermUserPickupMethodId;
          pickupMethodRow.label = 'Long Term User';
          pickupMethodRow.isDelivery = 2;
          pickupMethodTableNew.push(pickupMethodRow);
          pickupMethodId = this.state.longTermUserPickupMethodId;

          visibleProps.deviceAssign = false;
          readOnlyProps.pickupMethodAddress = true;

        }

        this.setState({
          corporateId         : responseJson[0].corporate_id,
          corporateName       : responseJson[0].corporate_name,
          address             : responseJson[0].address,
          taxCode             : responseJson[0].tax_code,
          vatNumber           : responseJson[0].vat_number,
          pickupMethodId      : pickupMethodId,
          pickupMethodLabel   : pickupMethodLabel,
          returnMethodId      : pickupMethodId,
          returnMethodLabel   : returnMethodLabel,
          discountRate        : responseJson[0].discount_rate,
          webDiscountRate     : responseJson[0].web_discount_rate,
          isLongTermUser      : responseJson[0].is_long_term_user,
          readOnlyProps       : readOnlyProps,
          visibleProps        : visibleProps,
          errorMsg            : errorMsg,
          pickupMethodTable   : pickupMethodTableNew,
          isInputDeliveryDate : false,
          isInputReturnDate   : false,
          priceId             : responseJson[0].price_id,
        });

      }
    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;

    const errorMsg = Object.assign({}, this.state.errorMsg);
    errorMsg[name] = '';

    this.setState({ 
      [name] : event.target.value ,
       errorMsg : errorMsg
    });

  }



  // 商品明細更新時の処理 this.stateを更新
  handleChangeItemList(rowData,index){

    this.setState({itemList:rowData});
    this.calcTotal();

  }



  // 受取方法更新時の処理
  handleChangePickupMethod(event) {

    this.handleChange(event);
    const selectValue = event.target.value;

    const readOnlyProps = Object.assign({}, this.state.readOnlyProps);
    const visibleProps = Object.assign({}, this.state.visibleProps);

    for ( let i=0;i<this.state.pickupMethodTable.length;i++ ) {
      if ( this.state.pickupMethodTable[i].id == selectValue ) {
        // Delivery
        if ( this.state.pickupMethodTable[i].isDelivery == 0 ) {
          readOnlyProps.pickupMethodAddress = false;
          visibleProps.deviceAssign = true;
          this.setState({
            readOnlyProps: readOnlyProps,
            isInputDeliveryDate:true,
            visibleProps:visibleProps,
          });
        }
        // LongTermUser
        else if ( this.state.pickupMethodTable[i].isDelivery == 2 ) {
          readOnlyProps.pickupMethodAddress = true;
          visibleProps.deviceAssign = false;
          this.setState({
            readOnlyProps: readOnlyProps,
            isInputDeliveryDate:false,
            visibleProps:visibleProps,
          });
        }
        // Pickup
        else {
          readOnlyProps.pickupMethodAddress = true;
          visibleProps.deviceAssign = false;
          this.setState({
            pickupMethodAddress : '',
            isInputDeliveryDate:false,
            readOnlyProps: readOnlyProps,
            visibleProps:visibleProps,
          });
        }
        break;
      }
    }

  }



  // 返却方法更新時の処理
  handleChangeReturnMethod(event) {
  
    this.handleChange(event);
    const selectValue = event.target.value;
    for ( let i=0;i<this.state.pickupMethodTable.length;i++ ) {
      if ( this.state.pickupMethodTable[i].id == selectValue ) {
        if ( this.state.pickupMethodTable[i].isDelivery == 0 ) {
          this.setState({
            isInputReturnDate:true
          });
        }
        else {
          this.setState({
            isInputReturnDate:false
          });
        }
        break;
      }
    }

  }



  handleChangeDiscountAmount(event){

//    this.handleChange(event);
    this.state.campaignId = null;
    this.calcTotal(event.target.value)

  }



  handleChangeCampaign(event){
    this.handleChange(event);
    this.state.campaignId = event.target.value;
    this.calcTotal()
  }



  // 合計金額の算出
  calcTotal(newCalcAmount){

    // VATは計算方法
    //     - Discount後の金額に対する10%
    //     - DeliveryFeeはVATの金額に関係ない
    //  - VAT     (RentalFee - DiscountAmount) * 10%
    //  - Total    RentalFee - DiscountAmount + VAT + DeliveryFee
    //
    // 明細行にあるVAT  --> 全行同じ値でなければならない 違ったらエラー（Validation)
    let rentalFee = 0;
    let vatVal = 0;
    let deliveryFee = parseInt(this.state.deliveryFee);

    let addVat = false;
    for ( let i=0;i<this.state.itemList.length;i++ ) {
      rentalFee+= parseInt(this.state.itemList[i].price);
      // VATを計算するか？ --> 明細の最後の行の値で判定
      addVat =  parseInt(this.state.itemList[i].isTaxInclude) === 0;
    }

    let discountAmount = 0;
    if ( newCalcAmount !== undefined && newCalcAmount !== '' ) {
      discountAmount += parseInt(newCalcAmount);
    }
    else {
      // CorporateDiscountがセットされている場合は、Discount額を計算
      // wifi Systemで登録
      if ( this.state.orderRoute === 0 ) {
        if ( this.state.discountRate !== 0 ) {
          discountAmount = Math.floor( rentalFee * this.state.discountRate / 100 );
        }
      }
      // WebSiteから登録
      else if ( this.state.orderRoute === 1 ) {
        if ( this.state.webDiscountRate !== 0 ) {
          discountAmount = Math.floor( rentalFee * this.state.webDiscountRate / 100 );
        }
      }
    }

    // Campaign
    // RentalFeeに対するパーセンテージ
    // DiscountRateも指定されいたら、それぞれRentalFeeから求めた合計した金額が割引額となる
    let campaignDiscountAmount = 0;
    if ( this.state.campaignId !== undefined && this.state.campaignId !== null && this.state.campaignId !== '' ) {
      for ( let i=0;i<this.state.campaignMasterData.length;i++ ) {
        if ( this.state.campaignMasterData[i].campaign_id === this.state.campaignId ) {
          if ( this.state.campaignMasterData[i].discount_rate !== undefined && this.state.campaignMasterData[i].discount_rate !== null ) {
            campaignDiscountAmount = Math.floor( rentalFee * this.state.campaignMasterData[i].discount_rate / 100 );
          }
          else if ( this.state.campaignMasterData[i].discount_amount !== undefined && this.state.campaignMasterData[i].discount_amount !== null ) {
            campaignDiscountAmount = this.state.campaignMasterData[i].discount_amount;
          }
          break;
        }
      }
      if ( ( rentalFee - discountAmount ) < campaignDiscountAmount ) {
        campaignDiscountAmount = rentalFee - discountAmount;
      }
      discountAmount = discountAmount + campaignDiscountAmount;
    }

    // Vatの計算
    if ( addVat ) {
      vatVal = Math.floor( (rentalFee - discountAmount) * 0.1 );
    }

    let totalFee = rentalFee - discountAmount + vatVal + deliveryFee;

    this.setState({
      rentalFee:rentalFee,
      discountAmount:discountAmount,
      vat:vatVal,
      totalFee:totalFee,
    });

  }



  // 単価を求める
  getPrice(planId,destinationId,startDate,endDate,quantity) {

    let unitPrice = 0;
    for ( let i=0;i<this.state.unitPriceTable.length;i++ ) {
      if ( parseInt(this.state.unitPriceTable[i].plan_id) == parseInt(planId) &&
          parseInt(this.state.unitPriceTable[i].destination_id) == parseInt(destinationId) ) {
        unitPrice = this.state.unitPriceTable[i].unit_price;
        break;
      }
    }

    const dateDiff = (Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 *24))) + 1;
    return unitPrice * dateDiff * quantity;

  }



  // Device選択画面からの戻り（SAVE or CLOSE）
  closeDeviceAssignmentDialogEvent(save){

    // Dialog閉じる
    this.setState({
      showDeviceAssignmentDialog:false
    });

    // Deviceが選択されていたら保存    
    if ( save ) {
      this.state.deviceAssigned = true;
      this.handleClickSave();
    }

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

  }


  // 入力チェック
  validateForm(){

    let hasError = false;

    const errorMsg = Object.assign({}, this.state.errorMsg);

    // 会社
    if ( this.state.corporateName === '' ) {
      errorMsg.corporateName = 'Select corporrate.';
      hasError = true;
    }

    // PickupMethod
    if ( this.state.pickupMethodId === 0 ) {
      if ( this.state.pickupMethodAddress.trim() === '' ) {
        errorMsg.pickupMethodAddress = 'Input address.';
        hasError = true;
      }
    }
    
    // ReturnMethod
    if ( this.state.returnMethodId === 0 ) {
      if ( this.state.returnMethodAddress.trim() === '' ) {
        errorMsg.returnMethodAddress = 'Input address.';
        hasError = true;
      }
    }

    let itemListErrorMsg = '';
    // Item List
    if ( this.state.itemList.length == 0 ) {
      itemListErrorMsg = '　- Please input detail(s).\r\n';
      hasError = true;
    }
    else {

      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
 
      let checkBorder = new Date();
      checkBorder.setHours(0);
      checkBorder.setMinutes(0);
      checkBorder.setSeconds(0);
      checkBorder.setMilliseconds(0);
      checkBorder.setDate(checkBorder.getDate()-3);

      let vatVal = true;

      for ( let i=0;i<this.state.itemList.length;i++ ) {

        if ( i !== 0 ) {
          if ( vatVal !== ( this.state.itemList[i].isTaxInclude === '0' )) {
            itemListErrorMsg += '　- Item List line[' + (i+1) + ']Tax Setting should be all same.\r\n';
          }
          vatVal = this.state.itemList[i].isTaxInclude === '0';
        }
        else {
          vatVal = this.state.itemList[i].isTaxInclude === '0';
        }
      
        if ( this.state.itemList[i].planId === undefined || this.state.itemList[i].planId === '' ) {
          itemListErrorMsg += '　- Item List line[' + (i+1) + ']Plan is blank.\r\n';
        }
        if ( this.state.itemList[i].destinationId === undefined || this.state.itemList[i].destinationId === '' ) {
          itemListErrorMsg += '　- Item List line[' + (i+1) + ']Destination is blank.\r\n';
        }
        if ( this.state.itemList[i].startDate === undefined || this.state.itemList[i].startDate === '' ) {
          itemListErrorMsg += '　- Item List line[' + (i+1) + ']Start Date is blank.\r\n';
        }
        if ( this.state.itemList[i].endDate === undefined || this.state.itemList[i].endDate === '' ) {
          itemListErrorMsg += '　- Item List line[' + (i+1) + ']End Date is blank.\r\n';
        }
        if ( this.state.itemList[i].quantity === undefined || this.state.itemList[i].quantity === '' ) {
          itemListErrorMsg += '　- Item List line[' + (i+1) + ']Quantity is blank.\r\n';
        }

        if ( this.state.itemList[i].startDate != undefined && this.state.itemList[i].startDate !== '' ) {
//          if ( this.state.itemList[i].startDate < checkBorder ) {
//            itemListErrorMsg += '　- Item List line[' + (i+1) + ']Start Date shuod be later than today.\r\n';
//          }
          if ( this.state.itemList[i].endDate != undefined && this.state.itemList[i].endDate !== '' ) {
            if ( this.state.itemList[i].startDate > this.state.itemList[i].endDate  ) {
              itemListErrorMsg += '　- Item List line[' + (i+1) + ']Start Date shuod be smaller than End Data.\r\n';
            }
          }
        }

        if ( this.state.itemList[i].isTaxInclude === undefined || this.state.itemList[i].isTaxInclude === '' ) {
          itemListErrorMsg += '　- Item List line[' + (i+1) + ']Tax is blank.\r\n';
        }

        if ( this.state.itemList[i].isExtended == undefined || this.state.itemList[i].isExtended == '' ) {
          this.state.itemList[i].isExtended = constVal.is_extended.not_extended;
        }


        // 明細データTableに必要な項目をセットする
        // 新規登録時は、JSONに項目が存在しない場合がある
        this.state.itemList[i].row_id      = (i+1);
        this.state.itemList[i].plan        = this.getLabel(this.state.itemList[i].planId,this.state.planList);
        this.state.itemList[i].destination = this.getLabel(this.state.itemList[i].destinationId,this.state.destinationList[this.state.priceId+'']);
        this.state.itemList[i].country     = this.getLabel(this.state.itemList[i].countryId,this.state.countryList);

        if ( this.state.itemList[i].selectedDevices === undefined || this.state.itemList[i].selectedDevices === null ) {
          this.state.itemList[i].selectedDevices = [];
        }
        if ( this.state.itemList[i].selectedDevicesUpdatedAt === undefined || this.state.itemList[i].selectedDevicesUpdatedAt === null ) {
          this.state.itemList[i].selectedDevicesUpdatedAt = [];
        }
        if ( this.state.itemList[i].selectedDevicesOrg === undefined || this.state.itemList[i].selectedDevicesOrg === null ) {
          this.state.itemList[i].selectedDevicesOrg = [];
        }
        if ( this.state.itemList[i].selectedDevicesOrgUpdatedAt === undefined || this.state.itemList[i].selectedDevicesOrgUpdatedAt === null ) {
          this.state.itemList[i].selectedDevicesOrgUpdatedAt = [];
        }
        if ( this.state.itemList[i].deviceNumber === undefined || this.state.itemList[i].deviceNumber === null ) {
          this.state.itemList[i].deviceNumber = '';
        }
        
      }

    }


    // 金額更新チェック
    if ( this.state.paymentStatus === constVal.payment_status.Payed || this.state.invoiceIssueRequestId !== null ) {
      if ( this.state.rentalFee !== this.state.rentalFeeOrg ) {
        itemListErrorMsg = 'This booking is already payed.Can not change amount.';
      }
    }

    if ( itemListErrorMsg !== '' ) {
      hasError = true;
    }

    this.state.errorMsg.itemList = itemListErrorMsg;
    this.setState({errorMsg:errorMsg});

    return hasError;

  }



  getLabel(id,table){

    const keys = Object.keys(table)
    for(var k of keys){
      if ( parseInt(k) == parseInt(id) ) {
        return table[k];
      }
    }

  }



  // 保存ボタン押下時の処理
  handleClickSave(){

    // 入力チェック
    if ( this.validateForm() ) {

      let errorMsg = 'Error(s) occurs.';
      const itemListErrorMsg = this.state.errorMsg.itemList;
      if ( itemListErrorMsg !== '' ) {
        errorMsg += '\r\n' + itemListErrorMsg;
      }
      this.setState({
        informDialogOpen:true,
        informDialogTitle:'Regist Error',
        informDialogMessage:errorMsg,
      });
      return;

    }


    // DeviceAsign選択時 -> DeviceAssignDialogを開く
    if ( this.state.deviceAssign == 1 && this.state.deviceAssigned == false ) {
      this.setState({
        showDeviceAssignmentDialog:true,
      });
      return;
    }

    // Device有効数量チェック
    let requestParam = {};
    if ( this.state.bookingId == undefined || this.state.bookingId == '' ) {
      requestParam.bookingId = 0;
    }
    else {
      requestParam.bookingId = this.state.bookingId;
    }
    requestParam.pickupMethodId = this.state.pickupMethodId;

    requestParam.details = [];
    for ( let i=0;i<this.state.itemList.length;i++ ) {
      let requestParamRow = {};
      requestParamRow.destinationId = this.state.itemList[i].destinationId;
      requestParamRow.startDate = this.state.itemList[i].startDate;
      requestParamRow.quantity = this.state.itemList[i].quantity;
      requestParam.details.push(requestParamRow);
    }

    fetch('/api/checkAbailableDevice', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      // 以下のケースで、Device有効数量チェックを行わう
      //  - 確認ダイアログでcontinueが押されたイベントではない
      //  - デバイス未アサイン
      //  - pickup method <> Delivery
      //  - long term userじゃない
      if ( !this.state.confirmDialogContinue &&
           parseInt(this.state.deviceAssign) == 0 &&
           this.state.deliveryPickupMethodId.indexOf(this.state.pickupMethodId) === -1　 &&
           this.state.isLongTermUser == 0 ) {

        let hasAlert = false;
        let alertMsg = [];
        for ( let i=0;i<responseJson.length;i++ ) {
          if ( parseInt(responseJson[i].quantity) > parseInt(responseJson[i].availableDeviceCount) ) {
            alertMsg.push('line' + (i+1) + ': available device is lacking for this booking detail.');
            hasAlert = true;
          }
        }
  
        if ( hasAlert ) {
          let alertMsgString = '';
          for ( let i=0;i<alertMsg.length;i++ ) {
            if ( alertMsgString != '' ) {
              alertMsgString += '\r\n';
            }
            alertMsgString += alertMsg[i];
          }
          this.setState({
            confirmDialogContinue : false,
            confirmDialogOpen     : true,
            confirmDialogTitle    : 'Available Device Alert',
            confirmDialogMessage  : alertMsgString,
          });
          return;
        }

      }

      // 新規作成時
      if ( this.state.bookingStatus === 0 ) {

        // Deliveryの場合は[100:Booked]
        if ( this.state.deliveryPickupMethodId.indexOf(this.state.pickupMethodId) >=0 ) {
          this.state.bookingStatus = constVal.booking_status.Booked;
        }
        // LongTermUserの場合は[100:Booked] -- BatchでUsingに更新（画面からは更新出来ない）
        else if ( this.state.pickupMethodId == this.state.longTermUserPickupMethodId ) {
          this.state.bookingStatus = constVal.booking_status.Booked;
        }
        // Delivery不要の場合は[500:WaitForPickup]
        else {
          this.state.bookingStatus = constVal.booking_status.WaitForPickup;
        }

        // 保存API
        fetch('/api/resolveNewBooking', {
          method: "POST",
          body: JSON.stringify(this.state), 
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((responseJson) => {

          // 成功
          if ( responseJson.bookingId !== undefined ) {

            this.setState({
              informDialogOpen:true,
              informDialogTitle:'Create Complete',
              informDialogMessage:'Create new booking successfully Done.Apply Number [' + responseJson.applyNumber + ']',
            });

          }
          // エラー
          else {

            this.setState({
              informDialogOpen:true,
              informDialogTitle:'Save Error',
              informDialogMessage:'Save failed!!.error message',
            });

          }

        })
        .catch((error) =>{
          console.error(error);
        });

      }
      else {

        // Delivery要の場合は[100:Booked]
        if ( this.state.deliveryPickupMethodId.indexOf(this.state.pickupMethodId) >=0 ) {
          // Pickup → Deliveryへの変更
          if ( this.state.bookingStatus == constVal.booking_status.WaitForPickup ) {
            this.state.bookingStatus = constVal.booking_status.Booked;
          } 
        }
        // LongTermUserの場合は[100:Booked] -- BatchでUsingに更新（画面からは更新出来ない）
        else if ( this.state.pickupMethodId == this.state.longTermUserPickupMethodId ) {
          this.state.bookingStatus = constVal.booking_status.Booked;
        }
        // Delivery不要の場合は[500:WaitForPickup]
        else {
          this.state.bookingStatus = constVal.booking_status.WaitForPickup;
        }

        // 保存API
        fetch('/api/resolveBooking', {
          method: "POST",
          body: JSON.stringify(this.state), 
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((responseJson) => {

          // 成功
          if ( responseJson.error === undefined ) {

            this.setState({
              informDialogOpen:true,
              informDialogTitle:'Update Complete',
              informDialogMessage:'Update booking successfully Done.Apply Number [' + this.state.applyNumber + ']',
            });

          }
          // エラー
          else {

            this.setState({
              informDialogOpen:true,
              informDialogTitle:'Update Error',
              informDialogMessage:'Update booking failed!!' + responseJson.error,
            });

          }

        })
        .catch((error) =>{
          console.error(error);
        });

      }
    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // Cancelボタン押下時の処理
  handleClickCancel(){

    // 保存API
    fetch('/api/resolveDeleteBooking', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      // 成功
      if ( responseJson.error === undefined ) {

        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Delete Complete',
          informDialogMessage:'Delete selected booking successfully Done.Apply Number [' + this.state.applyNumber + ']',
        });

      }
      // エラー
      else {

        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Delete Error',
          informDialogMessage:'Delete booking failed!!' + responseJson.error,
        });

      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // レンタル延長ボタン
  handleClickExtend(){

    for ( let i=0;i<this.state.itemList.length;i++ ) {
      this.state.itemList[i].plan        = this.getLabel(this.state.itemList[i].planId,this.state.planList);
      this.state.itemList[i].destination = this.getLabel(this.state.itemList[i].destinationId,this.state.destinationList[this.state.priceId+'']);
    }

    this.setState({
      showExtendDialog:true,
    });

  }



  // Extend（延長）ダイアログからの戻りイベント → DB登録
  closeExtendDialogEvent(extendToList){

    this.setState({
      showExtendDialog:false,
    });

    // DialogでCLOSEが押下された → なにもしない
    if ( extendToList.length == 0 ) {
      return;
    }

    this.state.extendToList = extendToList;

    // 保存API
    fetch('/api/resolveBookingExtend', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      // 成功
      if ( responseJson.applyNumber !== undefined ) {

        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Create Complete',
          informDialogMessage:'Create new booking successfully Done.Apply Number [' + responseJson.applyNumber + ']',
        });

      }
      // エラー
      else {

        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Save Error',
          informDialogMessage:'Save failed!!.error message',
        });

      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // Refundボタン押下時の処理
  handleClickRefund(){

    this.setState({
      showRefundDialog  : true,
    })

  }



  // RefundDialogで保存押した時の処理
  closeRefundDialogEvent(informDialogTitle,informDialogMessage){

    if ( informDialogTitle !== '' ) {
      this.setState({
        informDialogOpen    : true,
        informDialogTitle   : informDialogTitle,
        informDialogMessage : informDialogMessage,
        showRefundDialog    : false,
      })
    }
    else {
      this.setState({
        showRefundDialog  : false,
      })
    }

  }



  // Device有効数警告ダイアログで「Continue」押下時の処理
  confirmDialogCloseEventContinue() {

    this.setState({
      confirmDialogContinue : true,
      confirmDialogOpen     : false,
      confirmDialogTitle    : '',
      confirmDialogMessage  : '',
    });
    this.handleClickSave();

  }



  // Device有効数警告ダイアログで「Cancel」押下時の処理 -> 無いもしない
  confirmDialogCloseEventCancel(){

    this.setState({
      confirmDialogContinue : false,
      confirmDialogOpen     : false,
      confirmDialogTitle    : '',
      confirmDialogMessage  : '',
    });

  }



  showApplyInfo(){

    this.setState({
      showApplyInfoDialog  : true,
      selectedBookingId    : this.state.bookingId,
      selectedApplyNumber  : this.state.applyNumber,
    });

  }



  closeApplyInfoDialogEvent(){

    this.setState({
      showApplyInfoDialog  : false,
    });

  }



  showLog(){

    this.setState({
      showUpdateLogDialog  : true,
      selectedBookingId    : this.state.bookingId,
      selectedApplyNumber  : this.state.applyNumber,
    });

  }



  closeUpdateLogDialogEvent(){

    this.setState({
      showUpdateLogDialog:false
    });

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />

        <Typography >
          <Link href="#" onClick={this.showApplyInfo}   style={{marginLeft:'30px',marginRight:'30px'}}>
            Apply Info
          </Link>
          <Link href="#" onClick={this.showLog}         style={{marginLeft:'30px',marginRight:'30px'}}>
            Show Log
          </Link>
        </Typography>

        <MiraiTextField handleChange={this.handleChange}    readOnly={true}                         id="contractType"   name="contractType"    label="Contract Type"  value="Corporate"                 errorText={this.state.errorMsg.contractType}/>
        <br />
        <MiraiTextField handleChange={this.handleChange}    readOnly={true}   width={400}           id="corporateName"  name="corporateName"   label="Corporate Name" value={this.state.corporateName}  errorText={this.state.errorMsg.corporateName}/>
        <SearchCorporate onSelectCorporateEvent={this.onSelectCorporate} readOnly={this.state.readOnlyProps.corporate}  />
        <br />
        <MiraiTextField handleChange={this.handleChange}    readOnly={this.state.readOnlyProps.userName} id="userName"       name="userName"        label="User Name"      value={this.state.userName}       errorText={this.state.errorMsg.userName}/>
        <br />
        <MiraiTextField handleChange={this.handleChange}    readOnly={true}                         id="address"        name="address"         label="Address"        value={this.state.address}        errorText={this.state.errorMsg.address}/>
        <br />
        <MiraiTextField handleChange={this.handleChange}    readOnly={true}                         id="taxCode"        name="taxCode"         label="Tax Code"       value={this.state.taxCode}        errorText={this.state.errorMsg.taxCode}/>
        <br />
        <MiraiTextField handleChange={this.handleChange}    readOnly={true}                         id="vatNumber"      name="vatNumber"       label="Vat Number"     value={this.state.vatNumber}      errorText={this.state.errorMsg.vatNumber}/>
        <br />
        <MiraiTextField handleChange={this.handleChange}    readOnly={this.state.readOnlyProps.invoiceNote} id="invoiceNote"     name="invoiceNote"      label="Invoice Note"    value={this.state.invoiceNote}    errorText={this.state.errorMsg.invoiceNote}/>
        <br />
        <br />
        <CommonSelect readOnlyLabel={this.state.pickupMethodLabel}   readOnly={this.state.readOnlyProps.pickupMethodId}  caption={"Pickup Method"} name={"pickupMethodId"} selectValue={this.state.pickupMethodId} onChangeEvent={this.handleChangePickupMethod} selectList={this.state.pickupMethodTable} />
        <br />
        <MiraiTextField visible={this.state.visibleProps.pickupMethodAddress} handleChange={this.handleChange} readOnly={this.state.readOnlyProps.pickupMethodAddress}  id="pickupMethodAddress"      name="pickupMethodAddress"       label="Delivery Address"     value={this.state.pickupMethodAddress}  errorText={this.state.errorMsg.pickupMethodAddress}/>
        <br />
        <br />
        <CommonSelect readOnlyLabel={this.state.returnMethodLabel}   readOnly={this.state.readOnlyProps.returnMethodId} caption={"Return Method"} name={"returnMethodId"} selectValue={this.state.returnMethodId} onChangeEvent={this.handleChangeReturnMethod} selectList={this.state.pickupMethodTable} />
        <br />
        <CommonRadio visible={this.state.visibleProps.paymentMethod} readOnly={this.state.readOnlyProps.paymentMethod}  radioList={this.state.paymentMethodList}   onChangeEvent={this.handleChange}   name={'paymentMethod'}   caption={'Payment Method'}   radioValue={this.state.paymentMethod} />

        <ItemListTable isInputDeliveryDate={this.state.isInputDeliveryDate} isInputReturnDate={this.state.isInputReturnDate} calcPriceFUnctin={this.calcPrice}   getPriceFunction={this.getPrice}     readOnly={this.state.readOnlyProps.itemList}        onChangeEvent={this.handleChangeItemList} rowData={this.state.itemList}  countryByDestination={this.state.countryByDestination}  countryList={this.state.countryList} planList={this.state.planList} destinationList={this.state.destinationList} priceId={this.state.priceId} />

        <MiraiTextField handleChange={this.handleChange}                  readOnly={true}                                      id="rentalFee"       name="rentalFee"       label="Rental Fee"    value={this.state.rentalFee}      errorText={this.state.errorMsg.rentalFee}/>
        <br />
        <MiraiTextField handleChange={this.handleChange}                  readOnly={true}                                      id="deliveryFee"     name="deliveryFee"     label="Delivery Fee"  value={this.state.deliveryFee}    errorText={this.state.errorMsg.deliveryFee}/>
        <br />
        <MiraiTextField handleChange={this.handleChangeDiscountAmount}    readOnly={this.state.readOnlyProps.discountAmount}   id="discountAmount"  name="discountAmount"  label="Discount"      value={this.state.discountAmount} errorText={this.state.errorMsg.discountAmount}  />
        <br />
        <MiraiTextField handleChange={this.handleChange}                  readOnly={true}                                      id="vat"             name="vat"             label="Vat"           value={this.state.vat}            errorText={this.state.errorMsg.vat}/>
        <br />
        <MiraiTextField handleChange={this.handleChange}                  readOnly={true}                                      id="totalFee"        name="totalFee"        label="Total Fee"     value={this.state.totalFee}       errorText={this.state.errorMsg.totalFee}/>
        <br />
        <br />
        <CommonSelect readOnly={this.state.readOnlyProps.campaignId} caption={"Campaign"} name={"campaignId"} onChangeEvent={this.handleChangeCampaign} selectValue={this.state.campaignId} selectList={this.state.campaignList} />
        <br />
        <br />
        <CommonSelect readOnly={this.state.readOnlyProps.personInCharge} caption={"Person In Charge"} name={"personInCharge"} onChangeEvent={this.handleChange} selectValue={this.state.personInCharge} selectList={this.state.userList} />
        <br />
        <br />
        <CommonRadio visible={this.state.visibleProps.deviceAssign} readOnly={this.state.readOnlyProps.deviceAssign}  radioList={this.state.deviceAssignList}   onChangeEvent={this.handleChange}   name={'deviceAssign'}   caption={'Device Assignment'}   radioValue={this.state.deviceAssign} />
        <br />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.saveButton}    clickEvent={this.handleClickSave}    label="Regist" />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.cancelButton}  clickEvent={this.handleClickCancel}  label="Cancel" />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.extendButton}  clickEvent={this.handleClickExtend}  label="Extend" />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.refundButton}  clickEvent={this.handleClickRefund}  label="Refund" />
        <InformDialog  informDialogStatus={this.state.informDialogOpen}   informDialogTitle={this.state.informDialogTitle}   informDialogMessage={this.state.informDialogMessage}  informDialogCloseEvent={this.informDialogCloseEvent} />
        <ConfirmDialog confirmDialogStatus={this.state.confirmDialogOpen} confirmDialogTitle={this.state.confirmDialogTitle} confirmDialogMessage={this.state.confirmDialogMessage} confirmDialogCloseEventCancel={this.confirmDialogCloseEventCancel} confirmDialogCloseEventContinue={this.confirmDialogCloseEventContinue} />
        <DeviceAssignmentDialog closeDeviceAssignmentDialogEvent={this.closeDeviceAssignmentDialogEvent} showDeviceAssignmentDialog={this.state.showDeviceAssignmentDialog} bookingDetail={this.state.itemList} pickupMethodId={this.state.pickupMethodId} />
        <ExtendDialog           closeExtendDialogEvent={this.closeExtendDialogEvent}                     showExtendDialog={this.state.showExtendDialog}                     bookingDetail={this.state.itemList} />
        <RefundDialog updatedAt={this.state.updatedAt} showRefundDialog={this.state.showRefundDialog}  selectedBookingId={this.state.bookingId} handleRefundDialogCloseEvent={this.closeRefundDialogEvent} />

        <UpdateLogDialog showUpdateLogDialog={this.state.showUpdateLogDialog} closeUpdateLogDialogEvent={this.closeUpdateLogDialogEvent} selectedApplyNumber={this.state.selectedApplyNumber} selectedBookingId={this.state.selectedBookingId} />
        <ApplyInfoDialog showApplyInfoDialog={this.state.showApplyInfoDialog} closeApplyInfoDialogEvent={this.closeApplyInfoDialogEvent} selectedApplyNumber={this.state.selectedApplyNumber} selectedBookingId={this.state.selectedBookingId} />

      </div>
    );

  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName,
    id               : state.login.id
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(bookingCorporate);
