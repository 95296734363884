import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '500px',
  },
}));

export default function TextFieldEmail(pPrams) {

  const classes = useStyles();
  const params = pPrams;

  return (
    <FormControl>
      <TextField
        id={params.id}
        name={params.name}
        label="Email Address"
        margin="normal"
        className={classes.textField}
        onChange={params.handleChange}
        error={params.errorText !== ""}
        helperText={params.errorText}
      />
    </FormControl>
  );

}