import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  header: {
    height: '30px',
    backgroundColor:'#fffaf0',
    position:"relative"
  },
  div_brank:{
    display: "inline-block",
    float:"left",
    width:"5%",
    "text-align":"left"
  },
  div_title:{
    display: "inline-block",
    float:"left",
    width:"95%",
    "font-size":"1.1rem",
    "text-align":"left",
    "font-weight":"bold"
  },
});


export default function ScreenTitle(pPrams) {

  const params = pPrams;

  const classes = useStyles();
  const dummyTest = '　';

  return (
    <div className={classes.header}>
      <div className={classes.div_brank}>
        {dummyTest}
      </div>
      <div className={classes.div_title}>
        {params.title}
      </div>
    </div>
  );

}
