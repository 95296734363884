import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  inputFileBtnHide : {
    opacity:0,
    'appearance': 'none',
    'position': 'absolute',
  },
}));


export default function InputFile(pPrams) {

  const classes = useStyles();
  const params = pPrams;

  let uploadButtonVisible = params.uploadFileName!==''?true:false;

  let uploadButton = '';
  if ( uploadButtonVisible ) {
    uploadButton = <Button variant="contained" color="primary"  onClick={params.handleClickUpload}>Upload</Button>
  }

  return (
    <div>
      <input 
        className={classes.inputFileBtnHide}
        id="deviceListFile" 
        multiple 
        type="file" 
        onChange={params.fileSelectEvent}
      />
      <label htmlFor="deviceListFile"> 
        <Button raised component="span" className={classes.button} >
          Select File
        </Button>
      </label>
      {params.uploadFileName}
      {uploadButton}
    </div>
  );

}