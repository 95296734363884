import React, { Component } from 'react';
import PaymentBankTransferList from './PaymentBankTransferList';

import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';
import InformDialog from '../../../../common/mirai_component/InformDialog'

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import CommonCheckbox from '../../../../common/CommonCheckbox';

import CommonButton from '../../../../common/mirai_component/CommonButton'

import {connect} from 'react-redux';
import * as commonFunction from '../../../../common/commonFunction';

import const_val from '../../../../const';
import PaymentDialog from '../PaymentDialog'

class PaymentBankTransfer extends Component {

  constructor(props) {

    super(props);
    this.state = {
      payment_status             : [],
      corporate_name             : '',
      apply_number               : '',
      dataRows                   : [],
      paymentStatusCheckBoxValue : [],
      showBookingDetailDialog    : false,
      rentalFee                  : 0,
      informDialogOpen           : false,
      informDialogTitle          : '',
      informDialogMessage        : '',
      showPaymentDialog          : false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.closePaymentDialogEvent = this.closePaymentDialogEvent.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }



  componentDidMount(prevProps) {

    // ログイン認証チェック
    // ログイン済みであればメニューを表示
    // checkSessionStatus(this.props,'/searchBooking');

    let paymentStatusCheckBoxValue = const_val.payment_status;
    paymentStatusCheckBoxValue[0].checked = true;
    this.state.payment_status.push(0);


    this.setState({
      paymentStatusCheckBoxValue:paymentStatusCheckBoxValue,
    });

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  // 一覧のCellClick時
  onCellClick(event){

    let cellIndex = event.target.cellIndex;

    // Detail押下 -> BookingDetailへ遷移
    if ( cellIndex == 0 ) {
      let applyNumber = event.target.children[0].value;
      // 会社
      if ( event.target.children[1].value === '0' ) {
        this.props.history.push('/bookingCorporate/' + applyNumber);
      }
      // 個人
      else {
        this.props.history.push('/bookingPersonal/' + applyNumber);
      }
    }
    else if ( cellIndex == 10 ) {
      let bookingId = event.target.children[0].value;
      this.setState({
        showPaymentDialog : true,
        selectedBookingId : bookingId,
      });
    }

  }



  handleCheckboxChange(event) {

    const name = event.name;
    const checked_list = [];
    for(const each of event.list){
      if(each.checked){
        checked_list.push(each.id);
      }
    }
    this.setState({ [name] : checked_list});
  
  }



  // 検索ボタン押下時の処理
  handleClickSearch(){

    // Booking検索API
    fetch('/api/searchPaymentBankTransfer', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      for ( let i=0;i<responseJson.length;i++ ) {

        responseJson[i].applyNumber      = responseJson[i].apply_number;
        responseJson[i].applyDate        = new Date(responseJson[i].apply_date).toLocaleDateString();
        responseJson[i].corporateName    = responseJson[i]["m20_corporate"]["corporate_name"]
        responseJson[i].personInCharge   = responseJson[i]["m10_user"]["name"]
        responseJson[i].userName         = responseJson[i].user_name;
        responseJson[i].totalFee         = responseJson[i].total_fee;
        responseJson[i].invoiceNumber    = responseJson[i].invoice_number;
        responseJson[i].invoiceIssueDate = new Date(responseJson[i].invoice_issue_date).toLocaleDateString();

        let minDate = new Date(responseJson[i]['t20_booking_details'][0].start_date);
        let maxDate = new Date(responseJson[i]['t20_booking_details'][0].end_date);
        let destination = [];

        for ( let j=0;j<responseJson[i]['t20_booking_details'].length;j++ ) {

          if ( minDate > new Date(responseJson[i]['t20_booking_details'][j].start_date) ) {
            minDate = new Date(responseJson[i]['t20_booking_details'][j].start_date);
          }
          if ( maxDate < new Date(responseJson[i]['t20_booking_details'][j].end_date) ){
            maxDate = new Date(responseJson[i]['t20_booking_details'][j].end_date);
          }
          if ( destination.indexOf(responseJson[i]['t20_booking_details'][j]['m40_destination']['destination']) === -1 ) {
            destination.push(responseJson[i]['t20_booking_details'][j]['m40_destination']['destination']);
          }

        }

        let destinationString = '';
        for ( let j=0;j<destination.length;j++ ) {
          if ( j > 0 ) {
            destinationString += ' / ' ;
          }
          destinationString += destination[j];
        }
        responseJson[i].contractDate = commonFunction.formatYYYYMMDD(minDate) + ' - ' + commonFunction.formatYYYYMMDD(maxDate);
        responseJson[i].destination = destinationString;
        responseJson[i].payment     = '[payment]';

      }

      this.setState({
        dataRows : responseJson
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

    this.handleClickSearch();

  }



  closePaymentDialogEvent(informDialogTitle,informDialogMessage){

    if ( informDialogTitle !== '' ) {
      this.setState({
        informDialogOpen    : true,
        informDialogTitle   : informDialogTitle,
        informDialogMessage : informDialogMessage,
      })
    }

    this.setState({
      showPaymentDialog : false
    });

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Payment Status</FormLabel></div>
            <CommonCheckbox name="payment_status"  checkboxValue={this.state.paymentStatusCheckBoxValue} onCheckedValue={this.handleCheckboxChange}/>
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Company Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="corporate_name" name="corporate_name" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Apply Number</FormLabel></div>
            <TextField onChange={this.handleChange} id="apply_number" name="apply_number" style={{width: "500px"}} type="search" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch} label="Search booking"/>
        <br />
        <PaymentBankTransferList dataRows={this.state.dataRows} onCellClickEvent={this.onCellClick}/>
        <PaymentDialog showPaymentDialog={this.state.showPaymentDialog} closePaymentDialogEvent={this.closePaymentDialogEvent} selectedBookingId={this.state.selectedBookingId}/>
        <InformDialog informDialogStatus={this.state.informDialogOpen}  informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentBankTransfer);