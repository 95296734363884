import {
  SEG_LOGIN_USER_INFO
} from '../actions';

const initialState = {
  id          : 0,
  userName    : '',
  userEmail   : '',
  isCorporate : 0,
  isLocation  : 0,
  admin       : false,
  isAgency    : 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEG_LOGIN_USER_INFO:
      return Object.assign({}, state, {
        id          : action.id,
        userName    : action.userName, 
        userEmail   : action.userEmail,
        isCorporate : action.isCorporate,
        isLocation  : action.isLocation,
        admin       : action.admin,
        isAgency    : action.isAgency,
      })
    default:
      return state;
  }
};