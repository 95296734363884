import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import LongTermRentDeviceList from './LongTermRentDeviceList';
import AvailableDeviceList from './AvailableDeviceList';
import CommonButton from '../../../../common/mirai_component/CommonButton'
import RaisedButton from 'material-ui/RaisedButton';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import CommonRadio from '../../../../common/CommonRadio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';


class CorporateDeviceEditDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData       : false,
      corporateId      : 0,
      devices          : [],
      availableDevices : [],
      orgDevices       : [],
      deviceNumber     : '',
      corporateName    : '',
      routerTypeId     : 9,
      routerTypeList   : [
        { id : '1' , label : 'WorldWide' },
        { id : '2' , label : 'VietNam'   },
        { id : '3' , label : 'Japan'     },
        { id : '9' , label : 'any'       },
      ],
      errorMsg :{
      }
    };

    this.handleCloseNoMessage = this.handleCloseNoMessage.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.doSearchAvailableDevice = this.doSearchAvailableDevice.bind(this);
    this.onSelectDevice = this.onSelectDevice.bind(this);
    this.onDelDevice = this.onDelDevice.bind(this);

  }


  handleCloseNoMessage(){
    this.setState({loadedData:false});
    this.props.handleEditDialogCloseEvent('','');
  }


  handleClose(informDialogTitle,informDialogMessage) {
    this.setState({loadedData:false});
    this.props.handleEditDialogCloseEvent(informDialogTitle,informDialogMessage);
  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  componentWillUpdate(nextProps,nextState){

    if ( nextProps.editCorporateId !=null && !this.state.loadedData ) {
    
      let queryParam = {};
      queryParam.corporateId = nextProps.editCorporateId;

      // Device Search
      // 会社検索
      fetch('/api/searchCorporateLongTermDevice', {
        method: "POST",
        body: JSON.stringify(queryParam), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        let devices = [];
        let orgDevices = [];
        for ( let i=0;i<responseJson.length;i++ ) {
          let deviceRow = {};
          deviceRow.row_id        = (i+1);
          deviceRow.model         = responseJson[i]['m70_device']['m90_model'].model;
          deviceRow.router_type   = responseJson[i]['m70_device']['m90_model']['m50_router_type'].router_type;
          deviceRow.device_number = responseJson[i].device_number;
          deviceRow.updatedAt     = responseJson[i]['m70_device']['updatedAt'];
          deviceRow.action        = 'del';
          devices.push(deviceRow);
          orgDevices.push(deviceRow);
        }

        this.setState({
          corporateId : nextProps.editCorporateId,
          devices     : devices,
          orgDevices  : orgDevices,
          loadedData  : true
        })

      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }



  // Device検索ボタン押下時
  doSearchAvailableDevice(){

    fetch('/api/searchAvailableDevice', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let availableDevices = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let availableDevicesRow = {};
        availableDevicesRow.row_id        = (i+1);
        availableDevicesRow.model         = responseJson[i]['m90_model']['model'];
        availableDevicesRow.router_type   = responseJson[i]['m90_model']['m50_router_type']['router_type'];
        availableDevicesRow.device_number = responseJson[i]['device_number'];
        availableDevicesRow.location      = responseJson[i]['m80_location']['location'];
        availableDevicesRow.deviceFor     = responseJson[i]['m20_corporate']===null? '' : responseJson[i]['m20_corporate']['corporate_name'];
        availableDevicesRow.select        = 'select';
        availableDevicesRow.updatedAt     = responseJson[i]['updatedAt'];
        availableDevices.push(availableDevicesRow);
      }

      this.setState({
        availableDevices : availableDevices
      })

    });

  }


  // 選択解除
  onDelDevice(event){

    let cellIndex = event.target.cellIndex;
    if ( cellIndex == 4 ) {

      let deviceNumber = event.target.children[0].value;
      let rowId = 0;

      let devices = [];
      for ( let i=0;i<this.state.devices.length;i++ ) {
        if ( this.state.devices[i].device_number != deviceNumber ) {
          this.state.devices[i].row_id = ++rowId;
          devices.push(this.state.devices[i])
        }
      }

      this.setState({
        devices:devices
      })

    }

  }


  // Device新規選択
  onSelectDevice(event){

    let cellIndex = event.target.cellIndex;

    // Select押下時 -> BookingPageへ遷移
    if ( cellIndex == 6 ) {

      let deviceNumber = event.target.children[0].value;
      let availableDevices = [];
      let rowId = 0;

      let devices = this.state.devices;
      for ( let i=0;i<this.state.availableDevices.length;i++ ) {
        if ( this.state.availableDevices[i].device_number == deviceNumber ) {
          let newSelectedDevice = {};
          newSelectedDevice.row_id        = (devices.length+1);
          newSelectedDevice.device_number = this.state.availableDevices[i].device_number;
          newSelectedDevice.model         = this.state.availableDevices[i].model;
          newSelectedDevice.router_type   = this.state.availableDevices[i].router_type;
          newSelectedDevice.updatedAt     = this.state.availableDevices[i].updatedAt;
          newSelectedDevice.action        = 'del';
          devices.push(newSelectedDevice);
        }
        else {
          let availableDevicesRow = this.state.availableDevices[i];
          availableDevicesRow.row_id = ++rowId;
          availableDevices.push(availableDevicesRow);
        }
      }

      this.setState({
        availableDevices:availableDevices,
        devices:devices
      });

    }

  }


  // 保存ボタン押下時
  handleClickSave(event) {

    // TODO
    // valiadteion

    fetch('/api/resolveCorporateLongTermRentDevices', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.handleClose('Corporate Updated','Long Term Device Setting was updated.');
    })
    .catch((error) =>{
      console.error(error);
    });


  }



  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleCloseNoMessage}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
          <Dialog
            actions={actions}
            modal={false}
            open={this.props.showDeviceEditDialog}
            onRequestClose={this.handleCloseNoMessage}
            contentStyle={{width: "1050px", maxWidth: "none" , height:"1200px" , maxHeight:"none"}}
          >
            <DialogTitle id="alert-dialog-slide-title">Long Term Corporate</DialogTitle>
            <DialogContent className="MyDialogContent">
              <LongTermRentDeviceList dataRows={this.state.devices} onCellClickEvent={this.onDelDevice} />
              <MiraiTextField width={200} handleChange={this.handleChange}  id="deviceNumber"    name="deviceNumber"    label="Device Number"  value={this.state.deviceNumber}  errorText={''} />
              <br />
              <MiraiTextField width={200} handleChange={this.handleChange}  id="corporateName"   name="corporateName"   label="Device For"     value={this.state.corporateName} errorText={''} />
              <br />
              <CommonRadio visible={true} readOnly={false}  radioList={this.state.routerTypeList}   onChangeEvent={this.handleChange}   name={'routerTypeId'}   caption={'Router Type'}   radioValue={this.state.routerTypeId} />
              <br />
              <RaisedButton label="Search Available Device" onClick={this.doSearchAvailableDevice} style={{marginTop:"5px"}}/>
              <br />
              <AvailableDeviceList dataRows={this.state.availableDevices} onCellClickEvent={this.onSelectDevice} />
            </DialogContent>
          </Dialog>
      </MuiThemeProvider>
    );

  }

}


export default CorporateDeviceEditDialog;