import { 
  MENU_SELECT_BOOKING,
  MENU_SELECT_NEW_BOOKING,
  MENU_SELECT_PAYMENT,
  MENU_SELECT_STOCK,
  MENU_SELECT_MASTER,
  MENU_SELECT_LOCATION,
  MENU_SELECT_REPORT,
  MENU_SELECT_INVOICE,
  MENU_SELECT_EXPENDITURE,
  MENU_SELECT_REVENUE,
} from '../actions';

const initialState = {
  selectedMenuItemIndexInvoice     : null,
  selectedMenuItemIndexExpenditure : null,
  selectedMenuItemIndexRevenue     : null,
  selectedMenuItemIndexBooking     : null,
  selectedMenuItemIndexNewBooking  : null,
  selectedMenuItemIndexPayment     : null,
  selectedMenuItemIndexStock       : null,
  selectedMenuItemIndexMaster      : null,
  selectedMenuItemIndexLocation    : null,
  selectedMenuItemIndexReport      : null,
  selectedMenuName                 : '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MENU_SELECT_BOOKING:
      return Object.assign({}, state, {
        selectedMenuItemIndexBooking    : action.selectedMenuItemIndexBooking, 
        selectedMenuItemIndexNewBooking : null,
        selectedMenuItemIndexPayment    : null,
        selectedMenuItemIndexStock      : null,
        selectedMenuItemIndexMaster     : null,
        selectedMenuItemIndexLocation   : null,
        selectedMenuItemIndexReport     : null,
        selectedMenuName                : action.selectedMenuName
      })
    case MENU_SELECT_NEW_BOOKING:
      return Object.assign({}, state, {
        selectedMenuItemIndexBooking    : null,
        selectedMenuItemIndexNewBooking : action.selectedMenuItemIndexNewBooking, 
        selectedMenuItemIndexPayment    : null,
        selectedMenuItemIndexStock      : null,
        selectedMenuItemIndexMaster     : null,
        selectedMenuItemIndexLocation   : null,
        selectedMenuItemIndexReport     : null,
        selectedMenuName                : action.selectedMenuName
      })
    case MENU_SELECT_PAYMENT:
      return Object.assign({}, state, {
        selectedMenuItemIndexBooking    : null,
        selectedMenuItemIndexNewBooking : null, 
        selectedMenuItemIndexPayment    : action.selectedMenuItemIndexPayment,
        selectedMenuItemIndexStock      : null,
        selectedMenuItemIndexMaster     : null,
        selectedMenuItemIndexLocation   : null,
        selectedMenuItemIndexReport     : null,
        selectedMenuName                : action.selectedMenuName
      })
    case MENU_SELECT_STOCK:
      return Object.assign({}, state, {
        selectedMenuItemIndexBooking    : null,
        selectedMenuItemIndexNewBooking : null, 
        selectedMenuItemIndexPayment    : null,
        selectedMenuItemIndexStock      : action.selectedMenuItemIndexStock,
        selectedMenuItemIndexMaster     : null,
        selectedMenuItemIndexLocation   : null,
        selectedMenuItemIndexReport     : null,
        selectedMenuName                : action.selectedMenuName
      })
    case MENU_SELECT_MASTER:
      return Object.assign({}, state, {
        selectedMenuItemIndexBooking    : null,
        selectedMenuItemIndexNewBooking : null, 
        selectedMenuItemIndexPayment    : null,
        selectedMenuItemIndexStock      : null,
        selectedMenuItemIndexMaster     : action.selectedMenuItemIndexMaster,
        selectedMenuItemIndexLocation   : null,
        selectedMenuItemIndexReport     : null,
        selectedMenuName                : action.selectedMenuName
      })
    case MENU_SELECT_LOCATION:
      return Object.assign({}, state, {
        selectedMenuItemIndexBooking    : null,
        selectedMenuItemIndexNewBooking : null, 
        selectedMenuItemIndexPayment    : null,
        selectedMenuItemIndexStock      : null,
        selectedMenuItemIndexMaster     : null,
        selectedMenuItemIndexLocation   : action.selectedMenuItemIndexLocation,
        selectedMenuItemIndexReport     : null,
        selectedMenuName                : action.selectedMenuName
      })
    case MENU_SELECT_REPORT:
      return Object.assign({}, state, {
        selectedMenuItemIndexBooking    : null,
        selectedMenuItemIndexNewBooking : null, 
        selectedMenuItemIndexPayment    : null,
        selectedMenuItemIndexStock      : null,
        selectedMenuItemIndexMaster     : null,
        selectedMenuItemIndexLocation   : null,
        selectedMenuItemIndexReport     : action.selectedMenuItemIndexReport,
        selectedMenuName                : action.selectedMenuName
      })
    case MENU_SELECT_INVOICE:
      return Object.assign({}, state, {
        selectedMenuItemIndexInvoice      : action.selectedMenuItemIndexInvoice,
        selectedMenuItemIndexExpenditure  : null,
        selectedMenuItemIndexRevenue      : null,
        selectedMenuName                  : action.selectedMenuName
      })
    case MENU_SELECT_EXPENDITURE:
      return Object.assign({}, state, {
        selectedMenuItemIndexInvoice      : null,
        selectedMenuItemIndexExpenditure  : action.selectedMenuItemIndexExpenditure,
        selectedMenuItemIndexRevenue      : null,
        selectedMenuName                  : action.selectedMenuName
      })
    case MENU_SELECT_REVENUE:
      return Object.assign({}, state, {
        selectedMenuItemIndexInvoice      : null,
        selectedMenuItemIndexExpenditure  : null,
        selectedMenuItemIndexRevenue      : action.selectedMenuItemIndexRevenue,
        selectedMenuName                  : action.selectedMenuName
      })
    default:
    return state;
  }
};