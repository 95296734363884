import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import CommonButton from '../../../../common/mirai_component/CommonButton'
import CommonRadio from '../../../../common/CommonRadio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';



class LocationEditDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      insertData      : false,
      loadedData      : false,
      locationId      : 0,
      location        : '',
      updatedAt       : '',
      errorMsg :{
        location        : '',
        pickupMethodId  : '',
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

  }


  handleClose() {
  
    this.setState({loadedData:false});
    this.props.handleEditDialogCloseEvent();
  
  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  componentWillUpdate(nextProps,nextState){

    if ( !this.state.loadedData && nextProps.editLocationId !== -1 && nextProps.showEditDialog === true ) {

      // 新規
      if ( nextProps.editLocationId === 0 ) {
        this.setState({
          locationId     : '',
          location       : '',
          insertData     : true,
        });
      }
      // 更新
      else {

        const search_condition = {
          locationId : [nextProps.editLocationId],
        };
    
        // 単価検索
        fetch('/api/searchLocation', {
          method: "POST",
          body: JSON.stringify(search_condition), 
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            locationId     : responseJson[0].location_id,
            location       : responseJson[0].location,
            updatedAt      : responseJson[0].updatedAt,
            insertData     : false,
          });
        })
        .catch((error) =>{
          console.error(error);
        });

      }

      this.state.loadedData = true;

    }

  }


  // 保存ボタン押下時
  handleClickSave(event) {

    if ( this.state.insertData ) {

      // Location登録(INSERT)
      fetch('/api/resolveNewLocation', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleClose();
      })
      .catch((error) =>{
        console.error(error);
      });

    }
    else {

      // Location登録(UPDATE)
      fetch('/api/resolveLocation', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleClose();
      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showEditDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">Location Edit</DialogTitle>
          <DialogContent className="MyDialogContent">
            <MiraiTextField id="location"  name="location"  label="Location"  handleChange={this.handleChange} readOnly={false}  value={this.state.location}   errorText={this.state.errorMsg.location}   />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}


export default LocationEditDialog;