import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";
import MiraiTextField from "../../../../common/mirai_component/MiraiTextField";
import CommonRadio from "../../../../common/CommonRadio";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

class BookingConfirmDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      insertData: false,
      loadedData: false,
      booking_status_id: null,
      updatedAt: "",
      errorMsg: {
        booking_status_id: "",
      },
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
  }

  handleClose() {
    this.setState({ loadedData: false, booking_status_id: null });
    this.props.handleEditDialogCloseEvent();
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    this.setState({ [name]: event.target.value });
    this.setState({ errorMsg: { ...this.state.errorMsg, [name]: "" } });
  }

  handleClickSave(event) {
    if (!this.state.booking_status_id) {
      this.state.errorMsg.booking_status_id = "Please choose the status.";
      this.setState({ errorMsg: this.state.errorMsg });
      return;
    }
    const data = {};
    data.bookingId = parseInt(this.props.selectedBookingId);
    data.bookingStatus = parseInt(this.state.booking_status_id);

    fetch("/api/updateBookingStatus", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleClose();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{ width: "200px", marginLeft: "10px" }}
        startIcon={<SaveIcon />}
      >
        Save
      </Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{ width: "200px", marginLeft: "10px" }}
        startIcon={<CloseIcon />}
      >
        Close
      </Button>,
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showEditDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">
            Booking Status
          </DialogTitle>
          <DialogContent className="MyDialogContent">
            <CommonRadio
              radioList={this.props.agencyBookingStatusList}
              onChangeEvent={this.handleChange}
              name={"booking_status_id"}
              caption={"Booking Status"}
              radioValue={
                this.state.booking_status_id ??
                this.props.selectedBookingStatusId
              }
              errorText={this.state.errorMsg.booking_status_id}
            />
            <br />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

export default BookingConfirmDialog;
