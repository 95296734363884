import React, { Component } from 'react';
import { FormControl } from '@material-ui/core';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class CommonCheckbox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      checkboxList: !this.props.checkboxValue ? [] : this.props.checkboxValue,
    };
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
  }


  handleChangeEvent(event) {
    for(const each of this.props.checkboxValue){
      if(each.id == event.target.value){
        each.checked = event.target.checked;
      }
    }
    this.setState({ checkboxList: this.props.checkboxValue});
    this.props.onCheckedValue({name: this.props.name, list : this.props.checkboxValue});
  }
  

  render() {

    const list = (!this.props.checkboxValue ? [] : this.props.checkboxValue).map((item, key) => (
      ((!this.props.skip || !this.props.skip.includes(item.id)) &&
      <FormControlLabel
        control={
          <Checkbox name={this.props.name} onChange={this.handleChangeEvent} value={item.id}  checked={item.checked}/>
        }
        label={item.label}
        key={item.id}
      />
      )
    ))

    return (
      <FormGroup row>
        {list}
      </FormGroup>
    );
  }

}

export default CommonCheckbox;