import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CommonButton from '../../../../common/mirai_component/CommonButton'
import FormLabel from '@material-ui/core/FormLabel';
import * as commonFunction from '../../../../common/commonFunction';
import DeviceAssignmentDetail from './DeviceAssignmentDetail';
import DeviceAssignmentAvailableDevices from './DeviceAssignmentAvailableDevices';
import RaisedButton from 'material-ui/RaisedButton';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import CommonRadio from '../../../../common/CommonRadio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';


class DeviceAssignmentDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData          : false,
      bokkingId           : 0,
      bokkingDetailId     : 0,
      applyNumber         : 0,
      customerName        : '',
      customerTel         : '',
      customerAddress     : '',
      contractDate        : '',
      deliveryDate        : '',
      plan                : '',
      destinationId       : 0,
      destination         : '',
      quantity            : 0,
      updatedAt           : '',
      informDialogTitle   : '',
      informDialogMessage : '',
      devices             : [],
      availableDevices    : [],
      deviceNumber        : '',
      corporateName       : '',
      routerTypeId        : 9,
      routerTypeList      : [
        { id : '1' , label : 'WorldWide' },
        { id : '2' , label : 'VietNam'   },
        { id : '3' , label : 'Japan'     },
        { id : '9' , label : 'any'       },
      ],
      errorMsg :{
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.doSearchAvailableDevice = this.doSearchAvailableDevice.bind(this);
    this.onSelectDevice = this.onSelectDevice.bind(this);

  }


  handleClose() {

    this.setState({
      loadedData:false,
      availableDevices:[]
    });
    this.props.closeDeviceAssignmentDialogEvent(this.state.informDialogTitle,this.state.informDialogMessage);

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  componentWillUpdate(nextProps,nextState){
    
    if ( !this.state.loadedData && nextProps.selectedBookingId !== -1 && nextProps.showDeviceAssignmentDialog === true ) {

      const search_condition = {
        booking_id        : nextProps.selectedBookingId,
        booking_detail_id : nextProps.selectedBookingDetailId,
      };
      fetch('/api/searchBookingDetail', {
        method: "POST",
        body: JSON.stringify(search_condition), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        
        let customerName = '';
        let customerTel = '';
        let customerAddress = '';

        // 法人
        if ( responseJson[0]['t10_booking'].contract_type === 0 ) {
          customerName = responseJson[0]['t10_booking'].m20_corporate.corporate_name;
          customerTel = responseJson[0]['t10_booking'].m20_corporate.tel_number;
          customerAddress = responseJson[0]['t10_booking'].m20_corporate.address;
        }
        // 個人
        else {
          customerName = responseJson[0]['t10_booking'].customer_name;
          customerTel = responseJson[0]['t10_booking'].customer_tel;
          customerAddress = responseJson[0]['t10_booking'].customer_address;
        }


        let devices = [];
        for ( let i=0;i<responseJson[0].quantity;i++ ) {
          let deviceRow = {};
          deviceRow.row_id = (i+1);
          deviceRow.device_number = '';
          deviceRow.model = '';
          deviceRow.router_type = '';
          devices.push(deviceRow);
        }


        this.setState({
          bookingId       : responseJson[0].booking_id,
          bokkingDetailId : responseJson[0].booking_detail_id,
          applyNumber     : responseJson[0]['t10_booking'].apply_number,
          customerName    : customerName,
          customerTel     : customerTel,
          customerAddress : customerAddress,
          deliveryDate    : commonFunction.formatYYYYMMDD(new Date(responseJson[0]['delivery_date'])),
          contractDate    : commonFunction.formatYYYYMMDD(new Date(responseJson[0]['start_date'])) + " - " + commonFunction.formatYYYYMMDD(new Date(responseJson[0]['end_date'])),
          pickupMethodId  : responseJson[0]['t10_booking'].pickup_method_id,
          destinationId   : responseJson[0].destination_id,
          plan            : responseJson[0]['m30_plan'].plan,
          destination     : responseJson[0]['m40_destination'].destination,
          quantity        : responseJson[0].quantity,
          updatedAt       : responseJson[0]['t10_booking'].updatedAt,
          devices         : devices,
        });

      });

      this.state.loadedData = true;

    }

  }


  doSearchAvailableDevice(){

    const searchCriteria = {
      destination_id   : this.state.destinationId,
      pickup_method_id : this.state.pickupMethodId,
      deviceNumber     : this.state.deviceNumber,
      routerTypeId     : this.state.routerTypeId,
      corporateName    : this.state.corporateName,
    };

    fetch('/api/searchAvailableDevice', {
      method: "POST",
      body: JSON.stringify(searchCriteria), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let availableDevices = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let availableDevicesRow = {};
        availableDevicesRow.row_id = (i+1);
        availableDevicesRow.model = responseJson[i]['m90_model']['model'];
        availableDevicesRow.router_type = responseJson[i]['m90_model']['m50_router_type']['router_type'];
        availableDevicesRow.device_number = responseJson[i]['device_number'];
        availableDevicesRow.deviceFor = responseJson[i]['m20_corporate']===null? '' : responseJson[i]['m20_corporate']['corporate_name'];
        availableDevicesRow.select = 'select';
        availableDevicesRow.updatedAt = responseJson[i]['updatedAt'];
        availableDevices.push(availableDevicesRow);
      }

      this.setState({
        availableDevices:availableDevices
      })

    });

  }


  onSelectDevice(event){

    let cellIndex = event.target.cellIndex;

    // Select押下時
    if ( cellIndex == 5 ) {

      // 全ての行が選択済みだったら何もしない
      let notAssigned = false;
      let devices = this.state.devices;
      for ( let j=0;j<devices.length;j++ ) {
        if ( devices[j].device_number == '' ) {
          notAssigned = true;
          break;
        }
      }
      if ( !notAssigned ) {
        return;
      }

      let deviceNumber = event.target.children[0].value;
      let availableDevices = [];
      let rowId = 0;

      for ( let i=0;i<this.state.availableDevices.length;i++ ) {
        if ( this.state.availableDevices[i].device_number == deviceNumber ) {
          let devices = this.state.devices;
          for ( let j=0;j<devices.length;j++ ) {
            if ( devices[j].device_number == '' ) {
              devices[j].device_number = this.state.availableDevices[i].device_number;
              devices[j].model = this.state.availableDevices[i].model;
              devices[j].router_type = this.state.availableDevices[i].router_type;
              devices[j].updatedAt = this.state.availableDevices[i].updatedAt;
              break;
            }
          }
        }
        else {
          let availableDevicesRow = this.state.availableDevices[i];
          availableDevicesRow.row_id = ++rowId;
          availableDevices.push(availableDevicesRow);
        }
      }

      this.setState({
        availableDevices:availableDevices,
        devices:devices
      });
    }
  
  }


  // 保存ボタン押下時
  handleClickSave(event) {

    const reqParam = {}
    reqParam.bookingId = this.state.bookingId;
    reqParam.bokkingDetailId = this.state.bokkingDetailId;
    reqParam.updatedAt = this.state.updatedAt;
    reqParam.functionName = 'DeviceAssignment';
    reqParam.bookingStatus = this.props.newStatus;
    reqParam.assignedDevices = JSON.stringify(this.state.devices);

    fetch('/api/updateBookingStatusDeviceAssignment', {
      method: "POST",
      body: JSON.stringify(reqParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if ( responseJson.error === undefined ) {

        this.setState({
          informDialogTitle:'Update OK',
          informDialogMessage:'Succesfully update booking.',
        });

      }
      else {

        this.setState({
          informDialogTitle:'Update Error',
          informDialogMessage:'Update booking failed!!' + responseJson.error,
        });

      }

      this.handleClose();

    })
    .catch((error) =>{
      console.error(error);
    });

  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];
    
    
    
    
    
    
    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showDeviceAssignmentDialog}
          onRequestClose={this.handleClose}
          contentStyle={{
            width: "1250px",
            maxWidth: "none"
          }}
        >
          <DialogTitle id="alert-dialog-slide-title">Device Assignment</DialogTitle>
          <DialogContent className="MyDialogContent">
            <FormLabel component="legend">Plan : {this.state.plan}</FormLabel>
            <br />
            <FormLabel component="legend">Destination : {this.state.destination}</FormLabel>
            <br />
            <FormLabel component="legend">Quantity : {this.state.quantity}</FormLabel>
            <br />
            <DeviceAssignmentDetail dataRows={this.state.devices}/>
            <MiraiTextField width={200} handleChange={this.handleChange}  id="deviceNumber"    name="deviceNumber"    label="Device Number"  value={this.state.deviceNumber} errorText={''} />
            <br />
            <MiraiTextField width={200} handleChange={this.handleChange}  id="corporateName"   name="corporateName"   label="Device For"     value={this.state.corporateName} errorText={''} />
              <br />
            <CommonRadio visible={true} readOnly={false}  radioList={this.state.routerTypeList}   onChangeEvent={this.handleChange}   name={'routerTypeId'}   caption={'Router Type'}   radioValue={this.state.routerTypeId} />
            <br />
            <RaisedButton label="Search Available Device" onClick={this.doSearchAvailableDevice} style={{marginTop:"5px"}}/>
            <DeviceAssignmentAvailableDevices onCellClickEvent={this.onSelectDevice} dataRows={this.state.availableDevices}/>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}

export default DeviceAssignmentDialog;

/*
*/