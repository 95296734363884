import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SearchIcon from '@material-ui/icons/Search';
import PhoneIcon from '@material-ui/icons/Phone';
import RouterIcon from '@material-ui/icons/Router';
import AddShoppingCartSharpIcon from '@material-ui/icons/AddShoppingCartSharp';
import AirportShuttleSharpIcon from '@material-ui/icons/AirportShuttleSharp';
import ThumbsUpDownSharpIcon from '@material-ui/icons/ThumbsUpDownSharp';
import KeyboardReturnSharpIcon from '@material-ui/icons/KeyboardReturnSharp';

const useStyles = makeStyles(theme => ({
  root: {
    width: '200px',
    maxWidth: 360,
    backgroundColor: '#a9a9a9'
  },
}));


const options = [
  'Search Booking',
  'Contact Customer',
  'Device Assignment',
  'Packing Devices',
  'Send devices',
  'Hand Over devices',
  'Return devices',
];


const menuLinks = [
  '/searchBooking',
  '/searchBookingContactCustomer',
  '/searchBookingDeviceAssignment',
  '/searchBookingPackingDevice',
  '/searchBookingSendDevice',
  '/searchBookingHandOverDevice',
  '/searchBookingReturnDevice',
]


export default function AnnamMenuBooking(pParams) {

  const classes = useStyles();
  const pSelectedIndex = pParams.selectedMenuIndex;

  const [anchorEl, setAnchorEl] = React.useState(null);
  let [selectedIndex, setSelectedIndex] = React.useState(pSelectedIndex);

  selectedIndex = pSelectedIndex;

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };


  const handleMenuItemClick = (event, index ,functionName) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    pParams.onClickEvent(index,functionName);
    pParams.screenTransitEvent.push(menuLinks[index]);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className={classes.root}>
      <List component="nav" aria-label="Booking">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="Booking"
          onClick={handleClickListItem}
        >
          <ListItemText primary="Booking" secondary={options[selectedIndex]} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          ((pParams.accept.length == 0 || pParams.accept.includes(menuLinks[index])) &&
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={event => handleMenuItemClick(event, index, options[index])}
          >
            <ListItemIcon>
              {   index === 0 ? <SearchIcon fontSize="small" /> : 
                ( index === 1 ? <PhoneIcon fontSize="small" /> : 
                ( index === 2 ? <RouterIcon fontSize="small" /> : 
                ( index === 3 ? <AddShoppingCartSharpIcon fontSize="small" /> :
                ( index === 4 ? <AirportShuttleSharpIcon fontSize="small" /> : 
                ( index === 5 ? <ThumbsUpDownSharpIcon fontSize="small" /> : <KeyboardReturnSharpIcon fontSize="small" /> ) ) ) ) )
              }
            </ListItemIcon>
            {option}
          </MenuItem>
          )
        ))}
      </Menu>
    </div>
  );
}