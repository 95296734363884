import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '660px',
    minHeight: '25px',
    margin: '5px 0 0',
  },
  textField400: {
    width: '570px',
    height: '25px',
  },
  textField200: {
    width: '270px',
    height: '25px',
  },
}));

export default function Label(pPrams) {
  const classes = useStyles();
  const params = pPrams;

  let readOnly = params.readOnly;
  let visible = params.visible;

  const width = params.width;

  if (visible == undefined || visible) {
    let classField = '';
    if (width == 200) {
      classField = classes.textField200;
    } else if (width == 400) {
      classField = classes.textField400;
    } else {
      classField = classes.textField;
    }

    return (
      <FormControl>
        <TextField
          {...pPrams}
          id={params.id}
          name={params.name}
          label={params.label}
          value={params.value}
          margin="normal"
          className={classField}
          onChange={params.handleChange}
          error={params.errorText !== ''}
          helperText={params.errorText}
          type={params.type}
          InputLabelProps={{
            classes: {
              asterisk: 'Mui-error',
            },
            ...params.InputLabelProps,
          }}
          inputProps={params.inputProps}
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </FormControl>
    );
  } else {
    return <FormControl>{}</FormControl>;
  }
}
