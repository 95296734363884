import React, { Component } from 'react';
import Menu from '../../../common/Menu';
import ScreenTitle from '../../../common/ScreenTitle';

import CommonButton from '../../../common/mirai_component/CommonButton'

import FormLabel from '@material-ui/core/FormLabel';
import CommonCheckbox from '../../../common/CommonCheckbox';
import MonitorlingListTable from './MonitorlingListTable';

import {connect} from 'react-redux';

class Monitorling extends Component {

  constructor(props) {

    super(props);

    this.state = {
      location         : [],
      locationList     : [],
      routerType       : [],
      routerTypeList   : [],
      tableColumns     : [],
      tableData        : [],
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.getStringDateVal = this.getStringDateVal.bind(this);

  }

  componentDidMount(prevProps) {

    let requestParam = {};

    fetch('/api/getAllLocation', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let locationList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let locationRow = {};
        locationRow.id    = responseJson[i].location_id;
        locationRow.label = responseJson[i].location;
        locationList.push(locationRow);
      }
      this.setState({
        locationList:locationList
      });
    })
    .catch((error) =>{
      console.error(error);
    });


    fetch('/api/getAllRouterType', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let routerTypeList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let routerTypeRow = {};
        routerTypeRow.id    = responseJson[i].router_type_id;
        routerTypeRow.label = responseJson[i].router_type;
        routerTypeList.push(routerTypeRow);
      }
      this.setState({
        routerTypeList:routerTypeList
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  handleCheckboxChange(event) {
  
    const name = event.name;
    const checked_list = [];
    for(const each of event.list){
      if(each.checked){
        checked_list.push(each.id);
      }
    }
    this.setState({ [name] : checked_list});
 
  }
  
  getStringDateVal(date) {
    return ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
  }

  getStringDateYYYYMMDD(date){
    return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2); 
  }

  handleClickSearch(){

    fetch('/api/stcokMonitoring', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let tableColumns = []
      tableColumns.push( {id: 'location'       , label: 'Location'     , minWidth: 300 } );
      tableColumns.push( {id: 'router_type'    , label: 'Router Type'  , minWidth: 100 } );
  
      for ( let i=0;i<responseJson.length;i++ ) {
        if ( responseJson[i].corporate_name !== undefined && responseJson[i].corporate_name !== null && responseJson[i].corporate_name !== '' ) {
          responseJson[i].location = responseJson[i].location + " : " + responseJson[i].corporate_name;
        }
      }

      if ( responseJson.length > 0 ) {

        for ( let j=0;j<30;j++ ) {

          let baseDate = new Date(responseJson[0].start_date);
          baseDate.setDate(baseDate.getDate() + j);
          const dateString = this.getStringDateVal(baseDate);
          const dateStringYYYYMMDD = this.getStringDateYYYYMMDD(baseDate);
          tableColumns.push( {id: dateStringYYYYMMDD  , label: dateString  , minWidth: 40 , align: 'right'} );
  
        }

      }

      this.setState({
        tableColumns : tableColumns,
        tableData    : responseJson
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }


  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Location</FormLabel></div>
            <CommonCheckbox name="location" checkboxValue={this.state.locationList} onCheckedValue={this.handleCheckboxChange}/>
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Router Type</FormLabel></div>
            <CommonCheckbox name="routerType" checkboxValue={this.state.routerTypeList} onCheckedValue={this.handleCheckboxChange}/>
        </div>
        <CommonButton visible={true}   clickEvent={this.handleClickSearch}   label="Search"   />
        <MonitorlingListTable tableColumns={this.state.tableColumns} tableData={this.state.tableData} />
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Monitorling);