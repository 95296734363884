import React, { Component } from 'react';

import MenuGW from '../../common/MenuGW';
import {connect} from 'react-redux';


class TopGW extends Component {

  constructor(props) {

    super(props);
    this.state = {
      email                            : '',
      password                         : '',
    };

    this.handleChange = this.handleChange.bind(this);

  }



  componentDidMount(prevProps) {
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  render() {

    return (
      <div align="center">
        <MenuGW history={this.props.history}/>
      </div>
    );
 
  }

}

const mapStateToProps = state => {
  return {
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopGW);