import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import StyledPopup from "reactjs-popup";
import Button from '@material-ui/core/Button';


const columns = [
  { id: 'date_time', label: 'Date/Time', minWidth: 70},
  { id: 'customer_name', label: 'Staff\u00a0Name', minWidth: 150 },
  {
    id: 'action',
    label: 'Action',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'detail',
    label : 'detail',
    minWidth: 100,
    align: 'center'
  }
];



let rows = [
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 600,
  },
  applyNumber : {
    "text-decoration": "underline"
  },
  cell:{
    "line-height":"0.2rem"
  },
  header_cell:{
    "background-color":"#afeeee",
    "line-height":"0.2rem"
  },
});


export default function UpdateLogDialogList(pPrams) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const params = pPrams;
  rows = params.dataRows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.header_cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.date_time}>
                  {columns.map(column => {
                      const value = row[column.id];
                      if ( column.id !== 'detail' ) {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      }
                      else {
                        const popup = row['update_contents'];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <StyledPopup contentStyle={{"text-align":"left","overflow":"auto","top":"-50px","height":"500px"}} trigger={<Button>Detail</Button>} position="right center">
                              <pre>{popup}</pre>
                            </StyledPopup>
                          </TableCell>
                        );
                      }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}