import React, { Component } from 'react';
import SendDeviceList from './list/SendDeviceList';
import InformDialog from '../../../common/mirai_component/InformDialog'

import Menu from '../../../common/Menu';
import ScreenTitle from '../../../common/ScreenTitle';

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import CommonCheckbox from '../../../common/CommonCheckbox';

import CommonButton from '../../../common/mirai_component/CommonButton'

import {connect} from 'react-redux';
import * as constVal from '../../../common/constant';
import * as commonFunction from '../../../common/commonFunction';

import SendDeviceDialog from './dialog/SendDeviceDialog';

import const_val from '../../../const';

class SendDevice extends Component {

  constructor(props) {

    super(props);
    this.state = {
      customer_name               : '',
      corporate_name              : '',
      contract_date_from          : '',
      contract_date_to            : '',
      apply_date_from             : '',
      apply_date_to               : '',
      apply_number                : '',
      dataRows                    : [],
      showSendDeviceDialog        : false,
      informDialogOpen            : false,
      informDialogTitle           : '',
      informDialogMessage         : '',
      selectedBookingId           : '',
      selectedBookingDetailId     : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.closeSendDeviceDialogEvent = this.closeSendDeviceDialogEvent.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }


  componentDidMount(prevProps) {

    // ログイン認証チェック
    // ログイン済みであればメニューを表示
    // checkSessionStatus(this.props,'/searchBookingSendDevice');

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

    this.handleClickSearch(true);

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  closeUpdateLogDialogEvent(){
    this.setState({showUpdateLogDialog:false});
  }


  // 一覧のCellClick時
  onCellClick(event){
  
    let rowIndex = event.target.parentElement.rowIndex;
    let cellIndex = event.target.cellIndex;
    let keyVal = event.target.parentElement.children[0].textContent;

    // ApplyNumber押下時 -> BookingPageへ遷移
    if ( cellIndex == 0 ) {
      // 会社
      if ( event.target.children[0].value === '0' ) {
        this.props.history.push('/bookingCorporate/' + keyVal);
      }
      // 個人
      else {
        this.props.history.push('/bookingPersonal/' + keyVal);
      }
    }
    // Open Log dialog.
    else if ( cellIndex == 9 ) {
      let bookingId = event.target.children[0].value;
      let bookingDetailId = event.target.children[1].value;
      this.setState({
        showSendDeviceDialog:true,
        selectedBookingId:bookingId,
        selectedBookingDetailId:bookingDetailId,
      });
    }

  }


  handleCheckboxChange(event) {
  
    const name = event.name;
    const checked_list = [];
    for(const each of event.list){
      if(each.checked){
        checked_list.push(each.id);
      }
    }
    this.setState({ [name] : checked_list});
  
  }



  // 検索ボタン押下時の処理
  handleClickSearch(noMessage){
    
    // Booking検索API
    const search_condition = {
      booking_sub_status : [constVal.booking_status.ReadyForSend],
      corporate_name     : this.state.corporate_name,
      customer_name      : this.state.customer_name,
      contract_date_from : this.state.contract_date_from,
      contract_date_to   : this.state.contract_date_to,
      apply_date_from    : this.state.apply_date_from,
      apply_date_to      : this.state.apply_date_to,
      apply_number       : this.state.apply_number
    };

    fetch('/api/searchBookingDetail', {
      method: "POST",
      body: JSON.stringify(search_condition), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let resultDate = [];
      for ( let i=0;i<responseJson.length;i++ ) {

        let resultRow = {};

        resultRow['apply_number'] = responseJson[i]["t10_booking"]["apply_number"];

        if ( responseJson[i]["t10_booking"]['contract_type'] == constVal.contract_type['Corporate'] ) {
          resultRow['customer_name'] = responseJson[i]["t10_booking"]["m20_corporate"]["corporate_name"];
        }
        else {
          resultRow['customer_name'] = responseJson[i]["t10_booking"]['customer_name'];
        }
        resultRow['payment_method'] = commonFunction.getPaymentMethodNameByVal(responseJson[i]['t10_booking']['payment_method']);

        resultRow['apply_date'] = new Date(responseJson[i]["t10_booking"]['apply_date']).toLocaleString();
        resultRow['total_fee'] = parseInt(responseJson[i]["t10_booking"]['total_fee']).toLocaleString();

        resultRow['contract_date'] = commonFunction.formatYYYYMMDD(new Date(responseJson[i]['start_date'])) + " - " + commonFunction.formatYYYYMMDD(new Date(responseJson[i]['end_date']));
        resultRow['delivery_date'] = commonFunction.formatYYYYMMDD(new Date(responseJson[i]['delivery_date']));

        resultRow['contract_type'] = responseJson[i]["t10_booking"]['contract_type'];

        resultRow['destination'] = responseJson[i]["m40_destination"]["destination"];
        resultRow['plan'] = responseJson[i]["m30_plan"]["plan"];
        resultRow['quantity'] = responseJson[i]["quantity"];        

        resultRow['action'] = '[Assign]';

        resultRow['booking_id'] = responseJson[i]['booking_id'];
        resultRow['booking_detail_id'] = responseJson[i]['booking_detail_id'];

        resultDate.push(resultRow);

      }

      this.setState({dataRows:resultDate});

      if ( typeof noMessage !== 'boolean' && responseJson.length === 0 ) {
        this.setState({
          informDialogTitle   : "Search Booking Result",
          informDialogMessage : "No data found.",
          informDialogOpen    : true,
        });
      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  closeSendDeviceDialogEvent(informDialogTitle,informDialogMessage){

    let informDialogOpen = false;
    if ( informDialogTitle !== '' ) {
      informDialogOpen = true;
    }

    this.setState({
      showSendDeviceDialog : false,
      informDialogTitle    : informDialogTitle,
      informDialogMessage  : informDialogMessage,
      informDialogOpen     : informDialogOpen,
    });
  
  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Customer Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="customer_name" name="customer_name" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Corporate Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="corporate_name" name="corporate_name" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Contract Date</FormLabel></div>
            <TextField onChange={this.handleChange} id="contract_date_from" name="contract_date_from" style={{width: "200px"}} type="date" /> ~ 
            <TextField onChange={this.handleChange} id="contract_date_to" name="contract_date_to" style={{width: "200px"}} type="date" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Apply Date</FormLabel></div>
            <TextField onChange={this.handleChange} id="apply_date_from" name="apply_date_from" style={{width: "200px"}} type="date" /> ~ 
            <TextField onChange={this.handleChange} id="apply_date_to" name="apply_date_to" style={{width: "200px"}} type="date" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Apply Number</FormLabel></div>
            <TextField onChange={this.handleChange} id="apply_number" name="apply_number" style={{width: "500px"}} type="search" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch} label="Search booking"/>
        <br />
        <SendDeviceList dataRows={this.state.dataRows} onCellClickEvent={this.onCellClick}/>
        <SendDeviceDialog showSendDeviceDialog={this.state.showSendDeviceDialog} closeSendDeviceDialogEvent={this.closeSendDeviceDialogEvent} selectedBookingId={this.state.selectedBookingId}  selectedBookingDetailId={this.state.selectedBookingDetailId}/>
        <InformDialog informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendDevice);