import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  header: {
    height: '30px',
    backgroundColor:'#fffaf0',
    position:"relative"
  },
  div_logo:{
    display: "inline-block",
    float:"left",
    width:"30%",
    "text-align":"left"
  },
  div_title:{
    display: "inline-block",
    float:"center",
    width:"40%",
    "font-size":"1.3rem" 
  },
  div_name:{
    display: "inline-block",
    float:"right",
    width:"30%",
    "text-align":"right",
  }
});

export default function HeaderGW(pPrams) {

  const params = pPrams;

  const classes = useStyles();

  let userName = '　';
  if ( params.login_user_name !== undefined && params.login_user_name !== '' ) {
    userName = params.login_user_name
  }

  let email = '　';
  if ( params.login_user_email !== undefined && params.login_user_email !== '' ) {
    email = '(' + params.login_user_email + ')';
  }

  return (
    <div className={classes.header}>
      <div className={classes.div_logo}>
        <img src={`${process.env.PUBLIC_URL}/logo.webp`} alt="Logo" height="30" />
      </div>
      <div className={classes.div_title}>
        annam Group Ware
      </div>
      <div className={classes.div_name}>
        {userName} {email}
        {(params.login_user_name !== undefined && params.login_user_name !== '') &&
          <Link href="/logout">Logout</Link>
        }
      </div>
    </div>
  );

}
