import React, { Component } from 'react';

import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';
import InformDialog from '../../../../common/mirai_component/InformDialog'

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

import CommonButton from '../../../../common/mirai_component/CommonButton'

import {connect} from 'react-redux';
import * as commonFunction from '../../../../common/commonFunction';
import PickupList from './PickupList';

class Pickup extends Component {

  constructor(props) {

    super(props);
    this.state = {
      pickupDataFrom       : '',
      pickupDataTo         : '',
      reservationNumber    : '',
      userName             : '',
      dataRows             : [],
      informDialogOpen     : false,
      informDialogTitle    : '',
      informDialogMessage  : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }



  componentDidMount(prevProps) {

    this.handleClickSearch();

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  // 一覧のCellClick時
  onCellClick(event){

    let cellIndex = event.target.cellIndex;

    // Detail押下 -> BookingDetailへ遷移
    if ( cellIndex == 5 ) {

      let filePath = event.target.children[0].value;
      this.state.selectedFilePath = filePath;

      var saveAs = require('file-saver');

      fetch('/api/downloadRequestIssueInvoiceList', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, 'PaymentBookingList.xlsx');
        this.state.selectedFilePath = '';
      });

    }

  }



  // 検索ボタン押下時の処理
  handleClickSearch(){

    // Excelダウンロード実績検索
    fetch('/api/searchPickupList', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      for ( let i=0;i<responseJson.length;i++ ) {
        responseJson[i].reservationId = responseJson[i].reservation_id;
        if ( responseJson[i].customer_name !== null && responseJson[i].customer_name !== '' ) {
          responseJson[i].customerName = responseJson[i].customer_name;
        }
        else if ( responseJson[i].user_name !== null && responseJson[i].user_name !== '' ) {
          responseJson[i].customerName = responseJson[i].user_name;
        }
        responseJson[i].totalFee = responseJson[i].total_fee;
      }

      this.setState({dataRows:responseJson});

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

    this.handleClickSearch();

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
          <div style={{width: "300px"}}><FormLabel>Reserveation Number</FormLabel></div>
          <TextField onChange={this.handleChange} id="reservationNumber" name="reservationNumber" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
          <div style={{width: "300px"}}><FormLabel>User Name</FormLabel></div>
          <TextField onChange={this.handleChange} id="userName"   name="userName"   style={{width: "500px"}} type="search" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch}   label="Search"/>
        <br />
        <PickupList dataRows={this.state.dataRows} />
        <InformDialog  informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pickup);