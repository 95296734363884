import React, { Component } from 'react';
import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';

import CommonButton from '../../../../common/mirai_component/CommonButton'
import UserListTable from './UserListTable';
import UserEditDialog from './UserEditDialog';

import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';

import {connect} from 'react-redux';

class User extends Component {

  constructor(props) {

    super(props);

    this.state = {
      searchParamUserName : '',
      userList            : [],
      showEditDialog      : false,
      editUserId          : null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleEditRowClick = this.handleEditRowClick.bind(this);
    this.handleEditDialogClose = this.handleEditDialogClose.bind(this);
    this.handleClickAddNew = this.handleClickAddNew.bind(this);

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  handleClickSearch(){

    let requestParam = {};
    requestParam.userName = this.state.searchParamUserName;

    // ユーザ検索
    fetch('/api/searchUser', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      for ( let i=0;i<responseJson.length;i++ ) {

        if ( responseJson[i]['m80_location'] !== null ) {
          responseJson[i].location = responseJson[i]['m80_location']['location'];
        }
        if ( responseJson[i]['m20_corporate'] !== null ) {
          responseJson[i].corporate = responseJson[i]['m20_corporate']['corporate_name'];
        }

      }

      this.setState({
        userList : responseJson
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  handleClickAddNew(event){

    this.setState({
      showEditDialog : true,
      editUserId     : 0,
    });

  }



  handleEditRowClick(event){

    if ( event.target.cellIndex === 4 ) {

      let userId = event.target.children[0].value;
      this.setState({
        showEditDialog:true,
        editUserId:userId,
      });

    }

  }



  handleEditDialogClose(){

    this.setState({
      showEditDialog:false,
      editUserId:null
    });

    this.handleClickSearch();

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <MiraiTextField handleChange={this.handleChange}    readOnly={false}   id="searchParamUserName"       name="searchParamUserName"     label="User Name"     value={this.state.searchParamUserName}   errorText={''}/>
        <br />
        <CommonButton visible={true}   clickEvent={this.handleClickSearch}   label="Search"   />
        <CommonButton visible={true}   clickEvent={this.handleClickAddNew}   label="Add New"  />

        <UserListTable dataRows={this.state.userList} editRowClick={this.handleEditRowClick} />
        <UserEditDialog showEditDialog={this.state.showEditDialog} handleEditDialogCloseEvent={this.handleEditDialogClose} editUserId={this.state.editUserId} />
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);