import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles(theme => ({
  root: {
    width: '200px',
    maxWidth: 360,
    backgroundColor: '#a9a9a9'
  },
}));

const options = [
  'Search Revenue',
  'Monthly Summary Report',
  'Entry Revenue',
];


const menuLinks = [
  '/searchRevenue',
  '/summaryRevenue',
  '/entryRevenue',
]

export default function MenuGWRevenue(pParams) {

  const classes = useStyles();
  const pSelectedIndex = pParams.selectedMenuIndex;

  const [anchorEl, setAnchorEl] = React.useState(null);
  let [selectedIndex, setSelectedIndex] = React.useState(pSelectedIndex);

  selectedIndex = pSelectedIndex;

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };


  const handleMenuItemClick = (event, index,functionName) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    pParams.onClickEvent(index,functionName);
    pParams.screenTransitEvent.push(menuLinks[index]);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className={classes.root}>
      <List component="nav" aria-label="Revenue">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="Invoice"
          onClick={handleClickListItem}
        >
          <ListItemText primary="Revenue" secondary={options[selectedIndex]} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={event => handleMenuItemClick(event, index, options[index])}
          >
            <ListItemIcon>
              {   
                index === 0 ? <SearchIcon fontSize="small" /> : <CreateIcon fontSize="small" />
              }
            </ListItemIcon>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
