import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

class ChangePasswordDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData          : false,
      current_password    : '',
      new_password        : '',
      new_password_confirm: '',
      informDialogTitle   : '',
      informDialogMessage : '',
      errorMsg :{
        current_password  : '',
        new_password      : '',
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }


  handleClose() {

    this.setState({loadedData:false});
    this.props.closeChangePasswordDialogEvent(this.state.informDialogTitle,this.state.informDialogMessage, true);

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});
    
    const errorMsg = Object.assign({}, this.state.errorMsg);
    errorMsg[name] = '';
    if(name == 'new_password' || name == 'new_password_confirm'){
      var confirm_value = (name == 'new_password') ? this.state.new_password_confirm : this.state.new_password;
      if(event.target.value == confirm_value) {
        var target_name = (name == 'new_password') ? 'new_password_confirm' : 'new_password';
        errorMsg[target_name] = '';
      }
    }
    this.setState({ errorMsg : errorMsg });

  }

  componentWillUpdate(nextProps,nextState){
    
  }

  validateForm(){
    let hasError = false;

    const errorMsg = Object.assign({}, this.state.errorMsg);
    if ( this.state.current_password === '' ) {
      errorMsg.current_password = 'Current password cannot be blank';
      hasError = true;
    }
    if ( this.state.new_password === '' ) {
      errorMsg.new_password = 'Please input new password';
      hasError = true;
    } else {
      if (this.state.new_password != this.state.new_password_confirm) {
        errorMsg.new_password = 'Confirm password does not match new password';
        hasError = true;
      }
    }

    this.setState({errorMsg:errorMsg});
    return hasError;
  }

  // InformDialog閉じるボタン
  informDialogCloseEvent(){
    alert('aaa');
  }



  // 保存ボタン押下時
  handleClickSave(event) {

    if ( this.validateForm() ) {

      let errorMsg = 'Error(s) occurs.';
      this.setState({
        informDialogOpen:true,
        informDialogTitle:'Regist Error',
        informDialogMessage:errorMsg,
      });
      return;

    }
console.log(this.props);
    const reqParam = {}
    reqParam.current_password = this.state.current_password;
    reqParam.new_password = this.state.new_password;
    reqParam.functionName = 'ChangePassword';

    fetch('/api/changePassword', {
      method: "POST",
      body: JSON.stringify(reqParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      console.log(responseJson, this.props);

      if ( responseJson.error === undefined ) {

        this.setState({
          informDialogTitle:'Update OK',
          informDialogMessage:'Your password has been changed successfully.',
        });
        this.props.closeChangePasswordDialogEvent(this.state.informDialogTitle,this.state.informDialogMessage, false);

      }
      else {

        this.setState({
          informDialogTitle:'Update Error',
          informDialogMessage: responseJson.error,
        });
        this.props.closeChangePasswordDialogEvent(this.state.informDialogTitle,this.state.informDialogMessage, false);

      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showChangePasswordDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">Change Password</DialogTitle>
          <DialogContent className="MyDialogContent">
            <TextField onChange={this.handleChange} id="current_password" name="current_password" label="Current Password" fullWidth value={this.state.current_password} type="password" error={this.state.errorMsg.current_password !== ""} helperText={this.state.errorMsg.current_password} />
            <br />
            <TextField onChange={this.handleChange} id="new_password" name="new_password" label="New Password" fullWidth value={this.state.new_password} type="password" error={this.state.errorMsg.new_password !== ""} helperText={this.state.errorMsg.new_password} />
            <br />
            <TextField onChange={this.handleChange} id="new_password_confirm" name="new_password_confirm" label="Password Confirm" fullWidth value={this.state.new_password_confirm} type="password" />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}

export default ChangePasswordDialog;