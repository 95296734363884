import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import DialogSelect from '../../../../common/DialogSelect'
import DeviceStatusRadio from '../../../../common/DeviceStatusRadio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

class DeviceEditDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData      : false,
      location_list   : [],
      location_id     : '',
      deviceNumber    : '',
      model           : '',
      sim_number      : '',
      imei            : '',
      password        : '',
      ssid            : '',
      registerdDate   : '',
      deviceStatus    : '',
      updatedAt       : '',
      errorMsg :{
        deviceNumber   : '',
        registerdDate  : '',
        ssid           : '',
        sim_number     : '',
        imei           : '',
        password       : '',
  
      }

    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleChangeDeviceStatus = this.handleChangeDeviceStatus.bind(this);

  }



  handleClose() {
    this.setState({loadedData:false});
    this.props.handleEditDialogCloseEvent();
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  componentDidMount(prevProps) {

    fetch('/api/getAllLocation', {
      method: "POST",
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let list = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let row = {};
        row.id = responseJson[i].location_id;
        row.label = responseJson[i].location;
        list.push(row);
      }
      this.setState({
        location_list:list
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }



  componentWillUpdate(nextProps,nextState){

    if ( nextProps.editDeviceNumber !=null && !this.state.loadedData ) {

      let queryParam = {};
      queryParam.deviceNumberExact = nextProps.editDeviceNumber;
      queryParam.modelName = '';

      // Device検索
      fetch('/api/searchDevice', {
        method: "POST",
        body: JSON.stringify(queryParam), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          location_id      : responseJson[0].location_id,
          deviceNumber     : responseJson[0].device_number,
          registerdDate    : new Date(responseJson[0].register_date).toLocaleDateString(),
          ssid             : responseJson[0].ssid,
          sim_number       : responseJson[0].sim_number,
          imei             : responseJson[0].imei,
          password         : responseJson[0].password,
          deviceStatus     : responseJson[0].device_status,
          updatedAt        : responseJson[0].updatedAt,
          loadedData       : true,
        });
      })
      .catch((error) =>{
        console.error(error);
      });

    }
  }



  // 保存ボタン押下時
  handleClickSave(event) {

    // DEVICE登録(UPDATE)
    fetch('/api/resolveDevice', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.handleClose();
    })
    .catch((error) =>{
      console.error(error);
    });

  }



  handleChangeDeviceStatus(event){
    this.handleChange(event);
  }


  
  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
          <Dialog
            actions={actions}
            modal={false}
            open={this.props.showEditDialog}
            onRequestClose={this.handleClose}
          >
            <DialogTitle id="alert-dialog-slide-title">Device Edit</DialogTitle>
            <DialogContent className="MyDialogContent">
              <MiraiTextField id="deviceNumber"     name="deviceNumber"    label="Device Number"     handleChange={this.handleChange} readOnly={true}   value={this.state.deviceNumber}    errorText={this.state.errorMsg.deviceNumber}   /><br />
              <MiraiTextField id="registerdDate"    name="registerdDate"   label="Registered Date"   handleChange={this.handleChange} readOnly={true}   value={this.state.registerdDate}   errorText={this.state.errorMsg.registerdDate}  /><br />
              <MiraiTextField id="ssid"             name="ssid"            label="SSID"              handleChange={this.handleChange} readOnly={false}  value={this.state.ssid}            errorText={this.state.errorMsg.ssid}  /><br />
              <MiraiTextField id="sim_number"       name="sim_number"      label="Sim Number"        handleChange={this.handleChange} readOnly={false}  value={this.state.sim_number}      errorText={this.state.errorMsg.sim_number}  /><br />
              <MiraiTextField id="imei"             name="imei"            label="IMEI"              handleChange={this.handleChange} readOnly={false}  value={this.state.imei}            errorText={this.state.errorMsg.imei}  /><br />
              <MiraiTextField id="passwrod"         name="passwrod"        label="Password"          handleChange={this.handleChange} readOnly={false}  value={this.state.passwrod}        errorText={this.state.errorMsg.password}  /><br />
              <br />
              <DialogSelect caption={"Location"} name={"location_id"} selectValue={this.state.location_id} onChangeEvent={this.handleChange} selectList={this.state.location_list} />
              <br />
              <DeviceStatusRadio radioValue={this.state.deviceStatus} readOnly={false}  onChangeEvent={this.handleChangeDeviceStatus} />
            </DialogContent>
          </Dialog>
      </MuiThemeProvider>
    );

  }
}


export default DeviceEditDialog;