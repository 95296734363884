import React, { Component } from 'react';
import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';

import CommonButton from '../../../../common/mirai_component/CommonButton'
import ModelListTable from './ModelListTable';
import ModelEditDialog from './ModelEditDialog';

import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';

import {connect} from 'react-redux';


class Model extends Component {

  constructor(props) {

    super(props);

    this.state = {
      searchParamModelName : '',
      modelList            : [],
      showEditDialog       : false,
      editModelId          : null,
      routerTypeList       : [],
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleEditRowClick = this.handleEditRowClick.bind(this);
    this.handleEditDialogClose = this.handleEditDialogClose.bind(this);
    this.handleClickAddNew = this.handleClickAddNew.bind(this);

  }


  componentDidMount(prevProps){

    let requestParam = {};

    fetch('/api/getAllRouterType', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let routerTypeList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let routerTypeRow = {};
        routerTypeRow.id    = responseJson[i].router_type_id;
        routerTypeRow.label = responseJson[i].router_type;
        routerTypeList.push(routerTypeRow);
      }
      this.setState({
        routerTypeList:routerTypeList
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  handleClickSearch(){

    let requestParam = {};
    requestParam.modelName = this.state.searchParamModelName;

    // ユーザ検索
    fetch('/api/searchModel', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({
        modelList:responseJson
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  handleClickAddNew(event){

    this.setState({
      showEditDialog:true,
      editModelId:0,
    });

  }


  handleEditRowClick(event){

    if ( event.target.cellIndex === 2 ) {
      let modelId = event.target.children[0].value;
      this.setState({
        showEditDialog:true,
        editModelId:modelId,
      });
    }

  }


  handleEditDialogClose(){

    this.setState({
      showEditDialog:false,
      editModelId:null
    });

    this.handleClickSearch();

  }


  render() {

    return (
      <div align="center">
        <Menu history={this.props.history} />
        <ScreenTitle title={this.props.selectedMenuName} />
        <MiraiTextField handleChange={this.handleChange}    readOnly={false}   id="searchParamModelName"       name="searchParamModelName"     label="Model Name"     value={this.state.searchParamModelName}   errorText={''} />
        <br />
        <CommonButton visible={true}   clickEvent={this.handleClickSearch}   label="Search"   />
        <CommonButton visible={true}   clickEvent={this.handleClickAddNew}   label="Add New"  />

        <ModelListTable dataRows={this.state.modelList} rowClickEvent={this.handleEditRowClick} />
        <ModelEditDialog showEditDialog={this.state.showEditDialog} handleEditDialogCloseEvent={this.handleEditDialogClose} editModelId={this.state.editModelId} routerTypeList={this.state.routerTypeList} />
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Model);