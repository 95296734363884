import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
  button: {
    width: '300px',
    marginTop: '5px',
    'margin-left': '5px',
    'margin-right': '5px',
    marginBottom: '15px',
  },
  formContorl : {
    marginTop: '20px',
  }
}));

export default function CommonButton(pPrams) {

  const classes = useStyles();
  const params = pPrams;

  let visible = params.visible;

  let button;

  if ( visible === undefined ) {
    visible = true;
  }

  if ( visible ) {
    button = <Button variant="contained" color="primary" className={classes.button} onClick={params.clickEvent}>{params.label}</Button>
  }
  return (
    <FormControl className={classes.formContorl}>
      {button}
    </FormControl>
  );

}