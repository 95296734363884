import React, { Component } from 'react';

import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';
import InformDialog from '../../../../common/mirai_component/InformDialog'

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

import CommonButton from '../../../../common/mirai_component/CommonButton'

import {connect} from 'react-redux';
import RequestIssueInvoiceBookingList from './RequestIssueInvoiceBookingList';
import * as commonFunction from '../../../../common/commonFunction';


class RequestIssueInvoice extends Component {

  constructor(props) {

    super(props);
    this.state = {
      corporateName              : '',
      applyNumber                : '',
      dataRows                   : [],
      informDialogOpen           : false,
      informDialogTitle          : '',
      informDialogMessage        : '',
      selectedFilePath           : '',
      currencyRateTable          : {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);
    this.handleClickDownload = this.handleClickDownload.bind(this);

  }



  componentDidMount(prevProps) {

    let requestParam = {};
    fetch('/api/getAllCurrency', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let currencyRateTable = {};
      for ( let i=0;i<responseJson.length;i++ ) {

        let currency = responseJson[i].currency;
        if ( currencyRateTable[currency] === undefined || currencyRateTable[currency] === null || currencyRateTable[currency] === '' ) {
          currencyRateTable[currency] = responseJson[i].exchange_rate;
        }

      }

      this.setState({
        currencyRateTable : currencyRateTable
      })

      this.handleClickSearch();

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  // 一覧のCellClick時
  onCellClick(event){

    let cellIndex = event.target.cellIndex;

    // Detail押下 -> BookingDetailへ遷移
    if ( cellIndex == 0 ) {
      let applyNumber = event.target.children[0].value;
      // 会社
      if ( event.target.children[1].value === '0' ) {
        this.props.history.push('/bookingCorporate/' + applyNumber);
      }
      // 個人
      else {
        this.props.history.push('/bookingPersonal/' + applyNumber);
      }
    }

  }



  // 検索ボタン押下時の処理
  handleClickSearch(){

    // Booking検索API
    fetch('/api/searchPaymentRequestIssueInvoiceBooking', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      for ( let i=0;i<responseJson.length;i++ ) {

        responseJson[i].applyNumber    = responseJson[i].apply_number;
        responseJson[i].corporateName  = responseJson[i]['m20_corporate']['corporate_name'];
        responseJson[i].personInCharge = responseJson[i]['m10_user']['name'];
        responseJson[i].userName       = responseJson[i].user_name;
        responseJson[i].apply_date     = new Date(responseJson[i].apply_date).toLocaleDateString();
        responseJson[i].totalFee       = Math.floor(responseJson[i].total_fee * this.state.currencyRateTable[responseJson[i]["m20_corporate"]["currency"]]);
        responseJson[i].currency       = responseJson[i]["m20_corporate"]["currency"];
        responseJson[i].contractType   = responseJson[i].contract_type;
        responseJson[i].invoiceNote    = responseJson[i].invoice_note;

        let minDate = new Date(responseJson[i]['t20_booking_details'][0].start_date);
        let maxDate = new Date(responseJson[i]['t20_booking_details'][0].end_date);
        let destination = [];

        for ( let j=0;j<responseJson[i]['t20_booking_details'].length;j++ ) {

          if ( minDate > new Date(responseJson[i]['t20_booking_details'][j].start_date) ) {
            minDate = new Date(responseJson[i]['t20_booking_details'][j].start_date);
          }
          if ( maxDate < new Date(responseJson[i]['t20_booking_details'][j].end_date) ){
            maxDate = new Date(responseJson[i]['t20_booking_details'][j].end_date);
          }
          if ( destination.indexOf(responseJson[i]['t20_booking_details'][j]['m40_destination']['destination']) === -1 ) {
            destination.push(responseJson[i]['t20_booking_details'][j]['m40_destination']['destination']);
          }

        }

        let destinationString = '';
        for ( let j=0;j<destination.length;j++ ) {
          if ( j > 0 ) {
            destinationString += ' / ' ;
          }
          destinationString += destination[j];
        }
        responseJson[i].contractDate = commonFunction.formatYYYYMMDD(minDate) + ' - ' + commonFunction.formatYYYYMMDD(maxDate);
        responseJson[i].destination = destinationString;

      }
      this.setState({dataRows:responseJson});

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  handleClickDownload(){

    var saveAs = require('file-saver');

    fetch('/api/downloadRequestIssueInvoiceList', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.blob())
    .then(blob => {

      let now = new Date();
      const y = now.getFullYear();
      const m = ('00' + (now.getMonth()+1)).slice(-2);
      const d = ('00' + now.getDate()).slice(-2);
      const hh = ('00' + now.getHours()).slice(-2);
      const mm = ('00' + now.getMinutes()).slice(-2);
      const ss = ('00' + now.getSeconds()).slice(-2);

      saveAs(blob, 'RequestIssueInvoiceList_' + y+m+d+hh+mm+ss + '.xlsx');
      this.state.selectedFilePath = '';

    });

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

    this.handleClickSearch();

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Company Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="corporateName" name="corporateName" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Apply Number</FormLabel></div>
            <TextField onChange={this.handleChange} id="applyNumber"   name="applyNumber"   style={{width: "500px"}} type="search" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch}   label="Search booking"/>
        <CommonButton clickEvent={this.handleClickDownload} label="List Download"/>
        <br />
        <RequestIssueInvoiceBookingList dataRows={this.state.dataRows}  onCellClickEvent={this.onCellClick}/>
        <InformDialog  informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestIssueInvoice);