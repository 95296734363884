import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import CommonRadio from '../../../../common/CommonRadio';
import FormLabel from '@material-ui/core/FormLabel';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import * as constVal from '../../../../common/constant';

class FixDeliveryMethodDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData            : false,
      applyNumber           : 0,
      customerName          : '',
      customerTel           : '',
      customerAddress       : '',
      deliveryBy            : '',
      updatedAt             : '',
      deliveryByList        : [{"id":1,"label":"Grab"},{"id":2,"label":"XXXX"},{"id":3,"label":"XXXX"}],
      deliveryFee           : 0,
      informDialogTitle     : '',
      informDialogMessage   : '',
      rentalFee             : 0,
      discountAmount        : 0,
      vat                   : 0,
      bookingDetails        : [],
      nextBookingStatus     : 0,
      errorMsg :{
        deliveryFee : '',
        deliveryBy  : '',
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }


  handleClose() {

    this.setState({loadedData:false});
    this.props.closeFixDeliveryMethodDialogEvent(this.state.informDialogTitle,this.state.informDialogMessage);

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  componentWillUpdate(nextProps,nextState){

    if ( !this.state.loadedData && nextProps.selectedApplyNumber !== -1 && nextProps.showFixDeliveryMethodDialog === true ) {

      const search_condition = {
        apply_number   : nextProps.selectedApplyNumber
      };
      fetch('/api/searchBooking', {
        method: "POST",
        body: JSON.stringify(search_condition), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        let customerName = '';
        let customerTel = '';
        let customerAddress = '';

        // 法人
        if ( responseJson[0].contract_type === 0 ) {
          customerName = responseJson[0].m20_corporate.corporate_name;
          customerTel = responseJson[0].m20_corporate.tel_number;
          customerAddress = responseJson[0].m20_corporate.address;
        }
        // 個人
        else {
          customerName = responseJson[0].customer_name;
          customerTel = responseJson[0].customer_tel;
          customerAddress = responseJson[0].customer_address;
        }

        let bookingDetails = [];
        let nextBookingStatus = constVal.booking_status.DeviceAssigned;
        for ( let i=0;i<responseJson[0]['t20_booking_details'].length;i++ ) {
          let bookingDetailsRow = {}
          bookingDetailsRow.bookingDetailId = responseJson[0]['t20_booking_details'][i].booking_detail_id;
          if ( responseJson[0]['t20_booking_details'][i]["t30_booking_detail_devices"].length > 0 ) {
            bookingDetailsRow.nextStatus = constVal.booking_status.DeviceAssigned;
          }
          else {
            bookingDetailsRow.nextStatus = constVal.booking_status.CustomreContacted;
            nextBookingStatus = constVal.booking_status.CustomreContacted;
          }
          bookingDetails.push(bookingDetailsRow);
        }

        this.setState({
          bookingId             : responseJson[0].booking_id,
          applyNumber           : responseJson[0].apply_number,
          customerName          : customerName,
          customerTel           : customerTel,
          customerAddress       : customerAddress,
          updatedAt             : responseJson[0].updatedAt,
          rentalFee             : responseJson[0].rental_fee,
          discountAmount        : responseJson[0].discount_amount,
          vat                   : responseJson[0].vat,
          nextBookingStatus     : nextBookingStatus,
          bookingDetails        : bookingDetails,
          deliveryFee           : responseJson[0].delivery_fee,
        });

      });

      this.state.loadedData = true;

    }

  }


  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

  }


  // 保存ボタン押下時
  handleClickSave(event) {

    const reqParam = {}
    reqParam.bookingId = this.state.bookingId;
    reqParam.deliveryFee = this.state.deliveryFee;
    reqParam.deliveryBy = this.state.deliveryBy;
    // Booking登録時にDevicceをAssignしている場合は次のSTEP(DeviceAssign)はスキップできる
    reqParam.bookingStatus = this.state.nextBookingStatus;
    reqParam.updatedAt = this.state.updatedAt;
    reqParam.totalFee = parseInt(this.state.rentalFee) - parseInt(this.state.discountAmount) + parseInt(this.state.vat) + parseInt(this.state.deliveryFee);
    reqParam.functionName = 'FixDeliveryMethod';
    reqParam.bookingDetails = this.state.bookingDetails;

    fetch('/api/updateBookingStatusContactCustomer', {
      method: "POST",
      body: JSON.stringify(reqParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if ( responseJson.error === undefined ) {

        this.setState({
          informDialogTitle:'Update OK',
          informDialogMessage:'Succesfully update booking.',
        });

      }
      else {

        this.setState({
          informDialogTitle:'Update Error',
          informDialogMessage:'Update booking failed!!' + responseJson.error,
        });

      }

      this.handleClose();

    })
    .catch((error) =>{
      console.error(error);
    });

  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showFixDeliveryMethodDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">Fix Delivery Method</DialogTitle>
          <DialogContent className="MyDialogContent">
            <FormLabel component="legend">Apply Number : {this.state.applyNumber}</FormLabel>
            <br />
            <FormLabel component="legend">Customer Name : {this.state.customerName}</FormLabel>
            <br />
            <FormLabel component="legend">Customer Tel : {this.state.customerTel}</FormLabel>
            <br />
            <FormLabel component="legend">Customer Address : {this.state.customerAddress}</FormLabel>
            <br />
            <CommonRadio readOnly={false}  radioList={this.state.deliveryByList}        onChangeEvent={this.handleChange}  name={'deliveryBy'}  caption={'Delivery By'}  radioValue={this.state.deliveryBy} />
            <br />
            <MiraiTextField id="deliveryFee"  name="deliveryFee"  label="Delivery Fee"  handleChange={this.handleChange}   readOnly={false}  value={this.state.deliveryFee}   errorText={this.state.errorMsg.deliveryFee}   /><br />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}

export default FixDeliveryMethodDialog;