import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";
import MiraiTextField from "../../../../common/mirai_component/MiraiTextField";
import PasswordField from "../../../../common/mirai_component/PasswordField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogSelect from "../../../../common/DialogSelect";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CommonRadio from "../../../../common/CommonRadio";

class UserEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedData: false,
      id: "",
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      locationId: "",
      corporateId: "",
      admin_flg: 0,
      updatedAt: "",
      location_list: [],
      corporate_list: [],
      admin_list: [],
      userTypeList: [
        { id: 1, label: "Annam Staff" },
        { id: 2, label: "Agency" },
      ],
      userType: 1,
      errorMsg: {
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
      },
      visibleProps: {
        password: false,
      },
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleChangeDeviceStatus = this.handleChangeDeviceStatus.bind(this);
  }

  handleClose() {
    this.setState({ loadedData: false });
    this.props.handleEditDialogCloseEvent();
  }

  // 画面入力項目をthis.stateにバインドする
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    this.setState({ [name]: event.target.value });
  }

  componentDidMount(prevProps) {
    let admin_list = [];
    let row1 = {};
    row1.id = 0;
    row1.label = "General User";
    admin_list.push(row1);

    let row2 = {};
    row2.id = 1;
    row2.label = "Admin User";
    admin_list.push(row2);

    this.setState({
      admin_list: admin_list,
    });

    fetch("/api/getAllLocation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let list = [];
        let row = {};
        row.id = "";
        row.label = " --- ";
        list.push(row);

        for (let i = 0; i < responseJson.length; i++) {
          let row = {};
          row.id = responseJson[i].location_id;
          row.label = responseJson[i].location;
          list.push(row);
        }
        this.setState({
          location_list: list,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    fetch("/api/getAllCorporate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let list = [];
        let row = {};
        row.id = "";
        row.label = " --- ";
        list.push(row);

        for (let i = 0; i < responseJson.length; i++) {
          let row = {};
          row.id = responseJson[i].corporate_id;
          row.label = responseJson[i].corporate_name;
          list.push(row);
        }
        this.setState({
          corporate_list: list,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.editUserId != null && !this.state.loadedData) {
      if (nextProps.editUserId === 0) {
        const visibleProps = Object.assign({}, this.state.visibleProps);
        visibleProps.password = true;

        this.setState({
          id: null,
          name: "",
          email: "",
          password: "",
          admin: 0,
          updatedAt: null,
          loadedData: true,
          visibleProps: visibleProps,
        });
      } else {
        const visibleProps = Object.assign({}, this.state.visibleProps);
        visibleProps.password = false;

        let queryParam = {};
        queryParam.id = nextProps.editUserId;

        // User検索
        fetch("/api/searchUser", {
          method: "POST",
          body: JSON.stringify(queryParam),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            this.setState({
              id: responseJson[0].id,
              name: responseJson[0].name,
              email: responseJson[0].email,
              locationId: responseJson[0].location_id,
              corporateId: responseJson[0].corporate_id,
              admin_flg: responseJson[0].admin_flg,
              userType: responseJson[0].type,
              password: "",
              updatedAt: responseJson[0].updatedAt,
              loadedData: true,
              visibleProps: visibleProps,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  // 保存ボタン押下時
  handleClickSave(event) {
    if (this.state.locationId === undefined || this.state.locationId === "") {
      this.state.locationId = null;
    }
    if (this.state.corporateId === undefined || this.state.corporateId === "") {
      this.state.corporateId = null;
    }

    if (this.state.id == null) {
      // User登録(INSERT)
      fetch("/api/resolveNewUser", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.handleClose();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // User登録(UPDATE)
      fetch("/api/resolveUser", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.handleClose();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleChangeDeviceStatus(event) {
    this.handleChange(event);
  }

  render() {
    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{ width: "200px", "margin-left": "10px" }}
        startIcon={<SaveIcon />}
      >
        Save
      </Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{ width: "200px", "margin-left": "10px" }}
        startIcon={<CloseIcon />}
      >
        Close
      </Button>,
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showEditDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">User Edit</DialogTitle>
          <DialogContent className="MyDialogContent">
            <MiraiTextField
              id="name"
              name="name"
              label="User Name"
              handleChange={this.handleChange}
              readOnly={false}
              value={this.state.name}
              errorText={this.state.errorMsg.name}
            />
            <br />
            <MiraiTextField
              id="email"
              name="email"
              label="Email Address"
              handleChange={this.handleChange}
              readOnly={false}
              value={this.state.email}
              errorText={this.state.errorMsg.email}
            />
            <br />
            <PasswordField
              id="password"
              name="password"
              label="Password"
              handleChange={this.handleChange}
              readOnly={false}
              value={this.state.password}
              errorText={this.state.errorMsg.password}
              visible={this.state.visibleProps.password}
            />
            <br />
            <PasswordField
              id="passwordConfirm"
              name="passwordConfirm"
              label="Password(Confirm)"
              handleChange={this.handleChange}
              readOnly={false}
              value={this.state.passwordConfirm}
              errorText={this.state.errorMsg.passwordConfirm}
              visible={this.state.visibleProps.password}
            />
            <br />
            <br />
            <DialogSelect
              caption={"Location"}
              name={"locationId"}
              selectValue={this.state.locationId}
              onChangeEvent={this.handleChange}
              selectList={this.state.location_list}
            />
            <br />
            <DialogSelect
              caption={"Corporate"}
              name={"corporateId"}
              selectValue={this.state.corporateId}
              onChangeEvent={this.handleChange}
              selectList={this.state.corporate_list}
            />
            <br />
            <CommonRadio
              visible={true}
              readOnly={false}
              radioList={this.state.userTypeList}
              onChangeEvent={this.handleChange}
              name={"userType"}
              caption={"User Type"}
              radioValue={this.state.userType}
            />
            <br />
            {this.state.userType != 2 ? (
              <DialogSelect
                caption={"Admin User"}
                name={"admin_flg"}
                selectValue={this.state.admin_flg}
                onChangeEvent={this.handleChange}
                selectList={this.state.admin_list}
              />
            ) : null}
            <br />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

export default UserEditDialog;
