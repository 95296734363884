import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  inputFileBtnHide : {
    opacity:0,
    'appearance': 'none',
    'position': 'absolute',
  },
}));


export default function UploadFile(pPrams) {

  const classes = useStyles();
  const params = pPrams;
  let viewButton = '';
  if ( params.download ) {
    viewButton = <Button variant="contained" color="primary"  onClick={params.handleClickView}>View</Button>
  }
  let downloadButton = '';
  if ( params.download ) {
    downloadButton = <Button variant="contained" color="primary"  onClick={params.handleClickDownload}>Download</Button>
  }
  let deleteButton = '';
  if ( params.selected ) {
    deleteButton   = <Button variant="contained" color="primary"  onClick={params.handleClickDelete}>Delete</Button>
  }

  return (
    <div>
      <input 
        className={classes.inputFileBtnHide}
        id={params.fileId} 
        multiple 
        type="file" 
        onChange={params.fileSelectEvent}
      />
      <label htmlFor={params.fileId}> 
        <Button raised component="span" className={classes.button} >
          {params.filedName}
        </Button>
      </label>
      {params.uploadFileName}
      {viewButton}
      {downloadButton}
      {deleteButton}
    </div>
  );

}