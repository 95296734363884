import React, { Component } from 'react';

import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';
import InformDialog from '../../../../common/mirai_component/InformDialog'

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

import CommonButton from '../../../../common/mirai_component/CommonButton'

import {connect} from 'react-redux';
import MonthlyReportList from './MonthlyReportList';

import * as commonFunction from '../../../../common/commonFunction';


class MonthlyReport extends Component {

  constructor(props) {

    super(props);
    this.state = {
      reportTargetMonth          : '',
      reportMonthFrom            : '',
      reportMonthTo              : '',
      dataRows                   : [],
      informDialogOpen           : false,
      informDialogTitle          : '',
      informDialogMessage        : '',
      selectedFilePath           : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);
    this.handleClickDownload = this.handleClickDownload.bind(this);

  }



  componentDidMount(prevProps) {

    // ログイン認証チェック
    // ログイン済みであればメニューを表示
    this.handleClickSearch();

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  // 一覧のCellClick時
  onCellClick(event){

    let cellIndex = event.target.cellIndex;

    // Detail押下 -> BookingDetailへ遷移
    if ( cellIndex == 3 ) {

      let filePath = event.target.children[0].value;
      this.state.selectedFilePath = filePath;

      var saveAs = require('file-saver');

      fetch('/api/createMonthlyReport', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.blob())
      .then(blob => {

        let now = new Date();
        const y = now.getFullYear();
        const m = ('00' + (now.getMonth()+1)).slice(-2);
        const d = ('00' + now.getDate()).slice(-2);
        const hh = ('00' + now.getHours()).slice(-2);
        const mm = ('00' + now.getMinutes()).slice(-2);
        const ss = ('00' + now.getSeconds()).slice(-2);
  
        saveAs(blob, 'MonthlyReport_' + y+m+d+hh+mm+ss + '.xlsx');
        this.state.selectedFilePath = '';

      });

    }

  }



  // 検索ボタン押下時の処理
  handleClickSearch(){

    // Booking検索API
    fetch('/api/searchMonthlyReport', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      for ( let i=0;i<responseJson.length;i++ ) {

        const yearMonth = new Date(responseJson[i].year_month);
        responseJson[i].fileGenerateTimestamp = new Date(responseJson[i].file_generate_timestamp).toLocaleDateString() + new Date(responseJson[i].file_generate_timestamp).toLocaleTimeString();
        responseJson[i].yearMonth             = yearMonth.getFullYear() + "-" + ("00" + (yearMonth.getMonth()+1)).slice(-2);
        responseJson[i].userName              = responseJson[i]['m10_user'].name;
        responseJson[i].download              = '[download]';

      }

      this.setState({dataRows:responseJson});

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // 新規Report作成
  handleClickDownload(){

    var saveAs = require('file-saver');

    fetch('/api/createMonthlyReport', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.blob())
    .then(blob => {
      let now = new Date();
      const y = now.getFullYear();
      const m = ('00' + (now.getMonth()+1)).slice(-2);
      const d = ('00' + now.getDate()).slice(-2);
      const hh = ('00' + now.getHours()).slice(-2);
      const mm = ('00' + now.getMinutes()).slice(-2);
      const ss = ('00' + now.getSeconds()).slice(-2);
      saveAs(blob, 'MonthlyReport_' + y+m+d+hh+mm+ss + '.xlsx');
    });

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

    this.handleClickSearch();

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Create Report Month</FormLabel></div>
            <TextField onChange={this.handleChange} id="reportTargetMonth" name="reportTargetMonth" style={{width: "200px"}} type="month" />
        </div>
        <br />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Report Month</FormLabel></div>
            <TextField onChange={this.handleChange} id="reportMonthFrom" name="reportMonthFrom" style={{width: "200px"}} type="month" />　〜　 
            <TextField onChange={this.handleChange} id="reportMonthTo"   name="reportMonthTo"   style={{width: "200px"}} type="month" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch}   label="Search booking"/>
        <CommonButton clickEvent={this.handleClickDownload} label="Create Monthly Report"/>
        <br />
        <MonthlyReportList dataRows={this.state.dataRows} onCellClickEvent={this.onCellClick} />
        <InformDialog  informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyReport);