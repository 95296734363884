import React, { Component } from "react";

import TextFieldEmail from "../../common/mirai_component/TextFieldEmail";
import PasswordField from "../../common/mirai_component/PasswordField";
import CommonButton from "../../common/mirai_component/CommonButton";
import ErrorMessage from "../../common/mirai_component/ErrorMessage";

import { connect } from "react-redux";
import { setLoginUserInfo } from "../../actions";

import Header from "../../common/Header";
import Link from "@material-ui/core/Link";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      status: "",
      errorMsg_main: "",
      errorMsg_email: "",
      errorMsg_password: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickLogin = this.handleClickLogin.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount(prevProps) {
    // ログイン認証チェック
    // ログイン済みであればメニューを表示
    //    checkSessionStatus(this.props,'/menu');
  }

  // 画面入力項目をthis.stateにバインドする
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    this.setState({ [name]: event.target.value });

    if (name === "email") {
      this.setState({ errorMsg_email: "" });
    }
    if (name === "password") {
      this.setState({ errorMsg_password: "" });
    }
  }

  // 入力チェック
  validateForm() {
    let hasError = false;
    if (this.state.email === "") {
      this.setState({ errorMsg_email: "Please input email_address." });
      hasError = true;
    }

    if (this.state.password === "") {
      this.setState({ errorMsg_password: "Please input password." });
      hasError = true;
    }

    return hasError;
  }

  // ログインボタン押下時の処理
  handleClickLogin = async (mode) => {
    if (this.validateForm()) {
      return;
    }

    // ログイン処理
    fetch("/api/login", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          status: responseJson,
        });

        if (responseJson.auth === "OK") {
          let url = mode === 0 ? "/top" : "/topGW";
          this.props.history.push(url);
          this.props.setLoginUserInfo(
            responseJson.id,
            responseJson.userName,
            this.state.email,
            responseJson.isCorporate,
            responseJson.isLocation,
            responseJson.admin,
            responseJson.isAgency
          );
        } else {
          this.setState({
            errorMsg_main: "Wrong user_id/password were posted.",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <div className="App" id="div">
        <Header />
        <h1>Please input userid/password</h1>

        <TextFieldEmail
          handleChange={this.handleChange}
          id="email"
          name="email"
          errorText={this.state.errorMsg_email}
        />
        <br />
        <PasswordField
          handleChange={this.handleChange}
          id="password"
          name="password"
          label={"password"}
          visible={true}
          errorText={this.state.errorMsg_password}
        />
        <br />
        <br />
        <Link href="/forgotPassword">Forgot password</Link>
        <br />
        <CommonButton
          clickEvent={() => this.handleClickLogin(0)}
          label="login (Wifi System)"
        />
        <br />
        <CommonButton
          clickEvent={() => this.handleClickLogin(1)}
          label="login (Group ware)"
        />
        <br />
        <br />
        <ErrorMessage message={this.state.errorMsg_main} />
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userName: state.login.userName,
    userEmail: state.login.userEmail,
    isCorporate: state.login.isCorporate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginUserInfo: (
      id,
      userName,
      userEmail,
      isCorporate,
      isLocation,
      admin,
      isAgency
    ) =>
      dispatch(
        setLoginUserInfo(
          id,
          userName,
          userEmail,
          isCorporate,
          isLocation,
          admin,
          isAgency
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
