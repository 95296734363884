import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogSelect from '../../../../common/DialogSelect'
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import CommonRadio from '../../../../common/CommonRadio';


class CorporateEditDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData           : false,
      corporateId          : 0,
      corporateName        : '',
      zip                  : '',
      telNumber            : '',
      email                : '',
      address              : '',
      taxCode              : '',
      vatNumber            : '',
      discountRate         : '',
      webDiscountRate      : '',
      personInCharge       : '',
      personInChargeOvs    : '',
      personInChargeTel    : '',
      personInChargeEmail  : '',
      personInChargeNote   : '',
      webAuthId            : '',
      webAuthPassword      : '',
      annamPersonInCharge  : 0,
      priceId              : 0,
      currency             : '',
      updatedAt            : '',
      userList             : [],
      currencyList         : [{id:'VND',label:'VND'},{id:'USD',label:'USD'}],
      priceIdList          : [{id:0,label:"Normal"},{id:1,label:"Roam"},{id:2,label:"Special(Klook)"}],
      errorMsg :{
        corporateName        : '',
        telNumber            : '',
        email                : '',
        zip                  : '',
        address              : '',
        taxCode              : '',
        vatNumber            : '',
        discountRate         : '',
        webDiscountRate      : '',
        personInCharge       : '',
        personInChargeOvs    : '',
        personInChargeTel    : '',
        personInChargeEmail  : '',
        personInChargeNote   : '',
        webAuthId            : '',
        webAuthPassword      : '',
        annamPersonInCharge  : 0,
      }

    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

  }


  handleCancel(){
    this.setState({loadedData:false});
    this.props.handleEditDialogCloseEvent('','');
  }


  handleClose() {
    this.setState({loadedData:false});
    this.props.handleEditDialogCloseEvent('','');
  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  componentWillUpdate(nextProps,nextState){

    if ( nextProps.editCorporateId !=null && !this.state.loadedData ) {


      this.state.loadedData = true;

      if ( nextProps.editCorporateId === 0 ) {

        this.setState({
          corporateId         : 0,
          corporateName       : '',
          zip                 : '',
          telNumber           : '',
          email               : '',
          address             : '',
          taxCode             : '',
          vatNumber           : '',
          discountRate        : 0,
          webDiscountRate     : 0,
          personInCharge      : '',
          personInChargeOvs   : '',
          personInChargeTel   : '',
          personInChargeEmail : '',
          personInChargeNote  : '',
          annamPersonInCharge : '',
          webAuthId           : '',
          webAuthPassword     : '',
          priceId             : 0,
          currency            : '',
          updatedAt           : '',
          loadedData          : true,
        });

      }
      else {

        let queryParam = {};
        queryParam.corporate_id = parseInt(nextProps.editCorporateId);
  
        // 会社検索
        fetch('/api/searchCorporateById', {
          method: "POST",
          body: JSON.stringify(queryParam), 
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            corporateId          : responseJson[0].corporate_id,
            corporateName        : responseJson[0].corporate_name,
            zip                  : responseJson[0].zip_code,
            telNumber            : responseJson[0].tel_number,
            email                : responseJson[0].email,
            address              : responseJson[0].address,
            taxCode              : responseJson[0].tax_code,
            vatNumber            : responseJson[0].vat_number,
            discountRate         : responseJson[0].discount_rate,
            webDiscountRate      : responseJson[0].web_discount_rate,
            personInCharge       : responseJson[0].person_in_charge,
            personInChargeOvs    : responseJson[0].person_in_charge_ovs,
            personInChargeTel    : responseJson[0].person_in_charge_tel,
            personInChargeEmail  : responseJson[0].person_in_charge_email,
            personInChargeNote   : responseJson[0].person_in_charge_note,
            annamPersonInCharge  : responseJson[0].annam_person_in_charge,
            webAuthId            : responseJson[0].web_auth_id,
            webAuthPassword      : responseJson[0].web_auth_password,
            priceId              : responseJson[0].price_id,
            currency             : responseJson[0].currency,
            updatedAt            : responseJson[0].updatedAt,
            loadedData           : true,
          });
        })
        .catch((error) =>{
          console.error(error);
        });

      }

      fetch('/api/getUserList', {
        method: "POST",
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
  
        let list = [];
        for ( let i=0;i<responseJson.length;i++ ) {
          let row = {};
          row.id = responseJson[i].id;
          row.label = responseJson[i].name;
          list.push(row);
        }
  
        this.setState({
          userList : list
        });
  
      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }


  // 保存ボタン押下時
  handleClickSave(event) {

    if ( this.state.corporateId === 0 ) {

      // 会社登録(INSERT)
      fetch('/api/resolveNewCorporate', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          loadedData : false
        });
        this.props.handleEditDialogCloseEvent('Corporate Updated','Corpoate infomation was updated.');

      })
      .catch((error) =>{
        console.error(error);
      });

    }
    else {

      // 会社登録(UPDATE)
      fetch('/api/resolveCorporate', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          loadedData:false
        });
        this.props.handleEditDialogCloseEvent('Corporate Updated','Corpoate infomation was updated.');

      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }



  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
          <Dialog
            actions={actions}
            modal={false}
            open={this.props.showEditDialog}
            onRequestClose={this.handleCancel}
          >
            <DialogTitle id="alert-dialog-slide-title">Corporate Edit</DialogTitle>
            <DialogContent className="MyDialogContent">
              <MiraiTextField id="corporateName"       name="corporateName"         label="Corporate Name"             handleChange={this.handleChange} readOnly={false} value={this.state.corporateName}        errorText={this.state.errorMsg.corporateName}       /><br />
              <MiraiTextField id="telNumber"           name="telNumber"             label="Tel Number"                 handleChange={this.handleChange} readOnly={false} value={this.state.telNumber}            errorText={this.state.errorMsg.telNumber}           /><br />
              <MiraiTextField id="zip"                 name="zip"                   label="Zip"                        handleChange={this.handleChange} readOnly={false} value={this.state.zip}                  errorText={this.state.errorMsg.zip}                 /><br />
              <MiraiTextField id="address"             name="address"               label="Address"                    handleChange={this.handleChange} readOnly={false} value={this.state.address}              errorText={this.state.errorMsg.address}             /><br />
              <MiraiTextField id="email"               name="email"                 label="Email"                      handleChange={this.handleChange} readOnly={false} value={this.state.email}                errorText={this.state.errorMsg.email}               /><br />
              <MiraiTextField id="taxCode"             name="taxCode"               label="Tax Code"                   handleChange={this.handleChange} readOnly={false} value={this.state.taxCode}              errorText={this.state.errorMsg.taxCode}             /><br />
              <MiraiTextField id="vatNumber"           name="vatNumber"             label="Vat Number"                 handleChange={this.handleChange} readOnly={false} value={this.state.vatNumber}            errorText={this.state.errorMsg.vatNumber}           /><br />
              <MiraiTextField id="discountRate"        name="discountRate"          label="Discount Rate"              handleChange={this.handleChange} readOnly={false} value={this.state.discountRate}         errorText={this.state.errorMsg.discountRate}        /><br />
              <MiraiTextField id="webDiscountRate"     name="webDiscountRate"       label="Discount Rate(Web)"         handleChange={this.handleChange} readOnly={false} value={this.state.webDiscountRate}      errorText={this.state.errorMsg.webDiscountRate}     /><br />
              <MiraiTextField id="personInCharge"      name="personInCharge"        label="Person in Charge"           handleChange={this.handleChange} readOnly={false} value={this.state.personInCharge}       errorText={this.state.errorMsg.personInCharge}      /><br />
              <MiraiTextField id="personInChargeOvs"   name="personInChargeOvs"     label="Person in Charge(OverSea)"  handleChange={this.handleChange} readOnly={false} value={this.state.personInChargeOvs}    errorText={this.state.errorMsg.personInChargeOvs}   /><br />
              <MiraiTextField id="personInChargeTel"   name="personInChargeTel"     label="Person in Charge(TEL)"      handleChange={this.handleChange} readOnly={false} value={this.state.personInChargeTel}    errorText={this.state.errorMsg.personInChargeTel}   /><br />
              <MiraiTextField id="personInChargeEmail" name="personInChargeEmail"   label="Person in Charge(Email)"    handleChange={this.handleChange} readOnly={false} value={this.state.personInChargeEmail}  errorText={this.state.errorMsg.personInChargeEmail} /><br />
              <MiraiTextField id="personInChargeNote"  name="personInChargeNote"    label="Person in Charge(Note)"     handleChange={this.handleChange} readOnly={false} value={this.state.personInChargeNote}   errorText={this.state.errorMsg.personInChargeNote}  /><br />
              <MiraiTextField id="webAuthId"           name="webAuthId"             label="Web Booling Loigin ID"      handleChange={this.handleChange} readOnly={false} value={this.state.webAuthId}            errorText={this.state.errorMsg.webAuthId}           /><br />
              <MiraiTextField id="webAuthPassword"     name="webAuthPassword"       label="Web Booling Password"       handleChange={this.handleChange} readOnly={false} value={this.state.webAuthPassword}      errorText={this.state.errorMsg.webAuthPassword}     /><br />
              <br />
              <DialogSelect caption={"Annam Sales"}     name={"annamPersonInCharge"} selectValue={this.state.annamPersonInCharge} onChangeEvent={this.handleChange} selectList={this.state.userList} />
              <br />
              <CommonRadio readOnly={false}  radioList={this.state.priceIdList} onChangeEvent={this.handleChange} name={'priceId'} caption={'Rank'}   radioValue={this.state.priceId}       />
              <br />
              <DialogSelect caption={"Currency"}        name={"currency"}            selectValue={this.state.currency}            onChangeEvent={this.handleChange} selectList={this.state.currencyList} />
              <br />
            </DialogContent>
          </Dialog>
      </MuiThemeProvider>
    );

  }
}


export default CorporateEditDialog;