export const contract_type = {
  Corporate: 0,
  Personal: 1,
  Agency: 2,
};

export const booking_status = {
  Booked: 100,
  CustomreContacted: 200,
  DeviceAssigned: 300,
  ReadyForSend: 400,
  WaitForPickup: 500,
  "Using(Delivery)": 600,
  "Using(HandOver)": 700,
  Done: 800,
  Cancelled: 900,
};

export const pickup_method = {
  Delivery: 0,
  ApexVietNamHeadOffice: 1,
  HaNoiPickupCounter: 2,
  Lancaster: 3,
};

export const return_method = {
  Delivery: 0,
  ApexVietNamHeadOffice: 1,
  HaNoiPickupCounter: 2,
  Lancaster: 3,
};

export const payment_method = {
  Cash: 0,
  BankTransfer: 1,
  CreditCard: 2,
};

export const payment_status = {
  NotPayed: 0,
  Payed: 1,
};

export const is_extended = {
  not_extended: 0,
  extended: 1,
};
