import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CreditCardSharpIcon from '@material-ui/icons/CreditCardSharp';
import LocalAtmSharpIcon from '@material-ui/icons/LocalAtmSharp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import CloudUploadSharpIcon from '@material-ui/icons/CloudUploadSharp';
import AccountBalanceWalletSharpIcon from '@material-ui/icons/AccountBalanceWalletSharp';
import EventSharpIcon from '@material-ui/icons/EventSharp';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const useStyles = makeStyles(theme => ({
  root: {
    width: '200px',
    maxWidth: 360,
    backgroundColor: '#a9a9a9'
  },
}));


const options = [
  'Credit Card',
  'Cash',
  'Bank Transffer(Personal)',
  'Bank Transfer(Request Issue Invoice)',
  'Bank Transfer(Invoice number/date upload)',
  'Bank Transfer(Update Payment Status)',
  'Payment Montly Report',
  'Refund',
];


const menuLinks = [
  '/paymentCreditCard',
  '/paymentCash',
  '/paymentBankTransferPersonal',
  '/paymentRequestIssueInvoice',
  '/paymentInvoiceInfoUpload',
  '/paymentBankTransfer',
  '/paymentMonthlyReport',
  '/refund',
]


export default function AnnamMenuPayment(pParams) {

  const classes = useStyles();
  const pSelectedIndex = pParams.selectedMenuIndex;

  const [anchorEl, setAnchorEl] = React.useState(null);
  let [selectedIndex, setSelectedIndex] = React.useState(pSelectedIndex);

  selectedIndex = pSelectedIndex;

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, functionName) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    pParams.onClickEvent(index, functionName);
    pParams.screenTransitEvent.push(menuLinks[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className={classes.root}>
      <List component="nav" aria-label="Booking">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="Booking"
          onClick={handleClickListItem}
        >
          <ListItemText primary="Payment" secondary={options[selectedIndex]} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={event => handleMenuItemClick(event, index, options[index])}
          >
            <ListItemIcon>
              {   index === 0 ? <CreditCardSharpIcon fontSize="small" /> : 
                ( index === 1 ? <LocalAtmSharpIcon fontSize="small" /> : 
                ( index === 2 ? <AccountBalanceIcon fontSize="small" /> : 
                ( index === 3 ? <DescriptionSharpIcon fontSize="small" /> : 
                ( index === 4 ? <CloudUploadSharpIcon fontSize="small" /> :
                ( index === 5 ? <AccountBalanceWalletSharpIcon fontSize="small" /> : 
                ( index === 6 ? <EventSharpIcon fontSize="small" /> : <AttachMoneySharpIcon fontSize="small" /> ) ) ) ) ) )
              }
            </ListItemIcon>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
