import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import CommonButton from '../../../common/mirai_component/CommonButton'
import FormLabel from '@material-ui/core/FormLabel';
import MiraiTextField from '../../../common/mirai_component/MiraiTextField';
import FlatButton from 'material-ui/FlatButton';
import CommonRadio from '../../../common/CommonRadio';


class PaymentDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData            : false,
      applyNumber           : 0,
      customerName          : '',
      customerTel           : '',
      customerAddress       : '',
      deliveryBy            : '',
      updatedAt             : '',
      informDialogTitle     : '',
      informDialogMessage   : '',
      totalFee             : 0,
      paymentStatus         : 0,
      paymentStatusList     : [{"id":0,"label":"Not Payed"},{"id":1,"label":"Payed"}],
      errorMsg :{
        deliveryFee : '',
        deliveryBy  : '',
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

  }



  componentWillUpdate(nextProps,nextState){
  
    if ( !this.state.loadedData && nextProps.selectedBookingId !== -1 && nextProps.showPaymentDialog === true ) {

      if ( this.state.loadedData ) {
        return false;
      }

      const search_condition = {
        booking_id : nextProps.selectedBookingId
      };
      fetch('/api/searchBooking', {
        method: "POST",
        body: JSON.stringify(search_condition), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        let customerName = '';
        let customerTel = '';
        let customerAddress = '';

        // 法人
        if ( responseJson[0].contract_type === 0 ) {
          customerName = responseJson[0].m20_corporate.corporate_name;
          customerTel = responseJson[0].m20_corporate.tel_number;
          customerAddress = responseJson[0].m20_corporate.address;
        }
        // 個人
        else {
          customerName = responseJson[0].customer_name;
          customerTel = responseJson[0].customer_tel;
          customerAddress = responseJson[0].customer_address;
        }

        this.setState({
          bookingId             : responseJson[0].booking_id,
          applyNumber           : responseJson[0].apply_number,
          customerName          : customerName,
          customerTel           : customerTel,
          customerAddress       : customerAddress,
          updatedAt             : responseJson[0].updatedAt,
          totalFee              : responseJson[0].total_fee,
          paymentStatus         : responseJson[0].payment_status,
          informDialogTitle     : '',
          informDialogMessage   : '',
        });

      });

      this.state.loadedData = true;

    }

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  handleClose() {

    this.props.closePaymentDialogEvent(this.state.informDialogTitle,this.state.informDialogMessage);
    this.setState({
      loadedData: false
    });

  }



  handleClickSave(event){

    const reqParam = {}
    reqParam.bookingId     = this.state.bookingId;
    reqParam.updatedAt     = this.state.updatedAt;
    reqParam.paymentStatus = this.state.paymentStatus;
    reqParam.functionName  = 'payment'

    fetch('/api/updatePaymentStatus', {
      method: "POST",
      body: JSON.stringify(reqParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if ( responseJson.error === undefined ) {

        this.setState({
          informDialogTitle:'Update OK',
          informDialogMessage:'Succesfully update booking.',
        });

      }
      else {

        this.setState({
          informDialogTitle:'Update Error',
          informDialogMessage:'Update booking failed!!' + responseJson.error,
        });

      }

      this.handleClose();

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  render() {

    const actions = [
      <FlatButton
        label="Close"
        primary={true}
        onClick={this.handleClose}
      />,
    ];

    return (
      <MuiThemeProvider>
          <Dialog
            title="Payment Status Update"
            modal={false}
            actions={actions}
            open={this.props.showPaymentDialog}
            onRequestClose={this.handleClose}
          >
          <FormLabel component="legend">Apply Number : {this.state.applyNumber}</FormLabel>
          <br />
          <FormLabel component="legend">Customer Name : {this.state.customerName}</FormLabel>
          <br />
          <FormLabel component="legend">Customer Tel : {this.state.customerTel}</FormLabel>
          <br />
          <FormLabel component="legend">Customer Address : {this.state.customerAddress}</FormLabel>
          <br />
          <FormLabel component="legend">Total Fee : {this.state.totalFee}</FormLabel>
          <br />
          <CommonRadio readOnly={false}  radioList={this.state.paymentStatusList}   onChangeEvent={this.handleChange}  name={'paymentStatus'}  caption={'Payment Status'}  radioValue={this.state.paymentStatus} />
          <br />
          <CommonButton visible={true}   clickEvent={this.handleClickSave}  label="SAVE" />
          </Dialog>
      </MuiThemeProvider>
    );

  }

}


export default PaymentDialog;