export default function checkSessionStatus(props,url) {

  const testMode = false;

  if ( testMode ) {
    return;
  }

  fetch('/api/checkStatus', {
    method: "GET",
    headers:{
      'Content-Type': 'application/json'
    }
  })
  .then((response) => response.json())
  .then((responseJson) => {
    if ( responseJson.auth === 'OK' ) {
      props.history.push(url)
    }
    else {
      props.history.push('/login')
    }
  })
  .catch((error) =>{
    console.error(error);
  });

}