import React, { Component } from 'react';

import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';
import InformDialog from '../../../../common/mirai_component/InformDialog'

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

import CommonButton from '../../../../common/mirai_component/CommonButton'

import {connect} from 'react-redux';
import * as commonFunction from '../../../../common/commonFunction';
import InputFile from '../../../../common/mirai_component/InputFile'
import InvoiceInfoUploadList from './InvoiceInfoUploadList';

class InviceInfoUpload extends Component {

  constructor(props) {

    super(props);
    this.state = {
      fileDownloadDataFrom       : '',
      fileDownloadDataTo         : '',
      corporateName              : '',
      applyNumber                : '',
      dataRows                   : [],
      informDialogOpen           : false,
      informDialogTitle          : '',
      informDialogMessage        : '',
      excelFile                  : null,
      uploadFileName             : '',
      selectedFilePath           : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);
    this.handleClickUpload = this.handleClickUpload.bind(this);
    this.handleFileSelectButtonClick = this.handleFileSelectButtonClick.bind(this);

  }



  componentDidMount(prevProps) {

    // ログイン認証チェック
    // ログイン済みであればメニューを表示
    this.handleClickSearch();

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  // 一覧のCellClick時
  onCellClick(event){

    let cellIndex = event.target.cellIndex;

    // Detail押下 -> BookingDetailへ遷移
    if ( cellIndex == 5 ) {

      let filePath = event.target.children[0].value;
      this.state.selectedFilePath = filePath;

      var saveAs = require('file-saver');

      fetch('/api/downloadRequestIssueInvoiceList', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, 'PaymentBookingList.xlsx');
        this.state.selectedFilePath = '';
      });

    }

  }



  // 検索ボタン押下時の処理
  handleClickSearch(){

    // Excelダウンロード実績検索
    fetch('/api/searchRequestIssueInvoiceDownloadResult', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      for ( let i=0;i<responseJson.length;i++ ) {

        responseJson[i].fileGenerateTimestamp = new Date(responseJson[i].file_generate_timestamp).toLocaleDateString() + new Date(responseJson[i].file_generate_timestamp).toLocaleTimeString();
        responseJson[i].bookingCount          = responseJson[i].booking_count;
        responseJson[i].userName              = responseJson[i].name;
        responseJson[i].rentalFeeTotal        = responseJson[i].rental_fee_total;
        responseJson[i].uploadedCount         = responseJson[i].uploaded_count;
        responseJson[i].download              = '[download]';

      }

      this.setState({dataRows:responseJson});

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // uploadファイル選択時
  handleFileSelectButtonClick(event){

    var f = event.target.files[0]
    this.setState({
      uploadFileName : f.name,
      excelFile      : f,
    });

  }



  // uplaodボタン押下時の処理
  handleClickUpload(event){

    const formData = new FormData();
    formData.append('excelFile', this.state.excelFile);

    fetch('/api/uploadInvoiceInfo', {
      method: "POST",
      body: formData,
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if ( responseJson.error === undefined ) {

        this.setState({
          informDialogTitle:'Update OK',
          informDialogMessage:'Succesfully update booking.',
        });

      }
      else {

        this.setState({
          informDialogTitle:'Update Error',
          informDialogMessage:'Update booking failed!!' + responseJson.error,
        });

      }

      this.handleClickSearch();
    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

    this.handleClickSearch();

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>File Download Date</FormLabel></div>
            <TextField onChange={this.handleChange} id="fileDownloadDataFrom" name="fileDownloadDataFrom" style={{width: "200px"}} type="date" />　〜　 
            <TextField onChange={this.handleChange} id="fileDownloadDataTo"   name="fileDownloadDataTo"   style={{width: "200px"}} type="date" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Company Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="corporateName" name="corporateName" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Apply Number</FormLabel></div>
            <TextField onChange={this.handleChange} id="applyNumber"   name="applyNumber"   style={{width: "500px"}} type="search" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch}   label="Search Download List"/>
        <br />
        <InvoiceInfoUploadList dataRows={this.state.dataRows}  onCellClickEvent={this.onCellClick}/>
        <InputFile fileSelectEvent={this.handleFileSelectButtonClick} uploadFileName={this.state.uploadFileName} handleClickUpload={this.handleClickUpload} />
        <InformDialog  informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviceInfoUpload);