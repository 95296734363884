import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CommonButton from '../../../../common/mirai_component/CommonButton'
import FormLabel from '@material-ui/core/FormLabel';
import PackingDeviceDialogList from './PackingDeviceDialogList';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import * as constVal from '../../../../common/constant';

class PackingDeviceDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData          : false,
      applyNumber         : 0,
      destination         : '',
      model               : '',
      routerType          : '',
      deviceNumber        : '',
      devices             : [],
      updatedAt           : '',
      informDialogTitle   : '',
      informDialogMessage : '',
      battery             : false,
      bag                 : false,
      recharger           : false,
      device              : false,
      errorMsg :{
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }


  handleClose() {

    this.setState({loadedData:false});
    this.props.closePackingDeviceDialogEvent(this.state.informDialogTitle,this.state.informDialogMessage);

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }

  handleChangeCheckbox(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.checked });

  }

  componentWillUpdate(nextProps,nextState){

    if ( !this.state.loadedData && nextProps.selectedBookingId !== -1 && nextProps.showPackingDeviceDialog === true ) {
            
      const search_condition = {
        booking_id        : nextProps.selectedBookingId,
        booking_detail_id : nextProps.selectedBookingDetailId,
      };
      fetch('/api/searchBookingDetailByView', {
        method: "POST",
        body: JSON.stringify(search_condition), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        
        let devices = [];
        for ( let i=0;i<responseJson.length;i++ ) {
          let deviceRow = {};
          deviceRow.row_id = (i+1);
          deviceRow.device_number = responseJson[i]['device_number'];
          deviceRow.battery = '';
          deviceRow.bag = '';
          deviceRow.recharger = '';
          deviceRow.device = '';
          devices.push(deviceRow);
        }

        this.setState({
          bookingId : responseJson[0].booking_id,
          booking_detail_id : responseJson[0].booking_detail_id,
          applyNumber:responseJson[0].apply_number,
          destination:responseJson[0].destination,
          routerType:responseJson[0].router_type,
          model:responseJson[0].model,
          devices:devices,
          updatedAt:responseJson[0].t10_booking_updated_at,
        });

      });

      this.state.loadedData = true;

    }

  }


  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

  }

  // 保存ボタン押下時
  handleClickSave(event) {

    // valiadteion
    for ( let i=0;i<this.state.devices.length;i ++ ) {

      const idBattery = (i+1) + "_battery";
      const idBag = (i+1) + "_bag";
      const idRecharger = (i+1) + "_recharger";
      const idDevice = (i+1) + "_device";

      if ( !document.getElementById(idBattery).checked 
      || !document.getElementById(idBag).checked 
      || !document.getElementById(idRecharger).checked 
      || !document.getElementById(idDevice).checked ) {
        alert('Please confirm Battery/Bag/Recharger/Device');
        return;
      }
      this.state.devices[i].battery = '1';
      this.state.devices[i].bag = '1';
      this.state.devices[i].recharger = '1';
      this.state.devices[i].device = '1';

    }


    const reqParam = {}
    reqParam.bookingId = this.state.bookingId;
    reqParam.booking_detail_id = this.state.booking_detail_id;
    reqParam.bookingStatus = constVal.booking_status.ReadyForSend;
    reqParam.updatedAt = this.state.updatedAt;
    reqParam.devices = JSON.stringify(this.state.devices);
    reqParam.functionName = 'PackingDevice';

    fetch('/api/updateBookingStatusPackingDevice', {
      method: "POST",
      body: JSON.stringify(reqParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if ( responseJson.error === undefined ) {

        this.setState({
          informDialogTitle:'Update OK',
          informDialogMessage:'Succesfully update booking.',
        });

      }
      else {

        this.setState({
          informDialogTitle:'Update Error',
          informDialogMessage:'Update booking failed!!' + responseJson.error,
        });

      }

      this.handleClose();

    })
    .catch((error) =>{
      console.error(error);
    });


  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showPackingDeviceDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">Device Assignment</DialogTitle>
          <DialogContent className="MyDialogContent">
            <FormLabel component="legend">Apply Number : {this.state.applyNumber}</FormLabel>
            <br />
            <FormLabel component="legend">Destination: {this.state.destination}</FormLabel>
            <br />
            <FormLabel component="legend">Model : {this.state.model}</FormLabel>
            <br />
            <FormLabel component="legend">Router Type : {this.state.routerType}</FormLabel>
            <br />
            <PackingDeviceDialogList dataRows={this.state.devices}/>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}

export default PackingDeviceDialog;