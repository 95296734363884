import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import CommonButton from '../../../../common/mirai_component/CommonButton'
import CommonRadio from '../../../../common/CommonRadio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';


class ModelEditDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      insertData      : false,
      loadedData      : false,
      modelId         : 0,
      model           : '',
      routerTypeId    : 0,
      updatedAt       : '',
      errorMsg :{
        model         : '',
        routerTypeId  : '',
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

  }


  handleClose() {
  
    this.setState({loadedData:false});
    this.props.handleEditDialogCloseEvent();
  
  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  componentWillUpdate(nextProps,nextState){

    if ( !this.state.loadedData && nextProps.editModelId !== -1 && nextProps.showEditDialog === true ) {

      // 新規
      if ( nextProps.editModelId === 0 ) {
        this.setState({
          modelId       : '',
          model         : '',
          routerTypeId  : '',
          insertData    : true,
        });
      }
      // 更新
      else {

        const search_condition = {
          modelId : [nextProps.editModelId],
        };
    
        // 単価検索
        fetch('/api/searchModel', {
          method: "POST",
          body: JSON.stringify(search_condition), 
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            modelId       : responseJson[0].model_id,
            model         : responseJson[0].model,
            routerTypeId  : responseJson[0].router_type_id,
            updatedAt     : responseJson[0].updatedAt,
            insertData    : false,
          });
        })
        .catch((error) =>{
          console.error(error);
        });

      }

      this.state.loadedData = true;

    }

  }


  // 保存ボタン押下時
  handleClickSave(event) {

    if ( this.state.insertData ) {

      // UnitPrice登録(INSERT)
      fetch('/api/resolveNewModel', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleClose();
      })
      .catch((error) =>{
        console.error(error);
      });

    }
    else {

      // UnitPrice登録(UPDATE)
      fetch('/api/resolveModel', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleClose();
      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          title="Model Edit"
          actions={actions}
          modal={false}
          open={this.props.showEditDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">Model Edit</DialogTitle>
          <DialogContent className="MyDialogContent">
            <MiraiTextField id="model"  name="model"  label="Model Name"  handleChange={this.handleChange} readOnly={false}  value={this.state.model}   errorText={this.state.errorMsg.model}   /><br />
            <CommonRadio readOnly={false}  radioList={this.props.routerTypeList}      onChangeEvent={this.handleChange} name={'routerTypeId'}         caption={'Router Type'}         radioValue={this.state.routerTypeId} />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}


export default ModelEditDialog;