import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const columnsShow = [
  { id: 'end_date'                              , label: 'Date'              ,  minWidth: 120 },
  { id: 'count_of_booking'                      , label: 'count of booking'  ,  minWidth: 100 , align: 'right' },
  { id: 'amount'                                , label: 'Amount'            ,  minWidth: 100 , align: 'right' },
  { id: 'returnMethod_headOffice'               , label: 'HeadOffice'        ,  minWidth: 100 , align: 'right' },
  { id: 'returnMethod_HaNoi'                    , label: 'HaNoi'             ,  minWidth: 100 , align: 'right' },
  { id: 'returnMethod_Lancaster'                , label: 'Lancaster'         ,  minWidth: 100 , align: 'right' },
  { id: 'returnMethod_Delivery_using'           , label: 'Not Returned'      ,  minWidth: 100 , align: 'right' },
  { id: 'returnMethod_Delivery_done'            , label: 'Returned'          ,  minWidth: 100 , align: 'right' },
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  cell:{
    "line-height":"0.2rem"
  },
  header_cell:{
    "background-color":"#afeeee",
    "line-height":"1rem"
  }
});


export default function ContractEndBooking(pPrams) {

  const params = pPrams;

  const classes = useStyles();
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const rows = params.dataRows;


  const handleClick = rowId => {
  }

  const columnsShow = params.column;


  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.header_cell}>
              </TableCell>
              <TableCell align="left" colSpan={2} className={classes.header_cell}>
                Total
              </TableCell>
              <TableCell align="left" colSpan={params.pickupMethodColSpan} className={classes.header_cell}>
                Pickup
              </TableCell>
              <TableCell align="left" colSpan={5} className={classes.header_cell}>
                Delivery
              </TableCell>
            </TableRow>
            <TableRow>
              {columnsShow.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.header_cell}
                  style={{ 
                    minWidth:column.minWidth ,
                    maxWidth:column.maxWidth 
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.end_date} >
                  {columnsShow.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} onClick={() => handleClick()} className={classes.cell}>
                        { typeof value === 'number' ? value.toLocaleString() : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}