import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '500px',
  },
}));

export default function TextFieldEmail(pPrams) {

  const classes = useStyles();
  const params = pPrams;

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };


  if ( params.visible ) {

    return (

      <FormControl >
        <TextField
          id={params.id}
          name={params.name}
          label={params.label}
          type={values.showPassword ? 'text' : 'password'}
          onChange={handleChange('password'),params.handleChange}
          className={classes.textField}
          error={params.errorText !== ""}
          helperText={params.errorText}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
          }}
        />
      </FormControl>
    );
  }
  else {
  
    return (
      <FormControl >
      </FormControl>
    );
  }

}