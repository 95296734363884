import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as commonFunction from '../../../../common/commonFunction';
import * as constVal from '../../../../const';



const columnsShow = [
  { id: 'device_number'       , label: 'Device Number'     , minWidth: 120 },
  { id: 'model'               , label: 'Model'             , minWidth: 150 },
  { id: 'router_type'         , label: 'Router Type'       , minWidth: 150 },
  { id: 'location'            , label: 'Location'          , minWidth: 150 },
  { id: 'ssid'                , label: 'SSID'              , minWidth: 100 },
  { id: 'sim_number'          , label: 'SIM Number'        , minWidth: 100 },
  { id: 'imei'                , label: 'IMEI'              , minWidth: 100 },
  { id: 'device_for'          , label: 'Device For'        , minWidth: 150 },
  { id: 'device_status_name'  , label: 'Device Status'     , minWidth: 100 },
  { id: 'register_date'       , label: 'Register Date'     , minWidth: 100 },
  { id: 'history_link'        , label: 'History'           , minWidth: 80  },
  { id: 'edit_link'           , label: 'Edit'              , minWidth: 50  },
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  applyNumber : {
    "text-decoration" : "underline",
    "line-height"     : "0.2rem",
    "cursor"          : "hand",
    "cursor"          : "pointer"
  },
  header_cell:{
    "background-color":"#afeeee",
    "line-height":"0.2rem"
  },
  cell:{
  },
});

export default function DeviceListTable(pPrams) {

  const params = pPrams;

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let rows = [];
  for ( let i=0;i<params.dataRows.length;i++ ) {
    params.dataRows[i].model = params.dataRows[i].m90_model.model;
    params.dataRows[i].router_type = params.dataRows[i].m90_model.m50_router_type.router_type;
    params.dataRows[i].location = params.dataRows[i].m80_location.location;
    params.dataRows[i].register_date = new Date(params.dataRows[i].register_date).toLocaleDateString();
    params.dataRows[i].device_status_name = commonFunction.getKeyByValue(constVal.device_status,params.dataRows[i].device_status);
    params.dataRows[i].device_for = params.dataRows[i]['m20_corporate']!==null?params.dataRows[i]['m20_corporate']['corporate_name']:'';
    params.dataRows[i].history_link = "[history]";
    params.dataRows[i].edit_link = "[edit]";
    rows.push(params.dataRows[i]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnsShow.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth:column.minWidth ,
                    maxWidth:column.maxWidth 
                  }}
                  className={classes.header_cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              const deviceNumber = <input type="hidden" value={row['device_number']} id="device_number" />;
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.device_number} >
                  {columnsShow.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} onClick={params.rowClickEvent} className={ (column.id==='history_link' || column.id==='edit_link') ? classes.applyNumber : classes.cell } >
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                        {column.id==='edit_link'    ? deviceNumber : ''}
                        {column.id==='history_link' ? deviceNumber : ''}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}