import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';


const useStyles = makeStyles(theme => ({
  message: {
    color: 'red',
  },
}));


export default function ErrorMessage(pPrams) {

  const classes = useStyles();
  const params = pPrams;

  return (
    <FormControl>
      <FormLabel className={classes.message}>
        {params.message}
      </FormLabel>
    </FormControl>
  );

}