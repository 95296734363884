import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";

import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Booking from "./pages/Booking";
import Top from "./pages/top";
import TopGW from "./pages/topGW";

import AuthRouter from "../common/auth/AuthRouter";

//invoice
import searchInvoice from "./pages/invoice/searchInvoice";
import entryInvoice from "./pages/invoice/entryInvoice";

//expenditure
import searchExpenditure from "./pages/expenditure/searchExpenditure";
import summaryExpenditure from "./pages/expenditure/summaryExpenditure";
import entryExpenditure from "./pages/expenditure/entryExpenditure";

//revenue
import searchRevenue from "./pages/revenue/searchRevenue";
import summaryRevenue from "./pages/revenue/summaryRevenue";
import entryRevenue from "./pages/revenue/entryRevenue";

// regist bookin
import bookingCorporate from "./pages/entryBooking/bookingCorporate";
import bookingPersonal from "./pages/entryBooking/bookingPersonal";

// search booking
import SearchBooking from "./pages/searchBooking/searchBooking";
import ContactCustomer from "./pages/searchBooking/ContactCustomer";
import DeviceAssignment from "./pages/searchBooking/DeviceAssignment";
import PackingDevice from "./pages/searchBooking/PackingDevice";
import SendDevice from "./pages/searchBooking/SendDevice";
import HandOverDevice from "./pages/searchBooking/HandOverDevice";
import ReturnDevice from "./pages/searchBooking/ReturnDevice";

// Agency
import AgencySearchBooking from "./pages/agency/SearchBooking";
import AgencyCreateBooking from "./pages/agency/CreateBooking";

// Master Maintenance
import Device from "./pages/masterDataMaintenance/device/Device";
import Corporate from "./pages/masterDataMaintenance/corporate/Corporate";
import User from "./pages/masterDataMaintenance/User/User";
import UnitPrice from "./pages/masterDataMaintenance/UnitPrice/UnitPrice";
import Model from "./pages/masterDataMaintenance/Model/Model";
import Location from "./pages/masterDataMaintenance/Location/Location";
import PickupMethod from "./pages/masterDataMaintenance/pickupMethod/PickupMethod";
import Campaign from "./pages/masterDataMaintenance/Campaign/Campaign";

// Stock
import Monitorling from "./pages/stock/Monitorling";

// Payment
import PaymentCreditCard from "./pages/payment/CreditCard/PaymentCreditCard";
import PaymentCash from "./pages/payment/Cash/PaymentCash";
import PaymentBankTransferPersonal from "./pages/payment/BankTransferPersonal/BankTransferPersonal";
import PaymentRequestIssueInvoice from "./pages/payment/RequestIssueInvoice/RequestIssueInvoice";
import InviceInfoUpload from "./pages/payment/InviceInfoUpload/InviceInfoUpload";
import PaymentBankTransfer from "./pages/payment/BankTransfer/PaymentBankTransfer";
import MonthlyReport from "./pages/payment/MonthlyReport/MonthlyReport";
import Refund from "./pages/payment/Refund/Refund";

// location
import locationPickup from "./pages/location/Pickup/Pickup";

// Report
import ReportDestination from "./pages/report/SummaryByDestination/ReportDestination";

class App extends Component {
  render() {
    const App = () => (
      <div>
        <Switch>
          // Common
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/booking" component={Booking} />
          // Wifi System
          <AuthRouter exact path="/top" component={Top} />
          <AuthRouter
            exact
            path="/bookingCorporate/"
            component={bookingCorporate}
          />
          <AuthRouter
            exact
            path="/bookingCorporate/:applyNumber"
            component={bookingCorporate}
          />
          <AuthRouter
            exact
            path="/bookingPersonal"
            component={bookingPersonal}
          />
          <AuthRouter
            exact
            path="/bookingPersonal/:applyNumber"
            component={bookingPersonal}
          />
          <AuthRouter exact path="/masterMaintDevive" component={Device} />
          <AuthRouter
            exact
            path="/masterMaintCorporate"
            component={Corporate}
          />
          <AuthRouter exact path="/masterMaintUser" component={User} />
          <AuthRouter
            exact
            path="/masterMaintUnitPrice"
            component={UnitPrice}
          />
          <AuthRouter exact path="/masterMaintModel" component={Model} />
          <AuthRouter exact path="/masterMaintLocation" component={Location} />
          <AuthRouter
            exact
            path="/masterMaintPickupMethod"
            component={PickupMethod}
          />
          <AuthRouter exact path="/masterCampaign" component={Campaign} />
          <AuthRouter exact path="/searchBooking" component={SearchBooking} />
          <AuthRouter
            exact
            path="/searchBookingContactCustomer"
            component={ContactCustomer}
          />
          <AuthRouter
            exact
            path="/searchBookingDeviceAssignment"
            component={DeviceAssignment}
          />
          <AuthRouter
            exact
            path="/searchBookingPackingDevice"
            component={PackingDevice}
          />
          <AuthRouter
            exact
            path="/searchBookingSendDevice"
            component={SendDevice}
          />
          <AuthRouter
            exact
            path="/searchBookingHandOverDevice"
            component={HandOverDevice}
          />
          <AuthRouter
            exact
            path="/searchBookingReturnDevice"
            component={ReturnDevice}
          />
          <AuthRouter exact path="/stockMonitoring" component={Monitorling} />
          <AuthRouter
            exact
            path="/paymentCreditCard"
            component={PaymentCreditCard}
          />
          <AuthRouter exact path="/paymentCash" component={PaymentCash} />
          <AuthRouter
            exact
            path="/paymentBankTransferPersonal"
            component={PaymentBankTransferPersonal}
          />
          <AuthRouter
            exact
            path="/paymentRequestIssueInvoice"
            component={PaymentRequestIssueInvoice}
          />
          <AuthRouter
            exact
            path="/paymentInvoiceInfoUpload"
            component={InviceInfoUpload}
          />
          <AuthRouter
            exact
            path="/paymentBankTransfer"
            component={PaymentBankTransfer}
          />
          <AuthRouter
            exact
            path="/paymentMonthlyReport"
            component={MonthlyReport}
          />
          <AuthRouter exact path="/refund" component={Refund} />
          <AuthRouter exact path="/locationPickup" component={locationPickup} />
          <AuthRouter
            exact
            path="/reportDestination"
            component={ReportDestination}
          />
          <AuthRouter
            exact
            path="/agency/searchBooking"
            component={AgencySearchBooking}
          />
          <AuthRouter
            exact
            path="/agency/createBooking"
            component={AgencyCreateBooking}
          />
          <AuthRouter
            exact
            path="/agency/createBooking/:applyNumber"
            component={AgencyCreateBooking}
          />
          // Group ware
          <AuthRouter exact path="/topGW" component={TopGW} />
          <AuthRouter exact path="/searchInvoice" component={searchInvoice} />
          <AuthRouter exact path="/entryInvoice" component={entryInvoice} />
          <AuthRouter
            exact
            path="/entryInvoice/:invoiceId"
            component={entryInvoice}
          />
          <AuthRouter
            exact
            path="/searchExpenditure"
            component={searchExpenditure}
          />
          <AuthRouter
            exact
            path="/summaryExpenditure"
            component={summaryExpenditure}
          />
          <AuthRouter
            exact
            path="/entryExpenditure"
            component={entryExpenditure}
          />
          <AuthRouter
            exact
            path="/entryExpenditure/:expenditureId"
            component={entryExpenditure}
          />
          <AuthRouter exact path="/searchRevenue" component={searchRevenue} />
          <AuthRouter exact path="/summaryRevenue" component={summaryRevenue} />
          <AuthRouter exact path="/entryRevenue" component={entryRevenue} />
          <AuthRouter
            exact
            path="/entryRevenue/:revenueId"
            component={entryRevenue}
          />
          <Route exact path="*" component={Login} />
        </Switch>
      </div>
    );
    return (
      <Switch>
        <App />
      </Switch>
    );
  }
}

export default App;
