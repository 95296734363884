import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Alert from '@material-ui/lab/Alert';
import MiraiTextField from '../../../common/mirai_component/MiraiTextField';
import CommonRadio from '../../../common/CommonRadio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import UploadFile from './UploadFile'
import const_val from '../../../const';

class ChangeStatusDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData          : false,
      expenditureId       : -1,
      paymentStatus       : 0,
      accountingStatus    : 0,
      updatedAt           : '',
      hasError            : false,
      errorMsg            : '',
      paymentStatusList   : [
        { id : '0' , label : 'Not Payed'},
        { id : '1' , label : 'Payed'},
      ],
      accountingStatusList   : const_val.accounting_status,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

  }



  componentWillUpdate(nextProps,nextState){

    
    if ( !this.state.loadedData && nextProps.showChangeStatusDialog === true ) {

      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      let todayString = today.getFullYear() + "-" + ("0" + (today.getMonth()+1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2)

      this.setState({
        expenditureId : nextProps.expenditureId,
        updatedAt     : nextProps.updatedAt,
        paymentStatus : nextProps.paymentStatus,
        accountingStatus : nextProps.accountingStatus,
      })

      this.state.loadedData = true;

    }

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;

    const errorMsg = Object.assign({}, this.state.errorMsg);
    errorMsg[name] = '';

    this.setState({ 
      [name] : event.target.value ,
       errorMsg : errorMsg
    });

  }



  handleClose() {

    this.setState({
      loadedData : false
    });
    this.props.closeChangeStatusDialogEvent(false);

  }




  handleClickSave(event){

    const formData = new FormData();
    formData.append('fields', JSON.stringify(this.state));

    fetch('/api/updateExpenditureStatus', {
      method: "POST",
      body: formData,
    })
    .then((response) => response.json())
    .then((responseJson) => {

      this.setState({
        loadedData : false
      });
      this.props.closeChangeStatusDialogEvent(true);

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px",marginLeft:"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px",marginLeft:"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    const alert = this.state.hasError ? <Alert severity="error">{this.state.errorMsg}</Alert> : '';

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showChangeStatusDialog}
          contentStyle={{width: "1050px", maxWidth: "none" , height:"1200px" , maxHeight:"none"}}
          >
          <DialogTitle id="alert-dialog-slide-title">Payment Status Update</DialogTitle>
          <DialogContent >
            <CommonRadio visible={true} readOnly={false}  radioList={this.state.paymentStatusList}  onChangeEvent={this.handleChange}   name={'paymentStatus'}   caption={'Payment Status'}   radioValue={this.state.paymentStatus} />
            <br />
            <CommonRadio visible={true} readOnly={false}  radioList={this.state.accountingStatusList}  onChangeEvent={this.handleChange}   name={'accountingStatus'}   caption={'Accounting Status'}   radioValue={this.state.accountingStatus} />
            <br />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}

export default ChangeStatusDialog;