import React, { Component } from 'react';
import checkSessionStatus from '../../../common/CheckSession'
import RevenueList from './RevenueList';
import InformDialog from '../../../common/mirai_component/InformDialog'
import CommonSelect from '../../../common/CommonSelect'


import MenuGW from '../../../common/MenuGW';
import ScreenTitle from '../../../common/ScreenTitle';

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import CommonCheckbox from '../../../common/CommonCheckbox';

import CommonButton from '../../../common/mirai_component/CommonButton'
import UpdateLogDialog from '../../../common/UpdateLogDialog'

import {connect} from 'react-redux';
import * as constVal from '../../../common/constant';
import * as commonFunction from '../../../common/commonFunction';

import const_val from '../../../const';

class SearchRevenue extends Component {

  constructor(props) {

    super(props);
    this.state = {
      payment_status        : [],
      customer_name         : '',
      corporate_name        : '',
      revenue_date_from     : '',
      revenue_date_to       : '',
      dataRows              : [],
      destination_list      : [],
      showUpdateLogDialog   : false,
      selectedRevenueId     : 0,
      user_id               : null,
      informDialogOpen      : false,
      informDialogTitle     : '',
      informDialogMessage   : '',
      userList              : [],
      paymentStatusCheckList: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.closeUpdateLogDialogEvent = this.closeUpdateLogDialogEvent.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }



  componentDidMount(prevProps) {
    
    fetch('/api/getUserList',{
      method: "POST",
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let userList = [];
      let userListRowBlank = {}
      userListRowBlank.id = null;
      userListRowBlank.label = '---';
      userList.push(userListRowBlank);

      for ( let i=0;i<responseJson.length;i++ ) {
        let userListRow = {}
        userListRow.id = responseJson[i].id;
        userListRow.label = responseJson[i].name;
        userList.push(userListRow);
      }
      this.setState({
        userList : userList,
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  closeUpdateLogDialogEvent(){
    this.setState({showUpdateLogDialog:false});
  }



  // 一覧のCellClick時
  onCellClick(event){
  
    let rowIndex = event.target.parentElement.rowIndex;
    let cellIndex = event.target.cellIndex;
    let keyVal = event.target.parentElement.children[0].textContent;

    if ( cellIndex == 3 ) {
      var revenue_id = event.target.parentElement.querySelector("#revenue_id").value;
      this.props.history.push('/entryRevenue/' + revenue_id);
    }
  }



  handleCheckboxChange(event) {

    const name = event.name;
    const checked_list = [];
    for(const each of event.list){
      if(each.checked){
        checked_list.push(each.id);
      }
    }
    this.setState({ [name] : checked_list});

  }

  

  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

  }



  // 検索ボタン押下時の処理
  handleClickSearch(){
    
    // Revenue検索API
    const search_condition = {
      revenue_date_from     : this.state.revenue_date_from,
      revenue_date_to       : this.state.revenue_date_to,
      user_id               : this.state.user_id,
      corporate_name        : this.state.corporate_name,
    };
    fetch('/api/searchRevenue', {
      method: "POST",
      body: JSON.stringify(search_condition), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      console.log(responseJson);

      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      let resultDate = [];
      for ( let i=0;i<responseJson.length;i++ ) {

        let resultRow = {};

        resultRow['corporate_name'] = !responseJson[i]['m20_corporate'] ? '' : responseJson[i]['m20_corporate']['corporate_name'];
        const revenueDate = new Date(responseJson[i]['date']);
        const revenueDateString = revenueDate.getFullYear() + "-" + ("0" + (revenueDate.getMonth()+1)).slice(-2) + "-" + ("0" + (revenueDate.getDate())).slice(-2)
        resultRow['year'] = revenueDate.getFullYear();
        resultRow['month'] = revenueDate.toLocaleString('default', { month: 'short' });;
        resultRow['service_type']       = commonFunction.getKeyByValue(const_val.service_type_list, responseJson[i]['service_type']);
        resultRow['revenue_price']      = responseJson[i]['revenue_price'];
        resultRow['vat_price']          = responseJson[i]['vat_price'];
        resultRow['total_price']        = responseJson[i]['total_price'];
        resultRow['total_price_vat']    = responseJson[i]['total_price_vat_included'];
        resultRow['note']               = responseJson[i]['note'];
        
        resultRow['revenue_id'] = responseJson[i]['revenue_id'];

        resultDate.push(resultRow);
      }

      this.setState({
        dataRows:resultDate
      });

      if ( responseJson.length === 0 ) {

        this.setState({
          informDialogTitle   : "Search Revenue Result",
          informDialogMessage : "No data found.",
          informDialogOpen    : true,
        });

      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  render() {

    return (
      <div align="center">
        <MenuGW history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Revenue Date</FormLabel></div>
            <TextField onChange={this.handleChange} id="revenue_date_from" name="revenue_date_from" style={{width: "200px"}} type="date" /> ~ 
            <TextField onChange={this.handleChange} id="revenue_date_to" name="revenue_date_to" style={{width: "200px"}} type="date" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Company Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="corporate_name" name="corporate_name" style={{width: "500px"}} type="search" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch} label="Search revenue"/>
        <br />
        <RevenueList dataRows={this.state.dataRows} onCellClickEvent={this.onCellClick}/>
        <UpdateLogDialog showUpdateLogDialog={this.state.showUpdateLogDialog} closeUpdateLogDialogEvent={this.closeUpdateLogDialogEvent} selectedRevenueId={this.state.selectedRevenueId} selectedRevenueId={this.state.selectedRevenueId}/>
        <InformDialog informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchRevenue);