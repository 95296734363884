import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CommonButton from '../../../../common/mirai_component/CommonButton'
import FormLabel from '@material-ui/core/FormLabel';
import * as commonFunction from '../../../../common/commonFunction';
import DeviceAssignmentDetail from './DeviceAssignmentDetail';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import ErrorMessage from '../../../../common/mirai_component/ErrorMessage';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';


import * as constVal from '../../../../common/constant';

class SendDeviceDaiglog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData          : false,
      bookingId           : 0,
      bookingDetailId     : 0,
      applyNumber         : 0,
      customerName        : '',
      customerTel         : '',
      customerAddress     : '',
      contractDate        : '',
      deliveryDate        : '',
      plan                : '',
      destinationId       : 0,
      destination         : '',
      quantity            : 0,
      paymentStatus       : 0,
      paymentMethod       : 0,
      updatedAt           : '',
      t20updatedAt        : '',
      informDialogTitle   : '',
      informDialogMessage : '',
      devices             : [],
      availableDevices    : [],
      deliveryNumber      : '',
      alertMessage        : '',
      errorMsg :{
        deliveryNumber:''
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

  }


  handleClose() {

    this.setState({
      loadedData:false,
      availableDevices:[]
    });
    this.props.closeSendDeviceDialogEvent(this.state.informDialogTitle,this.state.informDialogMessage);

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  componentWillUpdate(nextProps,nextState){
    
    if ( !this.state.loadedData && nextProps.selectedBookingId !== -1 && nextProps.showSendDeviceDialog === true ) {

      const search_condition = {
        booking_id        : nextProps.selectedBookingId,
        booking_detail_id : nextProps.selectedBookingDetailId,
      };
      fetch('/api/searchBookingDetailByView', {
        method: "POST",
        body: JSON.stringify(search_condition), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        let customerName = '';
        let customerTel = '';
        let customerAddress = '';

        // 法人
        if ( responseJson[0].contract_type === 0 ) {
          customerName = responseJson[0]['corporate_name'];
          customerTel = responseJson[0]['m20_corporate_tel_number'];
          customerAddress = responseJson[0]['m20_corporate_address'];
        }
        // 個人
        else {
          customerName = responseJson[0]['t10_booking_customer_name'];
          customerTel = responseJson[0]['t10_booking_customer_tel'];
          customerAddress = responseJson[0]['t10_booking_customer_address'];
        }

        let devices = [];
        for ( let i=0;i<responseJson.length;i++ ) {
          let deviceRow = {};
          deviceRow.row_id = (i+1);
          deviceRow.device_number = responseJson[i]['device_number'];
          deviceRow.model = responseJson[i].model;
          deviceRow.router_type = responseJson[i].router_type;
          devices.push(deviceRow);
        }

        let alertMessage = '';
        // Cash/CreditCardで未払いの場合
        if ( responseJson[0].payment_method === constVal.payment_method.Cash || responseJson[0].payment_method === constVal.payment_method.CreditCard ) {
          if ( responseJson[0].payment_status === constVal.payment_status.NotPayed ) {
            alertMessage = 'This booking is not yet payed.Are you sure to send this device(s)?';
          }
        }
        else {
          if ( responseJson[0].contract_type === 1 ) {
            alertMessage = 'This booking is not yet payed.Are you sure to send this device(s)?';
          }
        }

        this.setState({
          bookingId       : responseJson[0].booking_id,
          bookingDetailId : responseJson[0].booking_detail_id,
          applyNumber     : responseJson[0].apply_number,
          customerName    : customerName,
          customerTel     : customerTel,
          customerAddress : customerAddress,
          deliveryDate    : commonFunction.formatYYYYMMDD(new Date(responseJson[0]['delivery_date'])),
          contractDate    : commonFunction.formatYYYYMMDD(new Date(responseJson[0]['start_date'])) + " - " + commonFunction.formatYYYYMMDD(new Date(responseJson[0]['end_date'])),
          plan            : responseJson[0].plan,
          destination     : responseJson[0].destination,
          quantity        : responseJson[0].quantity,
          updatedAt       : responseJson[0]['t10_booking_updated_at'],
          t20updatedAt    : responseJson[0]['t20_booking_detail_updated_at'],
          devices         : devices,
          paymentStatus   : responseJson[0].payment_status,
          paymentMethod   : responseJson[0].payment_method,
          alertMessage    : alertMessage,
        });

      });

      this.state.loadedData = true;

    }

  }



  // 保存ボタン押下時
  handleClickSave(event) {

    const reqParam = {}
    reqParam.bookingId = this.state.bookingId;
    reqParam.bookingDetailId = this.state.bookingDetailId;
    reqParam.updatedAt = this.state.updatedAt;
    reqParam.t20updatedAt = this.state.t20updatedAt;
    reqParam.functionName = 'SendDevice';
    reqParam.bookingStatus = constVal.booking_status['Using(Delivery)'];
    reqParam.deliveryNumber = this.state.deliveryNumber;

    fetch('/api/updateBookingStatusSendDevice', {
      method: "POST",
      body: JSON.stringify(reqParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      
      if ( responseJson.error === undefined ) {

        this.setState({
          informDialogTitle:'Update OK',
          informDialogMessage:'Succesfully update booking.',
        });

      }
      else {

        this.setState({
          informDialogTitle:'Update Error',
          informDialogMessage:'Update booking failed!!' + responseJson.error,
        });

      }

      this.handleClose();

    })
    .catch((error) =>{
      console.error(error);
    });

  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showSendDeviceDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">Send Device</DialogTitle>
          <DialogContent className="MyDialogContent">
            <FormLabel component="legend">Apply Number : {this.state.applyNumber}</FormLabel>
            <br />
            <FormLabel component="legend">Customer Name : {this.state.customerName}</FormLabel>
            <br />
            <FormLabel component="legend">Delivery Date : {this.state.deliveryDate}</FormLabel>
            <br />
            <FormLabel component="legend">Contract Date : {this.state.contractDate}</FormLabel>
            <br />
            <FormLabel component="legend">Plan : {this.state.plan}</FormLabel>
            <br />
            <FormLabel component="legend">Destination : {this.state.destination}</FormLabel>
            <br />
            <FormLabel component="legend">Quantity : {this.state.quantity}</FormLabel>
            <br />
            <DeviceAssignmentDetail dataRows={this.state.devices}/>
            <br />
            <br />
            <ErrorMessage message={this.state.alertMessage} />
            <br />
            <MiraiTextField id="deliveryNumber"  name="deliveryNumber"  label="Delivery Number"  handleChange={this.handleChange}   readOnly={false}  value={this.state.deliveryNumber}   errorText={this.state.errorMsg.deliveryNumber}   /><br />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}

export default SendDeviceDaiglog;