import React, { Component } from 'react';
import Link from '@material-ui/core/Link';

import MenuGW from '../../../common/MenuGW';
import ScreenTitle from '../../../common/ScreenTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import MiraiTextField from '../../../common/mirai_component/MiraiTextField';
import SearchCorporate from '../../../common/SearchCorporate';

import CommonSelect from '../../../common/CommonSelect'
import CommonButton from '../../../common/mirai_component/CommonButton'
import InformDialog from '../../../common/mirai_component/InformDialog'
import ConfirmDialog from '../../../common/mirai_component/ConfirmDialog'

import {connect} from 'react-redux';
import * as constVal from '../../../common/constant';
import CommonRadio from '../../../common/CommonRadio';
import * as commonFunction from '../../../common/commonFunction';


class entryRevenue extends Component {

  constructor(props) {

    super(props);

    this.state = {
      revenueId              : null,
      revenueDate            : '',
      userId                 : -1,
      corporateId            : '',
      corporateName          : '',
      corporate_list         : [],
      serviceType            : '',
      note                   : '',
      revenuePrice           : 0,
      vatPrice               : 0,
      totalPrice             : 0,
      totalPriceVAT          : 0,
      updatedAt              : '',
      userList               : [],
      showChangeStatusDialog : false,
      saveButtonLabel        : 'Entry',
      informDialogOpen       : false,
      informDialogTitle      : '',
      informDialogMessage    : '',
      reloadData             : false,
      errorMsg : {
        revenueDate : '',
        userId          : '',
        corporateId     : '',
        corporateName   : '',
        serviceType     : '',
        note            : '',
        revenuePrice    : '',
        vatPrice        : '',
        totalPrice      : '',
        totalPriceVAT   : '',
      },
      visibleProps : {
        saveButton         : true,
        changeStatusButton : false,
        deleteButton       : false,
      },
      readOnlyProps : {
        userId        : false,
        corporateId   : false,
        serviceType   : false,
        note          : false,
        revenuePrice  : false,
        vatPrice      : false,
        totalPrice    : false,
        totalPriceVAT : false,
      },
      serviceTypeList : [
        { id : '0' , label : 'Postpaid'},
        { id : '1' , label : 'Prepaid'},
        { id : '2' , label : 'Wifi rental'},
        { id : '3' , label : 'Device +sim' },
        { id : '4' , label : 'etc' },
      ],
    }

    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

    const revenueId = props.match.params.revenueId;
    // 一覧からの遷移時
    if ( revenueId !== undefined && revenueId !== '' ) {
      this.state.revenueId = revenueId;
    }

  }



  componentDidMount(prevProps) {

    // 新規作成
    if ( this.state.revenueId === null ) {

      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      let todayString = today.getFullYear() + "-" + ("0" + (today.getMonth()+1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2)

      let thisMonthlastDate = new Date();
      thisMonthlastDate.setHours(0);
      thisMonthlastDate.setMinutes(0);
      thisMonthlastDate.setSeconds(0);
      thisMonthlastDate.setMilliseconds(0);
      thisMonthlastDate.setDate(1);
      thisMonthlastDate.setMonth(thisMonthlastDate.getMonth()+1);
      thisMonthlastDate.setDate(thisMonthlastDate.getDate()-1);

      let thisMonthlastDateString = thisMonthlastDate.getFullYear() + "-" + ("0" + (thisMonthlastDate.getMonth()+1)).slice(-2) + "-" + ("0" + (thisMonthlastDate.getDate())).slice(-2)

      const visibleProps = Object.assign({}, this.state.visibleProps);
      visibleProps.deleteButton = false;

      this.setState({
        revenueDate    : todayString,
        userId         : this.props.id,
        visibleProps   : visibleProps,
      })

    }
    else {

      let reqJson = {};
      reqJson.revenueId = this.state.revenueId;

      // Booking検索
      fetch('/api/searchRevenue', {
        method: "POST",
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqJson)
      })
      .then((response) => response.json())
      .then((responseJson) => {

        let revenueDate = new Date(responseJson[0].date);
        revenueDate.setHours(0);
        revenueDate.setMinutes(0);
        revenueDate.setSeconds(0);
        revenueDate.setMilliseconds(0);
        let revenueDateString = revenueDate.getFullYear() + "-" + ("0" + (revenueDate.getMonth()+1)).slice(-2) + "-" + ("0" + (revenueDate.getDate())).slice(-2)

        const visibleProps = Object.assign({}, this.state.visibleProps);
        if ( this.props.admin === true ){
          visibleProps.deleteButton = true;
        }
        else {
          visibleProps.deleteButton = false;
        }

        this.setState({
          revenueId          : responseJson[0].revenue_id,
          revenueDate        : revenueDateString,
          userId             : responseJson[0].user_id,
          corporateId        : responseJson[0].corporate_id,
          corporateName      : !responseJson[0]['m20_corporate'] ? '' : responseJson[0]['m20_corporate'].corporate_name,
          serviceType        : responseJson[0].service_type,
          note               : responseJson[0].note,
          revenuePrice       : responseJson[0].revenue_price,
          vatPrice           : responseJson[0].vat_price,
          totalPrice         : responseJson[0].total_price,
          totalPriceVAT      : responseJson[0].total_price_vat_included,
          updatedAt          : responseJson[0].updatedAt,
          visibleProps       : visibleProps,
          saveButtonLabel    : 'Update'
        });

      });
    
    }


    let requestParam = {};
    fetch('/api/getUserList',{
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      let userList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let userListRow = {}
        userListRow.id = responseJson[i].id;
        userListRow.label = responseJson[i].name;
        userList.push(userListRow);
      }
      this.setState({
        userList : userList,
      });
    })
    .catch((error) =>{
      console.error(error);
    });

    fetch('/api/getAllCorporate', {
      method: "POST",
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let list = [];
      let row = {};
//      row.id = '';
//      row.label = ' --- ';
//      list.push(row);

      for ( let i=0;i<responseJson.length;i++ ) {
        let row = {};
        row.id = responseJson[i].corporate_id;
        row.label = responseJson[i].corporate_name;
        list.push(row);
      }
      this.setState({
        corporate_list:list
      });

    })
    .catch((error) =>{
      console.error(error);
    });
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    const errorMsg = Object.assign({}, this.state.errorMsg);
    errorMsg[name] = '';

    this.setState({ 
      [name] : event.target.value ,
       errorMsg : errorMsg
    });

    this.state[name] = event.target.value;

  }

  //入力チェック
  validateForm(){

    let hasError = false;

    const errorMsg = Object.assign({}, this.state.errorMsg);
    if ( this.state.corporateName == '' ) {
      errorMsg.corporateName = 'Please input corporate.';
      hasError = true;
    }
    
    if ( this.state.serviceType === '') {
      errorMsg.serviceType = 'Select service type.';
      hasError = true;
    }
    
    if ( this.state.revenuePrice.length == 0 || isNaN(this.state.revenuePrice)) {
      errorMsg.revenuePrice = 'Please input Revenue (VND)';
      hasError = true;
    }
    
    if ( this.state.vatPrice.length == 0 || isNaN(this.state.vatPrice)) {
      errorMsg.vatPrice = 'Please input VAT';
      hasError = true;
    }
    
    if ( this.state.totalPrice.length == 0 || isNaN(this.state.totalPrice)) {
      errorMsg.totalPrice = 'Please input total price';
      hasError = true;
    }
    
    if ( this.state.totalPriceVAT.length == 0 || isNaN(this.state.totalPriceVAT)) {
      errorMsg.totalPriceVAT = 'Please input total price with VAT';
      hasError = true;
    }
    this.state.errorMsg.errList = errorMsg;
    this.setState({errorMsg:errorMsg});
    return hasError;

  }

  handleClickSave(event){
    // 入力チェック
    if ( this.validateForm() ) {
      let errorMsg = 'Error(s) occurs.';
      const listErrorMsg = this.state.errorMsg.errList;
      for (const index in listErrorMsg) {
        if ( listErrorMsg[index] !== '' ) {
          errorMsg += '\r\n' + listErrorMsg[index];
        }
      }
      this.setState({
        informDialogOpen:true,
        informDialogTitle:'Save Error',
        informDialogMessage:errorMsg,
      });
      return;
    }

    const formData = new FormData();
    formData.append('fields', JSON.stringify(this.state));
    // 新規登録
    if ( this.state.revenueId === null ) {

      fetch('/api/entryRevenueData', {
        method: "POST",
        body: formData,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.error) return false;
        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Entry',
          informDialogMessage:'Revenue data entry was succesfuly done.',
          revenueId : responseJson.revenue_id,
          reloadData : true,
        });

      })
      .catch((error) =>{
        console.error(error);
      });

    }
    else {

      fetch('/api/updateRevenueData', {
        method: "POST",
        body: formData,
      })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Update',
          informDialogMessage:'Revenue data update was succesfuly done.',
          reloadData : true,
        });

      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }

  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

    if ( this.state.reloadData && this.state.revenueId) {
      this.props.history.push('/entryRevenue/' + this.state.revenueId);
    }

  }


  handleClickDelete(event){

    fetch('/api/deleteRevenueData', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      this.setState({
        informDialogOpen:true,
        informDialogTitle:'Delete',
        informDialogMessage:'Revenue data delete was succesfuly done.',
        expenditureId : responseJson.expenditure_id,
        reloadData : true,
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }


  render() {

    return (
      <div align="center">
        <MenuGW history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />

        <TextField label="Revenue Date" onChange={this.handleChange} id="revenueDate" name="revenueDate" value={this.state.revenueDate} width="400" type="date" />
        <br />
        <CommonSelect readOnly={this.state.readOnlyProps.userId} caption={"Person In Charge"} name={"userId"} onChangeEvent={this.handleChange} selectValue={this.state.userId} selectList={this.state.userList} />
        <br />
        <Autocomplete id="corporate-ac" freeSolo style={{ width: 660 }}
          options={this.state.corporate_list.map((option) => option.label)} 
          inputValue={this.state.corporateName}
          onInputChange={(event, newInputValue) => {
            this.handleChange({target:{name : 'corporateName', value : newInputValue}});
          }}
          renderInput={(params) => (
            <TextField {...params} label="Corporate Name" margin="normal" id="corporateName" name="corporateName"
            error={this.state.errorMsg.corporateName !== ""}
            helperText={this.state.errorMsg.corporateName} />
          )}
        />
        <br />
        <CommonSelect readOnly={this.state.readOnlyProps.serviceType} caption={"Service Type"} name={"serviceType"} onChangeEvent={this.handleChange} selectValue={this.state.serviceType} selectList={this.state.serviceTypeList} errorText={this.state.errorMsg.serviceType}/>
        <br />
        <MiraiTextField handleChange={this.handleChange} label="Revenue (VND)" id="revenuePrice" name="revenuePrice"  value={this.state.revenuePrice} width="400" type="number" errorText={this.state.errorMsg.revenuePrice} />
        <br />
        <br />
        <MiraiTextField handleChange={this.handleChange} label="VAT" id="vatPrice" name="vatPrice"  value={this.state.vatPrice} width="400" type="number" errorText={this.state.errorMsg.vatPrice} />
        <br />
        <br />
        <MiraiTextField handleChange={this.handleChange} label="Total Price" id="totalPrice" name="totalPrice"  value={this.state.totalPrice} width="400" type="number" errorText={this.state.errorMsg.totalPrice} />
        <br />
        <br />
        <MiraiTextField handleChange={this.handleChange} label="Total with VAT" id="totalPriceVAT" name="totalPriceVAT"  value={this.state.totalPriceVAT} width="400" type="number" errorText={this.state.errorMsg.totalPriceVAT} />
        <br />
        <br />
        <MiraiTextField visible={true} handleChange={this.handleChange} id="note"         name="note"         label="Note"         value={this.state.note}        errorText={this.state.errorMsg.note}/>
        <br />
        <br />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.saveButton}          clickEvent={this.handleClickSave}          label={this.state.saveButtonLabel} />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.deleteButton}        clickEvent={this.handleClickDelete}        label={"Delete"} />
        <br />
        <InformDialog        informDialogStatus={this.state.informDialogOpen}   informDialogTitle={this.state.informDialogTitle}   informDialogMessage={this.state.informDialogMessage}  informDialogCloseEvent={this.informDialogCloseEvent} />
      </div>
    );

  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName,
    id               : state.login.id,
    admin            : state.login.admin,
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(entryRevenue);
