import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CommonButton from '../../../common/mirai_component/CommonButton'
import * as commonFunction from '../../../common/commonFunction';
import ExtendDialogList from './ExtendDialogList';
import Alert from '@material-ui/lab/Alert';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';



class ExtendDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData          : false,
      bookingDetail       : [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

  }


  handleClose() {

    this.setState({
      loadedData:false,
    });
    this.props.closeExtendDialogEvent([]);

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  componentWillUpdate(nextProps,nextState){

    if ( !this.state.loadedData && nextProps.bookingDetail.length > 0 && nextProps.showExtendDialog === true ) {

      let bookingDetail = []
      let rowId = 0;
      for ( let i=0;i<nextProps.bookingDetail.length;i++ ) {

        if ( parseInt(nextProps.bookingDetail[i].isExtended) === 0 ) {
          if ( parseInt(nextProps.bookingDetail[i].bookingSubStatus) === 600 || parseInt(nextProps.bookingDetail[i].bookingSubStatus) === 700 ) {
            let bookingDetailRow = nextProps.bookingDetail[i];
            bookingDetailRow.rowNum = ++rowId;
            bookingDetailRow.startDateYYYYMMDD = commonFunction.formatYYYYMMDD(new Date(nextProps.bookingDetail[i].startDate));
            bookingDetailRow.endDateYYYYMMDD = commonFunction.formatYYYYMMDD(new Date(nextProps.bookingDetail[i].endDate));
            bookingDetailRow.extendTo = '';
            bookingDetail.push(bookingDetailRow);
          }
        }
      }

      this.setState({
        bookingDetail    : bookingDetail,
      });
      
      this.state.loadedData = true;

    }

  }



  // 保存ボタン押下時
  handleClickSave(event) {
 
    this.setState({
      loadedData:false,
      bookingDetail:[]
    });

    let bookingDetail = this.state.bookingDetail;
    for ( let i=0;i<bookingDetail.length;i++ ) {

      const extendToId = "extendTo_" + (i+1) 

      if ( document.getElementById(extendToId).value != '' ) {
        bookingDetail[i].extendTo = document.getElementById(extendToId).value;
      }

    }

    this.props.closeExtendDialogEvent(bookingDetail);

  }



  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Extend</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    const alert = this.state.hasError ? <Alert severity="error">{this.state.errorMsg}</Alert> : '';

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showExtendDialog}
          onRequestClose={this.handleClose}
          contentStyle={{width: "1050px", maxWidth: "none"}}
        >
          <DialogTitle id="alert-dialog-slide-title">Contract Term Extend</DialogTitle>
          <DialogContent className="MyDialogContent">
            <ExtendDialogList dataRows={this.state.bookingDetail} handleChange={this.handleChange} />
            <br />
            {alert}
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}

export default ExtendDialog;