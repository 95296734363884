import React, { Component } from 'react';

import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';
import InformDialog from '../../../../common/mirai_component/InformDialog'
import RefundList from './RefundList';
import RefundDialog from './RefundDialog';

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import CommonCheckbox from '../../../../common/CommonCheckbox';

import CommonButton from '../../../../common/mirai_component/CommonButton'

import {connect} from 'react-redux';
import * as commonFunction from '../../../../common/commonFunction';

import const_val from '../../../../const';

class Refund extends Component {

  constructor(props) {

    super(props);
    this.state = {
      refund_status              : [],
      customer_name              : '',
      corporate_name             : '',
      apply_number               : '',
      dataRows                   : [],
      refundStatusCheckBoxValue  : [],
      showRefundDialog           : false,
      selectedRefundId           : 0,
      informDialogOpen           : false,
      informDialogTitle          : '',
      informDialogMessage        : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.closeDialogEvent = this.closeDialogEvent.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }



  componentDidMount(prevProps) {

    // ログイン認証チェック
    // ログイン済みであればメニューを表示
    // checkSessionStatus(this.props,'/searchBooking');

    let refundStatusCheckBoxValue = const_val.refund_status;
    refundStatusCheckBoxValue[0].checked = true;
    this.state.refund_status.push(0);

    this.setState({
      refundStatusCheckBoxValue:refundStatusCheckBoxValue,
    });

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  closeDialogEvent(informDialogTitle,informDialogMessage){

    if ( informDialogTitle !== '' ) {
      this.setState({
        informDialogOpen    : true,
        informDialogTitle   : informDialogTitle,
        informDialogMessage : informDialogMessage,
      })
    }
    this.setState({showRefundDialog:false});

    if ( informDialogTitle !== '' ) {
      this.handleClickSearch()
    }

  }



  // 一覧のCellClick時
  onCellClick(event){

    let cellIndex = event.target.cellIndex;

    // Detail押下 -> BookingDetailへ遷移
    if ( cellIndex == 6 ) {
      let refundId = event.target.children[0].value;
      this.setState({
        showRefundDialog : true,
        selectedRefundId : refundId,
      });
    }

  }



  handleCheckboxChange(event) {

    const name = event.name;
    const checked_list = [];
    for(const each of event.list){
      if(each.checked){
        checked_list.push(each.id);
      }
    }
    this.setState({ [name] : checked_list});
  
  }



  // 検索ボタン押下時の処理
  handleClickSearch(){

    // Booking検索API
    const search_condition = {
      refund_status      : this.state.refund_status,
      corporate_name     : this.state.corporate_name,
      customer_name      : this.state.customer_name,
      apply_number       : this.state.apply_number
    };
    fetch('/api/searchRefund', {
      method: "POST",
      body: JSON.stringify(search_condition), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      for ( let i=0;i<responseJson.length;i++ ) {

        responseJson[i].contract_type_label = commonFunction.getContractTypeNameById(responseJson[0]["t10_booking"]["contract_type"]);
        if ( responseJson[i]["t10_booking"]["contract_type"] === 0 ) {
          responseJson[i].corporate_customer_name = responseJson[i]["t10_booking"]["m20_corporate"]["corporate_name"];
        }
        else {
          responseJson[i].corporate_customer_name = responseJson[i]["t10_booking"]["customer_name"];
        }
        responseJson[i].apply_date = new Date(responseJson[i]['apply_date']).toLocaleString();
        responseJson[i].refund_status_label = commonFunction.getRefundStatusNameById(responseJson[i]['refund_status']);
        responseJson[i].refund = '[refund]';

      }
      this.setState({dataRows:responseJson});

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

    this.handleClickSearch();

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Refund Status</FormLabel></div>
            <CommonCheckbox name="refund_status"  checkboxValue={this.state.refundStatusCheckBoxValue} onCheckedValue={this.handleCheckboxChange}/>
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Company Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="corporate_name" name="corporate_name" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Customer Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="customer_name" name="customer_name" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Apply Number</FormLabel></div>
            <TextField onChange={this.handleChange} id="apply_number" name="apply_number" style={{width: "500px"}} type="search" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch} label="Search booking"/>
        <br />
        <RefundList dataRows={this.state.dataRows} onCellClickEvent={this.onCellClick} />
        <RefundDialog showRefundDialog={this.state.showRefundDialog} closeRefundDialogEvent={this.closeDialogEvent} selectedRefundId={this.state.selectedRefundId} /> 
        <InformDialog informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Refund);