import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AvailFlgRadio from './AvailFlgRadio';


class CampaignEditDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      insertData      : false,
      loadedData      : false,
      campaignId      : 0,
      campaignTitle   : '',
      discountRate    : null,
      discountAmount  : null,
      availFlg        : 0,
      updatedAt       : '',
      errorMsg :{
        campaignTitle  : '',
        discountRate   : '',
        discountAmount : '',
        availFlg       : '',
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

  }



  handleClose() {
  
    this.setState({loadedData:false});
    this.props.handleEditDialogCloseEvent();
  
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  componentWillUpdate(nextProps,nextState){

    if ( !this.state.loadedData && nextProps.editCampaignId !== -1 && nextProps.showEditDialog === true ) {

      // 新規
      if ( nextProps.editCampaignId === 0 ) {
        this.setState({
          campaignId     : '',
          campaignTitle  : '',
          discountRate   : null,
          discountAmount : null,
          availFlg       : 0,
          insertData     : true,
        });
      }
      // 更新
      else {

        const search_condition = {
          campaignId : [nextProps.editCampaignId],
        };
    
        // 単価検索
        fetch('/api/searchCampaign', {
          method: "POST",
          body: JSON.stringify(search_condition), 
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            campaignId     : responseJson[0].campaign_id,
            campaignTitle  : responseJson[0].campaign_title,
            discountRate   : responseJson[0].discount_rate,
            discountAmount : responseJson[0].discount_amount,
            availFlg       : responseJson[0].avail_flg,
            updatedAt      : responseJson[0].updatedAt,
            insertData     : false,
          });
        })
        .catch((error) =>{
          console.error(error);
        });

      }

      this.state.loadedData = true;

    }

  }



  // 保存ボタン押下時
  handleClickSave(event) {

    if ( this.state.insertData ) {

      // Campaign登録(INSERT)
      fetch('/api/resolveNewCampaign', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleClose();
      })
      .catch((error) =>{
        console.error(error);
      });

    }
    else {

      // Campaign登録(UPDATE)
      fetch('/api/resolveCampaign', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleClose();
      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }



  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showEditDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">Campaign Edit</DialogTitle>
          <DialogContent className="MyDialogContent">
            <MiraiTextField id="campaignTitle"   name="campaignTitle"   label="Campaign Title"        handleChange={this.handleChange} readOnly={false}  value={this.state.campaignTitle}   errorText={this.state.errorMsg.campaignTitle}  />
            <MiraiTextField id="discountRate"    name="discountRate"    label="Discount Rate(%)"      handleChange={this.handleChange} readOnly={false}  value={this.state.discountRate}    errorText={this.state.errorMsg.discountRate}   />
            <MiraiTextField id="discountAmount"  name="discountAmount"  label="Discount Amount(VND)"  handleChange={this.handleChange} readOnly={false}  value={this.state.discountAmount}  errorText={this.state.errorMsg.discountAmount} />
            <AvailFlgRadio radioValue={this.state.availFlg} readOnly={false}  onChangeEvent={this.handleChange} />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}


export default CampaignEditDialog;