import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SearchIcon from "@material-ui/icons/Search";
import PhoneIcon from "@material-ui/icons/Phone";
import RouterIcon from "@material-ui/icons/Router";
import AddShoppingCartSharpIcon from "@material-ui/icons/AddShoppingCartSharp";
import AirportShuttleSharpIcon from "@material-ui/icons/AirportShuttleSharp";
import ThumbsUpDownSharpIcon from "@material-ui/icons/ThumbsUpDownSharp";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";

const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    display: "inline-flex",
  },
  listItem: {
    backgroundColor: "#a9a9a9",
    padding: "4px",
    marginRight: "8px",
    width: "200px",
  },
  listItemText: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
}));

const options = ["Search Booking", "Create Booking"];

const menuLinks = ["/agency/searchBooking", "/agency/createBooking"];

export default function AgencyMenuBooking(pParams) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, functionName) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    pParams.onClickEvent(index, functionName);
    pParams.screenTransitEvent.push(menuLinks[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <List className={classes.list} component="nav" aria-label="Booking">
        {options.map(
          (option, index) =>
            (pParams.accept.length == 0 ||
              pParams.accept.includes(menuLinks[index])) && (
              <ListItem
                className={classes.listItem}
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="Booking"
                key={option}
                selected={index === selectedIndex}
                onClick={(event) =>
                  handleMenuItemClick(event, index, options[index])
                }
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={option}
                  secondary=""
                />
              </ListItem>
            )
        )}
      </List>
    </div>
  );
}
