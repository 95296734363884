import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import CommonButton from '../../../../common/mirai_component/CommonButton'
import CommonRadio from '../../../../common/CommonRadio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';


class PickupMethodEditDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      insertData       : false,
      loadedData       : false,
      pickupMethodId   : 0,
      pickupMethod     : '',
      isDelivery       : 1,
      isLongTerm       : 0,
      locationId       : 0,
      updatedAt        : '',
      isDeliveryList   : [
        { id : '0' , label : 'Delivery'},
        { id : '1' , label : 'Pickup'},
        { id : '2' , label : 'Long Term User'},
      ],
      errorMsg :{
        pickupMethod  : '',
        isDelivery    : '',
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);

  }


  handleClose() {
  
    this.setState({loadedData:false});
    this.props.handleEditDialogCloseEvent();
  
  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  componentWillUpdate(nextProps,nextState){

    if ( !this.state.loadedData && nextProps.editPickupMethodId !== -1 && nextProps.showEditDialog === true ) {

      // 新規
      if ( nextProps.editPickupMethodId === 0 ) {
        this.setState({
          pickupMethodId : '',
          pickupMethod   : '',
          isDelivery     : 1,
          locationId     : 0,
          insertData     : true,
        });
      }
      // 更新
      else {

        const search_condition = {
          pickupMethodId : [nextProps.editPickupMethodId],
        };
    
        // 単価検索
        fetch('/api/searchPickupMethod', {
          method: "POST",
          body: JSON.stringify(search_condition), 
          headers:{
            'Content-Type': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            pickupMethodId  : responseJson[0].pickup_method_id,
            pickupMethod    : responseJson[0].pickup_method,
            isDelivery      : responseJson[0].is_delivery,
            locationId      : responseJson[0].location_id,
            updatedAt       : responseJson[0].updatedAt,
            insertData      : false,
          });
        })
        .catch((error) =>{
          console.error(error);
        });

      }

      this.state.loadedData = true;

    }

  }


  // 保存ボタン押下時
  handleClickSave(event) {

    if ( this.state.insertData ) {

      // UnitPrice登録(INSERT)
      fetch('/api/resolveNewPickupMethod', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleClose();
      })
      .catch((error) =>{
        console.error(error);
      });

    }
    else {

      // UnitPrice登録(UPDATE)
      fetch('/api/resolvePickupMethod', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleClose();
      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];


    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showEditDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">Pickup Method Edit</DialogTitle>
          <DialogContent className="MyDialogContent">
            <MiraiTextField id="pickupMethod"  name="pickupMethod"  label="Pickup Method"  handleChange={this.handleChange} readOnly={false}  value={this.state.pickupMethod}   errorText={this.state.errorMsg.pickupMethod}   /><br />
            <CommonRadio readOnly={false}  radioList={this.state.isDeliveryList}   onChangeEvent={this.handleChange}   name={'isDelivery'}   caption={'Is Delivery'}      radioValue={this.state.isDelivery} />
            <br />
            <CommonRadio readOnly={false}  radioList={this.props.locationList}     onChangeEvent={this.handleChange}   name={'locationId'}   caption={'Location'}         radioValue={this.state.locationId} />
            <br />
            <CommonButton visible={true}   clickEvent={this.handleClickSave}  label="SAVE" />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}


export default PickupMethodEditDialog;