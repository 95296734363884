import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as commonFunction from '../../../../common/commonFunction';
import * as constVal from '../../../../const';



const columnsShow = [
  { id: 'plan'                , label: 'Plan'              , minWidth: 150 },
  { id: 'destination'         , label: 'Destination'       , minWidth: 150 },
  { id: 'unit_price'          , label: 'Price(per day)'    , minWidth: 150  },
  { id: 'edit_link'           , label: 'Edit'              , minWidth: 50  },
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  header_cell:{
    "background-color":"#afeeee",
    "line-height":"0.2rem"
  },
  cell:{
    "line-height":"0.2rem"
  },
});

export default function UnitPriceListTable(pPrams) {

  const params = pPrams;

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let rows = [];
  for ( let i=0;i<params.dataRows.length;i++ ) {
    params.dataRows[i].rowId = i;
    params.dataRows[i].plan = params.dataRows[i].m30_plan.plan;
    params.dataRows[i].destination = params.dataRows[i].m40_destination.destination;
    params.dataRows[i].edit_link = "[edit]";
    rows.push(params.dataRows[i]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnsShow.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth:column.minWidth ,
                    maxWidth:column.maxWidth 
                  }}
                  className={classes.header_cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              const planId        = <input type="hidden" value={row['plan_id']}        id="plan_id"        />;
              const destinationId = <input type="hidden" value={row['destination_id']} id="destination_id" />;
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={(row.rowId)} >
                  {columnsShow.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} onClick={params.rowClickEvent} className={classes.cell}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                        {column.id==='edit_link'    ? planId        : ''}
                        {column.id==='edit_link'    ? destinationId : ''}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}