import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const columns = [
  { id: 'apply_number', label: 'Apply\u00a0Number', minWidth: 70 ,class :'applyNumber'},
  { id: 'customer_name', label: 'Customer\u00a0Name', minWidth: 150 },
  {
    id: 'apply_date',
    label: 'Apply\u00a0Date',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'payment_method',
    label: 'Payment\u00a0Method',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'delivery_date',
    label: 'Delivery\u00a0Date',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'contract_date',
    label: 'Contract\u00a0Date',
    minWidth: 200,
    align: 'center'
  },
  {
    id: 'plan',
    label: 'Plan',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'destination',
    label: 'Destination',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'quantity',
    label: 'Quantity',
    minWidth: 70,
    align: 'center'
  },
  {
    id: 'action',
    label: 'action',
    minWidth: 50,
    align: 'center'
  },
];


let rows = [
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 450,
  },
  applyNumber : {
    "text-decoration" : "underline",
    "line-height"     : "0.2rem",
    "cursor"          : "hand",
    "cursor"          : "pointer"
  },
  header_cell:{
    "background-color":"#afeeee",
    "line-height":"0.2rem"
  },
  cell:{
    "line-height":"0.2rem"
  },
});


export default function DeviceAssignmentList(pPrams) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const params = pPrams;
  rows = params.dataRows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.header_cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              const contractTypeField = <input type="hidden" value={row['contract_type']}     id="contract_type"     />;
              const bookingId         = <input type="hidden" value={row['booking_id']}        id="booking_id"        />;
              const bookingDetailId   = <input type="hidden" value={row['booking_detail_id']} id="booking_detail_id" />;
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.apply_number}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} onClick={params.onCellClickEvent} className={ (column.id==='apply_number' || column.id==='action') ? classes.applyNumber : classes.cell } >
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                        {column.id==='apply_number' ? contractTypeField : ''}
                        {column.id==='action' ? bookingId: ''}
                        {column.id==='action' ? bookingDetailId: ''}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}