import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class AuthRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      status: 0,
      auth: null,
      url: null,
      redirectUrl: null,
      forceRedirect: false,
    };
  }

  async componentDidMount() {
    let reqParam = {};
    reqParam.url = this.props.location.pathname;
    fetch("/api/checkMenuAuth", {
      method: "POST",
      body: JSON.stringify(reqParam),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          auth: responseJson.auth,
          url: responseJson.url,
          redirectUrl: responseJson.redirectUrl,
        });
      });
    setTimeout(() => {
      this.setState({ forceRedirect: true });
    }, 5000);
  }

  render() {
    if (this.state.auth) {
      if (this.state.redirectUrl && this.props.path != this.state.redirectUrl) {
        const link = this.state.redirectUrl;
        this.state.redirectUrl = null;
        return <Redirect to={link} />;
      } else {
        return <Route {...this.props} />;
      }
    } else {
      if (!!this.state.forceRedirect) {
        return <Redirect to="/login" />;
      } else {
        return <div>Loading...</div>;
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userName: state.login.userName,
    userEmail: state.login.userEmail,
    isCorporate: state.login.isCorporate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRouter);
