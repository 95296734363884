import React, { Component } from 'react';
import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';

import CommonButton from '../../../../common/mirai_component/CommonButton'
import PickupMethodListTable from './PickupMethodListTable';
import PickupMethodEditDialog from './PickupMethodEditDialog';

import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';

import {connect} from 'react-redux';


class PickupMethod extends Component {

  constructor(props) {

    super(props);

    this.state = {
      searchParamPickupMethodName : '',
      pickupMethodList            : [],
      locationList                : [],
      showEditDialog              : false,
      editPickupMethodId          : null,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleEditRowClick = this.handleEditRowClick.bind(this);
    this.handleEditDialogClose = this.handleEditDialogClose.bind(this);
    this.handleClickAddNew = this.handleClickAddNew.bind(this);

  }


  componentDidMount(prevProps){

    let requestParam = {};

    // Location全件取得
    fetch('/api/getAllLocation', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      let locationList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let locationRow = {};
        locationRow.id = responseJson[i].location_id;
        locationRow.label = responseJson[i].location;
        locationList.push(locationRow);
      }
      this.setState({
        locationList:locationList
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  handleClickSearch(){

    let requestParam = {};
    requestParam.pickupMethodName = this.state.searchParamPickupMethodName;

    // PickUpMethod検索
    fetch('/api/searchPickupMethod', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({
        pickupMethodList:responseJson
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  handleClickAddNew(event){

    this.setState({
      showEditDialog:true,
      editPickupMethodId:0,
    });

  }


  handleEditRowClick(event){

    if ( event.target.cellIndex === 3 ) {
      let editPickupMethodId = event.target.children[0].value;
      this.setState({
        showEditDialog:true,
        editPickupMethodId:editPickupMethodId,
      });
    }

  }


  handleEditDialogClose(){

    this.setState({
      showEditDialog:false,
      editPickupMethodId:null
    });

    this.handleClickSearch();

  }


  render() {

    return (
      <div align="center">
        <Menu history={this.props.history} />
        <ScreenTitle title={this.props.selectedMenuName} />
        <MiraiTextField handleChange={this.handleChange}    readOnly={false}   id="searchParamPickupMethodName"       name="searchParamPickupMethodName"     label="Pickup Method"     value={this.state.searchParamPickupMethodName}   errorText={''} />
        <br />
        <CommonButton visible={true}   clickEvent={this.handleClickSearch}   label="Search"   />
        <CommonButton visible={true}   clickEvent={this.handleClickAddNew}   label="Add New"  />

        <PickupMethodListTable dataRows={this.state.pickupMethodList} rowClickEvent={this.handleEditRowClick} />
        <PickupMethodEditDialog locationList={this.state.locationList} showEditDialog={this.state.showEditDialog} handleEditDialogCloseEvent={this.handleEditDialogClose} editPickupMethodId={this.state.editPickupMethodId} />
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickupMethod);