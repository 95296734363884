import React, { Component } from 'react';
import checkSessionStatus from '../../../common/CheckSession'
import RevenueList from './RevenueList';
import InformDialog from '../../../common/mirai_component/InformDialog'
import CommonSelect from '../../../common/CommonSelect'


import MenuGW from '../../../common/MenuGW';
import ScreenTitle from '../../../common/ScreenTitle';

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import CommonCheckbox from '../../../common/CommonCheckbox';

import CommonButton from '../../../common/mirai_component/CommonButton'
import UpdateLogDialog from '../../../common/UpdateLogDialog'

import {connect} from 'react-redux';
import * as constVal from '../../../common/constant';
import * as commonFunction from '../../../common/commonFunction';

import const_val from '../../../const';

class SearchRevenue extends Component {

  constructor(props) {

    super(props);
    this.state = {
      revenue_year_month    : '',
      dataRows              : [],
      showUpdateLogDialog   : false,
      selectedRevenueId     : 0,
      user_id               : null,
      informDialogOpen      : false,
      informDialogTitle     : '',
      informDialogMessage   : '',
      userList              : [],
      revenueTotal          : '0',
      vatTotal              : '0',
      groudTotalTotal       : '0',
      groundTotalWithVat    : '0'
  };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.closeUpdateLogDialogEvent = this.closeUpdateLogDialogEvent.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }



  componentDidMount(prevProps) {
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  closeUpdateLogDialogEvent(){
    this.setState({showUpdateLogDialog:false});
  }



  // 一覧のCellClick時
  onCellClick(event){
  
    let rowIndex = event.target.parentElement.rowIndex;
    let cellIndex = event.target.cellIndex;
    let keyVal = event.target.parentElement.children[0].textContent;

    if ( cellIndex == 3 ) {
      var revenue_id = event.target.parentElement.querySelector("#revenue_id").value;
      this.props.history.push('/entryRevenue/' + revenue_id);
    }
  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

  }



  // 検索ボタン押下時の処理
  handleClickSearch(){
    
    // Revenue検索API
    const search_condition = {
      revenue_year_month    : this.state.revenue_year_month,
    };
    fetch('/api/summaryRevenue', {
      method: "POST",
      body: JSON.stringify(search_condition), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      console.log(responseJson);

      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      let revenueTotal = 0;
      let vatTotal = 0;
      let groudTotalTotal = 0;
      let groundTotalWithVat = 0;


      let resultDate = [];
      for ( let i=0;i<responseJson.length;i++ ) {

        let resultRow = {};

        resultRow['corporate_name'] = !responseJson[i]['m20_corporate'] ? '' : responseJson[i]['m20_corporate']['corporate_name'];
        const revenueDate = new Date(responseJson[i]['date']);
        const revenueDateString = revenueDate.getFullYear() + "-" + ("0" + (revenueDate.getMonth()+1)).slice(-2) + "-" + ("0" + (revenueDate.getDate())).slice(-2)
        resultRow['year'] = revenueDate.getFullYear();
        resultRow['month'] = revenueDate.toLocaleString('default', { month: 'short' });;
        resultRow['service_type']       = commonFunction.getKeyByValue(const_val.service_type_list, responseJson[i]['service_type']);
        resultRow['revenue_price']      = responseJson[i]['revenue_price'];
        resultRow['vat_price']          = responseJson[i]['vat_price'];
        resultRow['total_price']        = responseJson[i]['total_price'];
        resultRow['total_price_vat']    = responseJson[i]['total_price_vat_included'];
        resultRow['note']               = responseJson[i]['note'];
        
        resultRow['revenue_id'] = responseJson[i]['revenue_id'];

        revenueTotal += Number(responseJson[i]['revenue_price']);
        vatTotal += Number(responseJson[i]['vat_price']);
        groudTotalTotal += Number(responseJson[i]['total_price']);
        groundTotalWithVat += Number(responseJson[i]['total_price_vat_included']);

        resultDate.push(resultRow);
      }

      this.setState({
        dataRows:resultDate,
        revenueTotal       : revenueTotal,
        vatTotal           : vatTotal,
        groudTotalTotal    : groudTotalTotal,
        groundTotalWithVat : groundTotalWithVat
      });

      if ( responseJson.length === 0 ) {

        this.setState({
          informDialogTitle   : "Search Revenue Result",
          informDialogMessage : "No data found.",
          informDialogOpen    : true,
        });

      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  render() {

    return (
      <div align="center">
        <MenuGW history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Revenue Date</FormLabel></div>
            <TextField onChange={this.handleChange} id="revenue_year_month" name="revenue_year_month" style={{width: "200px"}} type="month" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch} label="Search revenue"/>
        <br />
        <RevenueList dataRows={this.state.dataRows} onCellClickEvent={this.onCellClick}/>
        <UpdateLogDialog showUpdateLogDialog={this.state.showUpdateLogDialog} closeUpdateLogDialogEvent={this.closeUpdateLogDialogEvent} selectedRevenueId={this.state.selectedRevenueId} selectedRevenueId={this.state.selectedRevenueId}/>


        <br />
        <TextField readOnly={true} label="Revenue Total" id="revenueTotal" name="revenueTotal" style={{width: "200px"}} value={this.state.revenueTotal} /> 
        &nbsp;&nbsp;&nbsp;
        <TextField readOnly={true} label="VAT Total" id="vatTotal" name="vatTotal" style={{width: "200px"}} value={this.state.vatTotal} /> 
        &nbsp;&nbsp;&nbsp;
        <TextField readOnly={true} label="Total" id="groudTotalTotal" name="groudTotalTotal" style={{width: "200px"}} value={this.state.groudTotalTotal} /> 
        &nbsp;&nbsp;&nbsp;
        <TextField readOnly={true} label="Total(With VAT)" id="groundTotalWithVat" name="groundTotalWithVat" style={{width: "200px"}} value={this.state.groundTotalWithVat} /> 

        <InformDialog informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchRevenue);