import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function DialogSelect(pPrams) {

  const classes = useStyles();
  const params = pPrams;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-dialog-select-label">{params.caption}</InputLabel>
      <Select
        MenuProps={{
          style: {zIndex: 1500}
        }}
        labelId="demo-dialog-select-label"
        id={params.name}
        name={params.name}
        value={params.selectValue}
        onChange={params.onChangeEvent}
        input={<Input />}
      >
        {params.selectList.map((item, index) =>
          <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}