import React, { Component } from 'react';
import checkSessionStatus from '../../../../common/CheckSession'

import InformDialog from '../../../../common/mirai_component/InformDialog'
import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

import CommonButton from '../../../../common/mirai_component/CommonButton'

import {connect} from 'react-redux';

import * as constVal from '../../../../common/constant';
import * as commonFunction from '../../../../common/commonFunction';

import const_val from '../../../../const';

import ReportDestinationListTable from './ReportDestinationListTable';


class ReportDestination extends Component {

  constructor(props) {

    let today = new Date();
    let startDateString = today.getFullYear() + "-" + ('0' + (today.getMonth()+1)).slice(-2) + '-01';
    today.setMonth(today.getMonth()+1);
    today.setDate(1);
    today.setDate(today.getDate()-1);
    let endDateString = today.getFullYear() + "-" + ('0' + (today.getMonth()+1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);

    super(props);
    this.state = {
      contract_date_from       : startDateString,
      contract_date_to         : endDateString,
      dataRows                 : [],
      showPackingDeviceDialog  : false,
      informDialogOpen         : false,
      informDialogTitle        : '',
      informDialogMessage      : '',
      countryId                : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }



  componentDidMount(prevProps) {
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  // 一覧のCellClick時
  onCellClick(event){

    let cellIndex = event.target.cellIndex;

    if ( cellIndex == 3 ) {

      this.state.countryId = event.target.children[0].value;

      var saveAs = require('file-saver');

      fetch('/api/downloadReportSummaryByDestinationList', {
        method: "POST",
        body: JSON.stringify(this.state), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.blob())
      .then(blob => {

        let now = new Date();
        const y = now.getFullYear();
        const m = ('00' + (now.getMonth()+1)).slice(-2);
        const d = ('00' + now.getDate()).slice(-2);
        const hh = ('00' + now.getHours()).slice(-2);
        const mm = ('00' + now.getMinutes()).slice(-2);
        const ss = ('00' + now.getSeconds()).slice(-2);

        saveAs(blob, 'BookingListByDestinationCountries' + y+m+d+hh+mm+ss + '.csv');

      });

    }

  }



  // 検索ボタン押下時の処理
  handleClickSearch(noMessage){

    const search_condition = {
      contract_date_from : this.state.contract_date_from,
      contract_date_to   : this.state.contract_date_to,
    };
    fetch('/api/getBookingSummaryByDestination', {
      method: "POST",
      body: JSON.stringify(search_condition), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      this.setState({
        dataRows:responseJson
      });

      if ( typeof noMessage !== 'boolean' && responseJson.length === 0 ) {
        this.setState({
          informDialogTitle   : "Report Booking Summary",
          informDialogMessage : "No data found.",
          informDialogOpen    : true,
        });
      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Contract Date</FormLabel></div>
            <TextField onChange={this.handleChange} id="contract_date_from" name="contract_date_from" value={this.state.contract_date_from}  style={{width: "200px"}} type="date" />　~　
            <TextField onChange={this.handleChange} id="contract_date_to"   name="contract_date_to"   value={this.state.contract_date_to}    style={{width: "200px"}} type="date" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch} label="Search booking"/>
        <br />
        <ReportDestinationListTable dataRows={this.state.dataRows} downloadListClick={this.onCellClick}  />
        <InformDialog informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );

  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportDestination);