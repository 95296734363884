import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import CommonButton from '../../../../common/mirai_component/CommonButton'
import FormLabel from '@material-ui/core/FormLabel';
import FlatButton from 'material-ui/FlatButton';
import CommonRadio from '../../../../common/CommonRadio';
import TextField from '@material-ui/core/TextField';
import const_val from '../../../../const';


class RefundDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData            : false,
      refundId              : 0,
      bookingId             : 0,
      applyNumber           : 0,
      customerName          : '',
      customerTel           : '',
      rentalFee             : 0,
      refundAmount          : 0,
      refundReason          : '',
      note                  : '',
      updatedAt             : '',
      informDialogTitle     : '',
      informDialogMessage   : '',
      refundStatus          : 0,
      refundStatusList      : const_val.refund_status,
      errorMsg :{
        deliveryFee : '',
        deliveryBy  : '',
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickRefund = this.handleClickRefund.bind(this);

  }



  componentWillUpdate(nextProps,nextState){
  
    if ( !this.state.loadedData && nextProps.selectedRefundId !== -1 && nextProps.showRefundDialog === true ) {

      const search_condition = {
        refund_id : nextProps.selectedRefundId
      };
      fetch('/api/searchRefund', {
        method: "POST",
        body: JSON.stringify(search_condition), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        let customerName = '';
        let customerTel = '';

        // 法人
        if ( responseJson[0]["t10_booking"]["contract_type"] === 0 ) {
          customerName = responseJson[0].t10_booking.m20_corporate.corporate_name;
          customerTel = responseJson[0].t10_booking.m20_corporate.tel_number;
        }
        // 個人
        else {
          customerName = responseJson[0].t10_booking.customer_name;
          customerTel = responseJson[0].t10_booking.customer_tel;
        }

        this.setState({
          refundId              : nextProps.selectedRefundId,
          bookingId             : responseJson[0].booking_id,
          applyNumber           : responseJson[0].t10_booking.apply_number,
          customerName          : customerName,
          customerTel           : customerTel,
          refundAmount          : responseJson[0].refund_amount,
          refundReason          : responseJson[0].refund_reason,
          note                  : responseJson[0].note,
          refundStatus          : responseJson[0].refund_status,
          updatedAt             : responseJson[0].t10_booking.updatedAt,
          rentalFee             : responseJson[0].t10_booking.rental_fee,
          refund_status         : responseJson[0].refund_status,
          informDialogTitle     : '',
          informDialogMessage   : '',
        });

      });

      this.state.loadedData = true;

    }

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  handleClose() {

    this.props.closeRefundDialogEvent(this.state.informDialogTitle,this.state.informDialogMessage);
    this.setState({
      loadedData: false
    });

  }



  handleClickRefund(event){

    const reqParam = {}
    reqParam.refundId      = this.state.refundId;
    reqParam.bookingId     = this.state.bookingId;
    reqParam.updatedAt     = this.state.updatedAt;
    reqParam.refundStatus  = this.state.refundStatus;
    reqParam.note          = this.state.note;
    reqParam.functionName  = 'refund';

    fetch('/api/updateRefundStatus', {
      method: "POST",
      body: JSON.stringify(reqParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if ( responseJson.error === undefined ) {

        this.setState({
          informDialogTitle:'Update OK',
          informDialogMessage:'Succesfully update booking.',
        });

      }
      else {

        this.setState({
          informDialogTitle:'Update Error',
          informDialogMessage:'Update booking failed!!' + responseJson.error,
        });

      }

      this.handleClose();

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  render() {

    const actions = [
      <FlatButton
        label="Close"
        primary={true}
        onClick={this.handleClose}
      />,
    ];

    return (
      <MuiThemeProvider>
          <Dialog
            title="Refund Status Update"
            modal={false}
            actions={actions}
            open={this.props.showRefundDialog}
            onRequestClose={this.handleClose}
          >
          <FormLabel component="legend">Apply Number : {this.state.applyNumber}</FormLabel>
          <br />
          <FormLabel component="legend">Customer Name : {this.state.customerName}</FormLabel>
          <br />
          <FormLabel component="legend">Customer Tel : {this.state.customerTel}</FormLabel>
          <br />
          <FormLabel component="legend">Rental Fee : {this.state.rentalFee}</FormLabel>
          <br />
          <FormLabel component="legend">Refund Amount : {this.state.refundAmount}</FormLabel>
          <br />
          <TextField
              id="refundReason"
              name="refundReason"
              label="Refund Reason"
              value={this.state.refundReason}
              margin="normal"
              onChange={this.handleChange}
              multiline
              rows={5}
              rowsMax={10}
              style={{
                width: "650px"
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          <br />
          <TextField
              id="note"
              name="note"
              label="Note"
              value={this.state.note}
              margin="normal"
              onChange={this.handleChange}
              multiline
              rows={5}
              rowsMax={10}
              style={{
                width: "650px"
              }}
            />
          <br/>
          <CommonRadio readOnly={false}  radioList={this.state.refundStatusList}   onChangeEvent={this.handleChange}  name={'refundStatus'}  caption={'Refund Status'}  radioValue={this.state.refundStatus} />
          <br />
          <CommonButton visible={true}   clickEvent={this.handleClickRefund}  label="Refund Commit" />
          </Dialog>
      </MuiThemeProvider>
    );

  }

}


export default RefundDialog;