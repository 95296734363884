import React, { Component } from 'react';
import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';

import CommonButton from '../../../../common/mirai_component/CommonButton'
import CorporateListTable from './CorporateListTable';
import CorporateEditDialog from './CorporateEditDialog';
import CorporateDeviceEditDialog from './CorporateDeviceEditDialog';
import CorporateDeviceForEditDialog from './CorporateDeviceForEditDialog';

import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import InformDialog from '../../../../common/mirai_component/InformDialog'

import {connect} from 'react-redux';


class Corporate extends Component {

  constructor(props) {

    super(props);

    this.state = {
      searchParamCorporateName   : '',
      searchParamCorporateTel    : '',
      corporateDataList          :[],
      showEditDialog             : false,
      showDeviceEditDialog       : false,
      showDeviceForEditDialog    : false,
      editCorporateId            : null,
      informDialogOpen           : false,
      informDialogTitle          : '',
      informDialogMessage        : '',
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleEditRowClick = this.handleEditRowClick.bind(this);
    this.handleEditDialogClose = this.handleEditDialogClose.bind(this);
    this.handleClickAddNew = this.handleClickAddNew.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

  }



  // 検索
  handleClickSearch(){

    let requestParam = {};
    requestParam.corporateName = this.state.searchParamCorporateName;
    requestParam.telNumber = this.state.searchParamCorporateTel;

    // 会社検索
    fetch('/api/searchCorporate', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      for ( let i=0;i<responseJson.length;i++ ) {
        if ( responseJson[i]["m10_user"] !== null ) {
          responseJson[i].annam_person_in_charge_name = responseJson[i]['m10_user']['name'];
        }
      }

      this.setState({
        corporateDataList:responseJson
      });

      if ( responseJson.length === 0 ) {

        this.setState({
          informDialogTitle   : "Search Corporate Result",
          informDialogMessage : "No data found.",
          informDialogOpen    : true,
        });

      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  handleClickAddNew(event){

    this.setState({
      showEditDialog:true,
      editCorporateId:0,
    });

  }



  handleEditRowClick(event){

    if ( event.target.cellIndex === 9 ) {
      let corporateId = event.target.children[0].value;
      this.setState({
        showDeviceForEditDialog : true,
        editCorporateId         : corporateId
      });
    }
    else if ( event.target.cellIndex === 11 ) {
      let corporateId = event.target.children[0].value;
      this.setState({
        showDeviceEditDialog : true,
        editCorporateId      : corporateId
      });
    }
    else if ( event.target.cellIndex === 12 ) {
      let corporateId = event.target.children[0].value;
      this.setState({
        showEditDialog  : true,
        editCorporateId : corporateId
      });
    }

  }



  handleEditDialogClose(informDialogTitle,informDialogMessage){

    let informDialogOpen = true;
    if ( informDialogTitle == '' ) {
      informDialogTitle = '';
      informDialogMessage = '';
      informDialogOpen = false;
    }

    this.setState({
      showEditDialog          : false,
      showDeviceEditDialog    : false,
      showDeviceForEditDialog : false,
      editCorporateId         : null,
      informDialogTitle       : informDialogTitle,
      informDialogMessage     : informDialogMessage,
      informDialogOpen        : informDialogOpen,
    });

    this.handleClickSearch();

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <MiraiTextField handleChange={this.handleChange}    readOnly={false}   id="searchParamCorporateName"     name="searchParamCorporateName"     label="Corporate Name"     value={this.state.searchParamCorporateName}   errorText={''}/>
        <br />
        <MiraiTextField handleChange={this.handleChange}    readOnly={false}   id="searchParamCorporateTel"      name="searchParamCorporateTel"      label="Tel Number"         value={this.state.searchParamCorporateTel}    errorText={''}/>
        <br />
        <CommonButton visible={true}   clickEvent={this.handleClickSearch}   label="Search"   />
        <CommonButton visible={true}   clickEvent={this.handleClickAddNew}   label="Add New"  />
        <CorporateListTable dataRows={this.state.corporateDataList} editRowClick={this.handleEditRowClick} />
        <CorporateEditDialog showEditDialog={this.state.showEditDialog} handleEditDialogCloseEvent={this.handleEditDialogClose} editCorporateId={this.state.editCorporateId} />
        <CorporateDeviceEditDialog    showDeviceEditDialog={this.state.showDeviceEditDialog}       handleEditDialogCloseEvent={this.handleEditDialogClose}    editCorporateId={this.state.editCorporateId} />
        <CorporateDeviceForEditDialog showDeviceForEditDialog={this.state.showDeviceForEditDialog} handleEditDialogForCloseEvent={this.handleEditDialogClose} editCorporateId={this.state.editCorporateId} />
        <InformDialog informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Corporate);