import React, { Component } from "react";
import checkSessionStatus from "../../../common/CheckSession";
import BookingList from "../../../common/BookingList";
import InformDialog from "../../../common/mirai_component/InformDialog";
import CommonSelect from "../../../common/CommonSelect";

import Menu from "../../../common/Menu";
import ScreenTitle from "../../../common/ScreenTitle";

import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import CommonCheckbox from "../../../common/CommonCheckbox";

import CommonButton from "../../../common/mirai_component/CommonButton";
import UpdateLogDialog from "../../../common/UpdateLogDialog";

import { connect } from "react-redux";
import * as constVal from "../../../common/constant";
import * as commonFunction from "../../../common/commonFunction";

import const_val from "../../../const";

class SearchBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booking_status: [],
      pickup_method: [],
      return_method: [],
      contract_type: [],
      payment_method: [],
      destination: [],
      customer_name: "",
      corporate_name: "",
      contract_date_from: "",
      contract_date_to: "",
      apply_date_from: "",
      apply_date_to: "",
      apply_number: "",
      voucher: "",
      dataRows: [],
      dataColumns: [
        {
          id: "apply_number",
          label: "Apply\u00a0Number",
          minWidth: 70,
          class: "applyNumber",
        },
        { id: "voucher", label: "Voucher", minWidth: 100, align: "right" },
        { id: "plan", label: "Plan", minWidth: 200, align: "right" },
        {
          id: "contract_date",
          label: "Contract\u00a0Date",
          minWidth: 200,
          align: "center",
        },
        {
          id: "booking_status",
          label: "Booking\u00a0Status",
          minWidth: 200,
          align: "center",
        },
        {
          id: "pickup_method",
          label: "Pickup\u00a0Method",
          minWidth: 200,
          align: "center",
        },
        {
          id: "quantity",
          label: "Quantity",
          minWidth: 100,
          align: "center",
        },
        {
          id: "customer_name",
          label: "Customer\u00a0Name",
          minWidth: 250,
          align: "left",
        },
        {
          id: "arrival_time",
          label: "Arrival Time",
          minWidth: 100,
          align: "center",
        },
        {
          id: "total_fee",
          label: "Total\u00a0Fee",
          minWidth: 100,
          align: "right",
        },
        {
          id: "person_in_charge",
          label: "Person\u00a0in\u00a0Charge",
          minWidth: 150,
          align: "left",
        },
      ],
      destination_list: [],
      is_corporate: 0,
      skip_pickup_method: [],
      showUpdateLogDialog: false,
      selectedApplyNumber: 0,
      selectedBookingId: 0,
      pickupMethodList: [],
      returnMethodList: [],
      person_in_charge: null,
      informDialogOpen: false,
      informDialogTitle: "",
      informDialogMessage: "",
      userList: [],
      bookingStatusCheckBoxValue: [],
      allowDownload: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleClickExportCSV = this.handleClickExportCSV.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.closeUpdateLogDialogEvent = this.closeUpdateLogDialogEvent.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);
  }

  componentDidMount(prevProps) {
    // ログイン認証チェック
    // ログイン済みであればメニューを表示
    checkSessionStatus(this.props, "/agency/searchBooking");
    fetch("/api/checkStatus", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.is_corporate) {
          this.setState({
            is_corporate: 1,
            skip_pickup_method: [0, 1],
          });
        }
      });

    fetch("/api/getAllDestination", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let destinationList = [];
        for (let i = 0; i < responseJson.length; i++) {
          let destinationRow = {};
          destinationRow.id = responseJson[i].destination_id;
          destinationRow.label = responseJson[i].destination;
          destinationList.push(destinationRow);
        }
        this.setState({
          destination_list: destinationList,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    fetch("/api/getAllPickupMethod", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let pickupMethodList = [];
        let returnMethodList = [];

        for (let i = 0; i < responseJson.length; i++) {
          let pickupMethodRow = {};
          pickupMethodRow.id = responseJson[i].pickup_method_id;
          pickupMethodRow.label = responseJson[i].pickup_method;
          pickupMethodList.push(pickupMethodRow);
          returnMethodList.push(pickupMethodRow);
        }
        this.setState({
          pickupMethodList: pickupMethodList,
          returnMethodList: returnMethodList,
        });
      });

    fetch("/api/getUserList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let userList = [];
        let userListRowBlank = {};
        userListRowBlank.id = null;
        userListRowBlank.label = "---";
        userList.push(userListRowBlank);

        for (let i = 0; i < responseJson.length; i++) {
          let userListRow = {};
          userListRow.id = responseJson[i].id;
          userListRow.label = responseJson[i].name;
          userList.push(userListRow);
        }
        this.setState({
          userList: userList,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // 画面入力項目をthis.stateにバインドする
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    this.setState({ [name]: event.target.value });
  }

  handleChangeBookingStatus(event) {
    const target = event.target;
    const name = target.name;
    this.setState({ [name]: event.target.checked });
  }

  closeUpdateLogDialogEvent() {
    this.setState({ showUpdateLogDialog: false });
  }

  // 一覧のCellClick時
  onCellClick(event) {
    let rowIndex = event.target.parentElement.rowIndex;
    let cellIndex = event.target.cellIndex;
    let keyVal = event.target.parentElement.children[0].textContent;

    // ApplyNumber押下時 -> BookingPageへ遷移
    if (event.target.classList.contains("apply_number")) {
      this.props.history.push("/agency/createBooking/" + keyVal);
    }
    // Open Log dialog.
    else if (event.target.classList.contains("showLog")) {
      var booking_id =
        event.target.parentElement.querySelector("#booking_id").value;
      this.setState({
        showUpdateLogDialog: true,
        selectedBookingId: booking_id,
        selectedApplyNumber: keyVal,
      });
    }
  }

  handleCheckboxChange(event) {
    const name = event.name;
    const checked_list = [];
    for (const each of event.list) {
      if (each.checked) {
        checked_list.push(each.id);
      }
    }
    this.setState({ [name]: checked_list });
  }

  // InformDialog閉じるボタン
  informDialogCloseEvent() {
    this.setState({
      informDialogOpen: false,
    });
  }

  handleClickExportCSV() {
    this.setState({ allowDownload: true }, () => {
      this.handleClickSearch();
    });
  }

  // 検索ボタン押下時の処理
  handleClickSearch() {
    const allowDownload = this.state.allowDownload;
    // Booking検索API
    const search_condition = {
      contract_date_from: this.state.contract_date_from,
      contract_date_to: this.state.contract_date_to,
      apply_date_from: this.state.apply_date_from,
      apply_date_to: this.state.apply_date_to,
      voucher: this.state.voucher,
      contract_type: constVal.contract_type.Agency,
      allowDownload: allowDownload,
    };
    this.setState({ allowDownload: false });
    const saveAs = require("file-saver");
    fetch("/api/searchBooking", {
      method: "POST",
      body: JSON.stringify(search_condition),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!!allowDownload) {
          return response.blob();
        } else {
          return response.json();
        }
      })
      .then((responseJson) => {
        if (!!allowDownload) {
          const blob = responseJson;
          let now = new Date();
          const y = now.getFullYear();
          const m = ("00" + (now.getMonth() + 1)).slice(-2);
          const d = ("00" + now.getDate()).slice(-2);
          const hh = ("00" + now.getHours()).slice(-2);
          const mm = ("00" + now.getMinutes()).slice(-2);
          const ss = ("00" + now.getSeconds()).slice(-2);

          saveAs(
            blob,
            "AgencyBookingList-" + y + m + d + hh + mm + ss + ".csv"
          );
          return;
        }
        if (!!responseJson.error) {
          this.setState({
            informDialogTitle: "Search Booking Result",
            informDialogMessage: responseJson.error,
            informDialogOpen: true,
          });
          return;
        }
        let resultDate = [];
        for (let i = 0; i < responseJson.length; i++) {
          const row = responseJson[i];
          let resultRow = {};
          resultRow["booking_id"] = row?.booking_id;
          resultRow["apply_number"] = row?.apply_number;
          resultRow["person_in_charge"] = row?.m10_user?.name;
          resultRow["voucher"] = row?.voucher;

          if (row?.contract_type == constVal?.contract_type?.Corporate) {
            resultRow["customer_name"] = row?.m20_corporate?.corporate_name;
          } else {
            resultRow["customer_name"] = row?.customer_name;
          }

          resultRow["apply_date"] = new Date(row?.apply_date).toLocaleString();
          resultRow["total_fee"] = parseFloat(row?.total_fee).toFixed(2);

          resultRow["pickup_method"] = row?.returnMethod?.pickup_method;
          resultRow["booking_status"] = commonFunction.getBookingStatusNameById(
            row?.booking_status
          );
          let min_date = new Date(row?.t20_booking_details[0]?.start_date);
          let max_date = new Date(row?.t20_booking_details[0]?.end_date);
          let quantity = 0;
          let planList = [];

          for (let j = 0; j < row?.t20_booking_details.length; j++) {
            if (min_date > new Date(row?.t20_booking_details[j]?.start_date)) {
              min_date = new Date(row?.t20_booking_details[j]?.start_date);
            }
            if (max_date < new Date(row?.t20_booking_details[j]?.end_date)) {
              max_date = new Date(row?.t20_booking_details[j]?.end_date);
            }

            quantity += row?.t20_booking_details[j]?.quantity;
            planList.push(row?.t20_booking_details[j]?.m30_plan?.plan);
          }
          resultRow["quantity"] = quantity;
          resultRow["plan"] = planList;
          resultRow["contract_date"] =
            commonFunction.formatYYYYMMDD(min_date) +
            " - " +
            commonFunction.formatYYYYMMDD(max_date);
          resultRow["contract_type"] = row?.contract_type;
          resultRow["showLog"] = "[show]";
          resultDate.push(resultRow);
        }

        this.setState({
          dataRows: resultDate,
        });

        if (responseJson.length === 0) {
          this.setState({
            informDialogTitle: "Search Booking Result",
            informDialogMessage: "No data found.",
            informDialogOpen: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <div align="center">
        <Menu history={this.props.history} />
        <ScreenTitle title="Agency Search Booking" />
        <div style={{ display: "flex" }}>
          <div style={{ width: "300px" }}>
            <FormLabel>Contract Date</FormLabel>
          </div>
          <TextField
            onChange={this.handleChange}
            id="contract_date_from"
            name="contract_date_from"
            style={{ width: "200px" }}
            type="date"
          />{" "}
          ~
          <TextField
            onChange={this.handleChange}
            id="contract_date_to"
            name="contract_date_to"
            style={{ width: "200px" }}
            type="date"
          />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "300px" }}>
            <FormLabel>Apply Date</FormLabel>
          </div>
          <TextField
            onChange={this.handleChange}
            id="apply_date_from"
            name="apply_date_from"
            style={{ width: "200px" }}
            type="date"
          />{" "}
          ~
          <TextField
            onChange={this.handleChange}
            id="apply_date_to"
            name="apply_date_to"
            style={{ width: "200px" }}
            type="date"
          />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "300px" }}>
            <FormLabel>Voucher</FormLabel>
          </div>
          <TextField
            onChange={this.handleChange}
            id="voucher"
            name="voucher"
            style={{ width: "200px" }}
            type="search"
          />
        </div>
        <br />
        <CommonButton
          clickEvent={this.handleClickSearch}
          label="Search booking"
        />
        <CommonButton
          clickEvent={this.handleClickExportCSV}
          label="Export CSV"
        />
        <br />
        <BookingList
          dataRows={this.state.dataRows}
          onCellClickEvent={this.onCellClick}
          dataColumns={this.state.dataColumns}
        />
        <UpdateLogDialog
          showUpdateLogDialog={this.state.showUpdateLogDialog}
          closeUpdateLogDialogEvent={this.closeUpdateLogDialogEvent}
          selectedApplyNumber={this.state.selectedApplyNumber}
          selectedBookingId={this.state.selectedBookingId}
        />
        <InformDialog
          informDialogStatus={this.state.informDialogOpen}
          informDialogTitle={this.state.informDialogTitle}
          informDialogMessage={this.state.informDialogMessage}
          informDialogCloseEvent={this.informDialogCloseEvent}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedMenuName: state.menu.selectedMenuName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBooking);
