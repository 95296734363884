import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ChangePasswordDialog from '../ChangePasswordDialog';
import InformDialog from '../mirai_component/InformDialog'
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';
import PhonelinkRingSharpIcon from '@material-ui/icons/PhonelinkRingSharp';
import FaceSharpIcon from '@material-ui/icons/FaceSharp';
import MoneySharpIcon from '@material-ui/icons/MoneySharp';
import MergeTypeSharpIcon from '@material-ui/icons/MergeTypeSharp';
import LocationCitySharpIcon from '@material-ui/icons/LocationCitySharp';
import PanToolSharpIcon from '@material-ui/icons/PanToolSharp';
import LockOpenSharpIcon from '@material-ui/icons/LockOpenSharp';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';


const useStyles = makeStyles(theme => ({
  root: {
    width: '200px',
    maxWidth: 360,
    backgroundColor: '#a9a9a9'
  },
}));




export default function AnnamMenuPayment(pParams) {

  let options = [
    'Corporate',
    'Device',
    'User',
    'Unit Price',
    'Device Model',
    'Location',
    'Pickup Method',
    'Campaign',
    'Change Password'
  ];
  
  
  let menuLinks = [
    '/masterMaintCorporate',
    '/masterMaintDevive',
    '/masterMaintUser',
    '/masterMaintUnitPrice',
    '/masterMaintModel',
    '/masterMaintLocation',
    '/masterMaintPickupMethod',
    '/masterCampaign',
    '/masterChangePassword'
  ]


  if ( !pParams.admin ) {

    options = [
      'Corporate',
      'Device',
      'Change Password'
    ];
    
    
    menuLinks = [
      '/masterMaintCorporate',
      '/masterMaintDevive',
      '/masterChangePassword'
    ]

  }


  const classes = useStyles();
  const pSelectedIndex = pParams.selectedMenuIndex;

  const [anchorEl, setAnchorEl] = React.useState(null);
  let [selectedIndex, setSelectedIndex] = React.useState(pSelectedIndex);

  selectedIndex = pSelectedIndex;

  const [showChangePasswordDialog, setChangePasswordDialog] = React.useState(false);
  const closeChangePasswordDialogEvent = (informDialogTitle, informDialogMessage, hideChangePasswordDialog) => {
    if(hideChangePasswordDialog) {
      setChangePasswordDialog(false);
    } else {
      setInformDialog(true);
      setInformDialogTitle(informDialogTitle);
      setInformDialogMessage(informDialogMessage);
    }
  };

  const [informDialogOpen, setInformDialog] = React.useState(false);
  const [informDialogTitle, setInformDialogTitle] = React.useState('');
  const [informDialogMessage, setInformDialogMessage] = React.useState('');
  
  const informDialogCloseEvent = event => {
    setInformDialog(false);
  };
  
  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index,functionName) => {
    
    setSelectedIndex(index);
    setAnchorEl(null);
    if(functionName == 'Change Password') {
      setChangePasswordDialog(true);
    } else {
      pParams.onClickEvent(index,functionName);
      pParams.screenTransitEvent.push(menuLinks[index]);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if ( !pParams.admin ) {

    return (
      <div className={classes.root}>
        <List component="nav" aria-label="Booking">
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="Booking"
            onClick={handleClickListItem}
          >
            <ListItemText primary="Master Data" secondary={options[selectedIndex]} />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === selectedIndex}
              onClick={event => handleMenuItemClick(event, index, options[index])}
            >
              <ListItemIcon>
                {   index === 0 ? <BusinessSharpIcon fontSize="small" /> : 
                  ( index === 1 ? <PhonelinkRingSharpIcon fontSize="small" /> : <LockOpenSharpIcon fontSize="small" /> ) 
                }
              </ListItemIcon>
              {option}
            </MenuItem>
          ))}
        </Menu>
        <ChangePasswordDialog showChangePasswordDialog={showChangePasswordDialog} closeChangePasswordDialogEvent={closeChangePasswordDialogEvent} />
        <InformDialog informDialogStatus={informDialogOpen} informDialogTitle={informDialogTitle} informDialogMessage={informDialogMessage} informDialogCloseEvent={informDialogCloseEvent}/>
      </div>
    );

  }
  else {

    return (
      <div className={classes.root}>
        <List component="nav" aria-label="Booking">
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="Booking"
            onClick={handleClickListItem}
          >
            <ListItemText primary="Master Data" secondary={options[selectedIndex]} />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === selectedIndex}
              onClick={event => handleMenuItemClick(event, index, options[index])}
            >
              <ListItemIcon>
                {   index === 0 ? <BusinessSharpIcon fontSize="small" /> : 
                  ( index === 1 ? <PhonelinkRingSharpIcon fontSize="small" /> : 
                  ( index === 2 ? <FaceSharpIcon fontSize="small" /> : 
                  ( index === 3 ? <MoneySharpIcon fontSize="small" /> :
                  ( index === 4 ? <MergeTypeSharpIcon fontSize="small" /> : 
                  ( index === 5 ? <LocationCitySharpIcon fontSize="small" /> : 
                  ( index === 6 ? <PanToolSharpIcon fontSize="small" /> : 
                  ( index === 7 ? <CardGiftcardIcon fontSize="small" /> : <LockOpenSharpIcon fontSize="small" />  ) ) ) ) ) ) )
                }
              </ListItemIcon>
              {option}
            </MenuItem>
          ))}
        </Menu>
        <ChangePasswordDialog showChangePasswordDialog={showChangePasswordDialog} closeChangePasswordDialogEvent={closeChangePasswordDialogEvent} />
        <InformDialog informDialogStatus={informDialogOpen} informDialogTitle={informDialogTitle} informDialogMessage={informDialogMessage} informDialogCloseEvent={informDialogCloseEvent}/>
      </div>
    );
  }

}