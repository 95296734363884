import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FormLabel from '@material-ui/core/FormLabel';
import * as commonFunction from '../../../common/commonFunction';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';


class ApplyInfoDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData            : false,
      bookingId             : '',
      applyNumber           : '',
      applyDate             : '',
      totalFee              : '',
      paymentStatus         : '',
      paymentDate           : '',
      refundStatus          : '',
    };

    this.handleClose = this.handleClose.bind(this);

  }


  handleClose() {

    this.setState({
      loadedData:false
    });
    this.props.closeApplyInfoDialogEvent();

  }



  componentWillUpdate(nextProps,nextState){

    if ( !this.state.loadedData && nextProps.selectedBookingId !== -1 && nextProps.showApplyInfoDialog === true ) {

      const search_condition = {
        bookingId   : nextProps.selectedBookingId
      };
      fetch('/api/searchBookingApplyInfo', {
        method: "POST",
        body: JSON.stringify(search_condition), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        if ( responseJson.length > 0 ) {

          let refundStatus = '';
          let refundDate = '';
          if ( responseJson[0]['t80_refunds'] !== null && responseJson[0]['t80_refunds'].length > 0 ) {
            refundStatus = commonFunction.getRefundStatusNameById(responseJson[0]['t80_refunds'][responseJson[0]['t80_refunds'].length-1].refund_status);
            if ( responseJson[0]['t80_refunds'][responseJson[0]['t80_refunds'].length-1].refund_date !== undefined ) {
              refundDate = new Date(responseJson[0]['t80_refunds'][responseJson[0]['t80_refunds'].length-1].refund_date).toLocaleDateString();
            }
          }
  
          this.setState({
            bookingId        : nextProps.selectedBookingId,
            applyNumber      : responseJson[0].apply_number,
            applyDate        : new Date(responseJson[0].apply_date).toLocaleDateString(),
            totalFee         : parseInt(responseJson[0].total_fee).toLocaleString(),
            paymentStatus    : commonFunction.getPaymentStatusNameById(responseJson[0].payment_status),
            invoiceNumber    : responseJson[0].invoice_number,
            invoiceIssueDate : responseJson[0].invoice_issue_date !== null ? new Date(responseJson[0].invoice_issue_date).toLocaleDateString() : '' ,
            paymentDate      : responseJson[0].payment_date !== null ? new Date(responseJson[0].payment_date).toLocaleDateString() : '',
            refundStatus     : refundStatus,
            refundDate       : refundDate,
          });

        }

      });

      this.state.loadedData = true;

    }

  }



  render() {

    const actions = [
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showApplyInfoDialog}
          onRequestClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">Booking Infomation</DialogTitle>
          <DialogContent className="MyDialogContent">
            <FormLabel component="legend">Booking Id : {this.state.bookingId}</FormLabel>
            <br />
            <FormLabel component="legend">Apply Number : {this.state.applyNumber}</FormLabel>
            <br />
            <FormLabel component="legend">Apply Date : {this.state.applyDate}</FormLabel>
            <br />
            <FormLabel component="legend">Total Fee : {this.state.totalFee}</FormLabel>
            <br />
            <FormLabel component="legend">Paymen Status : {this.state.paymentStatus}</FormLabel>
            <br />
            <FormLabel component="legend">Paymen Date : {this.state.paymentDate}</FormLabel>
            <br />
            <FormLabel component="legend">Invoice Number : {this.state.invoiceNumber}</FormLabel>
            <br />
            <FormLabel component="legend">Invoice Issue Date : {this.state.invoiceIssueDate}</FormLabel>
            <br />
            <FormLabel component="legend">Refund Status : {this.state.refundStatus}</FormLabel>
            <br />
            <FormLabel component="legend">Refund Date : {this.state.refundDate}</FormLabel>
            <br />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}

export default ApplyInfoDialog;