import React, { Component } from 'react';
import ScreenTitle from '../../common/ScreenTitle';

import Menu from '../../common/Menu';
import BookingReportDaily from './portal/BookingReportDaily';
import ContractStartBooking from './portal/ContractStartBooking';
import ContractEndBooking from './portal/ContractEndBooking';
import {connect} from 'react-redux';


class Top extends Component {

  constructor(props) {

    super(props);
    this.state = {
      email                            : '',
      password                         : '',
      bookingReportDaily               : [],
      contractStartBooking             : [],
      contractEndBooking               : [],
      columnsBookingReportDaily        : [],
      columnsContractStartBooking      : [],
      columnsContractEndBooking        : [],
      pickupMethodColSpan              : 0,
      pickupMethodColSpanStartBooking  : 0,
      pickupMethodColSpanEndBooking    : 0,
    };

    this.handleChange = this.handleChange.bind(this);

  }



  componentDidMount(prevProps) {

    // ログイン認証チェック
    // ログイン済みであればメニューを表示
    // checkSessionStatus(this.props,'/top');

    let requestParam = {};
    // 全PickUpMethod取得
    fetch('/api/getAllPickupMethod', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let columnsBookingReportDaily = [];

      columnsBookingReportDaily.push({ id: 'apply_date'                      , label: 'Date'              ,  minWidth: 120                  });
      columnsBookingReportDaily.push({ id: 'count_of_booking'                , label: 'count of booking'  ,  minWidth: 100 , align: 'right' });
      columnsBookingReportDaily.push({ id: 'amount'                          , label: 'Amount'            ,  minWidth: 100 , align: 'right' });

      // 受取方法毎の集計
      let pickupMethod = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let pickupMethodRow = {}
        pickupMethodRow.id = 'pickupMethod_' + responseJson[i].pickup_method_id;
        pickupMethodRow.pickupMethodId = responseJson[i].pickup_method_id;
        pickupMethodRow.label = responseJson[i].pickup_method;
        pickupMethodRow.minWidth = 200;
        pickupMethodRow.align = 'right;'
        pickupMethodRow.isDelivery = responseJson[i].is_delivery;
        pickupMethod.push(pickupMethodRow);
        columnsBookingReportDaily.push(pickupMethodRow);
      }

      // 支払情報毎の集計
      columnsBookingReportDaily.push({ id: 'paymenMethod_Cash'               , label: 'Cash'              ,  minWidth: 100 , align: 'right' });
      columnsBookingReportDaily.push({ id: 'paymenMethod_Banktransfer'       , label: 'Bank Transfer'     ,  minWidth: 100 , align: 'right' });
      columnsBookingReportDaily.push({ id: 'paymenMethod_CreditCard'         , label: 'Credit Card'       ,  minWidth: 100 , align: 'right' });


      // BookingDailyReportリスト取得
      fetch('/api/getBookingReportDaily', {
        method: "POST",
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state)
      })
      .then((response) => response.json())
      .then((responseJson) => {

        // データを格納する配列
        let bookingReportDaily = [];

        // 直近7日間分のDataRowを作る
        let applyDate = new Date();

        for ( let i=0;i<7;i++ ) {

          let bookingReportDailyRow = {};
          bookingReportDailyRow['apply_date'] = applyDate.getFullYear() + '-' + (('0' + (applyDate.getMonth()+1)).slice(-2)) + '-' + (('0' + applyDate.getDate()).slice(-2));
          bookingReportDailyRow['count_of_booking'] = 0;
          bookingReportDailyRow['amount'] = 0;

          for ( let j=0;j<pickupMethod.length;j++ ) {
            bookingReportDailyRow[pickupMethod[j].id] = 0;
          }
          bookingReportDailyRow['paymenMethod_Cash'] = 0;
          bookingReportDailyRow['paymenMethod_Banktransfer'] = 0;
          bookingReportDailyRow['paymenMethod_CreditCard'] = 0;

          bookingReportDaily.push(bookingReportDailyRow)

          applyDate.setDate(applyDate.getDate() - 1);

        }

        for ( let i=0;i<responseJson.length;i++ ) {

          for ( let j=0;j<bookingReportDaily.length;j++ ) {

            if ( responseJson[i]['apply_date'] === bookingReportDaily[j]['apply_date'] ) {

              bookingReportDaily[j]['count_of_booking'] = bookingReportDaily[j]['count_of_booking'] + parseInt(responseJson[i]['count_of_booking']);
              bookingReportDaily[j]['amount'] = bookingReportDaily[j]['amount'] + parseInt(responseJson[i]['amount']);

              // DeliveryMethod
              for ( let k=0;k<pickupMethod.length;k++ ) {
                if ( pickupMethod[k].pickupMethodId === responseJson[i]['pickup_method_id'] ) {
                  bookingReportDaily[j][pickupMethod[k].id] = bookingReportDaily[j][pickupMethod[k].id] + parseInt(responseJson[i]['count_of_booking']);
                  break;
                }
              }

              // PaymentMethod
              if ( responseJson[i]['payment_method'] === 0 ) {
                bookingReportDaily[j]['paymenMethod_Cash'] = bookingReportDaily[j]['paymenMethod_Cash'] + parseInt(responseJson[i]['count_of_booking']);
              }
              else if ( responseJson[i]['payment_method'] === 1 ) {
                bookingReportDaily[j]['paymenMethod_Banktransfer'] = bookingReportDaily[j]['paymenMethod_Banktransfer'] + parseInt(responseJson[i]['count_of_booking']);
              }
              else if ( responseJson[i]['payment_method'] === 2 ) {
                bookingReportDaily[j]['paymenMethod_CreditCard'] = bookingReportDaily[j]['paymenMethod_CreditCard'] + parseInt(responseJson[i]['count_of_booking']);
              }

              break;

            }

          }

        }

        this.setState({
          bookingReportDaily:bookingReportDaily,
          columnsBookingReportDaily:columnsBookingReportDaily,
          pickupMethodColSpan:pickupMethod.length
        });


      })
      .catch((error) =>{
        console.error(error);
      });


      
      let columnsContractStartBooking = [];
      columnsContractStartBooking.push({ id: 'start_date'                            , label: 'Date'              ,  minWidth: 120 });
      columnsContractStartBooking.push({ id: 'count_of_booking'                      , label: 'count of booking'  ,  minWidth: 100 , align: 'right' });
      columnsContractStartBooking.push({ id: 'amount'                                , label: 'Amount'            ,  minWidth: 100 , align: 'right' });

      let pickupMethodColSpanStartBooking = 0;
      for ( let i=0;i<pickupMethod.length;i++ ) {
        if ( pickupMethod[i].isDelivery === 1 || pickupMethod[i].isDelivery === 2 ) {
          pickupMethodColSpanStartBooking++;
          columnsContractStartBooking.push(pickupMethod[i]);
        }
      }

      columnsContractStartBooking.push({ id: 'pickupMethod_Delivery_booked'          , label: 'Not contacted'     ,  minWidth: 100 , align: 'right' });
      columnsContractStartBooking.push({ id: 'pickupMethod_Delivery_contacted'       , label: 'Contacted'         ,  minWidth: 100 , align: 'right' });
      columnsContractStartBooking.push({ id: 'pickupMethod_Delivery_device_assigned' , label: 'Device Assigned'   ,  minWidth: 100 , align: 'right' });
      columnsContractStartBooking.push({ id: 'pickupMethod_Delivery_ready_for_send'  , label: 'Rady for send'     ,  minWidth: 100 , align: 'right' });
      columnsContractStartBooking.push({ id: 'pickupMethod_Delivery_delivered'       , label: 'Sent'              ,  minWidth: 100 , align: 'right' });



      // ContractSartリスト取得
      // BookingDailyReportリスト取得
      fetch('/api/getContractStartBooking', {
        method: "POST",
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state)
      })
      .then((response) => response.json())
      .then((responseJson) => {

        let contractStartBooking = [];

        // 直近7日間分のDataRowを作る
        let startDate = new Date();

        for ( let i=0;i<7;i++ ) {

          let contractStartBookingRow = {};
          contractStartBookingRow['start_date'] = startDate.getFullYear() + '-' + (('0' + (startDate.getMonth()+1)).slice(-2)) + '-' + (('0' + startDate.getDate()).slice(-2));
          contractStartBookingRow['count_of_booking'] = 0;
          contractStartBookingRow['amount'] = 0;

          for ( let i=0;i<pickupMethod.length;i++ ) {
            if ( pickupMethod[i].isDelivery === 1 || pickupMethod[i].isDelivery === 2 ) {
              contractStartBookingRow[pickupMethod[i].id] = 0;
            }
          }
    
          contractStartBookingRow['pickupMethod_Delivery_booked'] = 0;
          contractStartBookingRow['pickupMethod_Delivery_contacted'] = 0;
          contractStartBookingRow['pickupMethod_Delivery_device_assigned'] = 0;
          contractStartBookingRow['pickupMethod_Delivery_ready_for_send'] = 0;
          contractStartBookingRow['pickupMethod_Delivery_delivered'] = 0;

          contractStartBooking.push(contractStartBookingRow)

          startDate.setDate(startDate.getDate() + 1);

        }

        for ( let i=0;i<responseJson.length;i++ ) {

          let startDateRow = new Date(responseJson[i]['start_date']);
          responseJson[i]['start_date'] = startDateRow.getFullYear() + '-' + (('0' + (startDateRow.getMonth()+1)).slice(-2)) + '-' + (('0' + startDateRow.getDate()).slice(-2));

          for ( let j=0;j<contractStartBooking.length;j++ ) {

            if ( responseJson[i]['start_date'] === contractStartBooking[j]['start_date'] ) {

              contractStartBooking[j]['count_of_booking'] = contractStartBooking[j]['count_of_booking'] + parseInt(responseJson[i]['count_of_booking']);
              contractStartBooking[j]['amount'] = contractStartBooking[j]['amount'] + parseInt(responseJson[i]['amount']);

              // DeliveryMethod
              for ( let k=0;k<pickupMethod.length;k++ ) {

                let thisRowPickupMethodId = responseJson[i]['pickup_method_id'];
                if ( thisRowPickupMethodId === pickupMethod[k].pickupMethodId ) {

                  // Deliveryの場合
                  if ( pickupMethod[k].isDelivery === 0 ) {
                    if ( responseJson[i]['booking_sub_status'] === 100 ) {
                      contractStartBooking[j]['pickupMethod_Delivery_booked'] = contractStartBooking[j]['pickupMethod_Delivery_booked'] + parseInt(responseJson[i]['count_of_booking']);
                    }
                    else if ( responseJson[i]['booking_sub_status'] === 200 ) {
                      contractStartBooking[j]['pickupMethod_Delivery_contacted'] = contractStartBooking[j]['pickupMethod_Delivery_contacted'] + parseInt(responseJson[i]['count_of_booking']);
                    }
                    else if ( responseJson[i]['booking_sub_status'] === 300 ) {
                      contractStartBooking[j]['pickupMethod_Delivery_device_assigned'] = contractStartBooking[j]['pickupMethod_Delivery_device_assigned'] + parseInt(responseJson[i]['count_of_booking']);
                    }
                    else if ( responseJson[i]['booking_sub_status'] === 400 ) {
                      contractStartBooking[j]['pickupMethod_Delivery_ready_for_send'] = contractStartBooking[j]['pickupMethod_Delivery_ready_for_send'] + parseInt(responseJson[i]['count_of_booking']);
                    }
                    else if ( responseJson[i]['booking_sub_status'] === 600 ) {
                      contractStartBooking[j]['pickupMethod_Delivery_delivered'] = contractStartBooking[j]['pickupMethod_Delivery_delivered'] + parseInt(responseJson[i]['count_of_booking']);
                    }
                  }
                  // Pickupの場合 
                  else {
                    contractStartBooking[j][pickupMethod[k].id] = contractStartBooking[j][pickupMethod[k].id] + parseInt(responseJson[i]['count_of_booking']);
                  }

                }

              }

              break;

            }

          }

        }

        this.setState({
          contractStartBooking:contractStartBooking,
          columnsContractStartBooking:columnsContractStartBooking,
          pickupMethodColSpanStartBooking:pickupMethodColSpanStartBooking
        });

      })
      .catch((error) =>{
        console.error(error);
      });




      let columnsContractEndBooking = [];
      columnsContractEndBooking.push({ id: 'end_date'                              , label: 'Date'              ,  minWidth: 120 });
      columnsContractEndBooking.push({ id: 'count_of_booking'                      , label: 'count of booking'  ,  minWidth: 100 , align: 'right' });
      columnsContractEndBooking.push({ id: 'amount'                                , label: 'Amount'            ,  minWidth: 100 , align: 'right' });

      let pickupMethodColSpanEndBooking = 0;
      for ( let i=0;i<pickupMethod.length;i++ ) {
        if ( pickupMethod[i].isDelivery === 1 || pickupMethod[i].isDelivery === 2 ) {
          pickupMethodColSpanEndBooking++;
          columnsContractEndBooking.push(pickupMethod[i]);
        }
      }

      columnsContractEndBooking.push({ id: 'returnMethod_Delivery_using'          , label: 'Not Returned'     ,  minWidth: 100 , align: 'right' });
      columnsContractEndBooking.push({ id: 'returnMethod_Delivery_done'           , label: 'Returned'         ,  minWidth: 100 , align: 'right' });



      // ContracEndリスト取得
      // BookingDailyReportリスト取得
      fetch('/api/getContractEndBooking', {
        method: "POST",
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state)
      })
      .then((response) => response.json())
      .then((responseJson) => {

        let contractEndBooking = [];

        // 直近7日間分のDataRowを作る
        let startDate = new Date();

        for ( let i=0;i<7;i++ ) {

          let contractEndBookingRow = {};
          contractEndBookingRow['end_date'] = startDate.getFullYear() + '-' + (('0' + (startDate.getMonth()+1)).slice(-2)) + '-' + (('0' + startDate.getDate()).slice(-2));
          contractEndBookingRow['count_of_booking'] = 0;
          contractEndBookingRow['amount'] = 0;

          for ( let i=0;i<pickupMethod.length;i++ ) {
            if ( pickupMethod[i].isDelivery === 1 || pickupMethod[i].isDelivery === 2 ) {
              contractEndBookingRow[pickupMethod[i].id] = 0;
            }
          }

          contractEndBookingRow['returnMethod_Delivery_using'] = 0;
          contractEndBookingRow['returnMethod_Delivery_done'] = 0;

          contractEndBooking.push(contractEndBookingRow)

          startDate.setDate(startDate.getDate() - 1);

        }

        for ( let i=0;i<responseJson.length;i++ ) {

          let endDateRow = new Date(responseJson[i]['end_date']);
          responseJson[i]['end_date'] = endDateRow.getFullYear() + '-' + (('0' + (endDateRow.getMonth()+1)).slice(-2)) + '-' + (('0' + endDateRow.getDate()).slice(-2));

          for ( let j=0;j<contractEndBooking.length;j++ ) {

            if ( responseJson[i]['end_date'] === contractEndBooking[j]['end_date'] ) {

              contractEndBooking[j]['count_of_booking'] = contractEndBooking[j]['count_of_booking'] + parseInt(responseJson[i]['count_of_booking']);
              contractEndBooking[j]['amount'] = contractEndBooking[j]['amount'] + parseInt(responseJson[i]['amount']);

              // DeliveryMethod
              for ( let k=0;k<pickupMethod.length;k++ ) {

                let thisRowReturnMethodId = responseJson[i]['return_method_id'];
                if ( thisRowReturnMethodId === pickupMethod[k].pickupMethodId ) {

                  // Deliveryの場合
                  if ( pickupMethod[k].isDelivery === 0 ) {
                    if ( responseJson[i]['booking_sub_status'] === 600 ) {
                      contractEndBooking[j]['returnMethod_Delivery_using'] = contractEndBooking[j]['returnMethod_Delivery_using'] + parseInt(responseJson[i]['count_of_booking']);
                    }
                    else if ( responseJson[i]['booking_sub_status'] === 800 ) {
                      contractEndBooking[j]['returnMethod_Delivery_done'] = contractEndBooking[j]['returnMethod_Delivery_done'] + parseInt(responseJson[i]['count_of_booking']);
                    }
                  }
                  // Pickupの場合 
                  else {
                    contractEndBooking[j][pickupMethod[k].id] = contractEndBooking[j][pickupMethod[k].id] + parseInt(responseJson[i]['count_of_booking']);
                  }

                }

              }

              break;

            }

          }

        }

        this.setState({
          contractEndBooking:contractEndBooking,
          columnsContractEndBooking:columnsContractEndBooking,
          pickupMethodColSpanEndBooking:pickupMethodColSpanEndBooking
        });

      })
      .catch((error) =>{
        console.error(error);
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        { !!this.props.isAgency && 
          <>
          </>
        }
        { !this.props.isAgency &&
          <>
            { (!this.props.isCorporate && !this.props.isLocation ) && 
              <ScreenTitle title={"Recent Registerd Bookings"} />
            }
            { (!this.props.isCorporate && !this.props.isLocation ) && 
              <BookingReportDaily columns={this.state.columnsBookingReportDaily} pickupMethodColSpan={this.state.pickupMethodColSpan} dataRows={this.state.bookingReportDaily} />
            }
            <br />
            <br />
            { (!this.props.isCorporate && !this.props.isLocation ) && 
              <ScreenTitle title={"Constract Start Bookings"} />
            }
            { (!this.props.isCorporate && !this.props.isLocation ) && 
              <ContractStartBooking column={this.state.columnsContractStartBooking} pickupMethodColSpan={this.state.pickupMethodColSpanStartBooking} dataRows={this.state.contractStartBooking} />
            }
            <br />
            <br />
            { (!this.props.isCorporate && !this.props.isLocation ) && 
            <ScreenTitle title={"Returned Bookings"} />
            }
            { (!this.props.isCorporate && !this.props.isLocation ) && 
              <ContractEndBooking column={this.state.columnsContractEndBooking} pickupMethodColSpan={this.state.pickupMethodColSpanEndBooking}  dataRows={this.state.contractEndBooking} />
            }
          </>
        }
      </div>
    );
 
  }

}

const mapStateToProps = state => {
  return {
    isCorporate : state.login.isCorporate,
    isLocation  : state.login.isLocation,
    isAgency    : state.login.isAgency,
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Top);