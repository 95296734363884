module.exports = Object.freeze({
  booking_status_booked: 100,
  booking_status_customre_contacted: 200,
  booking_status: [
    { id: 100, label: "Booked" },
    { id: 120, label: "Agency Booked" },
    { id: 121, label: "Booking confirmed" },
    { id: 122, label: "Booking done" },
    { id: 200, label: "Customer Contacted" },
    { id: 300, label: "Device Assigned" },
    { id: 400, label: "Ready for Send" },
    { id: 500, label: "Wait for Pickup" },
    { id: 600, label: "Using(Delivery)" },
    { id: 700, label: "Using(HandOver)" },
    { id: 800, label: "Done" },
    { id: 900, label: "Cancelled" },
  ],
  pickup_method: [
    { id: 0, label: "Delivery" },
    { id: 1, label: "Apex VietNam HeadOffice" },
    { id: 2, label: "HaNoi Pickup Counter" },
    { id: 3, label: "Lancaster" },
  ],
  pickup_address: [
    {
      id: 1,
      label: "14-16-18 Nguyen An Ninh St., Ward 14, Binh Thanh Dist., HCMC",
    },
    { id: 2, label: "22 Bis Le Thanh Ton St., Ben Nghe Ward, Dist.1, HCMC" },
    { id: 3, label: "71, Van Phuc St., Ba Dinh Dist., Hanoi" },
  ],
  return_method: [
    { id: 0, label: "Delivery" },
    { id: 1, label: "Apex VietNam HeadOffice" },
    { id: 2, label: "HaNoi Pickup Counter" },
    { id: 3, label: "Lancaster" },
  ],
  contract_type: [
    { id: 0, label: "Company" },
    { id: 1, label: "Personal" },
    { id: 2, label: "Agency" },
  ],
  payment_method: [
    { id: 0, label: "Cash" },
    { id: 1, label: "BankTransfer" },
    { id: 2, label: "CreditCard" },
    { id: 3, label: "VNPay" },
  ],
  payment_status: [
    { id: 0, label: "Not Payed" },
    { id: 1, label: "Payed" },
  ],
  refund_status: [
    { id: 0, label: "Not Refunded" },
    { id: 1, label: "Refunded" },
    { id: 2, label: "Cancelled" },
  ],
  destination: [
    { id: 1, label: "Vietnam" },
    { id: 2, label: "Japan" },
    { id: 3, label: "WorldWide" },
  ],
  device_status: [
    { id: 0, label: "Available" },
    { id: 1, label: "Using" },
    { id: 2, label: "Broken" },
    { id: 3, label: "Unavailable" },
  ],
  price_id: [
    { id: 0, label: "Normal" },
    { id: 1, label: "Roam User" },
    { id: 2, label: "Special(Klook)" },
  ],
  plan_list: [
    {
      id: 1,
      label: "ギガ得アジア９ヵ国(Asia 9 countries/9 nước Châu Á đặc biệt)",
      price: 130000,
    },
    { id: 2, label: "アジア (Asia / Châu Á) : 500MB/Day", price: 160000 },
    {
      id: 3,
      label: "アジア無期限(Asia Unlimited / Châu Á Không giới hạn dung lượng)",
      price: 260000,
    },
    {
      id: 4,
      label: "ヨーロッパ (Europe / Châu Áu) : 500MB/Day",
      price: 210000,
    },
    {
      id: 5,
      label:
        "ヨーロッパ無期限(Europe Unlimited / Châu Áu Không giới hạn dung lượng)",
      price: 330000,
    },
    {
      id: 6,
      label: "その他の国 (Other countries / Các quốc gia khác) : 500MB/Day",
      price: 230000,
    },
    {
      id: 7,
      label:
        "その他の国無期限(Other Countries Unlimited / Các quốc gia khác Không giới hạn dung lượng)",
      price: 350000,
    },
    {
      id: 8,
      label: "ベトナム国内 1日(Vietnam one day / Việt Nam Một ngày)",
      price: 80000,
    },
    {
      id: 9,
      label: "ベトナム国内 一ヶ月 (Vietnam one month / Việt Nam Một tháng)",
      price: 1450000,
    },
  ],
  delivery_method_list: [
    { id: 1, label: "Annamtelecom 本社 (Văn phòng Annamtelecom)" },
    { id: 2, label: "HANOI受取場所 (Quầy nhận máy Hà Nội)" },
    { id: 3, label: "ランカスター(Lancaster)" },
    { id: 4, label: "デリバリー(Delivery / Giao hàng)" },
  ],
  return_method_list: [
    { id: 1, label: "Annamtelecom 本社 (Văn phòng Annamtelecom)" },
    { id: 2, label: "HANOI受取場所 (Quầy nhận máy Hà Nội)" },
    { id: 3, label: "ランカスター(Lancaster)" },
    { id: 4, label: "デリバリー(Delivery / Giao hàng)" },
  ],
  payment_method_list: [
    { id: 1, label: "振り込み(Transfer / Chuyển khoản)" },
    { id: 2, label: "現金(Cash / Tiền mặt)" },
    { id: 3, label: "VNPay" },
  ],
  invoice_payment_status: [
    { id: 0, label: "Not Payed" },
    { id: 1, label: "Payed" },
    { id: 2, label: "Cancelled" },
  ],
  expenditure_payment_status: [
    { id: 0, label: "Not Payed" },
    { id: 1, label: "Payed" },
    { id: 2, label: "Cancelled" },
  ],
  accounting_status: [
    { id: 0, label: "Not reported" },
    { id: 1, label: "Reported " },
  ],
  service_type_list: [
    { id: 0, label: "Postpaid" },
    { id: 1, label: "Prepaid" },
    { id: 2, label: "Wifi rental" },
    { id: 3, label: "Device +sim" },
    { id: 4, label: "etc" },
  ],
});
