import * as constVal from "../const";

export function commonTest() {}

export function getPriceRankNameById(priceId) {
  return getKeyByValue(constVal.price_id, priceId);
}

export function getPaymentStatusNameById(paymentStatus) {
  return getKeyByValue(constVal.payment_status, paymentStatus);
}

export function getRefundStatusNameById(refundStatus) {
  return getKeyByValue(constVal.refund_status, refundStatus);
}

export function getContractTypeNameById(contractType) {
  return getKeyByValue(constVal.contract_type, contractType);
}

export function getBookingStatusNameById(bookingStatus) {
  return getKeyByValue(constVal.booking_status, bookingStatus);
  /*
  const keyVal = Object.keys(constVal.booking_status);
  for ( let i=0;i<keyVal.length;i++ ) {
    if ( constVal.booking_status[keyVal[i]] == bookingStatus ) {
      return keyVal[i]
    }
  }
  return '';
  */
}

export function getPickupMethodNameByVal(pickupMethod) {
  return getKeyByValue(constVal.pickup_method, pickupMethod);
}

export function getReturnMethodNameByVal(returnMethod) {
  return getKeyByValue(constVal.return_method, returnMethod);
}

export function getPaymentMethodNameByVal(paymentMethod) {
  return getKeyByValue(constVal.payment_method, paymentMethod);
}

export function getPaymentStatusNameByVal(paymentSatus) {
  return getKeyByValue(constVal.payment_status, paymentSatus);
}

export function formatYYYYMMDD(date, seperator = "/") {
  var y = date.getFullYear();
  var m = ("00" + (date.getMonth() + 1)).slice(-2);
  var d = ("00" + date.getDate()).slice(-2);
  return y + seperator + m + seperator + d;
}

export function formatYYYYMM(date) {
  var y = date.getFullYear();
  var m = ("00" + (date.getMonth() + 1)).slice(-2);
  return y + "/" + m;
}

export function getKeyByValue(array, val) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === val) {
      return array[i].label;
    }
  }
  return "";
}
