import React, { Component } from 'react';
import BookingList from './list/BookingContactCustomerList';
import InformDialog from '../../../common/mirai_component/InformDialog'

import Menu from '../../../common/Menu';
import ScreenTitle from '../../../common/ScreenTitle';

import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

import CommonButton from '../../../common/mirai_component/CommonButton'
import FixDeliveryMethodDialog from './dialog/FixDeliveryMethodDialog'

import {connect} from 'react-redux';
import * as constVal from '../../../common/constant';
import * as commonFunction from '../../../common/commonFunction';


class ContactCustomer extends Component {

  constructor(props) {

    super(props);
    this.state = {
      contract_type               : [],
      payment_method              : [],
      destination                 : [],
      customer_name               : '',
      corporate_name              : '',
      contract_date_from          : '',
      contract_date_to            : '',
      apply_date_from             : '',
      apply_date_to               : '',
      apply_number                : '',
      dataRows                    : [],
      destination_list            : [],
      showFixDeliveryMethodDialog : false,
      selectedApplyNumber         : 0,
      informDialogOpen            : false,
      informDialogTitle           : '',
      informDialogMessage         : '',
      longTermUserPickupMethodId  : null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.closeFixDeliveryMethodDialogEvent = this.closeFixDeliveryMethodDialogEvent.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

  }



  componentDidMount(prevProps) {

    fetch('/api/getAllDestination', {
      method: "POST",
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let destinationList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let destinationRow = {};
        destinationRow.id = responseJson[i].destination_id;
        destinationRow.label = responseJson[i].destination;
        destinationList.push(destinationRow);
      }
      this.setState({
        destination_list:destinationList
      });
    })
    .catch((error) =>{
      console.error(error);
    });


    let requestParam = {};
    fetch('/api/getAllPickupMethod', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let longTermUserPickupMethodId = null;

      for ( let i=0;i<responseJson.length;i++ ) {
        if ( responseJson[i].is_delivery === 2 ) {
          longTermUserPickupMethodId = responseJson[i].pickup_method_id;
        }
      }

      this.setState({
        longTermUserPickupMethodId:longTermUserPickupMethodId,
      })

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  closeFixDeliveryMethodDialogEvent(informDialogTitle,informDialogMessage){

    let informDialogOpen = false;
    if ( informDialogTitle !== '' ) {
      informDialogOpen = true;
    }

    this.setState({
      showFixDeliveryMethodDialog : false,
      informDialogTitle           : informDialogTitle,
      informDialogMessage         : informDialogMessage,
      informDialogOpen            : informDialogOpen,
    });

  }



  // 一覧のCellClick時
  onCellClick(event){
  
    let rowIndex = event.target.parentElement.rowIndex;
    let cellIndex = event.target.cellIndex;
    let keyVal = event.target.parentElement.children[0].textContent;

    // ApplyNumber押下時 -> BookingPageへ遷移
    if ( cellIndex == 0 ) {
      // 会社
      if ( event.target.children[0].value === '0' ) {
        this.props.history.push('/bookingCorporate/' + keyVal);
      }
      // 個人
      else {
        this.props.history.push('/bookingPersonal/' + keyVal);
      }
    }
    // Open Log dialog.
    else if ( cellIndex == 8 ) {
      this.setState({
        showFixDeliveryMethodDialog:true,
        selectedApplyNumber:keyVal
      });
    }

  }



  handleCheckboxChange(event) {

    const name = event.name;
    const checked_list = [];
    for(const each of event.list){
      if(each.checked){
        checked_list.push(each.id);
      }
    }
    this.setState({ [name] : checked_list});
  
  }



  // 検索ボタン押下時の処理
  handleClickSearch(noMessage){
    
    // Booking検索API
    const search_condition = {
      booking_status     : [constVal.booking_status.Booked],
      customer_name      : this.state.customer_name,
      corporate_name     : this.state.corporate_name,
      contract_date_from : this.state.contract_date_from,
      contract_date_to   : this.state.contract_date_to,
      apply_date_from    : this.state.apply_date_from,
      apply_date_to      : this.state.apply_date_to,
      apply_number       : this.state.apply_number,
    };
    fetch('/api/searchBooking', {
      method: "POST",
      body: JSON.stringify(search_condition), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let resultDate = [];
      for ( let i=0;i<responseJson.length;i++ ) {

        if ( responseJson[i]["pickup_method_id"] !== this.state.longTermUserPickupMethodId ) {

          let resultRow = {};

          resultRow['apply_number'] = responseJson[i]['apply_number'];

          if ( responseJson[i]['contract_type'] == constVal.contract_type['Corporate'] ) {
            resultRow['customer_name'] = responseJson[i]["m20_corporate"]["corporate_name"];
          }
          else {
            resultRow['customer_name'] = responseJson[i]['customer_name'];
          }

          resultRow['apply_date'] = new Date(responseJson[i]['apply_date']).toLocaleString();
          resultRow['total_fee'] = parseInt(responseJson[i]['total_fee']).toLocaleString();

          resultRow['return_method'] = responseJson[i]['pickupMethod']['pickup_method'];
          resultRow['pickup_method'] = responseJson[i]['returnMethod']['pickup_method'];
          resultRow['payment_method'] = commonFunction.getPaymentMethodNameByVal(responseJson[i]['payment_method']);
          resultRow['booking_status'] = commonFunction.getBookingStatusNameById(responseJson[i]['booking_status']);

          let bookinStatusList = [];
          let destinationList = [];
          let min_date = new Date(responseJson[i]["t20_booking_details"][0]['start_date']);
          let max_date = new Date(responseJson[i]["t20_booking_details"][0]['end_date']);

          for ( let j=0;j<responseJson[i]["t20_booking_details"].length;j++ ) {

            if ( min_date > new Date(responseJson[i]["t20_booking_details"][j]['start_date']) ) {
              min_date = new Date(responseJson[i]["t20_booking_details"][j]['start_date']);
            }
            if ( max_date < new Date(responseJson[i]["t20_booking_details"][j]['end_date']) ) {
              max_date = new Date(responseJson[i]["t20_booking_details"][j]['end_date']);
            }


            if ( destinationList.indexOf(responseJson[i]["t20_booking_details"][j]["m40_destination"]["destination"]) < 0 ) {
              if ( resultRow['destination'] != undefined && resultRow['destination'] != '' ) {
                resultRow['destination'] = resultRow['destination'] + '/' + responseJson[i]["t20_booking_details"][j]["m40_destination"]["destination"];
              }
              else {
                resultRow['destination'] = responseJson[i]["t20_booking_details"][j]["m40_destination"]["destination"];
              }
              destinationList.push(responseJson[i]["t20_booking_details"][j]["m40_destination"]["destination"]);
            }

          }

          resultRow['contract_date'] = commonFunction.formatYYYYMMDD(min_date) + " - " + commonFunction.formatYYYYMMDD(max_date);
          resultRow['contract_type'] = responseJson[i]['contract_type'];
          resultRow['action'] = '[Fix]';

          resultDate.push(resultRow);

        }
      }

      this.setState({dataRows:resultDate});

      if ( typeof noMessage !== 'boolean' && resultDate.length === 0 ) {

        this.setState({
          informDialogTitle   : "Search Booking Result",
          informDialogMessage : "No data found.",
          informDialogOpen    : true,
        });

      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen : false
    });

    this.handleClickSearch(true);

  }



  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Customer Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="customer_name" name="customer_name" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Corporate Name</FormLabel></div>
            <TextField onChange={this.handleChange} id="corporate_name" name="corporate_name" style={{width: "500px"}} type="search" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Contract Date</FormLabel></div>
            <TextField onChange={this.handleChange} id="contract_date_from" name="contract_date_from" style={{width: "200px"}} type="date" /> ~ 
            <TextField onChange={this.handleChange} id="contract_date_to" name="contract_date_to" style={{width: "200px"}} type="date" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Apply Date</FormLabel></div>
            <TextField onChange={this.handleChange} id="apply_date_from" name="apply_date_from" style={{width: "200px"}} type="date" /> ~ 
            <TextField onChange={this.handleChange} id="apply_date_to" name="apply_date_to" style={{width: "200px"}} type="date" />
        </div>
        <div style={{display: "flex"}}>
            <div style={{width: "300px"}}><FormLabel>Apply Number</FormLabel></div>
            <TextField onChange={this.handleChange} id="apply_number" name="apply_number" style={{width: "500px"}} type="search" />
        </div>
        <br />
        <CommonButton clickEvent={this.handleClickSearch} label="Search booking"/>
        <br />
        <BookingList dataRows={this.state.dataRows} onCellClickEvent={this.onCellClick}/>
        <FixDeliveryMethodDialog showFixDeliveryMethodDialog={this.state.showFixDeliveryMethodDialog} closeFixDeliveryMethodDialogEvent={this.closeFixDeliveryMethodDialogEvent} selectedApplyNumber={this.state.selectedApplyNumber} />
        <InformDialog informDialogStatus={this.state.informDialogOpen} informDialogTitle={this.state.informDialogTitle} informDialogMessage={this.state.informDialogMessage} informDialogCloseEvent={this.informDialogCloseEvent}/>
      </div>
    );
 
  }

}



const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactCustomer);