import React, { Component } from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CommonButton from '../../common/mirai_component/CommonButton'
import ErrorMessage from '../../common/mirai_component/ErrorMessage'
import const_val from '../../const';

import {connect} from 'react-redux';

class Booking extends Component {

  constructor(props) {

    super(props);
    this.state = {
      first_name              : '',
      last_name               : '',
      phone_number            : '',
      email                   : '',
      contract_type           : 1,
      company_name            : '',
      address                 : '',
      wifi_plan_id            : '',
      quantity                : '',
      start_date              : '',
      start_date_d            : '',
      start_date_m            : '',
      start_date_y            : '',
      end_date                : '',
      end_date_d              : '',
      end_date_m              : '',
      end_date_y              : '',
      price                   : 0,
      delivery_method_id      : '',
      delivery_address        : '',
      delivery_city           : '',
      return_method_id        : '',
      return_address          : '',
      return_city             : '',
      payment_method_id       : '',
      errorMsg_main : '',
      errorMsg_email : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleBooking = this.handleBooking.bind(this);
    this.validateForm = this.validateForm.bind(this);

  }

  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});
    if ( name === 'wifi_plan_id' && event.target.selectedIndex != 0) {
      const price = !const_val.plan_list[event.target.selectedIndex-1] ? 0 : const_val.plan_list[event.target.selectedIndex-1].price;
      this.setState({ price : price});
    }

    if ( name === 'email' ) {
      this.setState({ errorMsg_email : ''});
    }
  }

  handleStartDate(date){
    const month = date.getMonth()+1;
    this.setState({
      start_date      : date,
      start_date_d    : date.getDate(),
      start_date_m    : month.toString().length < 2 ? '0'+month : month,
      start_date_y    : date.getFullYear(),
    });
  }

  handleEndDate(date){
    const month = date.getMonth()+1;
    this.setState({
      end_date      : date,
      end_date_d    : date.getDate(),
      end_date_m    : month.toString().length < 2 ? '0'+month : month,
      end_date_y    : date.getFullYear(),
    });
  }

  // 入力チェック
  validateForm(){

    let hasError = false;
    if ( this.state.email === '' ) {
      this.setState({ errorMsg_email : 'Please input email_address.'});
      hasError = true;
    }

    return hasError;
  }

  handleBooking = async(e) => {
    e.preventDefault(); 
//    window.top.location.href = "http://www.example.com";
//    this.setState({ errorMsg_main : '' })
//    if ( this.validateForm() ) {
//      return;
//    }
    console.log(this.state);
    fetch('/api/createBooking', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      if (!responseJson.error) {
        this.setState({ errorMsg_main : "A booking has been made." })
      }
      else {
        this.setState({ errorMsg_main : responseJson.error })
      }
    })
    .catch((error) =>{
      console.error(error);
    });

  }
  
  render() {

    return (
      <div id="AppBooking">
        <div className="bmf-form-container"> 
          <form className="bmf-form " encType="multipart/form-data">
            <div className="bmf-header-container">
                <h2 className="bmf-header">
                    お客様情報 <br />
                    <font size="5"></font>
                </h2>
                <h4 className="bmf-description">(Information / Thông tin khách hàng)</h4>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div>
                        <div className="bmf-form-input-container bmf-first">
                            <input type="text" onChange={this.handleChange} name="first_name" className="bmf-form-input bmf-capitalize" required="" placeholder="*名(Name / Tên)"  />
                        </div>
                        <div className="bmf-form-input-container bmf-last">
                            <input type="text" onChange={this.handleChange} name="last_name" className="bmf-form-input bmf-capitalize" required="" placeholder="*姓(Family name / Họ)"  />
                        </div>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div className="bmf-form-input-container" id="phone-field-cont">
                        <div className="phone-input-container">
                            <div className="phone-flag-cont" id="flag-cont4">
                                <div className="phone-flag-icon-cont">
                                    <img src="https://infoearth.ru/wp-content/uploads/2019/06/1280px-Flag_of_Vietnam.svg_.png" alt="VN" />
                                </div>
                                <span className="phone-flag-triangle arrow-down"></span>
                            </div>
                            <ul className="phone-flag-list " style={{left: '0px'}}>
                                <li className="phone-flag-list-search-cont">
                                    <input type="text" placeholder="Search" className="phone-flag-list-search-cont" />
                                </li>
                                <li className="phone-flag-single">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg" alt="AF" className="phone-flag-single-flag" />
                                    Afghanistan
                                </li>
                                <li className="phone-flag-single">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_%C3%85land.svg" alt="AX" className="phone-flag-single-flag" />
                                    Åland Islands
                                </li>
                            </ul>
                            <div className="phone-input-dial-cont">+84</div>
                            <input type="number" onChange={this.handleChange} name="phone_number" className="bmf-form-input phone-cont-input" required="" pattern="^ *[0-9- ^*()+]{6,} *$" placeholder="*電話番号(Phone/Số điện thoại)"  />
                        </div>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div className="bmf-form-input-container">
                        <input type="email" onChange={this.handleChange} name="email" className="bmf-form-input" placeholder="*メールアドレス(Email/Địa chỉ email)" required="" pattern="^ *[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+ *$"  />
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <label className="bmf-form-label  bmf-required" style={{display: 'inline-block'}}>
                        契約書種類<font face="arial" size="3" weight="lighter">(User type/Loại hợp đồng)</font>
                    </label>
                    <div className="bmf-form-input-container">
                        <div style={{display: 'inline-block', width: '100%'}}>
                            <div className="form-radio-container" style={{display: 'inline-block', width: '50%'}}>
                                <input type="radio" className="boom-check-visible-status" track="0" required="" onChange={this.handleChange} name="contractType" value="1" />
                                <label className="bmf-boom-choice" htmlFor="field6-Item0">個人(Private/Cá Nhân)</label>
                            </div>
                            <div className="form-radio-container" style={{display: 'inline-block', width: '50%'}}>
                                <input type="radio" className="boom-check-visible-status" track="1" required="" onChange={this.handleChange}  name="contractType" value="0" />
                                <label className="bmf-boom-choice" htmlFor="field6-Item1">企業(Corperation/Doanh Nghiệp)</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                {(this.state.contractType == 0 && 
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div>
                        <div className="bmf-form-input-container bmf-first">
                            <input type="text" onChange={this.handleChange} name="company_name" className="bmf-form-input bmf-capitalize" required="" placeholder="*Comapny name"  />
                        </div>
                        <div className="bmf-form-input-container bmf-last">
                            <input type="text" onChange={this.handleChange} name="address" className="bmf-form-input bmf-capitalize" required="" placeholder="*Address"  />
                        </div>
                    </div>
                </div>
                )}
                {(this.state.contractType == 0 && 
                <div className="bmf-fields-divider"></div>
                )}
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div>
                        <div className="bmf-form-customHTML">
                            <br />
                            <br />
                            <font color="c91212"><font size="5">WIFIプラン<br />
                                <font face="arial" size="3" weight="lighter">(WIFI Plan / A Gói Wifi)</font></font></font>
                        </div>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div className="bmf-form-input-container">
                        <select className="bmf-form-input bmf-form-select" required="" onChange={this.handleChange} name="wifi_plan_id">
                            <option  track="0">プランを選択してください。(Choose WIFI Plan/Xin hãy chọn gói Wifi)</option>
                            {const_val.plan_list.map((item, key) => (
                                <option value={item.id} data-price={item.price} key={item.id}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                {(this.state.wifi_plan_id &&
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div>
                        <div className="bmf-form-customHTML">
                            <font size="2">This price is for one machine<font color="c91212"><font size="2"> {this.state.price}VND </font></font></font>
                        </div>
                    </div>
                </div>
                )}
                {(this.state.wifi_plan_id &&
                <div className="bmf-fields-divider"></div>
                )}
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div className="bmf-form-input-container">
                        <input type="number" onChange={this.handleChange} name="quantity" className="bmf-form-input" required="" min="1" max="" placeholder="*How many machine do you need?"  />
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <label className="bmf-form-label  bmf-required" style={{display: 'inline-block'}}>
                        <br />ご利用開始日<font face="arial" size="3" weight="lighter">(Start date/Ngày bắt đầu)</font>
                    </label>
                    <div id="dateContainer-503458" className="bmf-form-input-container bmf-date">
                        <div className="bmf-date-field-day bmf-calendar">
                            <input type="text" id="field25D" className="bmf-form-input" pattern="^[0-9]{2,2}$" placeholder="DD" required="" readOnly={true} value={this.state.start_date_d} />
                        </div>
                        <div className="bmf-date-field-month bmf-calendar">
                            <input type="text" id="field25M" className="bmf-form-input" pattern="^[0-9]{2,2}$" placeholder="MM" required="" readOnly={true} value={this.state.start_date_m} />
                        </div>
                        <div className="bmf-date-field-year bmf-calendar">
                            <input type="text" id="field25Y" className="bmf-form-input" pattern="^[0-9]{4,4}$" placeholder="YYYY" required="" readOnly={true} value={this.state.start_date_y} />
                        </div>
                        <DatePicker customInput={<button type="button" className="bmf-datepicker"></button>} onChange={this.handleStartDate} dateFormat="yyyy-MM-dd" />
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <label className="bmf-form-label  bmf-required" style={{display: 'inline-block'}}>
                        <br />終了予定日<font face="arial" size="3" weight="lighter">(End date/Ngày kết thúc)</font>
                    </label>
                    <div id="dateContainer-503458" className="bmf-form-input-container bmf-date">
                        <div className="bmf-date-field-day bmf-calendar">
                            <input type="text" id="field26D" className="bmf-form-input" pattern="^[0-9]{2,2}$" placeholder="DD" required="" readOnly={true} value={this.state.end_date_d} />
                        </div>
                        <div className="bmf-date-field-month bmf-calendar">
                            <input type="text" id="field26M" className="bmf-form-input" pattern="^[0-9]{2,2}$" placeholder="MM" required="" readOnly={true} value={this.state.end_date_m} />
                        </div>
                        <div className="bmf-date-field-year bmf-calendar">
                            <input type="text" id="field26Y" className="bmf-form-input" pattern="^[0-9]{4,4}$" placeholder="YYYY" required="" readOnly={true} value={this.state.end_date_y} />
                        </div>
                        <DatePicker customInput={<button type="button" className="bmf-datepicker"></button>} onChange={this.handleEndDate} dateFormat="yyyy-MM-dd" />
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div className="bmf-form-input-container">
                        <select className="bmf-form-input bmf-form-select" required="" onChange={this.handleChange} name="delivery_method_id">
                            {const_val.delivery_method_list.map((item, key) => (
                                <option value={item.id} key={item.id}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div className="bmf-form-input-container">
                        <select className="bmf-form-input bmf-form-select" required="" onChange={this.handleChange} name="return_method_id">
                            {const_val.return_method_list.map((item, key) => (
                                <option value={item.id} key={item.id}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                {(this.state.delivery_method_id == 4 &&
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <label className="bmf-form-label  bmf-required" style={{display: 'inline-block'}}>Deliver to</label>
                    <div>
                        <div className="bmf-form-input-container bmf-address-field">
                            <input onChange={this.handleChange} name="delivery_address" required="" className="bmf-form-input bmf-capitalize" type="text" placeholder="Street Address"  style={{margin: '0px 0px 15px'}} />
                        </div>
                        <div className="bmf-form-input-container bmf-address-field">
                            <input onChange={this.handleChange} name="delivery_city" required="" className="bmf-form-input bmf-capitalize" type="text" placeholder="City"  style={{margin: '0px 0px 15px'}} />
                        </div>
                    </div>
                </div>
                )}
                {(this.state.delivery_method_id == 4 &&
                <div className="bmf-fields-divider"></div>
                )}
            </div>
            <div className="bmf-form-field " data-tip="">
                {(this.state.return_method_id == 4 &&
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <label className="bmf-form-label  bmf-required" style={{display: 'inline-block'}}>Return from</label>
                    <div>
                        <div className="bmf-form-input-container bmf-address-field">
                            <input onChange={this.handleChange} name="return_address" className="bmf-form-input bmf-capitalize" type="text" placeholder="Street Address"  style={{margin: '0px 0px 15px'}} required="" />
                        </div>
                        <div className="bmf-form-input-container bmf-address-field">
                            <input onChange={this.handleChange} name="return_city" required="" className="bmf-form-input bmf-capitalize" type="text" placeholder="City"  style={{margin: '0px 0px 15px'}} />
                        </div>
                    </div>
                </div>
                )}
                {(this.state.return_method_id == 4 &&
                <div className="bmf-fields-divider"></div>
                )}
            </div>
            <div className="bmf-form-field " style={{display: 'none'}}>
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div className="bmf-form-input-container">
                        <div id="bmf_map33503458" className="bmf-map" style={{height: '200px', position: 'relative', overflow: 'hidden'}}>
                        </div>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div>
                        <div className="bmf-form-customHTML">
                            <br />
                            <br />
                            <font color="c91212"><font size="5">お支払方法<br />
                            <font face="arial" size="3" weight="lighter">(Payment / THANH TOÁN)</font></font></font>
                        </div>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-form-field " data-tip="">
                <div style={{display: 'inline-block', margin: '5px', width: 'calc(100% - 12px)'}}>
                    <div className="bmf-form-input-container">
                        <div style={{display: 'inline-block', width: '100%'}}>
                            {const_val.payment_method_list.map((item, key) => (
                            <div className="form-radio-container" key={item.id}>
                                <input type="radio" id={'payment_method_'+item.id} onChange={this.handleChange.bind(this)} className="boom-check-visible-status" required="" name="payment_method_id" value={item.id} />
                                <label className="bmf-boom-choice" htmlFor={'payment_method_'+item.id}>{item.label}</label>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="bmf-fields-divider"></div>
            </div>
            <div className="bmf-button-container" style={{'textAlign': 'right'}}>
                <button onClick={this.handleBooking} type="submit" className="bmf-button" value="Submit" style={{width: '100%'}}>
                    <span className="bmf-button-inner">Submit </span>
                </button>
            </div>
        </form>
      </div>
        <br />
        <br />
        <ErrorMessage message={this.state.errorMsg_main} />
        <br />
        <br />
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Booking);
