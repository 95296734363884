import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import DeviceRentHistoryDialogList from './DeviceRentHistoryDialogList';
import * as commonFunction from '../../../../common/commonFunction';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';



class DeviceRentHistoryDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData      : false,
      deviceNumber    : '',
      rowDate         : [],
      registerdDate   : '',
      deviceStatus    : '',
      updatedAt       : '',

    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCellClickEvent = this.onCellClickEvent.bind(this);

  }



  handleClose() {
    this.setState({loadedData:false});
    this.props.handleShowHistoryDialogClose();
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  onCellClickEvent(event){

    if ( event.target.cellIndex === 0 ) {
      let applyNumber = event.target.children[0].value;
      let contractType = event.target.children[1].value;

      if ( contractType === 0 ) {
        this.props.history.push('/bookingCorporate/' + applyNumber);
      }
      else {
        this.props.history.push('/bookingPersonal/' + applyNumber);
      }

    }

  }



  componentWillUpdate(nextProps,nextState){

    if ( nextProps.showHistoryDeviceNumber !=null && !this.state.loadedData ) {

      this.state.loadedData = true;

      let queryParam = {};
      queryParam.deviceNumber = nextProps.showHistoryDeviceNumber;

      this.setState({
        rowDate    : [],
      });

      // Device Rental History検索
      fetch('/api/searchDeviceRentHistory', {
        method: "POST",
        body: JSON.stringify(queryParam), 
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        for ( let i=0;i<responseJson.length;i++ ) {
          responseJson[i].apply_number = responseJson[i]["t20_booking_detail"]["t10_booking"]["apply_number"];
          responseJson[i].apply_date = new Date(responseJson[i]["t20_booking_detail"]["t10_booking"]["apply_date"]).toLocaleString();
          responseJson[i].contract_type = responseJson[i]["t20_booking_detail"]["t10_booking"]["contract_type"];
          responseJson[i].contract_type_label = commonFunction.getContractTypeNameById(responseJson[i]["t20_booking_detail"]["t10_booking"]["contract_type"]);
          if ( responseJson[i]["t20_booking_detail"]["t10_booking"]["contract_type"] == 0 ) {
            responseJson[i].customer_name = responseJson[i]["t20_booking_detail"]["t10_booking"]["m20_corporate"]["corporate_name"];
          }
          else {
            responseJson[i].customer_name = responseJson[i]["t20_booking_detail"]["t10_booking"]["customer_name"];
          }
          responseJson[i].date = commonFunction.formatYYYYMMDD(new Date(responseJson[i]["t20_booking_detail"]['start_date'])) + ' - ' + commonFunction.formatYYYYMMDD(new Date(responseJson[i]["t20_booking_detail"]['end_date']));
          responseJson[i].booking_status_label = commonFunction.getBookingStatusNameById(responseJson[i]["t20_booking_detail"]["booking_sub_status"]);
          responseJson[i].payment_status_label = commonFunction.getPaymentStatusNameById(responseJson[i]["t20_booking_detail"]["t10_booking"]["payment_status"]);

        }

        this.setState({
          rowDate    : responseJson,
          loadedData : true,
        });

      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }


  
  render() {

    const actions = [
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
          <Dialog
            actions={actions}
            modal={false}
            open={this.props.showHistoryDialog}
            onRequestClose={this.handleClose}
            contentStyle={{width: "1250px", maxWidth: "none" , height:"1200px" , maxHeight:"none"}}
          >
            <DialogTitle id="alert-dialog-slide-title">Device Rental History</DialogTitle>
            <DialogContent className="MyDialogContent">
              <DeviceRentHistoryDialogList dataRows={this.state.rowDate} onCellClickEvent={this.onCellClickEvent}/>
            </DialogContent>
          </Dialog>
      </MuiThemeProvider>
    );
  }

}

export default DeviceRentHistoryDialog;