import React, { Component } from 'react';

import TextFieldEmail from '../../common/mirai_component/TextFieldEmail'
import CommonButton from '../../common/mirai_component/CommonButton'
import ErrorMessage from '../../common/mirai_component/ErrorMessage'


import {connect} from 'react-redux';

import Header from '../../common/Header'

class ForgotPassword extends Component {

  constructor(props) {

    super(props);
    this.state = {
      email: '',
      errorMsg_main : '',
      errorMsg_email : '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickRecoverPassword = this.handleClickRecoverPassword.bind(this);
    this.validateForm = this.validateForm.bind(this);

  }

  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

    if ( name === 'email' ) {
      this.setState({ errorMsg_email : ''});
    }
  }



  // 入力チェック
  validateForm(){

    let hasError = false;
    if ( this.state.email === '' ) {
      this.setState({ errorMsg_email : 'Please input email_address.'});
      hasError = true;
    }

    return hasError;

  }



  handleClickRecoverPassword = async() => {
    this.setState({ errorMsg_main : '' })
    if ( this.validateForm() ) {
      return;
    }

    fetch('/api/forgotPassword', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      if (!responseJson.error) {
        this.setState({ errorMsg_main : "A new password has been sent to your email." })
      }
      else {
        this.setState({ errorMsg_main : responseJson.error })
      }
    })
    .catch((error) =>{
      console.error(error);
    });

  }



  render() {

    return (
      <div className="App" id="div">

        <Header/>
        <h2>Please input your email</h2>

        <TextFieldEmail handleChange={this.handleChange} id="email" name="email" errorText={this.state.errorMsg_email}/>
        <br />
        <br />
        <CommonButton clickEvent={this.handleClickRecoverPassword} label="Recover Password"/>
        <br />
        <br />
        <br />
        <ErrorMessage message={this.state.errorMsg_main} />
        <br />
        <br />
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
