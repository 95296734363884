import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TablePagination from '@material-ui/core/TablePagination';
import Popup from "reactjs-popup";
import Button from '@material-ui/core/Button';
import SelectedDeviceResetPopup from './SelectedDeviceResetPopup.js';

const columns = [
  { id: 'rowNum',        label: '#',                  minWidth: 30  },
  { id: 'row_selection', label: 'Sel',                minWidth: 30  },
  { id: 'plan',          label: 'Plan',               minWidth: 80  },
  { id: 'destination',   label: 'Destination',        minWidth: 80  },
  { id: 'quantity',      label: 'quantity',           minWidth: 50  },
  { id: 'deviceNumber',  label: 'DeviceNumber',       minWidth: 200 },
  { id: 'reset',         label: 'Reset',              minWidth: 50  },
];


let rows = [
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 200,
  },
  cell:{
    "padding-top":"4px",
    "padding-bottom":"4px",
  },
  header_cell:{
    "background-color":"#afeeee",
    "line-height":"0.2rem"
  },
  applyNumber : {
    "text-decoration": "underline"
  }
});


export default function DeviceAssignmentDialogList(pPrams) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const params = pPrams;
  rows = params.dataRows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.header_cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                const rowNum = row.rowNum;
                const rowNumParam = <input type="hidden" value={row.rowNum} id="rowNumParam" />;
                return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.rowNum}>
                  {columns.map(column => {
                    const value = row[column.id];
                    if ( column.id == 'row_selection' ) {
                      if ( rowNum == params.rowSelectionVal ) {
                        return (
                          <TableCell key={column.id} align={column.align} className={classes.cell} >
                            <RadioGroup aria-label="" name="rowSelection" value={params.rowSelectionVal} onChange={params.handleChange}>
                              <FormControlLabel value={rowNum} control={<Radio checked />} label="" />
                            </RadioGroup>
                          </TableCell>
                        );
                      }
                      else {
                        return (
                          <TableCell key={column.id} align={column.align} className={classes.cell} >
                            <RadioGroup aria-label="" name="rowSelection" value={params.rowSelectionVal} onChange={params.handleChange}>
                              <FormControlLabel value={rowNum} control={<Radio />} label="" />
                            </RadioGroup>
                          </TableCell>
                        );
                      }
                    }
                    else if ( column.id == 'reset' ) {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <SelectedDeviceResetPopup rowNum={rowNum} selectedDeviceList={row.selectedDevices} handleResetSelectedDevice={params.handleResetSelectedDevice} />
                        </TableCell>
                      );
                    }
                    else {
                      return (
                        <TableCell key={column.id} align={column.align} onClick={params.onCellClickEvent} className={classes.cell} >
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                          {column.id==='reset' ? rowNumParam : ''}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}