export const TEXTCHANGE = 'TEXTCHANGE';
export const textChange = (textValue, textLength) => {
  return {
    type: TEXTCHANGE,
    textValue: textValue,
    textLength: textLength,
  };
};

export const SEG_LOGIN_USER_INFO = 'SEG_LOGIN_USER_INFO';
export const setLoginUserInfo = (id,userName,userEmail,isCorporate,isLocation,admin,isAgency) => {
  return {
    type         : SEG_LOGIN_USER_INFO,
    id           : id,
    userName     : userName,
    userEmail    : userEmail,
    isCorporate  : isCorporate,
    isLocation   : isLocation,
    admin        : admin,
    isAgency     : isAgency,
  };
};

export const MENU_SELECT_INVOICE = 'MENU_SELECT_INVOICE';
export const selectMenuItemInvoice = (selectedMenuItemIndexInvoice,selectedMenuName) => {
  return {
    type : MENU_SELECT_INVOICE,
    selectedMenuItemIndexInvoice : selectedMenuItemIndexInvoice,
    selectedMenuName             : selectedMenuName
  };
};

export const MENU_SELECT_EXPENDITURE = 'MENU_SELECT_EXPENDITURE';
export const selectMenuItemExpenditure = (selectedMenuItemIndexExpenditure,selectedMenuName) => {
  return {
    type : MENU_SELECT_EXPENDITURE,
    selectedMenuItemIndexExpenditure : selectedMenuItemIndexExpenditure,
    selectedMenuName                 : selectedMenuName
  };
};

export const MENU_SELECT_REVENUE = 'MENU_SELECT_REVENUE';
export const selectMenuItemRevenue = (selectedMenuItemIndexRevenue,selectedMenuName) => {
  return {
    type : MENU_SELECT_REVENUE,
    selectedMenuItemIndexRevenue : selectedMenuItemIndexRevenue,
    selectedMenuName             : selectedMenuName
  };
};

export const MENU_SELECT_BOOKING = 'MENU_SELECT_BOOKING';
export const selectMenuItemBooking = (selectedMenuItemIndexBooking,selectedMenuName) => {
  return {
    type : MENU_SELECT_BOOKING,
    selectedMenuItemIndexBooking : selectedMenuItemIndexBooking,
    selectedMenuName             : selectedMenuName
  };
};

export const MENU_SELECT_NEW_BOOKING = 'MENU_SELECT_NEW_BOOKING';
export const selectMenuItemNewBooking = (selectedMenuItemIndexNewBooking,selectedMenuName) => {
  return {
    type : MENU_SELECT_NEW_BOOKING,
    selectedMenuItemIndexNewBooking : selectedMenuItemIndexNewBooking,
    selectedMenuName                : selectedMenuName
  };
};

export const MENU_SELECT_PAYMENT = 'MENU_SELECT_PAYMENT';
export const selectMenuItemPayment = (selectedMenuItemIndexPayment,selectedMenuName) => {
  return {
    type : MENU_SELECT_PAYMENT,
    selectedMenuItemIndexPayment : selectedMenuItemIndexPayment,
    selectedMenuName             : selectedMenuName
  };
};

export const MENU_SELECT_STOCK = 'MENU_SELECT_STOCK';
export const selectMenuItemStock = (selectedMenuItemIndexStock,selectedMenuName) => {
  return {
    type : MENU_SELECT_STOCK,
    selectedMenuItemIndexStock : selectedMenuItemIndexStock,
    selectedMenuName           : selectedMenuName
  };
};

export const MENU_SELECT_MASTER = 'MENU_SELECT_MASTER';
export const selectMenuItemMaster = (selectedMenuItemIndexMaster,selectedMenuName) => {
  return {
    type : MENU_SELECT_MASTER,
    selectedMenuItemIndexMaster : selectedMenuItemIndexMaster,
    selectedMenuName            : selectedMenuName
  };
};

export const MENU_SELECT_LOCATION = 'MENU_SELECT_LOCATION';
export const selectMenuItemLocation = (selectedMenuItemIndexLocation,selectedMenuName) => {
  return {
    type : MENU_SELECT_LOCATION,
    selectedMenuItemIndexLocation : selectedMenuItemIndexLocation,
    selectedMenuName              : selectedMenuName
  };
};

export const MENU_SELECT_REPORT = 'MENU_SELECT_REPORT';
export const selectMenuItemReport = (selectedMenuItemIndexReport,selectedMenuName) => {
  return {
    type : MENU_SELECT_REPORT,
    selectedMenuItemIndexReport : selectedMenuItemIndexReport,
    selectedMenuName            : selectedMenuName
  };
};