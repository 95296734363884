import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 660,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function CommonSelect(pPrams) {
  const classes = useStyles();
  const params = pPrams;

  let readOnly = params.readOnly;
  let readOnlyLabel = params.readOnlyLabel;

  if (readOnly) {
    if (readOnlyLabel === undefined || readOnlyLabel === '') {
      for (let i = 0; i < params.selectList.length; i++) {
        if (params.selectValue == params.selectList[i].id) {
          readOnlyLabel = params.selectList[i].label;
          break;
        }
      }
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{params.caption}</InputLabel>
        {readOnlyLabel}
      </FormControl>
    );
  } else {
    return (
      <FormControl
        className={classes.formControl}
        required={pPrams.required}
        error={
          params.errorText !== undefined && params.errorText != ''
            ? true
            : false
        }
      >
        <InputLabel
          classes={{ asterisk: 'Mui-error' }}
          id="demo-simple-select-label"
        >
          {params.caption}
        </InputLabel>
        <Select
          {...pPrams}
          labelId="demo-simple-select-label"
          id={params.name}
          name={params.name}
          value={params.selectValue ?? ''}
          onChange={params.onChangeEvent}
        >
          {params.selectList.map((item, index) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {params.errorText !== undefined && params.errorText != ''
            ? params.errorText
            : ''}
        </FormHelperText>
      </FormControl>
    );
  }
}
