import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import Header from "./Header";
import AnnamMenuBooking from "./menu/AnnamMenuBooking";
import AnnamMenuNewBooking from "./menu/AnnamMenuNewBooking";
import AnnamMenuPayment from "./menu/AnnamMenuPayment";
import AnnamMenuStock from "./menu/AnnamMenuStock";
import AnnamMenuMaster from "./menu/AnnamMenuMasterMaint";
import AnnamMenuLocation from "./menu/AnnamMenuLocation";
import AnnamMenuReport from "./menu/AnnamMenuReport";

import AgencyMenuBooking from "./menu/AgencyMenuBooking";

import { connect } from "react-redux";
import { selectMenuItemBooking } from "../actions";
import { selectMenuItemNewBooking } from "../actions";
import { selectMenuItemPayment } from "../actions";
import { selectMenuItemStock } from "../actions";
import { selectMenuItemMaster } from "../actions";
import { selectMenuItemLocation } from "../actions";
import { selectMenuItemReport } from "../actions";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      status: 0,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(prevProps) {}

  // 画面入力項目をthis.stateにバインドする
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    this.setState({ [name]: event.target.value });
  }

  render() {
    return (
      <div className="App" id="div">
        <Header
          login_user_name={this.props.userName}
          login_user_email={this.props.userEmail}
        />
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "5px",
            marginBottom: "5px",
            marginTop: "1px",
            backgroundColor: "#dcdcdc",
            position: "relative",
          }}
        >
          <Grid
            item
            xs={12}
            style={{ display: "inline-block", float: "center", padding: "5px" }}
          >
            <Grid
              container
              justify="center"
              spacing={1}
              style={{ paddingBottom: "7px" }}
            >
              {!!this.props.isAgency && (
                <>
                  <Grid item style={{ paddingBottom: "1px" }}>
                    <AgencyMenuBooking
                      accept={[]}
                      onClickEvent={this.props.selectMenuItemNewBooking}
                      screenTransitEvent={this.props.history}
                    />
                  </Grid>
                </>
              )}
              {!this.props.isAgency && (
                <>
                  {!this.props.isLocation && (
                    <Grid item style={{ paddingBottom: "1px" }}>
                      <AnnamMenuNewBooking
                        accept={
                          this.props.isCorporate ? ["/bookingCorporate"] : []
                        }
                        selectedMenuIndex={
                          this.props.selectedMenuItemIndexNewBooking
                        }
                        onClickEvent={this.props.selectMenuItemNewBooking}
                        screenTransitEvent={this.props.history}
                      />
                    </Grid>
                  )}
                  {!this.props.isLocation && (
                    <Grid item style={{ paddingBottom: "1px" }}>
                      <AnnamMenuBooking
                        accept={
                          this.props.isCorporate ? ["/searchBooking"] : []
                        }
                        selectedMenuIndex={
                          this.props.selectedMenuItemIndexBooking
                        }
                        onClickEvent={this.props.selectMenuItemBooking}
                        screenTransitEvent={this.props.history}
                      />
                    </Grid>
                  )}
                  {!this.props.isCorporate && !this.props.isLocation && (
                    <Grid
                      item
                      style={{
                        paddingBottom: "1px",
                        display: this.props.isCorporate ? "none" : "",
                      }}
                    >
                      <AnnamMenuPayment
                        selectedMenuIndex={
                          this.props.selectedMenuItemIndexPayment
                        }
                        onClickEvent={this.props.selectMenuItemPayment}
                        screenTransitEvent={this.props.history}
                      />
                    </Grid>
                  )}
                  {!this.props.isCorporate && !this.props.isLocation && (
                    <Grid
                      item
                      style={{
                        paddingBottom: "1px",
                        display: this.props.isCorporate ? "none" : "",
                      }}
                    >
                      <AnnamMenuStock
                        selectedMenuIndex={
                          this.props.selectedMenuItemIndexStock
                        }
                        onClickEvent={this.props.selectMenuItemStock}
                        screenTransitEvent={this.props.history}
                      />
                    </Grid>
                  )}
                  {!this.props.isCorporate && !this.props.isLocation && (
                    <Grid
                      item
                      style={{
                        paddingBottom: "1px",
                        display: this.props.isCorporate ? "none" : "",
                      }}
                    >
                      <AnnamMenuMaster
                        selectedMenuIndex={
                          this.props.selectedMenuItemIndexMaster
                        }
                        onClickEvent={this.props.selectMenuItemMaster}
                        screenTransitEvent={this.props.history}
                        admin={this.props.admin}
                      />
                    </Grid>
                  )}
                  {this.props.isLocation !== 0 && (
                    <Grid item style={{ paddingBottom: "1px" }}>
                      <AnnamMenuLocation
                        selectedMenuIndex={
                          this.props.selectedMenuItemIndexLocation
                        }
                        onClickEvent={this.props.selectMenuItemLocation}
                        screenTransitEvent={this.props.history}
                      />
                    </Grid>
                  )}
                  {this.props.admin === true && (
                    <Grid item style={{ paddingBottom: "1px" }}>
                      <AnnamMenuReport
                        selectedMenuIndex={
                          this.props.selectedMenuItemIndexReport
                        }
                        onClickEvent={this.props.selectMenuItemReport}
                        screenTransitEvent={this.props.history}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userName: state.login.userName,
    userEmail: state.login.userEmail,
    isCorporate: state.login.isCorporate,
    isLocation: state.login.isLocation,
    admin: state.login.admin,
    isAgency: state.login.isAgency,
    selectedMenuItemIndexBooking: state.menu.selectedMenuItemIndexBooking,
    selectedMenuItemIndexNewBooking: state.menu.selectedMenuItemIndexNewBooking,
    selectedMenuItemIndexPayment: state.menu.selectedMenuItemIndexPayment,
    selectedMenuItemIndexStock: state.menu.selectedMenuItemIndexStock,
    selectedMenuItemIndexMaster: state.menu.selectedMenuItemIndexMaster,
    selectedMenuItemIndexLocation: state.menu.selectedMenuItemIndexLocation,
    selectedMenuItemIndexReport: state.menu.selectedMenuItemIndexReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectMenuItemBooking: (selectMenuItemIndexBooking, functionName) =>
      dispatch(selectMenuItemBooking(selectMenuItemIndexBooking, functionName)),
    selectMenuItemNewBooking: (selectMenuItemIndexNewBooking, functionName) =>
      dispatch(
        selectMenuItemNewBooking(selectMenuItemIndexNewBooking, functionName)
      ),
    selectMenuItemPayment: (selectMenuItemIndexPayment, functionName) =>
      dispatch(selectMenuItemPayment(selectMenuItemIndexPayment, functionName)),
    selectMenuItemStock: (selectMenuItemIndexStock, functionName) =>
      dispatch(selectMenuItemStock(selectMenuItemIndexStock, functionName)),
    selectMenuItemMaster: (selectMenuItemIndexMaster, functionName) =>
      dispatch(selectMenuItemMaster(selectMenuItemIndexMaster, functionName)),
    selectMenuItemLocation: (selectMenuItemIndexLocation, functionName) =>
      dispatch(
        selectMenuItemLocation(selectMenuItemIndexLocation, functionName)
      ),
    selectMenuItemReport: (selectMenuItemIndexReport, functionName) =>
      dispatch(selectMenuItemReport(selectMenuItemIndexReport, functionName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
