import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const columns = [
  {
    id: "apply_number",
    label: "Apply\u00a0Number",
    minWidth: 70,
    class: "applyNumber",
  },
  { id: "voucher", label: "Voucher", minWidth: 100, align: "right" },
  { id: "plan", label: "Plan", minWidth: 200, align: "right" },
  {
    id: "contract_date",
    label: "Contract\u00a0Date",
    minWidth: 200,
    align: "center",
  },
  {
    id: "booking_status",
    label: "Booking\u00a0Status",
    minWidth: 200,
    align: "center",
  },
  {
    id: "pickup_method",
    label: "Pickup\u00a0Method",
    minWidth: 200,
    align: "center",
  },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: 100,
    align: "center",
  },
  {
    id: "customer_name",
    label: "Customer\u00a0Name",
    minWidth: 250,
    align: "left",
  },
  {
    id: "arrival_time",
    label: "Arrival Time",
    minWidth: 100,
    align: "center",
  },
  {
    id: "total_fee",
    label: "Total\u00a0Fee",
    minWidth: 100,
    align: "right",
  },
  {
    id: "person_in_charge",
    label: "Person\u00a0in\u00a0Charge",
    minWidth: 150,
    align: "left",
  },
  {
    id: "return_method",
    label: "Return\u00a0Method",
    minWidth: 200,
    align: "center",
  },
  {
    id: "payment_method",
    label: "Payment\u00a0Method",
    minWidth: 100,
    align: "center",
  },
  { id: "destination", label: "Destination", minWidth: 100, align: "center" },
  {
    id: "deviceNumber",
    label: "Device\u00a0Number",
    minWidth: 250,
    align: "center",
  },
  { id: "showLog", label: "Log", minWidth: 50, align: "center" },
];

let rows = [];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 450,
  },
  applyNumber: {
    "text-decoration": "underline",
    "line-height": "0.2rem",
    cursor: "hand",
    cursor: "pointer",
  },
  header_cell: {
    "background-color": "#afeeee",
    "line-height": "0.2rem",
  },
  cell: {},
});

export default function BookingList(pPrams) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataColumns, setDataColumns] = useState([]);
  const params = pPrams;
  rows = params.dataRows;

  useEffect(() => {
    setDataColumns(pPrams?.dataColumns ?? columns);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {dataColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.header_cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const contractTypeField = (
                  <input
                    type="hidden"
                    value={row["contract_type"]}
                    id="contract_type"
                  />
                );
                const bookingId = (
                  <input
                    type="hidden"
                    value={row["booking_id"]}
                    id="booking_id"
                  />
                );
                const bookingStatus = (
                  <input
                    type="hidden"
                    value={row["booking_status_id"]}
                    id="booking_status_id"
                  />
                );
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.apply_number}
                  >
                    {dataColumns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={params.onCellClickEvent}
                          className={
                            column.id +
                            " " +
                            (column.id === "apply_number" ||
                            column.id === "showLog"
                              ? classes.applyNumber
                              : classes.cell)
                          }
                        >
                          {column.id === "booking_status" ? bookingStatus : ""}
                          {column.id === "apply_number"
                            ? contractTypeField
                            : ""}
                          {column.id === "showLog" ? bookingId : ""}
                          {(() => {
                            if (column.id === "plan") {
                              return value.length == 1
                                ? value[0]
                                : value.map((val, idx) => {
                                    return (
                                      <div key={idx}>
                                        Plan #{idx + 1}: {val}
                                      </div>
                                    );
                                  });
                            } else if (
                              column.format &&
                              typeof value === "number"
                            ) {
                              return column.format(value);
                            } else {
                              return value;
                            }
                          })()}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
