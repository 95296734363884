import React from 'react';
import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function ItemListTable(pPrams) {

  const params = pPrams;
  let readOnly = params.readOnly;

  let [state, setState] = React.useState({
    columns: [
    ],
    data: [
    ],
  });

  let country_list = pPrams.countryByDestination;

  let tmpColumns = [];

  tmpColumns.push({ 
    title: 'Plan' , 
    field: 'planId' , 
    lookup: params.planList
  });

  tmpColumns.push({ 
    title: 'Destination', 
    field: 'destinationId' , 
    lookup: params.destinationList[params.priceId+''],
    editComponent: props => {
      const list = Object.keys(params.destinationList[params.priceId+'']).map((value) => (
        <MenuItem key={value} value={value}>{params.destinationList[params.priceId+''][value]}</MenuItem>
      ))
      return (
      <Select value={props.value || ''} 
        onChange={(e) => {
          var data = { ...props.rowData };
          delete data.countryId;
          data.destinationId = e.target.value;
          props.onRowDataChange(data);
        }}>
        {list}
      </Select>
      );
    },
  });

  tmpColumns.push({ 
    title: 'Country', 
    field: 'countryId' , 
    lookup: params.countryList,
    editComponent: props => {
      let countryList = (props.rowData.destinationId) ? country_list[props.rowData.destinationId] : params.countryList;
      const list = Object.keys(countryList || []).map((value) => (
        <MenuItem key={value} value={value}>{countryList[value]}</MenuItem>
      ))
      return (
      <Select value={props.value || ''} onChange={(e) => props.onChange(e.target.value)}>
        {list}
      </Select>
      );
    },
  });

  if ( params.isInputDeliveryDate == true ) {
    tmpColumns.push({ 
      title: 'Delivery Date', 
      field: 'deliveryDate',  
      type: 'date' , 
    });
  }

  tmpColumns.push({ 
    title: 'Start Date', 
    field: 'startDate',  
    type: 'date' , 
  });

  tmpColumns.push({ 
    title: 'End Date', 
    field: 'endDate',  
    type: 'date' , 
  });

  if ( params.isInputReturnDate == true ) {
    tmpColumns.push({ 
      title: 'Return  Date', 
      field: 'returnDate',  
      type: 'date' , 
    });
  }

  tmpColumns.push({
    title: 'Quantity',
    field: 'quantity',
    type: 'numeric',
    cellStyle: {
      width:20,
      maxWidth:20,
      padding:20
    }
  });

  tmpColumns.push({
    title: 'Price',
    field: 'price',
    type: 'numeric',
    editable :'never',
    cellStyle: {
      width:20,
      maxWidth:20,
      padding:20
    },      
  });

  tmpColumns.push({
    title: 'Tax' , 
    field: 'isTaxInclude' , 
    lookup: {"0":"WithTax","1":"NoTax"},
    cellStyle: {
      width:100,
      maxWidth:100,
      visible:false,
    }
  });

  tmpColumns.push({
    title: 'Status',
    field: 'bookingSubStatusLabel',
    editable :'never',
    cellStyle: {
      width:100,
      maxWidth:150,
      padding:10
    },      
  });

  state.data = params.rowData;


  if ( readOnly ) {

    return (
      <MaterialTable
        title="Item List"
        columns={tmpColumns}
        data={params.rowData}
        editable={{}}
      />
    );
  }
  else {

    return (
      <MaterialTable
        title="Item List"
        columns={tmpColumns}
        data={params.rowData}
        editable={{
          isEditable  : rowData => ( rowData.bookingSubStatus === undefined || rowData.bookingSubStatus === '' || parseInt(rowData.bookingSubStatus) < 600 ) ,
          isDeletable : rowData => ( rowData.bookingSubStatus === undefined || rowData.bookingSubStatus === '' || parseInt(rowData.bookingSubStatus) < 600 ) ,
          onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                setState(prevState => {
                  const data = [...prevState.data];
                  newData.price = params.getPriceFunction(newData.planId,newData.destinationId,newData.startDate,newData.endDate,newData.quantity);
                  data.push(newData);
                  params.onChangeEvent(data,data.length-1);
                  return { ...prevState, data };
                });
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                if (oldData ) {
                  setState(prevState => {
                    const data = [...prevState.data];
                    newData.price = params.getPriceFunction(newData.planId,newData.destinationId,newData.startDate,newData.endDate,newData.quantity);
                    data[data.indexOf(oldData)] = newData;
                    params.onChangeEvent(data,data.indexOf(oldData));
                    return { ...prevState, data };
                  });
                }
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                setState(prevState => {
                  const data = [...prevState.data];
                  data.splice(data.indexOf(oldData), 1);
                  params.onChangeEvent(data);
                  return { ...prevState, data };
                });
              }, 600);
            }),
        }}
      />
    );
  }

}