import React, { Component } from 'react';

import Menu from '../../../common/Menu';
import ScreenTitle from '../../../common/ScreenTitle';
import TextField from '@material-ui/core/TextField';
import MiraiTextField from '../../../common/mirai_component/MiraiTextField';

import CommonSelect from '../../../common/CommonSelect';
import CommonButton from '../../../common/mirai_component/CommonButton';
import InformDialog from '../../../common/mirai_component/InformDialog';
import ConfirmDialog from '../../../common/mirai_component/ConfirmDialog';

import { connect } from 'react-redux';
import * as constVal from '../../../common/constant';
import CommonRadio from '../../../common/CommonRadio';
import * as commonFunction from '../../../common/commonFunction';

import UpdateLogDialog from '../../../common/UpdateLogDialog';

class CreateBooking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      bookingStatus: '',
      bookingId: '',
      applyNumber: '',
      contractType: 1,
      userName: this.props.userName,
      isAdmin: this.props.admin,
      customerName: '',
      customerAddress: '',
      customerTel: '',
      customerEmail: '',
      productTypeList: [
        // { id: 1, label: "Wifi" },
        { id: 2, label: 'SIM' },
      ],
      productType: 2,
      pickupMethodTable: [],
      pickupMethodId: '',
      pickupMethodLabel: '',
      pickupMethodAddress: '',
      personInCharge: 0,
      planList: [],
      planId: '',
      quantity: 1,
      voucher: '',
      arrivalTime: '',
      contractDateFrom: '',
      contractDateTo: '',
      updatedAt: '',
      errorMsg: {
        customerName: '',
        customerAddress: '',
        customerTel: '',
        customerEmail: '',
        planId: '',
        quantity: '',
        voucher: '',
        pickupMethodId: '',
        pickupMethodAddress: '',
        arrivalTime: '',
        contractDateFrom: '',
        contractDateTo: '',
      },
      informDialogOpen: false,
      informDialogTitle: '',
      informDialogMessage: '',
      confirmDialogOpen: false,
      confirmDialogTitle: '',
      confirmDialogMessage: '',
      confirmDialogContinue: false,
      readOnlyProps: {
        customerName: false,
        customerAddress: false,
        customerTel: false,
        customerEmail: false,
        vat: false,
        itemList: false,
        pickupMethodId: false,
        returnMethodId: false,
        pickupMethodAddress: false,
        deviceAssign: false,
        discountAmount: false,
        personInCharge: false,
        campaignId: false,
      },
      visibleProps: {
        pickupMethodAddress: true,
        saveButton: true,
        cancelButton: false,
        extendButton: false,
        deviceAssign: true,
        discountAmount: true,
        refundButton: false,
        personInCharge: true,
      },
      destinationList: [{ 0: { 1: 'dummy' } }],
      countryList: [{ 1: 'dummy' }],
      countryMasterData: [],

      isInputDeliveryDate: false,
      isInputReturnDate: false,
      extendToList: [],
      bookingDetailsOrg: [],
      showUpdateLogDialog: false,
      selectedBookingId: '',
      selectedApplyNumber: '',
      redirectTo: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.createBooking = this.createBooking.bind(this);
    this.updateBooking = this.updateBooking.bind(this);

    this.confirmDialogCloseEventCancel =
      this.confirmDialogCloseEventCancel.bind(this);
    this.confirmDialogCloseEventContinue =
      this.confirmDialogCloseEventContinue.bind(this);

    const applyNumber = props.match.params.applyNumber;

    // 一覧からの遷移時
    if (applyNumber !== undefined && applyNumber !== '') {
      this.state.applyNumber = applyNumber;
    }
    // 新規作成
    else {
      this.state.bookingStatus = 0;
    }
  }

  componentDidMount(prevProps) {
    let applyNumberParam = '';
    if (this.state.applyNumber !== undefined && this.state.applyNumber !== '') {
      applyNumberParam = '/' + this.state.applyNumber;
    }

    // bookingデータ取得
    if (this.state.applyNumber !== undefined && this.state.applyNumber !== '') {
      const reqJson = { apply_number: this.state.applyNumber };

      // Booking検索
      fetch('/api/searchBooking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqJson),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // 会社だったら
          if (responseJson[0]?.contract_type === 0) {
            this.props.history.push('/bookingCorporate' + applyNumberParam);
          }

          // BookingDetail情報
          let detailRows = [];
          let bookingDetailsOrg = [];

          // 明細ごとのステータスで最も進んでいるもの
          let maxSubStatus = 0;
          // 明細ごとのステータスで最も進んでいないもの
          let minSubStatus = 0;
          // Extendボタン表示(使用中の明細がある場合のみExtendボタンは押せる)
          let showExtendButton = false;

          for (
            let i = 0;
            i < responseJson[0]['t20_booking_details'].length;
            i++
          ) {
            if (
              responseJson[0]['t20_booking_details'][i].booking_sub_status >
              maxSubStatus
            ) {
              maxSubStatus =
                responseJson[0]['t20_booking_details'][i].booking_sub_status;
            }
            if (
              minSubStatus >
              responseJson[0]['t20_booking_details'][i].booking_sub_status
            ) {
              minSubStatus =
                responseJson[0]['t20_booking_details'][i].booking_sub_status;
            }
            if (
              responseJson[0]['t20_booking_details'][i].booking_sub_status ==
                600 ||
              responseJson[0]['t20_booking_details'][i].booking_sub_status ==
                700
            ) {
              showExtendButton = true;
            }

            let detailRow = {};

            detailRow.row_id = i + 1;
            detailRow.bookingDetailId =
              responseJson[0]['t20_booking_details'][i].booking_detail_id;
            detailRow.deliveryDate =
              responseJson[0]['t20_booking_details'][i].delivery_date;
            detailRow.startDate =
              responseJson[0]['t20_booking_details'][i].start_date;
            detailRow.endDate =
              responseJson[0]['t20_booking_details'][i].end_date;
            detailRow.returnDate =
              responseJson[0]['t20_booking_details'][i].return_date;
            detailRow.planId =
              responseJson[0]['t20_booking_details'][i].plan_id;
            detailRow.plan = ''; // 名称は後で（Validation時）にセット
            detailRow.destinationId =
              responseJson[0]['t20_booking_details'][i].destination_id;
            detailRow.destination = ''; // 名称は後で（Validation時）にセット
            detailRow.countryId =
              responseJson[0]['t20_booking_details'][i].country_id;
            detailRow.country = ''; // 名称は後で（Validation時）にセット
            detailRow.quantity =
              responseJson[0]['t20_booking_details'][i].quantity;
            detailRow.price = responseJson[0]['t20_booking_details'][i].price;
            detailRow.unitPrice =
              responseJson[0]['t20_booking_details'][i].unit_price;
            detailRow.isTaxInclude =
              responseJson[0]['t20_booking_details'][i].is_tax_include;
            detailRow.isExtended =
              responseJson[0]['t20_booking_details'][i].is_extended;
            detailRow.bookingSubStatus =
              responseJson[0]['t20_booking_details'][i].booking_sub_status;
            detailRow.bookingSubStatusLabel =
              commonFunction.getBookingStatusNameById(
                responseJson[0]['t20_booking_details'][i].booking_sub_status
              );

            detailRows.push(detailRow);
            bookingDetailsOrg.push(
              Object.assign({}, JSON.parse(JSON.stringify(detailRow)))
            );
          }

          // -------------------
          // Statusによる画面制御
          // -------------------
          const readOnlyProps = Object.assign({}, this.state.readOnlyProps);
          const visibleProps = Object.assign({}, this.state.visibleProps);

          visibleProps.cancelButton = this.state.isAdmin ?? false;
          this.setState({
            bookingStatus: responseJson[0].booking_status,
            bookingId: responseJson[0].booking_id,
            applyNumber: responseJson[0].apply_number,
            // contractType: responseJson[0].contract_type,
            customerName: responseJson[0].customer_name,
            customerAddress: responseJson[0].customer_address,
            customerTel: responseJson[0].customer_tel,
            customerEmail: responseJson[0].customer_email,
            pickupMethodId: responseJson[0].pickup_method_id,
            pickupMethodAddress: responseJson[0].pickup_method_address,
            planId: detailRows[0]?.planId,
            contractDateFrom: commonFunction.formatYYYYMMDD(
              new Date(detailRows[0]?.startDate),
              '-'
            ),
            contractDateTo: detailRows[0]?.endDate
              ? commonFunction.formatYYYYMMDD(
                  new Date(detailRows[0]?.endDate),
                  '-'
                )
              : null,
            personInCharge: responseJson[0].person_in_charge,
            quantity: detailRows[0]?.quantity,
            voucher: responseJson[0]?.voucher,
            arrivalTime: responseJson[0]?.arrival_time,
            userName: responseJson[0]?.m10_user?.name,
            readOnlyProps: readOnlyProps,
            visibleProps: visibleProps,
            updatedAt: responseJson[0].updatedAt,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.setState({
        personInCharge: this.props.id,
      });
    }

    let requestParam = {};
    requestParam.is_agency = 1;

    fetch('/api/getAllPlan', {
      method: 'POST',
      body: JSON.stringify(requestParam),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const planList = [];
        for (let i = 0; i < responseJson.length; i++) {
          let planRow = {};
          planRow.id = responseJson[i].plan_id;
          planRow.label = responseJson[i].plan;
          planList.push(planRow);
        }

        this.setState({
          planList: planList,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    fetch('/api/getAllCountry', {
      method: 'POST',
      body: JSON.stringify(requestParam),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let countryList = {};
        let countryByDestination = {};
        for (let i = 0; i < responseJson.length; i++) {
          countryList[responseJson[i].country_id] = responseJson[i].name;
          if (!countryByDestination[responseJson[i].destination_id]) {
            countryByDestination[responseJson[i].destination_id] = {};
          }
          countryByDestination[responseJson[i].destination_id][
            responseJson[i].country_id
          ] = responseJson[i].name;
        }
        this.setState({
          countryList: countryList,
          countryMasterData: responseJson,
          countryByDestination: countryByDestination,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    fetch('/api/getAllPickupMethod', {
      method: 'POST',
      body: JSON.stringify(requestParam),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let pickupMethodTable = [];

        for (let i = 0; i < responseJson.length; i++) {
          if (responseJson[i].is_agency === 1) {
            let pickupMethodRow = {};
            pickupMethodRow.id = responseJson[i].pickup_method_id;
            pickupMethodRow.label = responseJson[i].pickup_method;
            pickupMethodRow.isDelivery = responseJson[i].is_delivery;
            pickupMethodTable.push(pickupMethodRow);
          }
        }

        this.setState({
          pickupMethodTable: pickupMethodTable,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // 画面入力項目をthis.stateにバインドする
  handleChange(event) {
    const target = event.target;
    const name = target.name;

    const errorMsg = Object.assign({}, this.state.errorMsg);
    errorMsg[name] = '';

    this.setState({
      [name]: event.target.value,
      errorMsg: errorMsg,
    });
  }

  handleChangeNumber(event) {
    const target = event.target;
    const name = target.name;

    const errorMsg = Object.assign({}, this.state.errorMsg);
    errorMsg[name] = '';

    this.setState({
      [name]: Math.max(
        0,
        parseInt(!!event.target.value ? event.target.value : 0)
      )
        .toString()
        .slice(0, 2),
      errorMsg: errorMsg,
    });
  }

  // InformDialog閉じるボタン
  informDialogCloseEvent() {
    this.setState({
      informDialogOpen: false,
    });
    if (!!this.state.redirectTo) {
      this.props.history.push(this.state.redirectTo);
    }
  }

  // 入力チェック
  validateForm() {
    let hasError = false;

    // const errorMsg = Object.assign({}, this.state.errorMsg);

    if (!this.state.customerName) {
      this.state.errorMsg.customerName = 'Please input customer name.';
      hasError = true;
    }

    if (!this.state.customerEmail) {
      // this.state.errorMsg.customerEmail = 'Please input email address.';
      // hasError = true;
    } else {
      const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.state.customerEmail.match(emailFormat)) {
        this.state.errorMsg.customerEmail =
          'Please input a valid email address.';
        hasError = true;
      }
    }

    // if (!this.state.customerTel) {
    //   this.state.errorMsg.customerTel = "Please input contact number.";
    //   hasError = true;
    // }

    if (!this.state.contractDateFrom) {
      this.state.errorMsg.contractDateFrom = 'Please input pickup date.';
      hasError = true;
    }

    // if (!this.state.contractDateTo) {
    //   this.state.errorMsg.contractDateTo = 'Please input end of service date.';
    //   hasError = true;
    // }

    if (!!this.state.contractDateFrom && !!this.state.contractDateTo) {
      if (this.state.contractDateFrom > this.state.contractDateTo) {
        this.state.errorMsg.contractDateFrom =
          'Pickup date should be smaller than the end of service date.';
        hasError = true;
      }
    }

    // PickupMethod
    if (!this.state.pickupMethodId) {
      this.state.errorMsg.pickupMethodId = 'Please choose pickup method.';
      hasError = true;
    } else if (
      this.state.pickupMethodId == 21 &&
      !this.state.pickupMethodAddress
    ) {
      this.state.errorMsg.pickupMethodAddress = 'Please input pickup address.';
      hasError = true;
    }

    if (!this.state.planId) {
      this.state.errorMsg.planId = 'Please choose your plan.';
      hasError = true;
    }

    if (!this.state.quantity) {
      this.state.errorMsg.quantity = 'Please input your quantity.';
      hasError = true;
    }

    if (!this.state.voucher) {
      this.state.errorMsg.voucher = 'Please input your voucher.';
      hasError = true;
    }

    if (!this.state.arrivalTime) {
      this.state.errorMsg.arrivalTime = 'Please input your arrival time.';
      hasError = true;
    }

    // this.setState({ errorMsg: errorMsg });

    return hasError;
  }

  // 保存ボタン押下時の処理
  handleClickSave() {
    // 入力チェック
    if (this.validateForm()) {
      let errorMsg = 'Error(s) occurs.';
      for (const [key, value] of Object.entries(this.state.errorMsg)) {
        if (!!value) {
          errorMsg += '\r\n' + value;
        }
      }

      this.setState({
        informDialogOpen: true,
        informDialogTitle: 'Regist Error',
        informDialogMessage: errorMsg,
      });
      return;
    } else {
      for (const [key, value] of Object.entries(this.state.errorMsg)) {
        this.state.errorMsg[key] = '';
      }
    }
    // 新規作成時
    const data = {};
    data.customerName = this.state?.customerName;
    data.customerEmail = this.state?.customerEmail;
    data.customerTel = this.state?.customerTel;
    data.contractDateFrom = this.state?.contractDateFrom;
    data.contractDateTo = this.state?.contractDateTo;
    data.pickupMethodId = this.state?.pickupMethodId;
    data.pickupMethodAddress = this.state?.pickupMethodAddress;
    data.planId = this.state?.planId;
    data.productType = this.state?.productType;
    data.quantity = this.state?.quantity;
    data.voucher = this.state?.voucher;
    data.arrivalTime = this.state?.arrivalTime;
    // this.state.bookingStatus = constVal.booking_status.WaitForPickup;
    if (this.state.bookingStatus === 0) {
      this.createBooking(data);
    } else {
      data.bookingId = this.state?.bookingId;
      this.updateBooking(data);
    }
  }

  createBooking(data) {
    fetch('/api/agency/createBooking', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // 成功
        if (responseJson.bookingId !== undefined) {
          this.setState({
            informDialogOpen: true,
            informDialogTitle: 'Create Complete',
            informDialogMessage:
              'Create new booking successfully Done.Apply Number [' +
              responseJson.applyNumber +
              ']',
          });
        }
        // エラー
        else {
          this.setState({
            informDialogOpen: true,
            informDialogTitle: 'Save Error',
            informDialogMessage: 'Save failed!!.error message',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateBooking(data) {
    // 保存API
    fetch('/api/agency/updateBooking', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // 成功
        if (responseJson.error === undefined) {
          this.setState({
            informDialogOpen: true,
            informDialogTitle: 'Update Complete',
            informDialogMessage:
              'Update booking successfully Done.Apply Number [' +
              this.state.applyNumber +
              ']',
          });
        }
        // エラー
        else {
          this.setState({
            informDialogOpen: true,
            informDialogTitle: 'Update Error',
            informDialogMessage:
              'Update booking failed!!' + '\r\n' + responseJson.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Cancelボタン押下時の処理
  handleClickCancel() {
    // 保存API
    const data = {};

    data.bookingId = parseInt(this.state.bookingId);
    data.bookingStatus = parseInt(constVal.booking_status.Cancelled);

    fetch('/api/updateBookingStatus', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // 成功
        if (responseJson.error === undefined) {
          this.setState({
            informDialogOpen: true,
            informDialogTitle: 'Cancel Booking',
            informDialogMessage:
              'The booking is successfully cancelled! Apply Number [' +
              this.state.applyNumber +
              ']',
            redirectTo: !!this.props.isAgency
              ? '/agency/searchBooking'
              : '/searchBooking',
          });
        }
        // エラー
        else {
          this.setState({
            informDialogOpen: true,
            informDialogTitle: 'Cancel Error',
            informDialogMessage:
              'Booking cancellation failed!!' + responseJson.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Device有効数警告ダイアログで「Continue」押下時の処理
  confirmDialogCloseEventContinue() {
    this.setState({
      confirmDialogContinue: true,
      confirmDialogOpen: false,
      confirmDialogTitle: '',
      confirmDialogMessage: '',
    });
    this.handleClickSave();
  }

  // Device有効数警告ダイアログで「Cancel」押下時の処理 -> 無いもしない
  confirmDialogCloseEventCancel() {
    this.setState({
      confirmDialogContinue: false,
      confirmDialogOpen: false,
      confirmDialogTitle: '',
      confirmDialogMessage: '',
    });
  }

  render() {
    return (
      <div align="center">
        <Menu history={this.props.history} />
        <ScreenTitle title={this.props.selectedMenuName} />

        <MiraiTextField
          handleChange={this.handleChange}
          readOnly={true}
          errorText=""
          label="Agency Name"
          value={this.state.userName}
          required
        />
        <br />
        <MiraiTextField
          handleChange={this.handleChange}
          id="voucher"
          name="voucher"
          label="Voucher"
          value={this.state.voucher}
          errorText={this.state.errorMsg.voucher}
          required
        />
        <br />
        <MiraiTextField
          handleChange={this.handleChange}
          readOnly={this.state.readOnlyProps.customerName}
          id="customerName"
          name="customerName"
          label="Customer Name"
          value={this.state.customerName}
          errorText={this.state.errorMsg.customerName}
          required
        />
        <br />
        <CommonRadio
          visible={true}
          readOnly={false}
          radioList={this.state.productTypeList}
          onChangeEvent={this.handleChange}
          name={'productType'}
          caption={'Product Type'}
          radioValue={this.state.productType}
          required
        />
        <br />

        <CommonSelect
          readOnlyLabel={''}
          readOnly={this.state.readOnlyProps.planId}
          caption={'Plan'}
          name={'planId'}
          selectValue={this.state.planId}
          onChangeEvent={this.handleChange}
          selectList={this.state.planList}
          errorText={this.state.errorMsg.planId}
          required
        />
        <br />

        <CommonSelect
          readOnlyLabel={''}
          readOnly={this.state.readOnlyProps.pickupMethodId}
          caption={'Pickup method'}
          name={'pickupMethodId'}
          selectValue={this.state.pickupMethodId}
          onChangeEvent={this.handleChange}
          selectList={this.state.pickupMethodTable}
          errorText={this.state.errorMsg.pickupMethodId}
          required
        />
        <br />
        {this.state?.pickupMethodId == 21 && (
          <MiraiTextField
            visible={this.state.visibleProps.pickupMethodAddress}
            handleChange={this.handleChange}
            readOnly={this.state.readOnlyProps.pickupMethodAddress}
            id="pickupMethodAddress"
            name="pickupMethodAddress"
            label="Pickup Address"
            value={this.state.pickupMethodAddress}
            errorText={this.state.errorMsg.pickupMethodAddress}
            required
          />
        )}
        <br />
        {/* <MiraiTextField handleChange={this.handleChange} readOnly={this.state.readOnlyProps.customerAddress} id="customerAddress" name="customerAddress" label="Address" value={this.state.customerAddress} errorText={this.state.errorMsg.customerAddress} /> */}

        <MiraiTextField
          handleChange={this.handleChange}
          readOnly={this.state.readOnlyProps.customerTel}
          id="customerTel"
          name="customerTel"
          label="Contact number"
          value={this.state.customerTel}
          errorText={this.state.errorMsg.customerTel}
        />
        <br />
        <MiraiTextField
          handleChange={this.handleChange}
          readOnly={this.state.readOnlyProps.customerEmail}
          id="customerEmail"
          name="customerEmail"
          label="Email address"
          value={this.state.customerEmail}
          errorText={this.state.errorMsg.customerEmail}
        />
        <br />
        <MiraiTextField
          handleChange={this.handleChangeNumber}
          id="quantity"
          name="quantity"
          label="Quantity"
          value={this.state.quantity}
          errorText={this.state.errorMsg.quantity}
          inputProps={{ min: 1, max: 99, step: 1 }}
          type="number"
          required
        />
        <br />
        <MiraiTextField
          handleChange={this.handleChange}
          id="contractDateFrom"
          name="contractDateFrom"
          label="Pickup date"
          value={this.state.contractDateFrom}
          errorText={this.state.errorMsg.contractDateFrom}
          type="date"
          InputLabelProps={{ shrink: true }}
          required
        />

        <br />
        <MiraiTextField
          handleChange={this.handleChange}
          id="contractDateTo"
          name="contractDateTo"
          label="End of service date"
          value={this.state.contractDateTo}
          errorText={this.state.errorMsg.contractDateTo}
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        <br />
        {/* <MiraiTextField
          handleChange={this.handleChange}
          id="arrivalTime"
          name="arrivalTime"
          label="Arrival time"
          value={this.state.arrivalTime}
          errorText={this.state.errorMsg.arrivalTime}
          type="time"
          InputLabelProps={{ shrink: true }}
          required
        /> */}

        <CommonSelect
          readOnlyLabel={''}
          caption={'Arrival time'}
          name={'arrivalTime'}
          selectValue={this.state.arrivalTime}
          onChangeEvent={this.handleChange}
          selectList={[
            ...Array(24)
              .keys()
              .map((item) => {
                const hours = (item.toString().length < 2 && `0`) + item;
                const time = `${hours}:00`;
                return { id: time, label: time };
              }),
          ]}
          errorText={this.state.errorMsg.arrivalTime}
          required
        />
        <br />
        <br />
        <CommonButton
          style={{ marginTop: '40px' }}
          visible={this.state.visibleProps.saveButton}
          clickEvent={this.handleClickSave}
          label="Save"
        />
        <CommonButton
          style={{ marginTop: '40px' }}
          visible={this.state.visibleProps.cancelButton}
          clickEvent={this.handleClickCancel}
          label="Cancel"
        />

        <InformDialog
          informDialogStatus={this.state.informDialogOpen}
          informDialogTitle={this.state.informDialogTitle}
          informDialogMessage={this.state.informDialogMessage}
          informDialogCloseEvent={this.informDialogCloseEvent}
        />
        <ConfirmDialog
          confirmDialogStatus={this.state.confirmDialogOpen}
          confirmDialogTitle={this.state.confirmDialogTitle}
          confirmDialogMessage={this.state.confirmDialogMessage}
          confirmDialogCloseEventCancel={this.confirmDialogCloseEventCancel}
          confirmDialogCloseEventContinue={this.confirmDialogCloseEventContinue}
        />

        <UpdateLogDialog
          showUpdateLogDialog={this.state.showUpdateLogDialog}
          closeUpdateLogDialogEvent={this.closeUpdateLogDialogEvent}
          selectedApplyNumber={this.state.selectedApplyNumber}
          selectedBookingId={this.state.selectedBookingId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedMenuName: state.menu.selectedMenuName,
    id: state.login.id,
    userName: state.login.userName,
    admin: state.login.admin,
    isAgency: state.login.isAgency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBooking);
