import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import DeviceAssignmentDialogList from './DeviceAssignmentDialogList';
import DeviceAssignmentDialogAvailableList from './DeviceAssignmentDialogAvailableList';
import Alert from '@material-ui/lab/Alert';
import MiraiTextField from '../../../common/mirai_component/MiraiTextField';
import CommonRadio from '../../../common/CommonRadio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';



class DeviceAssignmentDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData          : false,
      pickupMethodId      : 0,
      bookingDetail       : [],
      availableDevices    : [],
      rowSelection        : '',
      selectedRowId       : 0,
      hasError            : false,
      errorMsg            : '',
      deviceNumber        : '',
      corporateName       : '',
      routerTypeId        : 9,
      routerTypeList      : [
        { id : '1' , label : 'WorldWide' },
        { id : '2' , label : 'VietNam'   },
        { id : '3' , label : 'Japan'     },
        { id : '9' , label : 'any'       },
      ],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.doSearchAvailableDevice = this.doSearchAvailableDevice.bind(this);
    this.onSelectDevice = this.onSelectDevice.bind(this);
    this.onResetClick = this.onResetClick.bind(this);
    this.handleResetSelectedDevice = this.handleResetSelectedDevice.bind(this);

  }



  // CLOSEイベント（保存しない）
  handleClose() {

    // 当画面での変更を全て破棄する
    // orgに戻す
    // 同じオブジェクトを親画面で参照しているので、ここでCLOSEしたのにその変更が有効になってしまうと困るので
    for ( let i=0;i<this.state.bookingDetail.length;i++ ) {
      this.state.bookingDetail[i].deviceNumber             = this.state.bookingDetail[i].deviceNumberOrg;
      this.state.bookingDetail[i].selectedDevices          = this.state.bookingDetail[i].selectedDevicesOrg;
      this.state.bookingDetail[i].selectedDevicesUpdatedAt = this.state.bookingDetail[i].selectedDevicesOrgUpdatedAt;
    }

    this.setState({
      loadedData:false,
    });
    this.props.closeDeviceAssignmentDialogEvent(false);

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  // 選択DeviceのリセットPopupからの戻りイベント
  handleResetSelectedDevice(rowNum,pupupSelectedDeviceList){

    // Cancelが押下されたら何もしない
    if ( rowNum === -1 ) {
      return;
    }

    let bookingDetail = this.state.bookingDetail;

    for ( let i=0;i<bookingDetail.length;i++ ) {
      if ( bookingDetail[i].rowNum === rowNum) {

        let selectedDevices = bookingDetail[i].selectedDevices;
        let selectedDevicesUpdatedAt = bookingDetail[i].selectedDevicesUpdatedAt;

        let newDeviceNumber = '';
        let newSelectedDevices = [];
        let newSelectedDevicesUpdatedAt = [];

        for ( let j=0;j<selectedDevices.length;j++ ) {
          // 新規リストに存在するか？
          let exist = false;
          for ( let k=0;k<pupupSelectedDeviceList.length;k++ ) {
            if ( selectedDevices[j] === pupupSelectedDeviceList[k] ) {
              exist = true;
              break;
            }
          }
          if ( exist ) {
            if ( newDeviceNumber !== '' ) {
              newDeviceNumber += ' / ';
            }
            newDeviceNumber += selectedDevices[j];
            newSelectedDevices.push(selectedDevices[j]);
            newSelectedDevicesUpdatedAt.push(selectedDevicesUpdatedAt[j]);
          }
        }

        bookingDetail[i].deviceNumber = newDeviceNumber;
        bookingDetail[i].selectedDevices = newSelectedDevices;
        bookingDetail[i].selectedDevicesUpdatedAt = newSelectedDevicesUpdatedAt;

        break;

      }
    }

    this.setState({
      bookingDetail:bookingDetail
    });

  }



  componentWillUpdate(nextProps,nextState){

    if ( !this.state.loadedData && nextProps.bookingDetail.length > 0 && nextProps.showDeviceAssignmentDialog === true ) {

      let bookingDetail = [];

      let rowNum = 0;
      // Statusが利用中より進んでいる場合は非表示にする
      for ( let i=0;i<nextProps.bookingDetail.length;i++ ) {
        if ( nextProps.bookingDetail[i].bookingSubStatus === undefined || 
             nextProps.bookingDetail[i].bookingSubStatus === '' || 
             nextProps.bookingDetail[i].bookingSubStatus < 600 ) {

          nextProps.bookingDetail[i].rowNum = ++rowNum;
          nextProps.bookingDetail[i].reset = 'reset';
          bookingDetail.push(nextProps.bookingDetail[i]);

        }
      }

      this.setState({
        bookingDetail    : bookingDetail,
        pickupMethodId   : nextProps.pickupMethodId,
        availableDevices : [],
        hasError          : false,
        errorMsg          : '',
      });

      this.state.loadedData = true;

    }

  }



  // 利用可能なDeviceを検索する
  doSearchAvailableDevice(){

    let rowId = this.state.rowSelection;
    if ( rowId === undefined || rowId === '' ) {
      return;
    }
    rowId = parseInt(rowId) -1;

    let destinationId = this.state.bookingDetail[rowId].destinationId;

    const searchCriteria = {
      destination_id   : destinationId,
      pickup_method_id : this.state.pickupMethodId,
      deviceNumber     : this.state.deviceNumber,
      routerTypeId     : this.state.routerTypeId,
      corporateName    : this.state.corporateName,
    };

    fetch('/api/searchAvailableDevice', {
      method: "POST",
      body: JSON.stringify(searchCriteria), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let availableDevices = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let availableDevicesRow = {};
        availableDevicesRow.row_id = (i+1);
        availableDevicesRow.model = responseJson[i]['m90_model']['model'];
        availableDevicesRow.router_type = responseJson[i]['m90_model']['m50_router_type']['router_type'];
        availableDevicesRow.device_number = responseJson[i]['device_number'];
        availableDevicesRow.location = responseJson[i]['m80_location']['location'];
        availableDevicesRow.deviceFor = responseJson[i]['m20_corporate']===null? '' : responseJson[i]['m20_corporate']['corporate_name'];
        availableDevicesRow.select = 'select';
        availableDevicesRow.updatedAt = responseJson[i]['updatedAt'];
        availableDevices.push(availableDevicesRow);
      }

      this.setState({
        availableDevices:availableDevices,
        selectedRowId:rowId
      })

    });

  }



  onResetClick(event){

    let cellIndex = event.target.cellIndex;

    // Reset押下時
    if ( cellIndex == 6 ) {

      let bookingDetail = this.state.bookingDetail;
      let rowNum = event.target.children[0].value;

      for ( let i=0;i<bookingDetail.length;i++ ) {
        if ( parseInt(bookingDetail[i].rowNum) === parseInt(rowNum) ) {
          bookingDetail[i].deviceNumber = '';
          bookingDetail[i].selectedDevices = [];
          bookingDetail[i].selectedDevicesUpdatedAt = [];
        }
      }

      this.setState({
        bookingDetail:bookingDetail
      });

    }

  }



  onSelectDevice(event){

    let cellIndex = event.target.cellIndex;

    // Select押下時
    if ( cellIndex == 6 ) {

      let deviceNumber = event.target.children[0].value;
      let availableDevices = [];
      let bookingDetail = this.state.bookingDetail;
      let rowId = 0;

      // 既にQuantity分選択されている場合は何もしない
      if ( bookingDetail[this.state.selectedRowId].selectedDevices.length === parseInt(bookingDetail[this.state.selectedRowId].quantity) ) {
        return;
      }

      for ( let i=0;i<this.state.availableDevices.length;i++ ) {
        if ( this.state.availableDevices[i].device_number == deviceNumber ) {
          let newSelectedDeviceNumber = deviceNumber;
          if ( bookingDetail[this.state.selectedRowId].selectedDevices.length > 0 ) {
            newSelectedDeviceNumber = ' / ' + newSelectedDeviceNumber;
          }
          bookingDetail[this.state.selectedRowId].deviceNumber = bookingDetail[this.state.selectedRowId].deviceNumber + newSelectedDeviceNumber;
          bookingDetail[this.state.selectedRowId].selectedDevices.push(deviceNumber);
          bookingDetail[this.state.selectedRowId].selectedDevicesUpdatedAt.push(this.state.availableDevices[i].updatedAt);
        }
        else {
          let availableDevicesRow = this.state.availableDevices[i];
          availableDevicesRow.row_id = ++rowId;
          availableDevices.push(availableDevicesRow);
        }
      }

      this.setState({
        availableDevices:availableDevices,
        bookingDetail:bookingDetail
      });

    }
  
  }


  // 保存ボタン押下時
  handleClickSave(event) {
 
    let bookingDetail = this.state.bookingDetail;
    for ( let i=0;i<bookingDetail.length;i++ ) {
      if ( bookingDetail[i].selectedDevices.length !== 0 ) {
        if ( bookingDetail[i].selectedDevices.length !== parseInt(bookingDetail[i].quantity) ) {
          this.setState({
            hasError:true,
            errorMsg:'Please Select Device(s) for Line#' + (i+1),
          });
          return;
        }
      }
    }

    this.setState({
      loadedData:false,
    });
    this.props.closeDeviceAssignmentDialogEvent(true);

  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickSave}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Save</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    const alert = this.state.hasError ? <Alert severity="error">{this.state.errorMsg}</Alert> : '';

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showDeviceAssignmentDialog}
          onRequestClose={this.handleClose}
          contentStyle={{width: "1050px", maxWidth: "none" , height:"1200px" , maxHeight:"none"}}
          >
          <DialogTitle id="alert-dialog-slide-title">Device Assignment</DialogTitle>
          <DialogContent className="MyDialogContent">
            <DeviceAssignmentDialogList handleResetSelectedDevice={this.handleResetSelectedDevice} dataRows={this.state.bookingDetail} handleChange={this.handleChange} rowSelectionVal={this.state.rowSelection} onCellClickEvent={this.onResetClick} />

            <MiraiTextField width={200} handleChange={this.handleChange}  id="deviceNumber"   name="deviceNumber"   label="Device Number"  value={this.state.deviceNumber} errorText={''} />
            <br />
            <MiraiTextField width={200} handleChange={this.handleChange}  id="corporateName"   name="corporateName"   label="Device For"     value={this.state.corporateName} errorText={''} />
              <br />
            <CommonRadio visible={true} readOnly={false}  radioList={this.state.routerTypeList}   onChangeEvent={this.handleChange}   name={'routerTypeId'}   caption={'Router Type'}   radioValue={this.state.routerTypeId} />
            <br />
            <RaisedButton label="Search Available Device" onClick={this.doSearchAvailableDevice} style={{marginTop:"5px"}}/>
            <br />
            <DeviceAssignmentDialogAvailableList dataRows={this.state.availableDevices} onCellClickEvent={this.onSelectDevice}/>
            <br />
            {alert}
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );

  }

}

export default DeviceAssignmentDialog;