/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from '@material-ui/core/Link';

export default function SelectedDeviceResetPopupItem(param) {

  return (
    <div>
    <Link
      component="button"
      variant="body2"
      onClick={() => {
        param.removeDeviceEvent(param.deviceNumber);
      }}
    >
      {param.deviceNumber}
    </Link>
    </div>
  );
}