import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from '@material-ui/core/TextField';
import CorporateList from './CorporateList';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';


class SearchCorporate extends Component {

  constructor(props) {

    super(props);

    this.state = {
      open          : false ,
      corporateName : '',
      telNumber     : '',
      dataRows      : []
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.doSearchCorporate = this.doSearchCorporate.bind(this);
    this.onSelectCorporate = this.onSelectCorporate.bind(this);

  }


  handleOpen() {
    this.setState({open: true});
  }


  handleClose() {
    this.setState({open: false});
  }


  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  // 検索ボタン押下時
  doSearchCorporate(event) {

    // 会社検索
    fetch('/api/searchCorporate', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      for ( let i=0;i<responseJson.length;i++ ) {
        responseJson[i].longTermUser = responseJson[i].is_long_term_user === 0 ? '' : 'Long Term Customer';
      }

      this.setState({
        dataRows:responseJson
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }


  onSelectCorporate(corporateId){

    this.handleClose();
    this.props.onSelectCorporateEvent(corporateId);

  }


  render() {

    const actions = [
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px",marginLeft:"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
          {this.props.readOnly ? '' : <RaisedButton style={{marginTop:"23px"}} label="Search" onClick={this.handleOpen} />}
          <Dialog
            actions={actions}
            modal={false}
            open={this.state.open}
            onRequestClose={this.handleClose}
            contentStyle={{width: "1050px", maxWidth: "none" , height:"1200px" , maxHeight:"none"}}
            >
            <DialogTitle id="alert-dialog-slide-title" style={{backgroundColor : '#b0c4de', paddingTop :'0.2em','height':'1em'}} >Search Corporate</DialogTitle>
            <DialogContent className="MyDialogContent">
              <TextField id="corporateName" name="corporateName" label="Corporate Name" onChange={this.handleChange} /><br />
              <TextField id="telNumber"     name="telNumber"     label="Tel Number"     onChange={this.handleChange} />
              <RaisedButton label="Search" onClick={this.doSearchCorporate} style={{marginTop:"5px"}}/>
              <CorporateList dataRows={this.state.dataRows} onSelectCorporateEvent={this.onSelectCorporate} />
            </DialogContent>
          </Dialog>
      </MuiThemeProvider>
    );

  }
}


export default SearchCorporate;