import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

const columns = [
  { id: 'row_id',        label: '#',                  minWidth: 20  },
  { id: 'device_number', label: 'Device\u00a0Number', minWidth: 100 },
  { id: 'battery',       label: 'Battery',            minWidth: 50  },
  { id: 'bag',           label: 'Bag',                minWidth: 50  },
  { id: 'recharger',     label: 'Recharger',          minWidth: 50  },
  { id: 'device',        label: 'Device',             minWidth: 50  },
];


let rows = [
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  applyNumber : {
    "text-decoration": "underline"
  }
});


export default function PackingDeviceDialogList(pPrams) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const params = pPrams;
  rows = params.dataRows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.row_id}>
                  {columns.map(column => {
                    const value = row[column.id];
                    if ( column.id === 'row_id' || column.id === 'device_number' ) {
                      return (
                        <TableCell key={column.id} align={column.align} onClick={params.onCellClickEvent} >
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    }
                    else {
                      const id = row.row_id + "_" + column.id;
                      return (
                        <TableCell key={column.id} align={column.align} onClick={params.onCellClickEvent} >
                          <Checkbox value="1" id={id} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}