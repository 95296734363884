import React, { Component } from 'react';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  labelStyle: {
    'text-align'  : 'left' ,
    'font-weight' : 'bold'
  },
}));


export default function BookingStatusRadio(pPrams) {

  const classes = useStyles();
  const params = pPrams;

  return (
    <FormControl>
      <FormLabel component="legend" className={classes.labelStyle}>Bookin Status</FormLabel>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          control={<Checkbox 
            name="bookinStatus100"
            color="primary" 
            onChange={params.onChangeEvent}
            value="100"
          />}
          label="Booked"
          labelPlacement="end"
        />
        <FormControlLabel
          control={<Checkbox 
            name="bookinStatus200"
            color="primary" 
            onChange={params.onChangeEvent}
            value="200"
          />}
          label="Customre Contacted"
          labelPlacement="end"
        />
        <FormControlLabel
          control={<Checkbox 
            name="bookinStatus300"
            color="primary" 
            onChange={params.onChangeEvent}
            value="300"
          />}
          label="Device Assigned"
          labelPlacement="end"
        />
        <FormControlLabel
          control={<Checkbox 
            name="bookinStatus400"
            color="primary" 
            onChange={params.onChangeEvent}
            value="400"
          />}
          label="Ready For Send"
          labelPlacement="end"
        />
        <FormControlLabel
          control={<Checkbox 
            name="bookinStatus500"
            color="primary" 
            onChange={params.onChangeEvent}
            value="500"
          />}
          label="Wait For Pickup"
          labelPlacement="end"
        />
        <FormControlLabel
          control={<Checkbox 
            name="bookinStatus600"
            color="primary" 
            onChange={params.onChangeEvent}
            value="600"
          />}
          label="Using"
          labelPlacement="end"
        />
        <FormControlLabel
          control={<Checkbox 
            name="bookinStatus700"
            color="primary" 
            onChange={params.onChangeEvent}
            value="700"
          />}
          label="Done"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );

}