import React, { Component } from 'react';
import Link from '@material-ui/core/Link';

import MenuGW from '../../../common/MenuGW';
import ScreenTitle from '../../../common/ScreenTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import MiraiTextField from '../../../common/mirai_component/MiraiTextField';
import SearchCorporate from '../../../common/SearchCorporate';

import CommonSelect from '../../../common/CommonSelect'
import CommonButton from '../../../common/mirai_component/CommonButton'
import InformDialog from '../../../common/mirai_component/InformDialog'
import ConfirmDialog from '../../../common/mirai_component/ConfirmDialog'

import {connect} from 'react-redux';
import * as constVal from '../../../common/constant';
import CommonRadio from '../../../common/CommonRadio';
import * as commonFunction from '../../../common/commonFunction';
import UploadFile from './UploadFile'
import ChangeStatusDialog from './ChangeStatusDialog'



class entryInvoice extends Component {

  constructor(props) {

    super(props);

    this.state = {
      invoiceId              : null,
      invoiceDate            : '',
      paymentDueDate         : '',
      paymentStatus          : 0,
      userId                 : -1,
      corporateId            : '',
      corporateName          : '',
      paymentMethod          : '',
      invoiceNumber          : '',
      corporate_list         : [],
      serviceType            : '',
      item                   : '',
      itemDetail             : '',
      quantity               : 0,
      cost                   : 0,
      totalCost              : 0,
      currency               : '',
      totalPrice             : 0,
      file1                  : null,
      file2                  : null,
      file3                  : null,
      file4                  : null,
      file5                  : null,
      uploadFileName1        : '',
      uploadFileName2        : '',
      uploadFileName3        : '',
      uploadFileName4        : '',
      uploadFileName5        : '',
      uploadFilePath1        : '',
      uploadFilePath2        : '',
      uploadFilePath3        : '',
      uploadFilePath4        : '',
      uploadFilePath5        : '',
      file1Uploaded          : false,
      file2Uploaded          : false,
      file3Uploaded          : false,
      file4Uploaded          : false,
      file5Uploaded          : false,
      file1Selected          : false,
      file2Selected          : false,
      file3Selected          : false,
      file4Selected          : false,
      file5Selected          : false,
      proofFileName          : '',
      proofFilePath          : '',
      updatedAt              : '',
      userList               : [],
      currencyRateTable      : [],
      showChangeStatusDialog : false,
      saveButtonLabel        : 'Entry',
      informDialogOpen       : false,
      informDialogTitle      : '',
      informDialogMessage    : '',
      reloadData             : false,
      errorMsg : {
        invoiceDate     : '',
        paymentDueDate  : '',
        userId          : '',
        corporateId     : '',
        corporateName   : '',
        paymentMethod   : '',
        invoiceNumber   : '',
        serviceType     : '',
        item            : '',
        itemDetail      : '',
        quantity        : '',
        cost            : '',
        totalCost       : '',
        currency        : '',
        totalPrice      : '',
      },
      visibleProps : {
        saveButton         : true,
        changeStatusButton : false,
        deleteButton       : false,
      },
      readOnlyProps : {
        userId        : false,
        corporateId   : false,
        paymentMethod : false,
        invoiceNumber : false,
        serviceType   : false,
        item          : false,
        itemDetail    : false,
        quantity      : false,
        cost          : false,
        currency      : false,
      },
      paymentMethodList :[
        { id : '0' , label : 'Cash'},
        { id : '1' , label : 'Bank Transfer'},
        { id : '2' , label : 'Credit Card'},
        { id : '3' , label : 'International Transfer' }
      ],
      serviceTypeList : [
        { id : '0' , label : 'Postpaid'},
        { id : '1' , label : 'Prepaid'},
        { id : '2' , label : 'Wifi rental'},
        { id : '3' , label : 'Device +sim' },
        { id : '4' , label : 'etc' },
      ],
      currencyList :[
        { id : 'USD' , label : 'USD'},
        { id : 'JPY' , label : 'JPY'},
        { id : 'VND' , label : 'VND'},
      ],

    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClickChangeStatus = this.handleClickChangeStatus.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.closeChangeStatusDialogEvent = this.closeChangeStatusDialogEvent.bind(this);
    this.handleFileSelectButtonClick1 = this.handleFileSelectButtonClick1.bind(this);
    this.handleFileSelectButtonClick2 = this.handleFileSelectButtonClick2.bind(this);
    this.handleFileSelectButtonClick3 = this.handleFileSelectButtonClick3.bind(this);
    this.handleFileSelectButtonClick4 = this.handleFileSelectButtonClick4.bind(this);
    this.handleFileSelectButtonClick5 = this.handleFileSelectButtonClick5.bind(this);
    this.handleDownloadButtonClick = this.handleDownloadButtonClick.bind(this);
    this.handleDownloadButtonClick1 = this.handleDownloadButtonClick1.bind(this);
    this.handleDownloadButtonClick2 = this.handleDownloadButtonClick2.bind(this);
    this.handleDownloadButtonClick3 = this.handleDownloadButtonClick3.bind(this);
    this.handleDownloadButtonClick4 = this.handleDownloadButtonClick4.bind(this);
    this.handleDownloadButtonClick5 = this.handleDownloadButtonClick5.bind(this);
    this.handleDownloadButtonDelete1 = this.handleDownloadButtonDelete1.bind(this);
    this.handleDownloadButtonDelete2 = this.handleDownloadButtonDelete2.bind(this);
    this.handleDownloadButtonDelete3 = this.handleDownloadButtonDelete3.bind(this);
    this.handleDownloadButtonDelete4 = this.handleDownloadButtonDelete4.bind(this);
    this.handleDownloadButtonDelete5 = this.handleDownloadButtonDelete5.bind(this);
    this.handleViewButtonClick = this.handleViewButtonClick.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.calcTotal = this.calcTotal.bind(this);
    this.informDialogCloseEvent = this.informDialogCloseEvent.bind(this);

    const invoiceId = props.match.params.invoiceId;
    // 一覧からの遷移時
    if ( invoiceId !== undefined && invoiceId !== '' ) {
      this.state.invoiceId = invoiceId;
    }

  }



  componentDidMount(prevProps) {

    // 新規作成
    if ( this.state.invoiceId === null ) {

      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      let todayString = today.getFullYear() + "-" + ("0" + (today.getMonth()+1)).slice(-2) + "-" + ("0" + (today.getDate())).slice(-2)

      let thisMonthlastDate = new Date();
      thisMonthlastDate.setHours(0);
      thisMonthlastDate.setMinutes(0);
      thisMonthlastDate.setSeconds(0);
      thisMonthlastDate.setMilliseconds(0);
      thisMonthlastDate.setDate(1);
      thisMonthlastDate.setMonth(thisMonthlastDate.getMonth()+1);
      thisMonthlastDate.setDate(thisMonthlastDate.getDate()-1);

      let thisMonthlastDateString = thisMonthlastDate.getFullYear() + "-" + ("0" + (thisMonthlastDate.getMonth()+1)).slice(-2) + "-" + ("0" + (thisMonthlastDate.getDate())).slice(-2)

      const visibleProps = Object.assign({}, this.state.visibleProps);
      visibleProps.deleteButton = false;

      this.setState({
        invoiceDate    : todayString,
        paymentDueDate : thisMonthlastDateString,
        userId         : this.props.id,
        visibleProps   : visibleProps,
      })

    }
    else {

      let reqJson = {};
      reqJson.invoiceId = this.state.invoiceId;

      // Booking検索
      fetch('/api/searchInvoice', {
        method: "POST",
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqJson)
      })
      .then((response) => response.json())
      .then((responseJson) => {

        const file1Uploaded = responseJson[0].attach_file_name_1 !== '';
        const file2Uploaded = responseJson[0].attach_file_name_2 !== '';
        const file3Uploaded = responseJson[0].attach_file_name_3 !== '';
        const file4Uploaded = responseJson[0].attach_file_name_4 !== '';
        const file5Uploaded = responseJson[0].attach_file_name_5 !== '';

        let invoiceDate = new Date(responseJson[0].invoice_date);
        invoiceDate.setHours(0);
        invoiceDate.setMinutes(0);
        invoiceDate.setSeconds(0);
        invoiceDate.setMilliseconds(0);
        let invoiceDateString = invoiceDate.getFullYear() + "-" + ("0" + (invoiceDate.getMonth()+1)).slice(-2) + "-" + ("0" + (invoiceDate.getDate())).slice(-2)

        let paymentDueDate = new Date(responseJson[0].payment_due_date);
        paymentDueDate.setHours(0);
        paymentDueDate.setMinutes(0);
        paymentDueDate.setSeconds(0);
        paymentDueDate.setMilliseconds(0);
        let paymentDueDateString = paymentDueDate.getFullYear() + "-" + ("0" + (paymentDueDate.getMonth()+1)).slice(-2) + "-" + ("0" + (paymentDueDate.getDate())).slice(-2)

        const visibleProps = Object.assign({}, this.state.visibleProps);
        if ( this.props.admin === true ){
          visibleProps.deleteButton = true;
        }
        else {
          visibleProps.deleteButton = false;
        }

        if ( responseJson[0].payment_status === 0 ) {
          visibleProps.saveButton = true;
          visibleProps.changeStatusButton = true;
        }
        else if ( responseJson[0].payment_status === 1 ) {
          visibleProps.saveButton = true;
          visibleProps.changeStatusButton = true;
        }
        else if ( responseJson[0].payment_status === 2 ) {
          visibleProps.saveButton = false;
          visibleProps.changeStatusButton = false;
        }

        this.setState({
          invoiceId          : responseJson[0].invoice_id,
          invoiceDate        : invoiceDateString,
          paymentDueDate     : paymentDueDateString,
          userId             : responseJson[0].user_id,
          corporateId        : responseJson[0].corporate_id,
          corporateName      : !responseJson[0]['m20_corporate'] ? '' : responseJson[0]['m20_corporate'].corporate_name,
          paymentMethod      : responseJson[0].payment_method,
          paymentStatus      : responseJson[0].payment_status,
          invoiceNumber      : responseJson[0].invoice_number,
          serviceType        : responseJson[0].service_type,
          item               : responseJson[0].item,
          itemDetail         : responseJson[0].item_detail,
          quantity           : responseJson[0].quantity,
          cost               : responseJson[0].cost,
          totalCost          : responseJson[0].total_cost,
          currency           : responseJson[0].currency,
          totalPrice         : responseJson[0].total_price,
          uploadFileName1    : responseJson[0].attach_file_name_1,
          uploadFileName2    : responseJson[0].attach_file_name_2,
          uploadFileName3    : responseJson[0].attach_file_name_3,
          uploadFileName4    : responseJson[0].attach_file_name_4,
          uploadFileName5    : responseJson[0].attach_file_name_5,
          uploadFilePath1    : responseJson[0].attach_file_path_1,
          uploadFilePath2    : responseJson[0].attach_file_path_2,
          uploadFilePath3    : responseJson[0].attach_file_path_3,
          uploadFilePath4    : responseJson[0].attach_file_path_4,
          uploadFilePath5    : responseJson[0].attach_file_path_5,
          file1Uploaded      : file1Uploaded,
          file2Uploaded      : file2Uploaded,
          file3Uploaded      : file3Uploaded,
          file4Uploaded      : file4Uploaded,
          file5Uploaded      : file5Uploaded,
          file1Selected      : file1Uploaded,
          file2Selected      : file2Uploaded,
          file3Selected      : file3Uploaded,
          file4Selected      : file4Uploaded,
          file5Selected      : file5Uploaded,
          proofFileName      : responseJson[0].proof_file_name,
          proofFilePath      : responseJson[0].proof_file_path,
          updatedAt          : responseJson[0].updatedAt,
          visibleProps       : visibleProps,
          saveButtonLabel    : 'Update'
        });

      });
    
    }


    let requestParam = {};
    fetch('/api/getUserList',{
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      let userList = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let userListRow = {}
        userListRow.id = responseJson[i].id;
        userListRow.label = responseJson[i].name;
        userList.push(userListRow);
      }
      this.setState({
        userList : userList,
      });
    })
    .catch((error) =>{
      console.error(error);
    });


    fetch('/api/getAllCurrency', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let currencyRateTable = {};
      for ( let i=0;i<responseJson.length;i++ ) {

        let currency = responseJson[i].currency;
        if ( currencyRateTable[currency] === undefined || currencyRateTable[currency] === null || currencyRateTable[currency] === '' ) {
          currencyRateTable[currency] = responseJson[i].exchange_rate;
        }

      }

      this.setState({
        currencyRateTable : currencyRateTable
      })

    })
    .catch((error) =>{
      console.error(error);
    });

    fetch('/api/getAllCorporate', {
      method: "POST",
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let list = [];
      let row = {};

      for ( let i=0;i<responseJson.length;i++ ) {
        let row = {};
        row.id = responseJson[i].corporate_id;
        row.label = responseJson[i].corporate_name;
        list.push(row);
      }
      this.setState({
        corporate_list:list
      });

    })
    .catch((error) =>{
      console.error(error);
    });
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;

    const errorMsg = Object.assign({}, this.state.errorMsg);
    errorMsg[name] = '';

    this.setState({ 
      [name] : event.target.value ,
       errorMsg : errorMsg
    });

    this.state[name] = event.target.value;

  }


  // 会社選択時の処理
  onSelectCorporate(corporate_id) {

    const reqJson = { 'corporate_id' : corporate_id };

    // 会社検索
    fetch('/api/searchCorporateById', {
      method: "POST",
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqJson)
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if ( responseJson.length === 1 ) {

        this.setState({
          corporateId         : responseJson[0].corporate_id,
          corporateName       : responseJson[0].corporate_name,
        });

      }
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  // uploadファイル選択時
  handleFileSelectButtonClick1(event){

    var f = event.target.files[0]
    this.setState({
      uploadFileName1 : f.name,
      file1           : f,
      file1Selected   : true,
      file1Uploaded   : false,
    });

  }


  // uploadファイル選択時
  handleFileSelectButtonClick2(event){

    var f = event.target.files[0]
    this.setState({
      uploadFileName2 : f.name,
      file2           : f,
      file2Selected   : true,
      file2Uploaded   : false,
    });

  }


  // uploadファイル選択時
  handleFileSelectButtonClick3(event){

    var f = event.target.files[0]
    this.setState({
      uploadFileName3 : f.name,
      file3           : f,
      file3Selected   : true,
      file3Uploaded   : false,
    });

  }


  // uploadファイル選択時
  handleFileSelectButtonClick4(event){

    var f = event.target.files[0]
    this.setState({
      uploadFileName4 : f.name,
      file4           : f,
      file4Selected   : true,
      file4Uploaded   : false,
    });

  }


  // uploadファイル選択時
  handleFileSelectButtonClick5(event){

    var f = event.target.files[0]
    this.setState({
      uploadFileName5 : f.name,
      file5           : f,
      file5Selected   : true,
      file5Uploaded   : false,
    });

  }

  handleViewButtonClick(index){
    let filePath = this.state['uploadFilePath'+index];
    let fileName = this.state['uploadFileName'+index];
    let type= '';

    var saveAs = require('file-saver');
    let req = {}
    req.selectedFilePath = filePath;
    req.fileName = fileName;

    fetch('/api/viewAttachmentFile', {
      method: "POST",
      body: JSON.stringify(req), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then(function(response) {
        type = response.headers.get('content-type');
        return response.blob();
    })
    .then(blob => {
        var file = new Blob([blob], {type: type});
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    });

  }

  handleDownloadButtonClick(filePath,fileName){

    var saveAs = require('file-saver');
    let req = {}
    req.selectedFilePath = filePath;

    fetch('/api/downloadAttachmentFile', {
      method: "POST",
      body: JSON.stringify(req), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.blob())
    .then(blob => {
      saveAs(blob, fileName);
    });

  }

  handleDownloadButtonClick1(event){

    let filePath = this.state.uploadFilePath1;
    let fileName = this.state.uploadFileName1;
    this.handleDownloadButtonClick(filePath,fileName);

  }

  handleDownloadButtonClick2(event){

    let filePath = this.state.uploadFilePath2;
    let fileName = this.state.uploadFileName2;
    this.handleDownloadButtonClick(filePath,fileName);

  }

  handleDownloadButtonClick3(event){

    let filePath = this.state.uploadFilePath3;
    let fileName = this.state.uploadFileName3;
    this.handleDownloadButtonClick(filePath,fileName);

  }

  handleDownloadButtonClick4(event){

    let filePath = this.state.uploadFilePath4;
    let fileName = this.state.uploadFileName4;
    this.handleDownloadButtonClick(filePath,fileName);

  }

  handleDownloadButtonClick5(event){

    let filePath = this.state.uploadFilePath5;
    let fileName = this.state.uploadFileName5;
    this.handleDownloadButtonClick(filePath,fileName);

  }

  handleDownloadButtonDelete1(event){
    this.setState({
      uploadFileName1 : '',
      uploadFilePath1 : '',
      file1           : null,
      file1Selected   : false,
      file1Uploaded   : false,
    });
  }

  handleDownloadButtonDelete2(event){
    this.setState({
      uploadFileName2 : '',
      uploadFilePath2 : '',
      file2           : null,
      file2Selected   : false,
      file2Uploaded   : false,
    });
  }

  handleDownloadButtonDelete3(event){
    this.setState({
      uploadFileName3 : '',
      uploadFilePath3 : '',
      file3           : null,
      file3Selected   : false,
      file3Uploaded   : false,
    });
  }

  handleDownloadButtonDelete4(event){
    this.setState({
      uploadFileName4 : '',
      uploadFilePath4 : '',
      file4           : null,
      file4Selected   : false,
      file4Uploaded   : false,
    });
  }

  handleDownloadButtonDelete5(event){
    this.setState({
      uploadFileName5 : '',
      uploadFilePath5 : '',
      file5           : null,
      file5Selected   : false,
      file5Uploaded   : false,
    });
  }



  calcTotal(event){

    this.handleChange(event)

    let totalCost = parseInt(this.state.quantity) * parseInt(this.state.cost);

    let currency = this.state.currency;
    let totalPrice = Math.floor(totalCost / this.state.currencyRateTable[currency]);

    this.setState({
      totalCost  : totalCost,
      totalPrice : totalPrice,
    })

  }



  handleClickChangeStatus(event){

    this.setState({
      showChangeStatusDialog : true,
    });

  }



  closeChangeStatusDialogEvent(saved){

    this.setState({
      showChangeStatusDialog : false,
    });

    if ( saved ) {
      if ( this.state.invoiceId === null ) {
        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Entry',
          informDialogMessage:'Invoice date entry was succesfuly done.',
        });
      }
      else {
        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Update',
          informDialogMessage:'Invoice date update was succesfuly done.',
        });
      }
    }

  }


  //入力チェック
  validateForm(){

    let hasError = false;

    const errorMsg = Object.assign({}, this.state.errorMsg);

    if ( this.state.corporateName === '' ) {
      errorMsg.corporateName = 'Select corporate.';
      hasError = true;
    }
    
    if ( this.state.invoiceNumber === '' ) {
      errorMsg.invoiceNumber = 'Input invoice number.';
      hasError = true;
    }
    
    if ( this.state.paymentMethod === '' ) {
      errorMsg.paymentMethod = 'Select payment method.';
      hasError = true;
    }
    
    if ( this.state.item === '' ) {
      errorMsg.item = 'Input item.';
      hasError = true;
    }
    
    if ( this.state.serviceType === '') {
      errorMsg.serviceType = 'Select service type.';
      hasError = true;
    }
    
    if ( this.state.currency === '' ) {
      errorMsg.currency = 'Select currency.';
      hasError = true;
    }

    this.state.errorMsg.errList = errorMsg;
    this.setState({errorMsg:errorMsg});

    return hasError;

  }

  handleClickSave(event){
    // 入力チェック
    if ( this.validateForm() ) {
      let errorMsg = 'Error(s) occurs.';
      const listErrorMsg = this.state.errorMsg.errList;
      for (const index in listErrorMsg) {
        if ( listErrorMsg[index] !== '' ) {
          errorMsg += '\r\n' + listErrorMsg[index];
        }
      }
      this.setState({
        informDialogOpen:true,
        informDialogTitle:'Save Error',
        informDialogMessage:errorMsg,
      });
      return;
    }

    const formData = new FormData();
    formData.append('attachment1', this.state.file1);
    formData.append('attachment2', this.state.file2);
    formData.append('attachment3', this.state.file3);
    formData.append('attachment4', this.state.file4);
    formData.append('attachment5', this.state.file5);
    formData.append('fields', JSON.stringify(this.state));

    // 新規登録
    if ( this.state.invoiceId === null ) {

      fetch('/api/entryInvoiceData', {
        method: "POST",
        body: formData,
      })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Entry',
          informDialogMessage:'Invoice date entry was succesfuly done.',
          invoiceId : responseJson.invoice_id,
          reloadData : true,
        });

      })
      .catch((error) =>{
        console.error(error);
      });

    }
    else {

      fetch('/api/updateInvoiceData', {
        method: "POST",
        body: formData,
      })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          informDialogOpen:true,
          informDialogTitle:'Update',
          informDialogMessage:'Invoice date update was succesfuly done.',
          reloadData : true,
        });

      })
      .catch((error) =>{
        console.error(error);
      });

    }

  }


  handleClickCancel(event){

    fetch('/api/cancelInvoiceData', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      this.setState({
        informDialogOpen:true,
        informDialogTitle:'Cancel',
        informDialogMessage:'Invoice date cancel was succesfuly done.',
        invoiceId : responseJson.invoice_id,
        reloadData : true,
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }


  handleClickDelete(event){

    fetch('/api/deleteInvoiceData', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      this.setState({
        informDialogOpen:true,
        informDialogTitle:'Cancel',
        informDialogMessage:'Invoice date delete was succesfuly done.',
        invoiceId : responseJson.invoice_id,
        reloadData : true,
      });

    })
    .catch((error) =>{
      console.error(error);
    });

  }

  // InformDialog閉じるボタン
  informDialogCloseEvent(){

    this.setState({
      informDialogOpen:false
    });

    if ( this.state.reloadData ) {
      this.props.history.push('/entryInvoice/' + this.state.invoiceId);
    }

  }



  render() {

    return (
      <div align="center">
        <MenuGW history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />

        <TextField   label="Invoice Date"     onChange={this.handleChange}    id="invoiceDate"      name="invoiceDate"       value={this.state.invoiceDate}     style={{width: "400px"}} type="date" />
        <br />
        <TextField   label="Payment Due Date" onChange={this.handleChange}    id="paymentDueDate"   name="paymentDueDate"    value={this.state.paymentDueDate}  style={{width: "400px"}} type="date" />
        <br />
        <CommonSelect readOnly={this.state.readOnlyProps.userId} caption={"Person In Charge"} name={"userId"} onChangeEvent={this.handleChange} selectValue={this.state.userId} selectList={this.state.userList} />
        <br />
        <Autocomplete id="corporate-ac" freeSolo style={{ width: 660 }}
          options={this.state.corporate_list.map((option) => option.label)} 
          inputValue={this.state.corporateName}
          onInputChange={(event, newInputValue) => {
            this.handleChange({target:{name : 'corporateName', value : newInputValue}});
          }}
          renderInput={(params) => (
            <TextField {...params} label="Corporate Name" margin="normal" id="corporateName" name="corporateName"
            error={this.state.errorMsg.corporateName !== ""}
            helperText={this.state.errorMsg.corporateName} />
          )}
        />
        <br />
        <CommonRadio visible={true} readOnly={this.state.readOnlyProps.paymentMethod}  radioList={this.state.paymentMethodList}   onChangeEvent={this.handleChange}   name={'paymentMethod'}   caption={'Payment Method'}   radioValue={this.state.paymentMethod} />
        <br />
        <MiraiTextField visible={true} handleChange={this.handleChange} readOnly={this.state.readOnlyProps.invoiceNumber}  id="invoiceNumber"      name="invoiceNumber"       label="Invoice Number"     value={this.state.invoiceNumber}  errorText={this.state.errorMsg.invoiceNumber}/>
        <br />
        <br />
        <CommonSelect readOnly={this.state.readOnlyProps.serviceType} caption={"Service Type"} name={"serviceType"} onChangeEvent={this.handleChange} selectValue={this.state.serviceType} selectList={this.state.serviceTypeList} errorText={this.state.errorMsg.serviceType} />
        <br />
        <MiraiTextField visible={true} handleChange={this.handleChange} readOnly={this.state.readOnlyProps.item}       id="item"         name="item"         label="Item"         value={this.state.item}        errorText={this.state.errorMsg.item}/>
        <br />
        <MiraiTextField visible={true} handleChange={this.handleChange} readOnly={this.state.readOnlyProps.itemDetail}  id="itemDetail"  name="itemDetail"   label="Item Detail"  value={this.state.itemDetail}  errorText={this.state.errorMsg.itemDetail}/>
        <br />
        <br />
        <br />
        <br />
        <TextField   label="Quantity"    onChange={this.calcTotal}    id="quantity"   name="quantity"    value={this.state.quantity}   style={{width: "400px"}} type="number" />
        <br />
        <TextField   label="Cost"        onChange={this.calcTotal}    id="cost"       name="cost"        value={this.state.cost}       style={{width: "400px"}} type="number" />
        <br />
        <TextField   label="Total Cost"                                  id="totalCost"  name="totalCost"   value={this.state.totalCost}  style={{width: "400px"}} type="number" InputProps={{readOnly:true}} />
        <br />
        <CommonSelect readOnly={this.state.readOnlyProps.currency} caption={"Currency"}  name={"currency"}  onChangeEvent={this.calcTotal} selectValue={this.state.currency} selectList={this.state.currencyList} errorText={this.state.errorMsg.currency} />
        <br />
        <TextField   label="Total Price(VND)"                            id="totalPrice" name="totalPrice"  value={this.state.totalPrice} style={{width: "400px"}} type="number" InputProps={{readOnly:true}} />
        <br />
        <br />
        <br />
        <UploadFile fileId="file1" filedName="Attachment file(1)" fileSelectEvent={this.handleFileSelectButtonClick1} uploadFileName={this.state.uploadFileName1} handleClickView={() => {this.handleViewButtonClick(1)}}  handleClickDownload={this.handleDownloadButtonClick1} handleClickDelete={this.handleDownloadButtonDelete1} selected={this.state.file1Selected} download={this.state.file1Uploaded} />
        <UploadFile fileId="file2" filedName="Attachment file(2)" fileSelectEvent={this.handleFileSelectButtonClick2} uploadFileName={this.state.uploadFileName2} handleClickView={() => {this.handleViewButtonClick(2)}}  handleClickDownload={this.handleDownloadButtonClick2} handleClickDelete={this.handleDownloadButtonDelete2} selected={this.state.file2Selected} download={this.state.file2Uploaded} />
        <UploadFile fileId="file3" filedName="Attachment file(3)" fileSelectEvent={this.handleFileSelectButtonClick3} uploadFileName={this.state.uploadFileName3} handleClickView={() => {this.handleViewButtonClick(3)}}  handleClickDownload={this.handleDownloadButtonClick3} handleClickDelete={this.handleDownloadButtonDelete3} selected={this.state.file3Selected} download={this.state.file3Uploaded} />
        <UploadFile fileId="file4" filedName="Attachment file(4)" fileSelectEvent={this.handleFileSelectButtonClick4} uploadFileName={this.state.uploadFileName4} handleClickView={() => {this.handleViewButtonClick(4)}}  handleClickDownload={this.handleDownloadButtonClick4} handleClickDelete={this.handleDownloadButtonDelete4} selected={this.state.file4Selected} download={this.state.file4Uploaded} />
        <UploadFile fileId="file5" filedName="Attachment file(5)" fileSelectEvent={this.handleFileSelectButtonClick5} uploadFileName={this.state.uploadFileName5} handleClickView={() => {this.handleViewButtonClick(5)}}  handleClickDownload={this.handleDownloadButtonClick5} handleClickDelete={this.handleDownloadButtonDelete5} selected={this.state.file5Selected} download={this.state.file5Uploaded} />
        <br />
        <br />
        <br />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.saveButton}          clickEvent={this.handleClickSave}          label={this.state.saveButtonLabel} />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.changeStatusButton}  clickEvent={this.handleClickChangeStatus}  label="Change Status" />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.changeStatusButton}  clickEvent={this.handleClickCancel}        label="Cancel" />
        <CommonButton style={{marginTop:'40px'}} visible={this.state.visibleProps.deleteButton}        clickEvent={this.handleClickDelete}        label="Delete" />
        <br />
        <ChangeStatusDialog  proofFileName={this.state.proofFileName} proofFilePath={this.state.proofFilePath} updatedAt={this.state.updatedAt} showChangeStatusDialog={this.state.showChangeStatusDialog} closeChangeStatusDialogEvent={this.closeChangeStatusDialogEvent} paymentStatus={this.state.paymentStatus} invoiceId={this.state.invoiceId} />
        <InformDialog        informDialogStatus={this.state.informDialogOpen}   informDialogTitle={this.state.informDialogTitle}   informDialogMessage={this.state.informDialogMessage}  informDialogCloseEvent={this.informDialogCloseEvent} />
      </div>
    );

  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName,
    id               : state.login.id,
    admin            : state.login.admin,
  };
};



const mapDispatchToProps = dispatch => {
  return {
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(entryInvoice);
