import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";
import UpdateLogDialogList from "./UpdateLogDialogList";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

class UpdateLogDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.showUpdateLogDialog,
      loaded: false,
      dataRows: [],
    };

    this.handleClose = this.handleClose.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.showUpdateLogDialog &&
      this.state.dataRows.length === 0 &&
      !this.state.loaded
    ) {
      // query log data.
      this.state.loaded = true;

      const search_condition = {
        booking_id: nextProps.selectedBookingId,
      };
      fetch("/api/getBookingUpdateLog", {
        method: "POST",
        body: JSON.stringify(search_condition),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          let resultDate = [];
          for (let i = 0; i < responseJson.length; i++) {
            let resultRow = {};
            resultRow["id"] = responseJson[i]["id"];
            resultRow["customer_name"] = responseJson[i].m10_users[0].name;
            resultRow["date_time"] = new Date(
              responseJson[i]["update_timestamp"]
            ).toLocaleString();
            resultRow["action"] = responseJson[i]["action"];
            resultRow["update_contents"] = responseJson[i]["update_contents"];
            resultDate.push(resultRow);
          }

          this.setState({
            dataRows: resultDate,
          });
        });
    }
  }

  handleClose() {
    this.props.closeUpdateLogDialogEvent();
    this.setState({
      dataRows: [],
      loaded: false,
    });
  }

  render() {
    const actions = [
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{ width: "200px", marginLeft: "10px" }}
        startIcon={<CloseIcon />}
      >
        Close
      </Button>,
    ];

    return (
      <MuiThemeProvider>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.showUpdateLogDialog}
          onRequestClose={this.handleClose}
          contentStyle={{
            width: "1250px",
            maxWidth: "none",
            height: "1200px",
            maxHeight: "none",
          }}
        >
          <DialogTitle id="alert-dialog-slide-title">
            Booking update Log
          </DialogTitle>
          <DialogContent className="MyDialogContent">
            <UpdateLogDialogList dataRows={this.state.dataRows} />
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

export default UpdateLogDialog;
