import React, { Component } from 'react';
import Menu from '../../../../common/Menu';
import ScreenTitle from '../../../../common/ScreenTitle';

import CommonButton from '../../../../common/mirai_component/CommonButton'
import const_val from '../../../../const';
import CommonCheckbox from '../../../../common/CommonCheckbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputFile from '../../../../common/mirai_component/InputFile'

import MiraiTextField from '../../../../common/mirai_component/MiraiTextField';
import DeviceListTable from './DeviceListTable';
import DeviceEditDialog from './DeviceEditDialog';
import DeviceRentHistoryDialog from './DeviceRentHistoryDialog';

import {connect} from 'react-redux';


class DeviceMaster extends Component {

  constructor(props) {

    super(props);

    this.state = {
      device_status_param      : [],
      location_param           : [],
      location_list            : [],
      searchParamModelName     : '',
      searchParamDeviceNumber  : '',
      deviceList               : [],
      showEditDialog           : false,
      showHistoryDialog        : false,
      uploadFileName           : '',
      deviceListFile           : null,
      editDeviceNumber         : null,
      showDeviceNumber         : '',
      showHistoryDeviceNumber  : null,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleFileSelectButtonClick = this.handleFileSelectButtonClick.bind(this);
    this.handleClickUpload = this.handleClickUpload.bind(this);
    this.handleEditDialogClose = this.handleEditDialogClose.bind(this);
    this.handleShowHistoryDialogClose = this.handleShowHistoryDialogClose.bind(this);

  }

  componentDidMount(prevProps) {

    fetch('/api/getAllLocation', {
      method: "POST",
//      body: JSON.stringify({}),
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      let list = [];
      for ( let i=0;i<responseJson.length;i++ ) {
        let row = {};
        row.id = responseJson[i].location_id;
        row.label = responseJson[i].location;
        list.push(row);
      }
      this.setState({
        location_list:list
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }


  handleCheckboxChange(event) {

    const name = event.name;
    const checked_list = [];
    for(const each of event.list){
      if(each.checked){
        checked_list.push(each.id);
      }
    }
    this.setState({ [name] : checked_list});

  }


  // Device検索ボタン押下時の処理
  handleClickSearch(){

    let requestParam = {};
    requestParam.device_status_param = this.state.device_status_param;
    requestParam.location_param = this.state.location_param;
    requestParam.modelName = this.state.searchParamModelName;
    requestParam.deviceNumber = this.state.searchParamDeviceNumber;

    // Device検索
    fetch('/api/searchDevice', {
      method: "POST",
      body: JSON.stringify(requestParam), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      this.setState({
        deviceList:responseJson
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  // uploadファイル選択時
  handleFileSelectButtonClick(event){

    var f = event.target.files[0]
    this.setState({
      uploadFileName : f.name,
      deviceListFile : f,
    });

/*
    var r = new FileReader();
    r.onload = function(e) { 
      var contents = e.target.result;
      alert( contents.substr(1, 30));
    }
    r.readAsText(f);
*/

  }


  // uplaodボタン押下時の処理
  handleClickUpload(event){

    const formData = new FormData();
    formData.append('deviceListFile', this.state.deviceListFile);

    // Device検索
    fetch('/api/uploadDeviceList', {
      method: "POST",
      body: formData,
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({
        corporateDataList:responseJson
      });
    })
    .catch((error) =>{
      console.error(error);
    });

  }


  handleEditDialogClose(){

    this.setState({
      showEditDialog:false,
      editDeviceNumber:null
    });

    this.handleClickSearch();

  }


  handleShowHistoryDialogClose(){

    this.setState({
      showHistoryDialog:false,
      showHistoryDeviceNumber:null
    });

  }


  handleRowClick(event){

    if ( event.target.cellIndex === 10 ) {
      let deviceNumber = event.target.children[0].value;
      this.setState({
        showHistoryDialog:true,
        showHistoryDeviceNumber:deviceNumber,
        editDeviceNumber:deviceNumber,
      });
    }
    else if ( event.target.cellIndex === 11 ) {
      let deviceNumber = event.target.children[0].value;
      this.setState({
        showEditDialog:true,
        editDeviceNumber:deviceNumber,
      });
    }

  }


  render() {

    return (
      <div align="center">
        <Menu history={this.props.history}/>
        <ScreenTitle title={this.props.selectedMenuName} />
        <FormControl>
          <FormLabel component="legend" >Device Status</FormLabel>
          <CommonCheckbox name="device_status_param" checkboxValue={const_val.device_status} onCheckedValue={this.handleCheckboxChange}/>
        </FormControl>
        <br />
        <FormControl>
          <FormLabel component="legend" >Location</FormLabel>
          <CommonCheckbox name="location_param" checkboxValue={this.state.location_list} onCheckedValue={this.handleCheckboxChange}/>
        </FormControl>
        <br />
        <MiraiTextField handleChange={this.handleChange}    readOnly={false}   id="searchParamDeviceNumber"  name="searchParamDeviceNumber"  label="Device Number"  value={this.state.searchParamDeviceNumber}  errorText={''} />
        <br />
        <CommonButton visible={true}   clickEvent={this.handleClickSearch}   label="Search"   />
        <br />
        <InputFile fileSelectEvent={this.handleFileSelectButtonClick} uploadFileName={this.state.uploadFileName} handleClickUpload={this.handleClickUpload} />
        <br />
        <DeviceListTable dataRows={this.state.deviceList} rowClickEvent={this.handleRowClick}/>
        <DeviceEditDialog        showEditDialog={this.state.showEditDialog}        handleEditDialogCloseEvent={this.handleEditDialogClose}           editDeviceNumber={this.state.editDeviceNumber} />
        <DeviceRentHistoryDialog history={this.props.history}  showHistoryDialog={this.state.showHistoryDialog}  handleShowHistoryDialogClose={this.handleShowHistoryDialogClose}  showHistoryDeviceNumber={this.state.showHistoryDeviceNumber} />
      </div>
    );
 
  }

}


const mapStateToProps = state => {
  return {
    selectedMenuName : state.menu.selectedMenuName
  };
};


const mapDispatchToProps = dispatch => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceMaster);