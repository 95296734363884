import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MiraiTextField from '../../../common/mirai_component/MiraiTextField';
import CommonButton from '../../../common/mirai_component/CommonButton'
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

class RefundDialog extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loadedData      : false,
      bookingId       : 0,
      refundAmount    : 0,
      refundReason    : '',
      note            : '',
      updatedAt       : '',
      functionName    : 'RegisterRefund',
      errorMsg :{
        refundAmount   : '',
        refundReason   : '',
        note           : '',
      }

    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickRefund = this.handleClickRefund.bind(this);

  }



  handleClose() {
    this.setState({loadedData:false});
    this.props.handleRefundDialogCloseEvent('','');
  }



  // 画面入力項目をthis.stateにバインドする
  handleChange(event){

    const target = event.target;
    const name = target.name;
    this.setState({ [name] : event.target.value});

  }



  componentWillUpdate(nextProps,nextState){

    if ( nextProps.selectedBookingId !=null && !this.state.loadedData ) {

      this.state.loadedData = true;
      this.state.bookingId = nextProps.selectedBookingId;
      this.state.updatedAt = nextProps.updatedAt;

    }

  }



  // 保存ボタン押下時
  handleClickRefund(event) {

    // 保存API
    fetch('/api/registerRefund', {
      method: "POST",
      body: JSON.stringify(this.state), 
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {

      // 成功
      if ( responseJson.error === undefined ) {

        this.setState({loadedData:false});
        this.props.handleRefundDialogCloseEvent('Refund registered','Refund data successfully registered');

      }
      // エラー
      else {

        this.setState({loadedData:false});
        this.props.handleRefundDialogCloseEvent('Save Error','Save failed!!.error message' + responseJson.error);

      }

    })
    .catch((error) =>{
      console.error(error);
    });

  }



  render() {

    const actions = [
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.handleClickRefund}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<SaveIcon />}
      >Refund</Button>,
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={this.handleClose}
        style={{"width":"200px","margin-left":"10px"}}
        startIcon={<CloseIcon />}
      >Close</Button>
    ];

    return (
      <MuiThemeProvider>
          <Dialog
            actions={actions}
            modal={false}
            open={this.props.showRefundDialog}
            onRequestClose={this.handleCancel}
          >
            <DialogTitle id="alert-dialog-slide-title">Refund</DialogTitle>
            <DialogContent className="MyDialogContent">
              <MiraiTextField id="refundAmount"    name="refundAmount"    label="Refund Amount"   handleChange={this.handleChange} readOnly={false} value={this.state.refundAmount}   errorText={this.state.errorMsg.refundAmount} /><br />
              <TextField
                id="refundReason"
                name="refundReason"
                label="Refund Reason"
                value={this.state.refundReason}
                margin="normal"
                onChange={this.handleChange}
                error={this.state.errorMsg.refundReason !== ""}
                helperText={this.state.errorMsg.refundReason}
                multiline
                rows={5}
                rowsMax={10}
                style={{
                  width: "650px"
                }}
              />
              <br />
              <TextField
                id="note"
                name="note"
                label="Note"
                value={this.state.note}
                margin="normal"
                onChange={this.handleChange}
                error={this.state.errorMsg.note !== ""}
                helperText={this.state.errorMsg.note}
                multiline
                rows={5}
                rowsMax={10}
                style={{
                  width: "650px"
                }}
              />
              <br />
            </DialogContent>
          </Dialog>
      </MuiThemeProvider>
    );

  }
}


export default RefundDialog;